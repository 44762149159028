import React from 'react';
import GroupComponent1 from './GroupComponent1';
import GroupComponent from './GroupComponent';
import PropTypes from 'prop-types';

const FrameComponent = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center [row-gap:20px] max-w-full text-left text-29xl text-white font-inter mq1125:flex-wrap ${className}`}
    >
      <img
        className='w-[490px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full mq1125:flex-1'
        loading='lazy'
        alt=''
        src='/caseStudies/Financial-Chatbot/rectangle-3422@2x.png'
      />
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full mq750:pt-[38px] mq750:box-border mq750:min-w-full mq1125:pt-[58px] mq1125:box-border'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#1876d1,_#18233a)] flex flex-col items-start justify-start pt-[42px] pb-[43px] pl-[62px] pr-5 box-border relative gap-4 max-w-full z-[2] mq750:pl-[31px] mq750:box-border'>
            <div className='w-[950px] h-[252px] relative [background:linear-gradient(135deg,_#1876d1,_#18233a)] hidden max-w-full z-[0]' />
            <img
              className='w-12 h-12 absolute !m-[0] top-[calc(50%_-_24px)] right-[38px] overflow-hidden shrink-0 z-[3]'
              loading='lazy'
              alt=''
              src='/caseStudies/Financial-Chatbot/hugeiconsbot.svg'
            />
            <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[3] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
              <p className='m-0'>
                <b>{`Results `}</b>
              </p>
              <p className='m-0'>Which we achieved</p>
            </h1>
            <div className='relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[3] mq450:text-lgi mq450:leading-[28px]'>
              The solutions delivered impactful outcomes
            </div>
          </div>
          <div className='self-stretch bg-darkslategray-300 flex flex-row items-start justify-start py-[75px] px-[62px] box-border max-w-full z-[3] text-xl mq750:pt-[49px] mq750:pb-[49px] mq750:box-border mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
            <img
              className='h-[717px] w-[950px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/Financial-Chatbot/rectangle-3424.svg'
            />
            <div className='w-[738px] flex flex-col items-start justify-start gap-[55px] max-w-full z-[4] mq750:gap-[27px]'>
              <GroupComponent1
                enhancedDataAccess='Enhanced Data Access'
                integratedAPIsProvidedUsers='Integrated APIs provided users with real-time access to comprehensive financial data, improving decision-making by 50%.'
              />
              <GroupComponent1
                enhancedDataAccess='Interactive User Experience'
                integratedAPIsProvidedUsers='The Financial Chatbot increased user engagement and satisfaction, reducing the time spent searching for information by 40%.'
              />
              <GroupComponent
                improvedInvestmentInsights='Improved Investment Insights'
                advancedToolsAndAnalyticsEmpowe='Advanced tools and analytics empowered users to make informed decisions, '
                optimizingTheirPortfoliosAnd='optimizing their portfolios and enhancing investment outcomes by 30%.'
              />
              <GroupComponent
                improvedInvestmentInsights='Up-to-Date Crypto Data'
                advancedToolsAndAnalyticsEmpowe='Users could access current cryptocurrency prices and relevant news, staying '
                optimizingTheirPortfoliosAnd='informed and making timely investment decisions.'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
