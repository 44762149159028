import React from 'react';
import MainContent from './components/MainContent';
import ProblemStatement from './components/ProblemStatement';
import SolutionList from './components/SolutionList';
import FrameComponent from './components/FrameComponent';
import InterfaceDescription from './components/InterfaceDescription';
import FrameComponent1 from './components/FrameComponent1';

const CaseStudiesPortfolioWatch = () => {
  return (
    <div className='w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[87px] box-border gap-[135px] leading-[normal] tracking-[normal] mq750:gap-[67px] mq450:gap-[34px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Portfolio-Watc/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Portfolio-Watc/background.svg'
      />
      <section className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-2.5 box-border max-w-full'>
        <MainContent />
      </section>
      <img
        className='w-full h-[769px] absolute !m-[0] top-[1377px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Portfolio-Watc/workstepbg@2x.png'
      />
      <img
        className='w-[712px] h-[495px] absolute !m-[0] top-[1211px] right-[-48px] rounded-26xl object-cover z-[1]'
        alt=''
        src='/caseStudies/Portfolio-Watc/rectangle-3416@2x.png'
      />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 pb-[100px] pl-5 pr-[26px] box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[1134px] flex flex-col items-end justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[27px] pr-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative text-3xl leading-[40px] mq450:text-lg mq450:leading-[32px]'>
            Portfolio Watch offers users a proactive tool to safeguard their
            investments by continuously monitoring their linked brokerage
            accounts. This helps users stay informed and take necessary actions
            to protect their financial interests.
          </div>
        </div>
      </section>
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[2954px] right-[-187.4px]'
        alt=''
        src='/caseStudies/Portfolio-Watc/group-3051956.svg'
      />
      <ProblemStatement />
      <section className='self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[728px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Solutions
            </h1>
          </div>
          <div className='self-stretch relative text-xl mq450:text-base'>
            Devtrust implemented a comprehensive solution to address these
            challenges
          </div>
        </div>
      </section>
      <SolutionList />
      <FrameComponent />
      <InterfaceDescription />
      <FrameComponent1 />
      <div className='w-5 h-3 relative hidden z-[17]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesPortfolioWatch;
