import React from 'react';
import FrameComponent from './components/FrameComponent';
import FrameComponent1 from './components/FrameComponent1';
import SolutionsList from './components/SolutionsList';
import Results from './components/Results';
import DraydexInterface from './components/DraydexInterface';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import BgImage from '../../../assets/images/BgImage.png';
import Client from '../../../assets/images/client-dry.png';
const CaseStudiesDraydex = () => {
  return (
    <div className=' font-Inter w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[85px] box-border gap-[137px] leading-[normal] tracking-[normal] text-center text-17xl text-[#282828]  font-inter mq450:gap-[34px] mq750:gap-[68px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Draydex/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Draydex/background.svg'
      />
      <img
        className='w-full h-[734px] absolute !m-[0] top-[1457px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Draydex/workstepbg@2x.png'
      />
      <img
        className='w-1/2 h-[495px] absolute !m-[0] top-[1291px] right-[-48px] rounded-26xl object-cover z-[1]'
        loading='lazy'
        alt=''
        src='/caseStudies/Draydex/rectangle-3416@2x.png'
        style={{ borderRadius: '45px' }}
      />
      <section className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-2 box-border max-w-full'>
        <FrameComponent />
      </section>
      <section className=' font-Inter self-stretch flex flex-row items-start justify-center pt-0 pb-[98px] pl-[21px] pr-5 box-border max-w-full text-center text-17xl text-gray-900  mq450:pb-16 mq450:box-border'>
        <div className='w-[949px] flex flex-col items-start justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl text-4xl '>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative  leading-[40px] mq450:text-lg mq450:leading-[32px] font-normal text-xl text-[#282828]'>
            <p className='m-0'>{`Bob Mayo, COO of Freight Management Inc. and Founder & CEO of Draydex, LLC,`}</p>
            <p className='m-0'>{`sought to modernize the rate management system (RMS) for his 3PL brokerage company. `}</p>
            <p className='m-0'>
              Partnering with DEVtrust, Draydex developed an advanced digital
              platform that streamlined
            </p>
            <p className='m-0'>
              rate management operations, improved real-time data access, and
              facilitated
            </p>
            <p className='m-0'>seamless integration with third-party tools.</p>
          </div>
        </div>
      </section>
      <FrameComponent1 />
      <section className=' font-Inter self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-17xl text-[#282828] '>
        <div className='w-[750px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl text-4xl'>
              Solutions
            </h1>
          </div>
          <div className='self-stretch relative text-xl mq450:text-base'>
            DEVtrust implemented several innovative solutions to address these
            challenges
          </div>
        </div>
      </section>
      <SolutionsList />
      <div className=' font-Inter w-[876px] flex flex-row items-start justify-center pt-0 px-5 pb-[63px] box-border max-w-full mq750:pb-[41px] mq750:box-border'>
        <div className='w-[576px] flex flex-col items-start justify-start gap-[50px] max-w-full mq750:gap-[25px]'>
          <h1 className='m-0 w-[322px] relative text-inherit font-semibold font-[inherit] inline-block max-w-full mq450:text-3xl mq1050:text-10xl text-4xl whitespace-nowrap'>
            Product Attributes
          </h1>
          <div className='self-stretch flex flex-col items-start justify-start gap-[22px] max-w-full text-left text-lg'>
            <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-[17px] max-w-full'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <div className='flex flex-col items-start justify-start gap-[116px]'>
                  <input
                    type='checkbox'
                    className="cursor-pointer appearance-none w-6 h-6 border-2 border-gray-300 rounded-full bg-white checked:bg-[#FF9802] checked:border-transparent flex items-center justify-center checked:before:content-['✔'] checked:before:text-white checked:before:text-sm checked:before:leading-none checked:before:flex checked:before:items-center checked:before:justify-center focus:ring-2  "
                  />
                  <div className='flex flex-col items-start justify-start gap-[92px]'>
                    <input
                      type='checkbox'
                      className="cursor-pointer appearance-none w-6 h-6 border-2 border-gray-300 rounded-full bg-white checked:bg-[#FF9802] checked:border-transparent flex items-center justify-center checked:before:content-['✔'] checked:before:text-white checked:before:text-sm checked:before:leading-none checked:before:flex checked:before:items-center checked:before:justify-center focus:ring-2  "
                    />
                    <input
                      type='checkbox'
                      className="cursor-pointer appearance-none w-6 h-6 border-2 border-gray-300 rounded-full bg-white checked:bg-[#FF9802] checked:border-transparent flex items-center justify-center checked:before:content-['✔'] checked:before:text-white checked:before:text-sm checked:before:leading-none checked:before:flex checked:before:items-center checked:before:justify-center focus:ring-2  "
                    />
                  </div>
                </div>
              </div>
              <div className=' font-Inter flex-1 flex flex-col items-start justify-start gap-8 min-w-[348px] max-w-full mq450:min-w-full mq750:gap-4'>
                <div className='flex flex-col items-start justify-start gap-1.5 max-w-full'>
                  <div className='relative leading-[30px] font-semibold'>
                    Quote Listing and Bidding Flow
                  </div>
                  <div className='relative text-base leading-[24px]'>
                    <p className='m-0'>{`Enhanced the process of listing and bidding on the platform, `}</p>
                    <p className='m-0'>{`with brokers adding fees to the final bid price and customers `}</p>
                    <p className='m-0'>choosing the most relevant quote.</p>
                  </div>
                </div>
                <div className='self-stretch flex flex-col items-start justify-start gap-1.5 max-w-full'>
                  <div className='relative leading-[30px] font-semibold inline-block max-w-full'>
                    Administrative Functions and Authorization Levels
                  </div>
                  <div className='relative text-base leading-[24px]'>
                    <p className='m-0'>{`Implemented a super admin role with access to all listings and defined `}</p>
                    <p className='m-0'>
                      different authorization levels for various user roles.
                    </p>
                  </div>
                </div>
                <div className='flex flex-col items-start justify-start gap-1.5 max-w-full'>
                  <div className='relative leading-[30px] font-semibold'>
                    Historical Data and Reporting
                  </div>
                  <div className='relative text-base leading-[24px]'>
                    <p className='m-0'>{`Developed a reporting and dashboard feature for tracking `}</p>
                    <p className='m-0'>{`processed listings and subscriptions, and collecting historical `}</p>
                    <p className='m-0'>
                      data for accurate bids and transaction tracking.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className=' font-Inter flex flex-row items-start justify-start gap-[17px] max-w-full'>
              <div className='flex flex-col items-start justify-start pt-1 px-0 pb-0'>
                <input
                  type='checkbox'
                  className="cursor-pointer appearance-none w-6 h-6 border-2 border-gray-300 rounded-full bg-white checked:bg-[#FF9802] checked:border-transparent flex items-center justify-center checked:before:content-['✔'] checked:before:text-white checked:before:text-sm checked:before:leading-none checked:before:flex checked:before:items-center checked:before:justify-center focus:ring-2  "
                />
              </div>
              <div className='flex flex-col items-start justify-start gap-1.5 max-w-[calc(100%_-_41px)]'>
                <div className='relative leading-[30px] font-semibold'>
                  User Experience Enhancement
                </div>
                <div className='relative text-base leading-[24px]'>
                  <p className='m-0'>{`Focused on revamping the user interface design, navigation, `}</p>
                  <p className='m-0'>{`and overall system performance to ensure a more `}</p>
                  <p className='m-0'>
                    intuitive and efficient user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Results />
      <DraydexInterface />
      <section className='relative flex justify-center items-center isolate overflow-hidden py-20 sm:py-24 bg-[#F1FAFD] w-full font-Inter'>
        <BsChevronLeft className='text-[#282828] text-4xl cursor-pointer ml-16 hover:text-gray-600' />

        <div className='mx-auto max-w-2xl lg:max-w-4xl'>
          <h6 className='text-center font-semibold leading-[19.36px] text-[#282828] text-4xl'>
            Testimonials
          </h6>
          <p className='font-normal text-xl pt-5 text-[#282828] text-center'>
            Let’s see what our client says
          </p>
          <div className='mt-10 pt-8'>
            <img
              alt=''
              src={Client}
              className='mx-auto rounded-full w-[150px] h-[150px]'
            />
          </div>
          <img
            src={BgImage}
            alt='bg'
            className='absolute inset-0 -z-10 w-full'
          />
          <div className='font-medium w-full leading-8 text-xl text-center pt-5 mt-10'>
            {TestimonialData.map((item, index) => (
              <div key={index} className='pb-8'>
                <p className='italic text-[#282828]'>{item.description}</p>
                <div className='mt-8'>
                  <h6 className='font-semibold text-[20px] text-[#282828]'>
                    {item.name}
                  </h6>
                  <p className='text-[#5A5A5A] text-[16px]'>{item.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <BsChevronRight className='text-[#282828] text-4xl cursor-pointer mr-16 hover:text-gray-600' />
      </section>

      <div className='w-5 h-3 relative hidden z-[17]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesDraydex;

export const TestimonialData = [
  {
    name: 'Bob Mayo',
    image: Client,
    description: `"Working with DEVtrust was a game-changer for us.
    Their expertise in developing a modern rate management system not only streamlined our operations but also
    enhanced our competitive edge in the freight industry."`,
    company: `COO - Freight Management, Inc. Founder & CEO - Draydex, LLC`,
  },
];
