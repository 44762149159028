import React from 'react';
import OutcomeIcons from './OutcomeIcons';
import PropTypes from 'prop-types';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-[15px] box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <img
        className='w-[490px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1'
        loading='lazy'
        alt=''
        src='/caseStudies/OZ-Party/rectangle-3422@2x.png'
      />
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full mq750:pt-[38px] mq750:box-border mq750:min-w-full mq1050:pt-[58px] mq1050:box-border'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#fccf31,_#f55555)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 mq750:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
            <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#fccf31,_#f55555)] hidden max-w-full' />
            <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
              <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[1] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
                <p className='m-0'>
                  <b>{`Results `}</b>
                </p>
                <p className='m-0'>Which we achieved</p>
              </h1>
              <div className='relative text-5xl leading-[35px] font-medium z-[1] mq450:text-lgi mq450:leading-[28px]'>
                The solutions delivered impactful outcomes
              </div>
            </div>
            <div className='h-[92px] w-[100px] flex flex-col items-start justify-start'>
              <img
                className='self-stretch h-[18.8px] relative max-w-full overflow-hidden shrink-0 object-cover z-[1]'
                alt=''
                src='/caseStudies/OZ-Party/logoblack-1removebgpreview-1-1@2x.png'
              />
            </div>
          </div>
          <div className='self-stretch bg-darkslategray-200 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[83px] box-border gap-[54.7px] max-w-full z-[1] text-xl mq450:gap-[27px] mq750:pt-[49px] mq750:pb-[54px] mq750:box-border mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
            <img
              className='w-[950px] h-[684px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/OZ-Party/rectangle-3424.svg'
            />
            <div className='w-[738px] flex flex-row items-start justify-start gap-3 max-w-full mq750:flex-wrap'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[2]'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/OZ-Party/bxuparrow@2x.png'
                />
              </div>
              <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
                <b className='relative z-[2] mq450:text-base'>
                  Streamlined Booking Process
                </b>
                <div className='self-stretch relative text-lg leading-[30px] z-[2]'>
                  Hassle-free bookings are now facilitated through our improved
                  platform.
                </div>
              </div>
            </div>
            <OutcomeIcons
              enhancedUserInterface='Enhanced User Interface'
              theAdditionOfUserFriendlyFilte='The addition of user-friendly filters has greatly improved the overall UI/UX for our'
              users='users.'
            />
            <OutcomeIcons
              enhancedUserInterface='Secure Transactions'
              propDisplay='unset'
              theAdditionOfUserFriendlyFilte='The integration of E-Way Payment gateway ensures secure and convenient'
              users='payment transactions.'
            />
            <OutcomeIcons
              enhancedUserInterface='Increased Customer Satisfaction'
              propDisplay='inline-block'
              theAdditionOfUserFriendlyFilte='Live chat support has led to heightened customer satisfaction by providing'
              users='immediate assistance to users during their browsing experience.'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
