import React, { useState } from 'react';
import style from '../carrier.module.css';
import Contact from '../../../assets/socialsvg/Contact.svg';
import { FIELD_REQUIRED } from '../../../redux/constant/ErrorMessage';
import { checkEmail } from '../../../utils/Validation';
import { submitForm } from '../../../utils';
import { setAlert, setLoader } from '../../../redux/Action/actions';
import { useDispatch } from 'react-redux';
const CarrierField = () => {
  const [data, setData] = useState();
  const [validateErr, setValidateErr] = useState({});

  const dispatch = useDispatch();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValidateErr({ ...validateErr, [name]: '' });
    setData({ ...data, [name]: value });
  };

  const isValid = () => {
    let valid = true;
    if (data?.name === undefined || data?.name.trim() === '') {
      validateErr.name = FIELD_REQUIRED;
      valid = false;
    }
    if (data?.email === undefined || data?.email.trim() === '') {
      validateErr.email = FIELD_REQUIRED;
      valid = false;
    } else {
      let { status, msgs } = checkEmail(data?.email);
      if (status !== undefined) {
        valid = status;
        validateErr.email = msgs;
      }
    }
    if (data?.mobile === undefined || data?.mobile.trim() === '') {
      validateErr.mobile = FIELD_REQUIRED;
      valid = false;
    }
    if (data?.comment === undefined || data?.comment.trim() === '') {
      validateErr.comment = FIELD_REQUIRED;
      valid = false;
    }
    setValidateErr({ ...validateErr });
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validateData = isValid();
    if (validateData === true) {
      dispatch(setLoader(true));
      let formdata = new FormData();
      formdata.append('fullname', data?.name);
      formdata.append('email', data?.email);
      formdata.append('comment', data?.comment);
      formdata.append('type', 'address');
      let res = await submitForm(formdata);
      dispatch(setLoader(false));
      if (res.status === 200) {
        dispatch(
          setAlert({ type: 'success', msg: 'Message sent successfully' })
        );
        setData({});
        return;
      }
      dispatch(setAlert({ type: 'danger', msg: 'Somethings Went Wrong.' }));
    }
  };

  return (
    <div>
      <div className={style.backendCarrier} id='touchwithus'>
        <div className='container mx-auto'>
          <h1 className='text-[40px] text-center text-[#282828] font-bold leading-[50.78px] font-Gelasio'>
            Your Career{' '}
            <span className='text-[#43BDC1]'>At Wonders Awaits </span>
          </h1>
          <p className='text-lg m-auto max-w-[937px] text-center font-normal leading-[30px] py-2 font-[Inter] mb-8'>
            A wonderful workplace with exceptional people making a big impact{' '}
          </p>
          <div
            className={` xl:flex-row md:flex-row xl:overflow-visible overflow-hidden flex justify-between flex-col-reverse items-start gap-x-24 `}
          >
            <div
              className='flex-2  xsm:ml-0 xl:flex-[3] md:flex-[3] w-[100%] xsm:flex-2'
              data-aos='fade-down'
            >
              <form
                data-testid='forms'
                onSubmit={(e) => handleSubmit(e)}
                className='mt-5'
              >
                <input
                  name='name'
                  value={data?.name || ''}
                  onChange={(e) => handleChange(e)}
                  field=''
                  placeholder='Your name'
                  className={`${style.input}`}
                ></input>
                {validateErr && (
                  <span className='block	w-[100%] text-red-600	' role='error'>
                    {validateErr?.name}
                  </span>
                )}
                <br />
                <input
                  name='email'
                  value={data?.email || ''}
                  onChange={(e) => handleChange(e)}
                  field=''
                  placeholder='Your email address'
                  className={`${style.input}`}
                ></input>
                {validateErr && (
                  <span className='block	w-[100%] text-red-600	' role='error'>
                    {validateErr?.email}
                  </span>
                )}
                <br />
                <textarea
                  name='comment'
                  value={data?.comment || ''}
                  onChange={(e) => handleChange(e)}
                  field=''
                  placeholder='How can we help you?'
                  className={`${style.textareas}`}
                  rows='6'
                ></textarea>
                {validateErr && (
                  <span className='block	w-[100%] text-red-600	' role='error'>
                    {validateErr?.comment}
                  </span>
                )}
                <br />{' '}
                <div className='flex xl:justify-start xsm:justify-center'>
                  <button
                    role='button'
                    className='py-2.5 px-[25px]	my-10 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
                  >
                    <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                    <span className='relative group-hover:text-white text-lg font-normal font-[Inter] leading-[22px]'>
                      SEND MESSAGE
                    </span>
                  </button>
                </div>
              </form>
            </div>

            <div
              className='xl:relative xsm:static  xl:w-[458px] md:w-[458px] md:flex-[2] w-full xl:flex-[2] xsm:flex-1'
              data-aos='fade-up'
            >
              <img
                src={Contact}
                alt='circle'
                width='500'
                height='500'
                className='w-[534.13px] h-[629px] '
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CarrierField;
