import React from 'react';
import PropTypes from 'prop-types';

const Mobile = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[7.4px] box-border max-w-full text-center text-17xl text-gray-300 font-inter ${className}`}
    >
      <div className='w-[1060px] flex flex-col items-end justify-start gap-[95px] max-w-full lg:gap-[47px] mq750:gap-6'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 box-border max-w-full'>
          <div className='text-[#282828] flex flex-col items-end justify-start gap-[23px] max-w-full'>
            <div className='flex flex-row text-[36px] items-start justify-end py-0 pl-[30px] pr-[27px]'>
              <h1 className='m-0 relative text-inherit font-semibold font-[inherit] mq450:text-3xl mq1050:text-10xl'>
                Quant Capital’s user-friendly Mobile App
              </h1>
            </div>
            <div className='self-stretch relative text-[20px] mq450:text-base'>
              <p className='m-0'>
                Quant Capital provides accessibility to the users to invest
                money on Metatrader4
              </p>
              <p className='m-0'>without taking the pain of market study.</p>
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-20 lg:gap-10 mq750:gap-5'>
          <img
            className='h-[649.6px] flex-1 relative rounded-xl max-w-full overflow-hidden object-cover min-w-[225px]'
            loading='lazy'
            alt=''
            src='/caseStudies/Quant-Capital/login@2x.png'
          />
          <div className='flex-1 flex flex-col items-start justify-start pt-[103px] px-0 pb-0 box-border min-w-[225px] mq750:pt-[67px] mq750:box-border'>
            <img
              className='self-stretch h-[649.6px] relative rounded-xl max-w-full overflow-hidden shrink-0 object-cover'
              loading='lazy'
              alt=''
              src='/caseStudies/Quant-Capital/dashboard@2x.png'
            />
          </div>
          <div className='flex-1 flex flex-col items-start justify-start pt-[206px] px-0 pb-0 box-border min-w-[225px] mq450:pt-[87px] mq450:box-border mq1050:pt-[134px] mq1050:box-border'>
            <img
              className='self-stretch h-[649.6px] relative rounded-xl max-w-full overflow-hidden shrink-0 object-cover'
              loading='lazy'
              alt=''
              src='/caseStudies/Quant-Capital/transactions@2x.png'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Mobile.propTypes = {
  className: PropTypes.string,
};

export default Mobile;
