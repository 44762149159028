import React from 'react';
import PropTypes from 'prop-types';

const GroupComponent = ({
  className = '',
  improvedBookingProcess,
  usersBenefitedFromAStreamlined,
  resultingInA,
  prop,
  increaseInBookings,
}) => {
  return (
    <div
      className={`flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter ${className}`}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
          loading='lazy'
          alt=''
          src='/caseStudies/ATR/bxuparrow@2x.png'
        />
      </div>
      <div className='flex flex-col items-start justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
        <b className='relative mq450:text-base'>{improvedBookingProcess}</b>
        <div className='h-[60px] relative text-lg leading-[30px] inline-block'>
          <p className='m-0'>{usersBenefitedFromAStreamlined}</p>
          <p className='m-0'>
            <span>{resultingInA}</span>
            <span className='font-semibold font-inter text-tomato'>{prop}</span>
            <span className='font-inter text-white'>{increaseInBookings}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
  improvedBookingProcess: PropTypes.string,
  usersBenefitedFromAStreamlined: PropTypes.string,
  resultingInA: PropTypes.string,
  prop: PropTypes.string,
  increaseInBookings: PropTypes.string,
};

export default GroupComponent;
