import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <img
        className='w-[490px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1'
        loading='lazy'
        alt=''
        src='/caseStudies/Air-Concierge/rectangle-3422@2x.png'
      />
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full mq750:pt-[38px] mq750:box-border mq750:min-w-full mq1050:pt-[58px] mq1050:box-border'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#36b3a8,_#094e7e)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 mq750:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
            <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#36b3a8,_#094e7e)] hidden max-w-full' />
            <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
              <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[1] font-inherit mq450:text-10xl mq1050:text-19xl'>
                <p className='m-0'>
                  <b>{`Results `}</b>
                </p>
                <p className='m-0'>Which we achieved</p>
              </h1>
              <div className='relative text-5xl leading-[35px] font-medium z-[1] mq450:text-lgi mq450:leading-[28px]'>
                The solutions delivered impactful outcomes
              </div>
            </div>
            <div className='h-[118px] flex flex-col items-start justify-start'>
              <img
                className='w-[30.7px] h-[70px] relative object-cover z-[1]'
                alt=''
                src='/caseStudies/Air-Concierge/airconcierge-logo-black-1-1@2x.png'
              />
            </div>
          </div>
          <div className='self-stretch bg-darkslategray-200 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[83px] box-border gap-[54.5px] max-w-full text-xl mq750:pt-[49px] mq750:pb-[54px] mq750:box-border mq450:gap-[27px] mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
            <img
              className='w-[950px] h-[711px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/Air-Concierge/rectangle-3424.svg'
            />
            <div className='w-[738px] flex flex-row items-start justify-start gap-3 max-w-full mq750:flex-wrap'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[1]'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Air-Concierge/bxuparrow@2x.png'
                />
              </div>
              <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
                <b className='relative z-[1] mq450:text-base'>
                  Operational Efficiency
                </b>
                <div className='self-stretch relative text-lg leading-[30px] z-[1]'>
                  <p className='m-0'>
                    <span className='font-semibold font-inter'>
                      API Integrations:
                    </span>
                    <span>
                      {' '}
                      Successfully integrated multiple APIs,
                      streamliningoperations and improving user experience.
                    </span>
                  </p>
                  <p className='m-0'>&nbsp;</p>
                  <p className='m-0'>
                    <span className='font-semibold font-inter'>
                      Secure Transactions:
                    </span>
                    <span>
                      {' '}
                      Enhanced security in handling transactions, reducing
                    </span>
                  </p>
                  <p className='m-0'>
                    errors by 40% and increasing user trust.
                  </p>
                </div>
              </div>
            </div>
            <div className='w-[738px] flex flex-row items-start justify-start gap-3 max-w-full mq750:flex-wrap'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[1]'
                  alt=''
                  src='/caseStudies/Air-Concierge/bxuparrow@2x.png'
                />
              </div>
              <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
                <b className='relative inline-block max-w-full z-[1] mq450:text-base'>
                  Increased Property Exposure and Booking Efficiency
                </b>
                <div className='self-stretch relative text-lg leading-[30px] z-[1]'>
                  <p className='m-0'>
                    <span className='font-semibold font-inter'>{`Enhanced Visibility: `}</span>
                    <span>Achieved a 30% increase in property exposure.</span>
                  </p>
                  <p className='m-0 font-semibold'>&nbsp;</p>
                  <p className='m-0'>
                    <span className='font-semibold font-inter'>{`Improved Booking System: `}</span>
                    <span>Implemented a user-friendly booking system,</span>
                  </p>
                  <p className='m-0'>
                    resulting in a 25% increase in completed bookings.
                  </p>
                </div>
              </div>
            </div>
            <div className='w-[738px] flex flex-row items-start justify-start gap-3 max-w-full mq750:flex-wrap'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[1]'
                  alt=''
                  src='/caseStudies/Air-Concierge/bxuparrow@2x.png'
                />
              </div>
              <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
                <b className='relative z-[1] mq450:text-base'>
                  Efficient Contract Management
                </b>
                <div className='self-stretch relative text-lg leading-[30px] z-[1]'>
                  <p className='m-0'>
                    <span className='font-semibold font-inter'>{`E-Signature Solutions: `}</span>
                    <span>
                      Implemented efficient e-signature solutions, reducing
                    </span>
                  </p>
                  <p className='m-0'>contract processing time by 50%.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
