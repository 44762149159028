import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FrameComponent = ({ className = '' }) => {
  return (
    <div
      className={`w-full flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-21xl text-gray-900 font-gelasio ${className}`}
    >
      <div className='w-[1109.5px] flex flex-row flex-wrap items-start justify-start gap-[87.5px] max-w-full mq750:gap-[22px] mq1275:gap-11'>
        <div className='flex-1 flex flex-col items-start justify-start pt-[7.5px] px-0 pb-0 box-border min-w-[331px] max-w-full'>
          <div className='self-stretch flex flex-col items-start justify-start gap-3'>
            <h1 className='m-0 relative text-inherit font-bold font-[inherit] mq450:text-5xl mq750:text-13xl'>
              <p className='m-0 text-black text-5xl'>Case</p>
              <p className='m-0 text-black text-5xl text-[#43bdc1] text-darkturquoise'>
                Studies
              </p>
            </h1>
            <div className='self-stretch flex flex-col items-start justify-start gap-8 text-lg text-slate-700  mq750:gap-4'>
              <div className='self-stretch relative leading-[30px]'>
                Explore our customer success stories to discover how individuals
                similar to you have utilized our services to reach their
                business objectives. Browse through our client case studies,
                customer testimonials, and success stories to gain valuable
                insights into our business process. We appreciate those who have
                shared their experiences with us and invite you to learn from
                their journeys. Enjoy your reading!
              </div>
              <Link
                to={'/get-in-touch'}
                className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
                disableElevation
                variant='contained'
                sx={{
                  textTransform: 'none',
                  color: '#fff',
                  fontSize: '18',
                  background: '#474747',
                  borderRadius: '0px 0px 0px 0px',
                  '&:hover': { background: '#474747' },
                  width: 139,
                  height: 42,
                }}
              >
                Get Quote
              </Link>
            </div>
          </div>
        </div>
        <img
          className='h-[468.2px] flex-1 relative max-w-full w-full overflow-hidden min-w-[333px]'
          loading='lazy'
          alt=''
          src='/caseStudies/landingPage/group-1000001272.svg'
        />
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
