/* eslint-disable react/jsx-key */
import React, { useMemo, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import style from './Qaprocess.module.css';
import icon from '../../../assets/images/isolationMode.png';
import Uxcard from '../../../atoms/card/Uxcard';
import Uihovercard from '../../../atoms/card/Uihovercard';
import uiux from '../../../assets/images/uiux.png';
import secimg from '../../../assets/images/seconimg.png';
import six from '../../../assets/images/invision.png';
import four from '../../../assets/images/third.png';
import five from '../../../assets/images/four.png';
import third from '../../../assets/images/Group1.png';
import figma from '../../../assets/images/figma.png';
import illus from '../../../assets/images/illus.png';
import ps from '../../../assets/images/ps.png';
import spread from '../../../assets/images/adobesss.png';
import adobe from '../../../assets/images/adobe.png';
import Slider from 'react-slick';
import UxComponent from '../uxComponent/UxComponent';
import { Link } from 'react-router-dom';

const Uiux = () => {
  const sliderref = useRef();
  const cardItems = useMemo(() => {
    return data.map((item) => (
      <Uxcard
        title={item.title}
        img={item.img}
        description={item.description}
        key={item.id}
      />
    ));
  }, []);

  const cardItems1 = useMemo(() => {
    return imga.map((item) => (
      <Uihovercard
        title={item.title}
        img={item.img}
        description={item.description}
        key={item.id}
      />
    ));
  }, []);

  useEffect(() => {
    if (sliderref !== undefined) {
      sliderref.current.slickGoTo(4);
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 2000,
    arrows: false,
    cssEase: 'linear',
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className="className=' bg-white xl:py-28 py-24'" data-testid='Uiux'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='qaprocess'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='container mx-auto'>
        <div className=' py-20 flex  flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
          <div className='' data-aos='fade-right'>
            <h1 className='text-[40px]  text-[#282828] font-bold leading-[50.78px] font-Gelasio'>
              DEVtrust &nbsp;
              <span className='text-[#43BDC1]'>
                UI & UX <br /> Solutions
              </span>
            </h1>
            <p className='text-lg xl:w-[400px] w-auto  font-normal leading-[30px] py-2 font-[Inter]'>
              UI represents the visual elements, interactive components, and
              overall look and feel of a product. It encompasses everything a
              user can interact with — buttons, icons, images, typography, color
              schemes, and more.
            </p>
            <p className='text-lg xl:w-[400px] w-auto  font-normal leading-[30px] py-2 font-[Inter]'>
              User Experience (UX) represents the overall experience a user has
              when interacting with a product, system, or service. It
              encompasses various aspects, including usability, accessibility,
              satisfaction, and emotional response.
            </p>
            <div className='flex py-4 flex-col xl:items-start xsm:items-center justify-center'>
              <Link
                to={'/get-in-touch'}
                className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
              >
                <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                <span className='relative group-hover:text-white text-lg font-medium leading-[21px]'>
                  Get Quote
                </span>
              </Link>
            </div>
          </div>
          <div className='p-0'>
            <img src={icon}></img>
          </div>
        </div>
        <div className={style.list}>
          <h1 className='text-[32px] leading-[41px] font-normal font-Gelasio text-center'>
            <strong>
              UI &amp; <span className='text-[#43BDC1]'>UX Process flow</span>{' '}
            </strong>
          </h1>
        </div>
        <div className={style.lista}>
          <h1 className='font-medium font-[Inter] text-[#282828] text-xl leading-[20px] py-6 pb-4 text-center'>
            Our UI &amp; UX Design Services &amp; Capabilities
          </h1>
        </div>
      </div>
      <div className='container mx-auto xl:py-5 xsm:py-5 '>
        <Slider {...settings} ref={sliderref}>
          {cardItems}
        </Slider>
      </div>

      <div className={style.Container}>
        <div className='mt-8'>
          <UxComponent />
          {/* <img src={uilogo} style={{ margin: 'auto' }} /> */}
        </div>
      </div>

      <div className={style.Container}>
        <h1 className={style.Title}>
          Design<span className='text-custom-green ml-2 '>Tools</span>
        </h1>
        <div className='font-[Inter] grid xl:grid-cols-3 xsm:grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-0 my-3 items-center justify-center '>
          {cardItems1}
        </div>
      </div>
    </div>
  );
};
export default Uiux;

const imga = [
  {
    id: 1,
    img: figma,
    description:
      'Figma is a collaborative design tool that operates in the cloud. It allows multiple designers to work simultaneously on the same project in real time.',
  },
  {
    id: 2,
    img: six,
    description:
      'A prototyping and collaboration tool for user interfaces. It allows designers to create interactive prototypes of their designs and share them with others for feedback.',
  },
  {
    id: 3,
    img: adobe,
    description:
      'A vector graphics editor and user interface design tool. It allows designers to create wireframes, prototypes, and other user interface designs.',
  },
  {
    id: 4,
    img: ps,
    description:
      'A raster graphics editor that is used for a variety of tasks, including image editing, photo retouching, and graphic design.',
  },
  {
    id: 5,
    img: illus,
    description:
      'Adobe Illustrator is a vector graphics editor used for creating scalable illustrations and designs. It is used for creating icons, logos, and other vector-based graphics.',
  },
  {
    id: 6,
    img: spread,
    description:
      'Adobe InDesign is primarily a desktop publishing tool, but it is also used for creating layouts for print and digital media.',
  },
];
const data = [
  {
    id: 1,
    img: uiux,
    title: 'Mobile App UX & UI ',
  },
  {
    id: 2,
    img: secimg,
    title: 'Cross-Platform Design',
  },
  {
    id: 3,
    img: third,
    title: 'UI & UX Consulting',
  },
  {
    id: 4,
    img: four,
    title: 'Design Workshops',
  },
  {
    id: 5,
    img: five,
    title: 'Web Design Services',
  },
];
