import { INVALID_EMAIL_MSG } from '../redux/constant/ErrorMessage';
export const checkEmail = (email) => {
  let msg;
  let status;
  let re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email)) {
    msg = INVALID_EMAIL_MSG;
    status = false;
  }
  return { status, msg };
};

export const checkSize = (files) => {
  let size = 0;
  for (let value of files) {
    size += value.size;
  }
  return size;
};
