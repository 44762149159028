import React, { useMemo } from 'react';

const FrameComponent = ({
  className = '',
  propWidth,
  propAlignSelf,
  enhancedOperationalEffici,
  propDisplay,
  improvedDataVisibilityAnd,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      width: propWidth,
      alignSelf: propAlignSelf,
    };
  }, [propWidth, propAlignSelf]);

  const enhancedOperationalEfficiencStyle = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  return (
    <>
      <div
        className={`w-[738px] flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter mq750:flex-wrap ${className}`}
        style={frameDivStyle}
      >
        <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
          <img
            className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
            loading='lazy'
            alt=''
            src='/caseStudies/RankUpAcadmey/bxuparrow@2x.png'
          />
        </div>
        <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
          <b
            className='relative z-[3] mq450:text-base'
            style={enhancedOperationalEfficiencStyle}
          >
            {enhancedOperationalEffici}
          </b>
          <div className='self-stretch relative text-lg leading-[30px] whitespace-pre-wrap z-[3]'>
            {improvedDataVisibilityAnd}
          </div>
        </div>
      </div>
    </>
  );
};

export default FrameComponent;
