import React from 'react';
import Hero from './components/Hero';
import FrameComponent from './components/FrameComponent';
import FrameComponent1 from './components/FrameComponent1';
import FrameComponent2 from './components/FrameComponent2';
import OZPartyInterface from './components/OZPartyInterface';
import FrameComponent3 from './components/FrameComponent3';

const CaseStudiesOZParty = () => {
  return (
    <div className='w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[86px] box-border gap-[131px] leading-[normal] tracking-[normal] mq450:gap-[33px] mq750:gap-[65px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/OZ-Party/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/OZ-Party/background.svg'
      />
      <img
        className='w-full h-[799px] absolute !m-[0] top-[1457px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/OZ-Party/workstepbg@2x.png'
      />
      <img
        className='w-[712px] h-[495px] absolute !m-[0] top-[1291px] right-[-48px] rounded-26xl object-cover z-[1]'
        loading='lazy'
        alt=''
        src='/caseStudies/OZ-Party/rectangle-3416@2x.png'
      />
      <section className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-3.5 box-border max-w-full'>
        <Hero />
      </section>
      <section className='self-stretch flex flex-row items-start justify-center pt-0 pb-[104px] pl-5 pr-[26px] box-border max-w-full text-center text-17xl text-gray-200 font-inter mq450:pb-[68px] mq450:box-border'>
        <div className='w-[1134px] flex flex-col items-end justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[27px] pr-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative text-3xl leading-[40px] mq450:text-lg mq450:leading-[32px]'>
            <p className='m-0'>
              Oz Party Events is Australia&apos;s premier event planning and
              management platform, renowned for its meticulously curated
              selection of seasonal events. Their expert event planners have
              crafted a diverse range of event packages tailored for both local
              attendees and travelers exploring Australia. This case study
              delves into Oz Party&apos;s collaboration with Devtrust to enhance
              their platform with streamlined automations and
            </p>
            <p className='m-0'>
              user-friendly APIs, aiming to elevate the overall event experience
              for their clientele.
            </p>
          </div>
        </div>
      </section>
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[2739px] right-[-187.4px] z-[1]'
        alt=''
        src='/caseStudies/OZ-Party/group-3051956.svg'
      />
      <FrameComponent />
      <FrameComponent1 />
      <FrameComponent2 />
      <OZPartyInterface />
      <FrameComponent3 />
      <div className='w-5 h-3 relative hidden z-[16]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesOZParty;
