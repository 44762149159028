import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const FundManagement = ({
  className = '',
  fundManagement,
  propDisplay,
  endUsersCanEasilyDepositAnd,
}) => {
  const fundManagementStyle = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  return (
    <div
      className={`flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter ${className}`}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
          loading='lazy'
          alt=''
          src='/caseStudies/Quant-Capital/bxuparrow@2x.png'
        />
      </div>
      <div className='flex flex-col items-start justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
        <b
          className='relative z-[3] mq450:text-base'
          style={fundManagementStyle}
        >
          {fundManagement}
        </b>
        <div className='relative text-lg leading-[30px] z-[3]'>
          {endUsersCanEasilyDepositAnd}
        </div>
      </div>
    </div>
  );
};

FundManagement.propTypes = {
  className: PropTypes.string,
  fundManagement: PropTypes.string,
  endUsersCanEasilyDepositAnd: PropTypes.string,

  /** Style props */
  propDisplay: PropTypes.any,
};

export default FundManagement;
