import React from 'react';
import PropTypes from 'prop-types';

const ContentStructure = ({ className = '' }) => {
  return (
    <section
      className={`w-[1395px] flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-16 max-w-full text-center text-45xl text-gray-300 font-inter mq750:gap-8 mq450:gap-4 mq1125:flex-wrap ${className}`}
    >
      <div className='w-[552px] flex flex-col items-start justify-start pt-[230px] px-0 pb-0 box-border min-w-[552px] max-w-full mq750:min-w-full mq450:pt-[149px] mq450:box-border mq1125:flex-1'>
        <div className='self-stretch flex flex-col items-end justify-start gap-7'>
          <h1 className='m-0 self-stretch relative text-inherit font-bold font-[inherit] mq450:text-19xl mq1050:text-32xl'>
            <span>{`Financial `}</span>
            <span className='text-cornflowerblue'>Chatbot</span>
          </h1>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
          </div>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 text-xl text-dimgray-200'>
            <div className='relative leading-[30px] mq450:text-base mq450:leading-[24px]'>
              <p className='m-0'>How Devtrust Enhanced Investment</p>
              <p className='m-0'>Decision-Making for Financial chatbot</p>
            </div>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Financial-Chatbot/mask-group.svg'
      />
    </section>
  );
};

ContentStructure.propTypes = {
  className: PropTypes.string,
};

export default ContentStructure;
