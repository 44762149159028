import React from 'react';
import PropTypes from 'prop-types';

const Admin = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[9px] pl-[21px] pr-5 box-border max-w-full text-center text-17xl text-gray-300 font-inter ${className}`}
    >
      <div className='w-[1139px] flex flex-col items-end justify-start gap-[91px] max-w-full lg:gap-[45px] mq750:gap-[23px]'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5'>
          <h1 className='m-0 relative font-semibold text-[36px] text-[#282828]'>
            Quant Capital’s Admin Portal
          </h1>
        </div>
        <div className='self-stretch h-[1082px] relative'>
          <img
            className='absolute top-[0px] left-[0px] rounded-3xs w-[670px] h-[441px] object-cover'
            alt=''
            src='/caseStudies/Quant-Capital/drayagesoftwaredraydexsolutionconnectquotemanage-1@2x.png'
          />
          <div className='absolute top-[140px] left-[0px] w-[1139px] h-[942px]'>
            <img
              className='absolute top-[0px] left-[469px] rounded-3xs w-[670px] h-[441px] object-cover z-[1]'
              loading='lazy'
              alt=''
              src='/caseStudies/Quant-Capital/draydex-1@2x.png'
            />
            <img
              className='absolute top-[361px] left-[0px] rounded-3xs w-[670px] h-[441px] object-cover z-[2]'
              alt=''
              src='/caseStudies/Quant-Capital/dashbaorddraydex-1@2x.png'
            />
            <img
              className='absolute top-[501px] left-[469px] rounded-3xs w-[670px] h-[441px] object-cover z-[3]'
              loading='lazy'
              alt=''
              src='/caseStudies/Quant-Capital/draydex-2@2x.png'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

Admin.propTypes = {
  className: PropTypes.string,
};

export default Admin;
