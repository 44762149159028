import React from 'react';
import style from './Footer.module.css';
import logo from '../../assets/images/logo.webp';
import facebook from '../../assets/socialpng/facebook.png';
import linkedin from '../../assets/socialpng/linkedin.png';
import insta from '../../assets/socialpng/instagram.png';
import twitter from '../../assets/socialpng/twitter.png';
import location from '../../assets/socialpng/location.png';
import mail from '../../assets/socialpng/mail.png';

import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className={`${style.maindivg}`}>
      <div className={`${style.containersfooterne}`}>
        <div>
          <div className={`${style.maindivfooterr}`}>
            <div className='xl:hidden  w-[100%] flex flex-nowrap md:justify-center justify-between gap-x-10 md:py-0 xsm:py-10'>
              <div>
                <div className={`${style.imgparag}`}>
                  <img
                    className={`${style.image}`}
                    width='215'
                    height='220'
                    src={logo}
                    alt='Workflow'
                  />
                  <p className={`${style.para}`}>
                    Want to start a project? <br />
                    We collaborate with ambitious brand and people
                  </p>
                </div>

                <div className={`${style.imgparag}`}>
                  <p className={`${style.headingsfo}`}>Get in touch</p>
                  <ul className={`${style.ul}`}>
                    <div className='lg:flex md:block gap-14'>
                      <div className={`${style.divnumber}`}>
                        <li className='flex gap-3'>
                          <span className='m-auto'>
                            <img
                              width='18'
                              height='22'
                              src={location}
                              alt='Workflow'
                            />
                          </span>
                          221 E Indianola Ave, Phoenix, Arizona 85012
                        </li>
                        <li className='flex gap-3'>
                          <span className='m-auto'>
                            <img
                              width='18'
                              height='22'
                              src={mail}
                              alt='Workflow'
                            />
                          </span>
                          <Link
                            to='#'
                            onClick={(e) => {
                              window.location.href = 't+91-8447154118';
                              e.preventDefault();
                            }}
                          >
                            <li className='cursor-pointer'>
                              India: +91-8447154118, USA: +1-347-719-4819
                            </li>
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>

                  <div className={`${style.icondivfo}`}>
                    <div>
                      <a
                        href='https://www.facebook.com/DEVtrustllc/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={facebook}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://in.linkedin.com/company/devtrust'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={linkedin}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://www.instagram.com/devtrustllc/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={insta}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://twitter.com/devtrustinc'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={twitter}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='hidden xl:block w-1/2'>
              <div className={`${style.imgparag}`}>
                <img
                  className={`${style.image}`}
                  width='215'
                  height='220'
                  src={logo}
                  alt='Workflow'
                />
                <p className={`${style.para} mb-6`}>
                  Want to start a project? <br />
                  We collaborate with ambitious brand and people
                </p>
                <p>
                  <ul className={`${style.para}`}>
                    <Link
                      to='#'
                      onClick={(e) => {
                        window.location.href = 'mailto:hello@devtrust.biz';
                        e.preventDefault();
                      }}
                    >
                      <li className='cursor-pointer underline underline-offset-8'>
                        <span>{`>`}</span> hello@devtrust.biz
                      </li>
                    </Link>
                  </ul>
                </p>
              </div>
            </div>

            <div className='w-1/2'>
              <div className='hidden xl:block' style={{ paddingLeft: '170px' }}>
                <div className={`${style.imgparag}`}>
                  <p className={`${style.headingsfo}`}>Get In Touch</p>
                  <ul className={`${style.ul}`}>
                    <div className='lg:flex md:block gap-14'>
                      <div className={`${style.divnumber}`}>
                        <li className='flex gap-3'>
                          <span>
                            <img
                              width='18'
                              height='22'
                              src={location}
                              alt='Workflow'
                            />
                          </span>
                          221 E Indianola Ave, Phoenix, Arizona 85012
                        </li>
                        <li className='flex gap-3'>
                          <span className='m-auto'>
                            <img
                              width='18'
                              height='22'
                              src={mail}
                              alt='Workflow'
                            />
                          </span>
                          <Link
                            to='#'
                            onClick={(e) => {
                              window.location.href = 't+91-8447154118';
                              e.preventDefault();
                            }}
                          >
                            <li className='cursor-pointer'>
                              India: +91-8447154118, USA: +1-347-719-4819
                            </li>
                          </Link>
                        </li>
                      </div>
                    </div>
                  </ul>

                  <div className={`${style.icondivfo}`}>
                    <div>
                      <a
                        href='https://www.facebook.com/DEVtrustllc/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={facebook}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://in.linkedin.com/company/devtrust'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={linkedin}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://www.instagram.com/devtrustllc/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={insta}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href='https://twitter.com/devtrustinc'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <img
                          className={`${style.icon}`}
                          src={twitter}
                          alt='Workflow'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <p className={`${style.copyrightsss}`}>
            Copyright {new Date().getFullYear()}. DEVtrust
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
