import React from 'react';

const HeaderImage = ({ data }) => {
  return (
    <div data-aos='fade-left'>
      <img
        alt='headerImage'
        width='800'
        height='450'
        fetchPriority='high'
        src={data}
      />
    </div>
  );
};
export default HeaderImage;
