import React from 'react';
import ChallengeColumns from './ChallengeColumns';
import PropTypes from 'prop-types';

const ProblemStatement = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[92px] pl-5 pr-6 box-border max-w-full text-left text-29xl text-white font-inter mq450:pb-[39px] mq450:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
    >
      <div className='w-[1136px] flex flex-row flex-wrap items-end justify-center gap-[47px] max-w-full mq750:gap-[23px]'>
        <div className='flex-1 flex flex-col items-start justify-start gap-[90px] min-w-[538px] max-w-full mq750:min-w-full mq450:gap-[22px] mq1050:gap-[45px]'>
          <div className='w-[712px] flex flex-row items-start justify-start relative max-w-full'>
            <img
              className='h-[73px] w-[60px] absolute !m-[0] top-[-6px] right-[86px] z-[2]'
              loading='lazy'
              alt=''
            />
            <div className='flex-1 rounded-26xl [background:linear-gradient(135deg,_#e70000,_#3e0000)] flex flex-col items-start justify-start pt-[30px] pb-[82px] pl-[78px] pr-[29px] box-border gap-[14.7px] max-w-full z-[3] mq750:pl-[39px] mq750:box-border mq450:pt-5 mq450:pb-[53px] mq450:box-border'>
              <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#e70000,_#3e0000)] hidden max-w-full' />
              <div className='self-stretch flex flex-row items-start justify-end py-0 px-px'>
                <img
                  className='h-[46.6px] w-[36.2px] relative z-[4]'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Portfolio-Watc/group-1000001359.svg'
                />
              </div>
              <h1 className='m-0 w-[249px] relative text-inherit inline-block z-[4] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
                <p className='m-0'>
                  <b>{`Problem `}</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='h-[70px] relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[4] mq450:text-lgi mq450:leading-[28px]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of develop the application</p>
              </div>
            </div>
          </div>
          <div className='self-stretch flex flex-row items-start justify-start py-0 pl-[3px] pr-0 box-border max-w-full text-center text-13xl'>
            <div className='flex-1 flex flex-row items-start justify-start gap-[47px] max-w-full mq450:gap-[23px] mq1050:flex-wrap'>
              <ChallengeColumns
                emptyChallenge='1'
                needForAn='Need for an'
                interactivePlatform='Interactive Platform'
                usersRequiredAnEngaging='Users required an engaging'
                andInteractivePlatformThat='and interactive platform that'
                enhancesTheirInvestment='enhances their investment'
                monitoringExperience='monitoring experience. '
              />
              <ChallengeColumns
                propBackgroundColor='#c00a0a'
                propBackgroundColor1='#c00a0a'
                emptyChallenge='2'
                propWidth='unset'
                propMinWidth='21px'
                needForAn='Fetching of Stock'
                interactivePlatform='Brokerage Accounts'
                usersRequiredAnEngaging='Seamless integration was'
                andInteractivePlatformThat='needed to fetch and'
                enhancesTheirInvestment='consolidate data from various'
                monitoringExperience='stock brokerage accounts.'
              />
              <ChallengeColumns
                propBackgroundColor='#e43434'
                propBackgroundColor1='#e43434'
                emptyChallenge='3'
                propWidth='22px'
                propMinWidth='22px'
                needForAn='Data Security and'
                interactivePlatform='Fraud Analysis'
                usersRequiredAnEngaging='Need to ensure robust'
                andInteractivePlatformThat='security measures and'
                enhancesTheirInvestment='real-time fraud detection to'
                monitoringExperience='protect user investments.'
              />
            </div>
          </div>
        </div>
        <div className='w-[262px] flex flex-col items-start justify-start gap-10 text-center text-13xl mq450:gap-5'>
          <div className='rounded-3xs bg-tomato flex flex-row items-start justify-start py-[15px] px-[23px] z-[1]'>
            <div className='h-[69px] w-[69px] relative rounded-3xs bg-tomato hidden' />
            <b className='relative inline-block min-w-[22px] z-[1] mq450:text-lgi mq1050:text-7xl'>
              4
            </b>
          </div>
          <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
            <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
              <p className='m-0'>UI/UX</p>
              <p className='m-0'>Enhancement</p>
            </div>
            <div className='self-stretch relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
              Needed to improve user interface and experience to facilitate
              intuitive navigation and accessibility.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProblemStatement.propTypes = {
  className: PropTypes.string,
};

export default ProblemStatement;
