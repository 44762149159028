import React from 'react';
import TopContent from './components/TopContent';
import FrameComponent from './components/FrameComponent';
import StoriesHeader from './components/StoriesHeader';

const CaseStudiesLandingPage = () => {
  return (
    <div className='w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[85px] box-border gap-[60.3px] leading-[normal] tracking-[normal] mq450:gap-[15px] mq750:gap-[30px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[560px] relative max-h-full overflow-hidden object-contain hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/landingPage/6670983-1@2x.png'
      />
      <img
        className='w-[685.8px] h-[660.9px] absolute !m-[0] bottom-[578.5px] left-[-230.5px]'
        alt=''
        src='/caseStudies/landingPage/group-1000001372.svg'
      />
      <div className='w-5 h-3 relative hidden z-[3]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
      <div className='self-stretch flex flex-col items-start justify-start gap-[24.5px] max-w-full'>
        <TopContent />
        <FrameComponent />
      </div>
      <div className='w-5 h-3 relative hidden z-[8]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
      <main className='self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
        <section className='w-[1140px] flex flex-col items-start justify-start gap-[228px] max-w-full mq450:gap-7 mq750:gap-[57px] mq1275:gap-[114px]'>
          <StoriesHeader />
        </section>
      </main>
    </div>
  );
};

export default CaseStudiesLandingPage;
