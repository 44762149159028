import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const MarketplaceItems = ({
  className = '',
  propMinWidth,
  propFlex,
  propBackgroundColor,
  propBackgroundColor1,
  placeholder,
  propWidth,
  propMinWidth1,
  propAlignSelf,
  marketplace,
  propDisplay,
  definingAndImplementingFeatures,
  setTheCoachingMarketplaceApart,
  competitors,
}) => {
  const marketplaceItemsStyle = useMemo(() => {
    return {
      minWidth: propMinWidth,
      flex: propFlex,
    };
  }, [propMinWidth, propFlex]);

  const groupDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const rectangleDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor1,
    };
  }, [propBackgroundColor1]);

  const placeholderStyle = useMemo(() => {
    return {
      width: propWidth,
      minWidth: propMinWidth1,
    };
  }, [propWidth, propMinWidth1]);

  const frameDivStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const marketplaceStyle = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start gap-10 min-w-[231px] max-w-full text-center text-[22px] text-white font-inter mq450:gap-5 ${className}`}
      style={marketplaceItemsStyle}
    >
      <div
        className='rounded-xl w-[69px] h-[69px] bg-deepskyblue-200 flex flex-row items-center justify-center  z-[1]'
        style={groupDivStyle}
      >
        <div
          className='relative rounded-3xs bg-darkslateblue-100 hidden'
          style={rectangleDivStyle}
        />
        <b
          className='text-[32px] relative inline-block z-[1]'
          style={placeholderStyle}
        >
          {placeholder}
        </b>
      </div>
      <div
        className='self-stretch flex flex-col items-start justify-start gap-3 text-left leanding-[43.57px] text-[#282828]'
        style={frameDivStyle}
      >
        <div
          className='relative font-semibold z-[1] mq450:text-lg'
          style={marketplaceStyle}
        >
          {marketplace}
        </div>
        <div className='relative text-[#5A5A5A] font-normal leading-[30px] text-[18px]'>
          <p className='m-0'>{definingAndImplementingFeatures}</p>
          <p className='m-0'>{setTheCoachingMarketplaceApart}</p>
          <p className='m-0'>{competitors}</p>
        </div>
      </div>
    </div>
  );
};

MarketplaceItems.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  marketplace: PropTypes.string,
  definingAndImplementingFeatures: PropTypes.string,
  setTheCoachingMarketplaceApart: PropTypes.string,
  competitors: PropTypes.string,

  /** Style props */
  propMinWidth: PropTypes.any,
  propFlex: PropTypes.any,
  propBackgroundColor: PropTypes.any,
  propBackgroundColor1: PropTypes.any,
  propWidth: PropTypes.any,
  propMinWidth1: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propDisplay: PropTypes.any,
};

export default MarketplaceItems;
