import React from 'react';
import PropTypes from 'prop-types';

const BenefitItems = ({
  className = '',
  efficientManagement,
  streamlinedProposalContract,
}) => {
  return (
    <div
      className={`flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter font-Inter ${className}`}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[4]'
          loading='lazy'
          alt=''
          src='/caseStudies/Billing-Zero/bxuparrow@2x.png'
        />
      </div>
      <div className='flex flex-col items-start justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
        <b className='relative z-[4] mq450:text-base'>{efficientManagement}</b>
        <div className='relative text-lg leading-[30px] z-[4]'>
          {streamlinedProposalContract}
        </div>
      </div>
    </div>
  );
};

BenefitItems.propTypes = {
  className: PropTypes.string,
  efficientManagement: PropTypes.string,
  streamlinedProposalContract: PropTypes.string,
};

export default BenefitItems;
