import React from 'react';
import FrameComponent from './components/FrameComponent';
import FrameComponent1 from './components/FrameComponent1';
import SolutionList from './components/SolutionList';
import FrameComponent2 from './components/FrameComponent2';
import DocumentationProcess from './components/DocumentationProcess';

const CaseStudiesAIAssistant = () => {
  return (
    <div className=' font-Inter w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[85px] box-border gap-[145px] leading-[normal] tracking-[normal] mq450:gap-9 mq750:gap-[72px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/AI-Assistant/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/AI-Assistant/background.svg'
      />
      <img
        className='w-full h-[734px] absolute !m-[0] top-[1417px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/AI-Assistant/workstepbg@2x.png'
      />
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] right-[-187.4px] bottom-[2902.8px]'
        alt=''
        src='/caseStudies/AI-Assistant/group-3051956.svg'
      />
      <section className='self-stretch flex flex-col items-end justify-start max-w-full'>
        <FrameComponent />
      </section>
      <img
        className='w-1/2 h-[495px] absolute !m-[0] top-[1251px] right-[-48px] rounded-26xl object-cover z-[2]'
        loading='lazy'
        alt=''
        src='/caseStudies/AI-Assistant/rectangle-3416@2x.png'
        style={{ borderRadius: '45px' }}
      />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[90px] box-border max-w-full text-center text-17xl text-[#282828] font-inter'>
        <div className='w-[1054px] flex flex-col items-end justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl text-4xl '>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative leading-[40px] mq450:text-lg mq450:leading-[32px] font-normal text-xl'>
            <p className='m-0'>
              The client contacted DEVtrust to build “AI Assistant”, a tool for
              automating the documentation of
            </p>
            <p className='m-0'>physicians with the help of OpenAI.</p>
            <p className='m-0'>
              DEVtrust intended to work on transcribing the conversation between
              the patient and the physician by
            </p>
            <p className='m-0'>
              creating a HIPAA compliant SOAP note (treatment plan).
            </p>
          </div>
        </div>
      </section>
      <img
        className='w-[100px] h-[22.7px] relative object-cover hidden z-[8]'
        alt=''
        src='/caseStudies/AI-Assistant/billingzerologo-1@2x.png'
      />
      <FrameComponent1 />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[5px] box-border max-w-full text-center text-17xl text-[#282828] '>
        <div className='w-[750px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='m-0 w-[164px] relative  font-semibold inline-block mq450:text-3xl mq1050:text-10xl text-4xl	'>
              Solutions
            </h1>
          </div>
          <div className='self-stretch relative text-xl mq450:text-base font-normal'>
            DEVtrust implemented several innovative solutions to address these
            challenges
          </div>
        </div>
      </section>
      <SolutionList />
      <FrameComponent2 />
      <DocumentationProcess />
      <div className='w-5 h-3 relative hidden z-[17]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesAIAssistant;
