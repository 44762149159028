import React from 'react';
import MarketplaceItems from './MarketplaceItems';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[5.375rem] pr-[1.25rem] pl-[1.562rem] box-border max-w-full text-left text-[3rem] text-white font-inter mq450:pb-[2.25rem] mq450:box-border mq1050:pb-[3.5rem] mq1050:box-border ${className}`}
    >
      <div className='w-[67%] flex flex-col items-start justify-start gap-[5.625rem] max-w-full lg:gap-[2.813rem] mq750:gap-[1.375rem]'>
        <div
          style={{ borderRadius: '45px' }}
          className='w-[720px] h-[374px] rounded-26xl [background:linear-gradient(135deg,_#199FC5,#014558)] flex flex-col items-start justify-start pt-[1.875rem] pb-[5.125rem] pr-[1.875rem] pl-[4.875rem] box-border gap-[1rem] max-w-full z-[2] ml-4 mt-16 mq450:pt-[1.25rem] mq450:pb-[3.313rem] mq450:box-border mq750:pl-[2.438rem] mq750:box-border'
        >
          <div className='w-[720px] h-[23.375rem] relative rounded-26xl [background:linear-gradient(135deg,#199FC5,#014558)] hidden max-w-full' />
          <div className='self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq750:flex-wrap'>
            <div className='flex flex-col items-start justify-start pt-[3.75rem] px-[0rem] pb-[0rem] box-border'>
              <h1 className='m-0 text-[48px] self-stretch relative text-inherit z-[3] font-inherit mq450:text-[1.813rem] mq1050:text-[2.375rem]'>
                <p className='m-0 font-bold'>
                  <b>Problem</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='text-[24px] leading-[35px] font-normal z-[3]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of developing the application</p>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-end'>
              <img
                className='h-[48.5px] w-[50px] relative object-cover z-[3]'
                loading='lazy'
                alt=''
                src='/caseStudies/Scheduling/logo-2-2-1@2x.png'
              />
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-start gap-[20px] max-w-full text-center mt-20'>
          <MarketplaceItems
            placeholder='1'
            marketplace='User Role Management'
            propBackgroundColor='#014558'
            propBackgroundColor1='#014558'
            definingAndImplementingFeatures='Handling multiple user roles 
            with distinct permissions was 
            complex and prone to errors.'
            // setTheCoachingMarketplaceApart='The follow-up procedure for the leads
            // was manual because of which there '
            // competitors='were high chances of missing a lead.'
          />
          <MarketplaceItems
            // propMinWidth='253px'
            propFlex='1'
            propBackgroundColor='#036C89'
            propBackgroundColor1='#036C89'
            placeholder='2'
            marketplace='Appointment Scheduling'
            definingAndImplementingFeatures='Managing therapist schedules 
            and ensuring optimal use of 
            their availability was inefficient.'
            // setTheCoachingMarketplaceApart='time-consuming process into different onboarding and
            // payroll systems.'
            // competitors='processing, and user analytics.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#038CB1'
            propBackgroundColor1='#038CB1'
            placeholder='3'
            marketplace='Data Organization'
            definingAndImplementingFeatures='Organizing and accessing data 
            across multiple tables was 
            cumbersome.'
            // setTheCoachingMarketplaceApart='communication channels, and
            // challenges in managing their
            // conditions outside of healthcare facilities.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#04A9D6'
            propBackgroundColor1='#04A9D6'
            placeholder='4'
            marketplace='Communication'
            definingAndImplementingFeatures='Lacked a streamlined 
            messaging solution for group 
            discussions and attachment 
            sharing.'
            // setTheCoachingMarketplaceApart='privacy breaches, underscoring
            // the need for more robust digital solutions.'
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
