import React, { useState } from 'react';
import logo from '../../assets/images/logo.webp';
import flag from '../../assets/images/flag.webp';
import style from './Navbar.module.css';
import HeaderModal from '../../atoms/modal/HeaderModal';
import { Link, NavLink } from 'react-router-dom';
import { navbaritems } from '../../redux/constant/Constant';
import { setScreen } from '../../redux/Action/actions';
import { useDispatch } from 'react-redux';
function NavBar() {
  const [navbar, setNavbar] = useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const dispatch = useDispatch();
  const hideModal = () => {
    window.scrollTo(0, 200);
    document.body.style.overflow = 'unset';
    setShowModal(false);
  };
  const openModal = () => {
    setShowModal(true);
    setNavbar(false);
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  };
  const handleNavigate = () => {
    dispatch(setScreen(0));
    setNavbar(false);
  };

  const handleDropdownToggle = (event) => {
    event.preventDefault();
    setShowDropdown(!showDropdown);
  };

  const handleDropdownItemClick = (link) => {
    setActiveDropdown(link);
    setShowDropdown(false);
  };

  const linkClass = `cursor-pointer ${showDropdown ? 'text-custom-green' : ''}`;

  return (
    <>
      <nav className={`${style.navbar}`}>
        <div className={`${style.navbarMain}`}>
          <div>
            <div className={`${style.navbarLogoMain}`}>
              <div>
                <NavLink to='/'>
                  <img
                    className={`${style.logo}`}
                    width='100'
                    height='100'
                    src={logo}
                    alt='devtrust'
                  />
                </NavLink>
              </div>

              <div className='lg:hidden'>
                <button
                  className={`${style.mobToggleIcon}`}
                  onClick={() => setNavbar(!navbar)}
                >
                  {navbar ? (
                    <svg
                      xmlns='http://www.w3.org/20ser00/svg'
                      className='w-6 h-6 text-dsrblack'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                    >
                      <path
                        fillRule='evenodd'
                        d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      className='w-6 h-6 text-black'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke='currentColor'
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M4 6h16M4 12h16M4 18h16'
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 xl:ml-40 lg:ml-0 lg:block lg:pb-0 lg:mt-0 ${
                navbar
                  ? 'block xl:shadow-none xsm:shadow-lg bg-white mt-[0px] ml-10 xl:mr-0 xsm:-mr-4'
                  : 'hidden'
              }`}
            >
              <ul className={`${style.navList}`}>
                {navbaritems.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.innerDropdown ? (
                      <div className='relative group'>
                        <a
                          href={item.link}
                          onClick={handleDropdownToggle}
                          className={linkClass}
                        >
                          {item.title}
                        </a>
                        {showDropdown && (
                          <div
                            className='absolute bg-white shadow-lg mt-2 rounded-md border border-gray-200'
                            style={{
                              minWidth: '155px',
                              width: '100%',
                              textAlign: 'center',
                              left: '-25px',
                              padding: '8px 10px',
                              borderRadius: ' 0px',
                              top: '34px',
                            }}
                          >
                            <ul className='list-none p-0 m-0'>
                              {item.innerDropdown.map((subItem, subIndex) => (
                                <li key={subIndex}>
                                  <NavLink
                                    className={({ isActive }) =>
                                      `${
                                        isActive ||
                                        activeDropdown === subItem.link
                                          ? 'text-custom-green'
                                          : ''
                                      } block px-4 py-2`
                                    }
                                    to={subItem.link}
                                    onClick={() =>
                                      handleDropdownItemClick(subItem.link)
                                    }
                                  >
                                    {subItem.title}
                                  </NavLink>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    ) : (
                      <NavLink
                        className={({ isActive }) =>
                          isActive ? 'text-custom-green ' : ''
                        }
                        to={item.link}
                        onClick={handleNavigate}
                      >
                        {item.title}
                      </NavLink>
                    )}
                    <hr className='mb-3 xl:hidden lg:hidden t' />
                  </React.Fragment>
                ))}
              </ul>
              <div className='flex flex-col gap-x-4 items-end gap-y-4 w-full xl:hidden lg:hidden p-5'>
                <button
                  onClick={() => {
                    openModal();
                  }}
                  className='py-[6px] px-[10px]	 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
                >
                  <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                  <span className='relative group-hover:text-white text-sm font-normal font-[Inter] leading-[16.94px]'>
                    GET STARTED
                  </span>
                </button>
                <div className='flex items-center gap-x-4'>
                  <img className={`${style.webToggle}`} src={flag} />
                  <div>
                    <Link
                      to='#'
                      className='hover:underline'
                      onClick={(e) => {
                        window.location.href = 'tel:+1-347-719-4819';
                        e.preventDefault();
                      }}
                    >
                      {' '}
                      <h1 className='text-[#282828] xl:text-lg xsm:text-sm'>
                        +1-347-719-4819
                      </h1>
                    </Link>

                    <Link
                      to='#'
                      className='hover:underline'
                      onClick={(e) => {
                        window.location.href = 'hello@devtrust.biz';
                        e.preventDefault();
                      }}
                    >
                      <h3 className='text-[#828282]'>hello@devtrust.biz</h3>{' '}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='space-x-2 hidden lg:block'>
            <div className='flex md:flex-nowrap xsm:flex-wrap gap-x-4 md:gap-x-4 items-center'>
              <button
                onClick={() => openModal()}
                className='py-[6px] px-[10px] relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] block'
              >
                <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                <span className='relative break-normal group-hover:text-white text-sm font-normal font-[Inter] leading-[16.94px]'>
                  GET STARTED
                </span>
              </button>
              <hr className='rotate-180 bg-custom-green w-[1px] h-10'></hr>
              <div className='flex items-start xl:gap-x-2 xsm:gap-0'>
                <img className={`${style.webToggle}`} src={flag} />
                <div>
                  <Link
                    to='#'
                    className='hover:underline'
                    onClick={(e) => {
                      window.location.href = 'tel:+1-347-719-4819';
                      e.preventDefault();
                    }}
                  >
                    {' '}
                    <h1 className='text-[#282828] xl:text-lg xsm:text-sm'>
                      +1-347-719-4819
                    </h1>
                  </Link>
                  <Link
                    to='#'
                    className='hover:underline'
                    onClick={(e) => {
                      window.location.href = 'mailto:hello@devtrust.biz';
                      e.preventDefault();
                    }}
                  >
                    <h3 className='text-[#828282]'>hello@devtrust.biz</h3>{' '}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <HeaderModal showModal={showModal} hideModal={hideModal} />
    </>
  );
}
export default NavBar;
