import React from 'react';
import PropTypes from 'prop-types';

const DraydexInterface = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-0 pb-2.5 pl-[21px] pr-5 box-border max-w-full text-center text-17xl text-[#282828] ${className}`}
    >
      <div className='w-[1139px] flex flex-col items-start justify-start gap-[95px] max-w-full lg:gap-[47px] mq750:gap-6'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
          <div className='w-[677px] flex flex-col items-end justify-start gap-[23px] max-w-full'>
            <div className='flex flex-row items-start justify-end py-0 pl-[53px] pr-[50px] mq750:pl-[26px] mq750:pr-[25px] mq750:box-border'>
              <h1 className='m-0 relative text-inherit font-semibold font-[inherit] mq450:text-3xl mq1050:text-10xl text-4xl'>
                Draydex’s user-friendly interface
              </h1>
            </div>
            <div className='self-stretch relative text-xl mq450:text-base font-normal'>
              Visual insights into the Draydex platform and its transformative
              features
            </div>
          </div>
        </div>
        <div className='self-stretch h-[942px] relative'>
          <img
            className='absolute top-[0px] left-[0px] rounded-3xs w-[670px] h-[441px] object-cover'
            alt=''
            src='/caseStudies/Draydex/drayagesoftwaredraydexsolutionconnectquotemanage-1@2x.png'
          />
          <img
            className='absolute top-[140px] left-[469px] rounded-3xs w-[670px] h-[441px] object-cover z-[1]'
            loading='lazy'
            alt=''
            src='/caseStudies/Draydex/draydex-1@2x.png'
          />
          <img
            className='absolute top-[501px] left-[calc(50%_-_335.5px)] rounded-3xs w-[670px] h-[441px] object-cover z-[2]'
            alt=''
            src='/caseStudies/Draydex/dashbaorddraydex-1@2x.png'
          />
        </div>
      </div>
    </section>
  );
};

DraydexInterface.propTypes = {
  className: PropTypes.string,
};

export default DraydexInterface;
