import { combineReducers } from 'redux';
import buisnessReducer from './buisnessReducer';
import processReducer from './processReducer';
import screenReducer from './screenReducer';
import blogReducer from './blogreducer';
import alertReducer from './alertReducer';
import portfolioReducer from './portfolioReducer';
import loaderReducer from './loaderreducer';
import categoryReducer from './categoryReducer';
export default combineReducers({
  buisnessreducer: buisnessReducer,
  processreducer: processReducer,
  screenreducer: screenReducer,
  blogreducer: blogReducer,
  alertreducer: alertReducer,
  portfolioreducer: portfolioReducer,
  loaderreducer: loaderReducer,
  blogfilterreducer: categoryReducer,
});
