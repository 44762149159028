import React from 'react';

const CaseStudy = ({ className = '' }) => {
  return (
    <div
      className={`w-full flex flex-row items-start justify-between py-0 box-border gap-[0] max-w-full text-center font-inter ${className}`}
    >
      <div className='w-1/2 flex flex-col items-start justify-start pt-[194px] px-0 pb-0 box-border min-w-[493px] max-w-full lg:flex-1 mq750:min-w-full mq450:pt-[126px] mq450:box-border'>
        <div className='self-stretch flex flex-col items-center justify-center gap-[17px]'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[22px] pr-5'>
            <h1 className='m-0 w-[538px] text-[#004F6B] text-[84px] font-bold inline-block mq450:text-4xl mq1050:text-5xl'>
              Real Estate
            </h1>
          </div>
          <div className='relative text-[1.5rem]  mq450  text-[#282828] font-Inter'>
            Transforming Real Estate Management
          </div>
          <div className='self-stretch flex flex-col items-end justify-start gap-[45px] text-5xl text-gray-200 mq750:gap-[22px]'>
            <div className='self-stretch flex flex-col items-end justify-start gap-[38px] text-xl text-dimgray-200 mq750:gap-[19px]'>
              <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5'>
                <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
              </div>
              <div className='self-stretch relative leading-[30px] text-[#5A5A5A] text-[20px] mq450:text-base mq450:leading-[24px]'>
                <p className='m-0'>
                  <span className='font-inter text-[#5A5A5A] font-normal '>{`How `}</span>
                  <b className='font-inter font-bold text-[#5A5A5A] text-xl'>
                    DEVtrust
                  </b>
                  <span className='font-normal text-[#5A5A5A]'>
                    {' '}
                    Enabled Skyward to Digitize Real
                  </span>
                </p>
                <p className='m-0 font-normal text-[#5A5A5A]'>
                  Estate Transactions and Enhance
                </p>
                <p className='m-0 font-normal text-[#5A5A5A]'>
                  Client Experience
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full rounded-bl-[40px] overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Skyward/mask-group@2x.png'
      />
    </div>
  );
};

export default CaseStudy;
