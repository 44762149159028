import React from 'react';
import MarketplaceItems from './MarketplaceItems';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`w-[1428px] flex flex-row items-start justify-center pt-0 px-5 pb-20 box-border max-w-full text-left text-29xl text-white font-inter mq450:pb-[34px] mq450:box-border mq1050:pb-[52px] mr-24 mq1050:box-border ${className}`}
    >
      <div className='flex flex-col items-start justify-start gap-[90px] max-w-full mq450:gap-[22px] mq1050:gap-[45px] mt-6 mr-36'>
        <div className='w-[71.563rem] flex flex-col items-start justify-start gap-[5.625rem] max-w-full lg:gap-[2.813rem] mq750:gap-[1.375rem]'>
          <div
            style={{ borderRadius: '45px' }}
            className='w-[720px] rounded-26xl h-[374px] [background:linear-gradient(135deg,_#FF5757,_#2C5197_60.5%)] flex flex-row items-start justify-between pt-[1.875rem] pb-[5.125rem] pr-[1.875rem] pl-[4.875rem] box-border gap-[1rem] max-w-full z-[2] ml-4 mt-20 mq450:pt-[1.25rem] mq450:pb-[3.313rem] mq450:box-border mq750:pl-[2.438rem] mq750:box-border'
          >
            <div className='flex flex-col items-start justify-start pt-14 px-0 pb-0 box-border max-w-full'>
              <h1 className='text-[48px] relative text-inherit z-[3] font-[inherit]'>
                <p className='m-0'>
                  <b>Problem</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='text-[24px] leading-[35px] font-normal z-[3]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of developing the application</p>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-end'>
              <img
                className='h-[50px] w-[50px] relative z-[5]'
                loading='lazy'
                alt=''
                src='/caseStudies/Coachabilty/group-1000001292.svg'
              />
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-start gap-[42px] max-w-full text-center text-13xl mq450:gap-[18px] mq750:flex-wrap mt-10'>
          <MarketplaceItems
            placeholder='1'
            marketplace='Marketplace'
            propBackgroundColor='#2C5197'
            propBackgroundColor1='#2C5197'
            definingAndImplementingFeatures='Defining and implementing features that '
            setTheCoachingMarketplaceApart='set the coaching marketplace apart from'
            competitors='competitors.'
          />
          <MarketplaceItems
            // propMinWidth='253px'
            propFlex='1'
            propBackgroundColor='#4E75C0'
            propBackgroundColor1='#4E75C0'
            placeholder='2'
            marketplace='Integration and Interoperability'
            definingAndImplementingFeatures='Integrating multiple APIs to enhance platform '
            setTheCoachingMarketplaceApart='functionality, including scheduling, payment '
            competitors='processing, and user analytics.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#7CA2EC'
            propBackgroundColor1='#7CA2EC'
            placeholder='3'
            marketplace='Scaling Operations'
            definingAndImplementingFeatures='Developing and scaling backend infrastructure to accommodate '
            setTheCoachingMarketplaceApart='increased traffic and support future growth effectively.'
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
