import React from 'react';
import CaseStudy from './components/CaseStudy';
import FrameComponent4 from './components/FrameComponent4';
import VerificationSolution from './components/VerificationSolution';
import FrameComponent3 from './components/FrameComponent3';
import FrameComponent2 from './components/FrameComponent2';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import BgImage from '../../../assets/images/BgImage.png';
import SkywardClient from '../../../assets/images/skywardClient.png';
import FrameComponent1 from './components/FrameComponent1';

import './caseSkyward.css';
const CaseStudies = () => {
  return (
    <div className='w-full relative bg-white overflow-hidden flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[5.312rem] box-border  leading-[normal] tracking-[normal] mq450 gap-[8.625rem] mq750:gap-[4.313rem]'>
      <div className='self-stretch h-[25.25rem] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[22.75rem] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Skyward/background-simple.svg'
      />
      <img
        className='w-[54.5rem] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Skyward/background.svg'
      />
      <img
        className='w-[54.525rem] h-[49.325rem] absolute !m-[0] top-[174.938rem] right-[-11.712rem]'
        src='/caseStudies/Skyward/group-3051956.svg'
      />
      <section className='self-stretch flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[0.437rem] box-border max-w-full'>
        <CaseStudy />
      </section>
      <img
        className='w-full h-[800px] absolute !m-[0] top-[88.563rem] right-[0rem] left-[0rem] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Skyward/workstepbg@2x.png'
      />
      <img
        className='w-1/2 h-[30.938rem] absolute !m-[0] top-[78.188rem] right-[-3rem] rounded-26xl object-cover z-[1]'
        loading='lazy'
        alt=''
        src='/caseStudies/Skyward/rectangle-3416@2x.png'
        style={{ borderRadius: '45px' }}
      />
      <section className='self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[6.062rem] pr-[1.25rem] pl-[1.312rem] box-border max-w-full text-center text-[2.25rem]'>
        <div className='w-[67.938rem] flex flex-col items-center justify-start gap-[1.187rem] max-w-full'>
          <div className='self-stretch flex flex-row items-center justify-center py-[0rem] px-[1.25rem]'>
            <h2 className='m-0 w-[13.938rem] relative  inline-block mq450 text-[#282828] mq1050 font-semibold text-4xl font-Inter'>
              Introduction
            </h2>
          </div>
          <div className='relative text-[22px] text-[#282828] mq450  mq450  font-normal leading-10 font-Inter'>
            <p className='m-0 text-nowrap'>
              Skyward identified a significant gap in the real estate industry:
              the heavy reliance on manual processes
            </p>
            <p className='m-0'>
              for property transactions, leading to inefficiencies and client
              dissatisfaction. Partnering with DEVtrust,
            </p>
            <p className='m-0'>
              Skyward revolutionized real estate management by creating a
              digital platform that streamlined
            </p>
            <p className='m-0'>
              operations, improved client verification, and facilitated seamless
              transactions.
            </p>
          </div>
        </div>
      </section>
      <FrameComponent4 />
      <section className=' font-Inter self-stretch flex flex-row items-start justify-center py-[0rem] pr-[1.25rem] pl-[1.312rem] box-border max-w-full text-center text-[2.25rem] text-[#282828]'>
        <div className='w-[46.688rem] flex flex-col items-start justify-start gap-[1.437rem] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-[0rem] pr-[1.312rem] pl-[1.25rem]'>
            <h2 className='m-0 relative font-semibold  font-Inter mq1050 text-4xl '>
              Solutions
            </h2>
          </div>
          <div className='relative text-[1.25rem] mq450:text-[1rem] font-normal font-Inter text-nowrap'>
            DEVtrust implemented several innovative solutions to address these
            challenges
          </div>
        </div>
      </section>
      <VerificationSolution />
      <FrameComponent3 />
      <FrameComponent2 />
      <section className='relative flex justify-center items-center isolate overflow-hidden py-24 sm:py-32 bg-[#F1FAFD] w-full font-Inter'>
        <BsChevronLeft className='text-[#282828] text-4xl cursor-pointer ml-16 hover:text-gray-600' />

        <div className='mx-auto max-w-2xl lg:max-w-4xl'>
          <h6 className='text-center font-semibold leading-[19.36px] text-[#282828] text-4xl'>
            Testimonials
          </h6>
          <p className='font-normal text-xl pt-5 text-[#282828] text-center'>
            Let’s see what our client says
          </p>
          <div className='mt-10 pt-8'>
            <img
              alt=''
              src={SkywardClient}
              className='mx-auto rounded-full w-[155px] h-[155px]'
            />
          </div>
          <img
            src={BgImage}
            alt='bg'
            className='absolute inset-0 -z-10 w-full'
          />
          <div className='font-medium w-full leading-8 text-xl text-center pt-5 mt-10'>
            {TestimonialData.map((item, index) => (
              <div key={index} className='pb-8'>
                <p className='italic text-[#282828]'>{item.description}</p>
                <div className='mt-8'>
                  <h6 className='font-semibold text-[20px] text-[#282828]'>
                    {item.name}
                  </h6>
                  <p className='text-[#5A5A5A] text-[16px]'>{item.company}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <BsChevronRight className='text-[#282828] text-4xl cursor-pointer mr-16 hover:text-gray-600' />
      </section>
      <FrameComponent1 />
      <div className='w-[1.25rem] h-[0.75rem] relative hidden z-[18]'>
        <div className='absolute top-[-0.062rem] left-[0.125rem] box-border w-[1rem] h-[0.125rem] border-t-[2px] border-solid border-gray-200' />
        <div className='absolute top-[0.688rem] left-[0.125rem] box-border w-[1rem] h-[0.125rem] border-t-[2px] border-solid border-gray-200' />
        <div className='absolute top-[0.313rem] left-[-0.062rem] box-border w-[1.375rem] h-[0.125rem] border-t-[2px] border-solid border-gray-200' />
      </div>
    </div>
  );
};

export default CaseStudies;

export const TestimonialData = [
  {
    name: 'JOSIAH HYATT',
    image: SkywardClient,
    description: `"Devtrust has completely transformed our real estate management process.
    Their solutions are intuitive and have significantly reduced our manual workload, 
    allowing us to focus more on our clients."`,
    company: `Founder | Lic. R. E. Associate Broker `,
  },
];
