import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import style from './GetInTouch.module.css';
import Contact from '../../assets/socialpng/contact-img.png';
import downloadfile from '../../assets/socialpng/downloadfile.png';
import cut from '../../assets/images/cut.webp';
import { submitForm } from '../../utils';
import { setAlert, setLoader } from '../../redux/Action/actions';
import Alert from '../alert/alert';
import { useDispatch } from 'react-redux';

const GetInTouch = () => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object({
    fullname: Yup.string(), // Optional
    contact: Yup.string().matches(/^[0-9]+$/, 'Contact must be a number'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    comment: Yup.string()
      .max(100, 'Comment must be at most 100 words')
      .test(
        'wordCount',
        'Comment must be at most 100 words',
        (value) => !value || value.split(' ').length <= 100
      ),
    upload: Yup.array()
      .max(5, 'Only 5 files are allowed')
      .test(
        'fileSize',
        'File size must be less than 5MB',
        (value) => !value || value.every((file) => file.size <= 5242880)
      )
      .test(
        'fileType',
        'Unsupported File Format',
        (value) =>
          !value ||
          value.every((file) =>
            [
              'image/jpeg',
              'image/png',
              'application/msword',
              'application/pdf',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ].includes(file.type)
          )
      ),
  });

  const formik = useFormik({
    initialValues: {
      fullname: '',
      contact: '',
      email: '',
      comment: '',
      upload: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      dispatch(setLoader(true));
      let formdata = new FormData();
      formdata.append('fullname', values.fullname);
      formdata.append('email', values.email);
      formdata.append('contact', values.contact);
      formdata.append('comment', values.comment);

      for (let i = 0; i < values.upload.length; i++) {
        formdata.append('upload[]', values.upload[i]);
      }

      let res = await submitForm(formdata);
      dispatch(setLoader(false));

      if (res.status === 200) {
        dispatch(
          setAlert({ type: 'success', msg: 'Message sent successfully' })
        );
        resetForm();
      } else {
        dispatch(setAlert({ type: 'danger', msg: 'Something went wrong.' }));
      }
    },
  });

  const handleFileUpload = (e) => {
    let files = Array.from(e.target.files);
    let combinedFiles = [...files, ...formik.values.upload];
    formik.setFieldValue('upload', combinedFiles);
    if (combinedFiles.length <= 5) {
      formik.setFieldValue('upload', combinedFiles);
    } else {
      formik.setFieldError('upload', 'Only 5 files allowed');
    }
  };

  const deleteFile = (file) => {
    let updatedFiles = formik.values.upload.filter(
      (item) => item.name !== file.name
    );
    formik.setFieldValue('upload', updatedFiles);
  };

  const toImagePreview = (file) => {
    let fileTypeImage = '';
    switch (true) {
      case file.type.includes('image'):
        fileTypeImage = (
          <img
            className='rounded h-[35px] w-[35px] object-cover shadow p-1'
            src={URL.createObjectURL(file)}
            alt='uploaded'
          />
        );
        break;

      case file.type.includes('pdf'):
        fileTypeImage = (
          <img
            className='rounded h-[35px] w-[35px] object-cover shadow p-1'
            src='https://wpapi.devtrust.biz/wp-content/uploads/2024/08/PDF.png'
            alt='pdf'
          />
        );
        break;

      case file.type.includes('msword'):
        fileTypeImage = (
          <img
            className='rounded h-[35px] w-[35px] object-cover shadow p-1'
            src='https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Doc.png'
            alt='doc'
          />
        );
        break;

      case file.type.includes(
        'vnd.openxmlformats-officedocument.wordprocessingml.document'
      ):
        fileTypeImage = (
          <img
            className='rounded h-[35px] w-[35px] object-cover shadow p-1'
            src='https://wpapi.devtrust.biz/wp-content/uploads/2024/08/Docx.png'
            alt='docx'
          />
        );
        break;

      default:
        fileTypeImage = (
          <img
            className='rounded h-[35px] w-[35px] object-cover shadow p-1'
            src='https://img.icons8.com/ios-glyphs/30/000000/document--v1.png'
            alt='document'
          />
        );
        break;
    }

    // Render the image component
    return fileTypeImage;
  };
  return (
    <div className='bg-white xl:py-10 xl:pb-32 xsm:py-10' id='touchwithus'>
      <div className='container mx-auto xl:py-20 py-20'>
        <div className='flex flex-col xl:flex-row gap-x-20 justify-center xl:justify-start items-start flex-wrap'>
          <h1
            className='xl:text-start font-Gelasio xsm:text-start xl:text-[50px] xsm:text-[32px] font-bold xl:leading-[63px] xsm:leading-[50px]'
            data-aos='fade-down'
          >
            {`Let's `} get in touch <br />
            <span className='text-custom-green font-[Playfair]'>with us</span>
          </h1>
        </div>
        <div
          className={`xl:flex-row md:flex-row xl:overflow-visible overflow-hidden flex justify-between flex-col-reverse items-start gap-x-24 `}
        >
          <div
            className='flex-2 xsm:ml-0 xl:flex-[3] md:flex-[3] w-[100%] xsm:flex-2'
            data-aos='fade-down'
          >
            <form
              data-testid='forms'
              onSubmit={formik.handleSubmit}
              className='mt-5'
            >
              <input
                name='fullname'
                value={formik.values.fullname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Your full name'
                className={`${style.input}`}
              />
              {formik.touched.fullname && formik.errors.fullname ? (
                <span className='block	w-[100%] text-red-600' role='error'>
                  {formik.errors.fullname}
                </span>
              ) : null}
              <br />
              <input
                name='contact'
                value={formik.values.contact}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Contact number'
                className={`${style.input}`}
              />
              {formik.touched.contact && formik.errors.contact ? (
                <span className='block	w-[100%] text-red-600' role='error'>
                  {formik.errors.contact}
                </span>
              ) : null}
              <br />
              <input
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='Your email address'
                className={`${style.input}`}
              />
              {formik.touched.email && formik.errors.email ? (
                <span className='block	w-[100%] text-red-600' role='error'>
                  {formik.errors.email}
                </span>
              ) : null}
              <br />
              <textarea
                name='comment'
                value={formik.values.comment}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder='How can we help you?'
                className={`${style.textareas}`}
                rows='6'
              ></textarea>
              {formik.touched.comment && formik.errors.comment ? (
                <span className='block	w-[100%] text-red-600' role='error'>
                  {formik.errors.comment}
                </span>
              ) : null}
              <br />
              <div className='mb-4'>
                <div className='rounded w-full text-[#c4c4c4] leading-tight focus:outline-none focus:shadow-outline flex items-center justify-between'>
                  <div className='flex gap-x-1 px-2'>
                    {formik.values.upload.length > 0
                      ? formik.values.upload.map((file, index) => {
                          return (
                            <div key={index} className='flex items-start'>
                              {toImagePreview(file)}
                              <img
                                className='-mt-[4px] -ml-3 h-4 cursor-pointer text-red-600'
                                onClick={() => deleteFile(file)}
                                src={cut}
                                alt='delete'
                              ></img>
                            </div>
                          );
                        })
                      : 'Upload Your Documents'}
                  </div>
                  <label>
                    <a className='text-[#808080] border border-[#D9D9D9] cursor-pointer w-auto xl:w-[169px] xl:text-lg text-sm leading-[21.78px] font-medium px-5 py-3 text-center flex justify-center items-center font-[Inter]'>
                      <img
                        className='mr-2 -ml-1 w-[32px] h-[32px]'
                        src={downloadfile}
                        alt='upload'
                      ></img>
                      Upload
                    </a>
                    <input
                      onChange={handleFileUpload}
                      name='file'
                      type='file'
                      accept='image/*,.doc,.pdf,.docx'
                      placeholder='Upload Your File'
                      className='hidden'
                      multiple
                    />
                  </label>
                </div>
              </div>
              {formik.errors.upload && (
                <span className='text-end block w-[100%] text-red-600'>
                  {formik.errors.upload}
                </span>
              )}
              <p className='font-normal leading-[24px] text-[16px] font-[Inter] pt-0 text-[#828282] text-right'>
                (jpg, png, doc, docx, pdf | Max.: 5Mb)
              </p>
              <div className='flex xl:justify-start xsm:justify-center'>
                <button
                  type='submit'
                  className='py-2.5 px-[25px]	my-10 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
                >
                  SEND MESSAGE
                </button>
              </div>
            </form>
          </div>
          <div
            className='flex-2 mb-5 md:flex-[2] xsm:flex-[2] xsm:ml-0 xl:flex-[2] w-[100%] xl:h-[100%] xsm:h-[300px] mt-5 xl:mt-0'
            data-aos='fade-up'
          >
            <img
              src={Contact}
              alt='contact'
              className='xl:relative md:relative xsm:relative xl:ml-auto xl:mr-auto w-[100%] xl:h-[100%]'
            />
          </div>
        </div>
      </div>
      <Alert />
    </div>
  );
};

export default GetInTouch;
