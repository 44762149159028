import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const InstantBooking = ({
  className = '',
  propWidth,
  propAlignSelf,
  propGap,
  propWidth1,
  group,
  instantBookNowTechnology,
  enabledInstantDrayagePricing,
  immediateRateQuotes,
}) => {
  const instantBookingStyle = useMemo(() => {
    return {
      width: propWidth,
      alignSelf: propAlignSelf,
    };
  }, [propWidth, propAlignSelf]);

  const instantBookingContentStyle = useMemo(() => {
    return {
      gap: propGap,
    };
  }, [propGap]);

  const bookingIconStyle = useMemo(() => {
    return {
      width: propWidth1,
    };
  }, [propWidth1]);

  return (
    <div
      className={`font-Inter w-[90%] flex flex-col items-start justify-start pt-0 px-5 pb-2.5 box-border text-left text-xl text-[#282828] ${className}`}
      style={instantBookingStyle}
    >
      <div
        className='flex flex-row items-start justify-start gap-20 w-full'
        style={instantBookingContentStyle}
      >
        {/* Image/Icon */}
        <div
          className='w-[60px] flex-shrink-0 flex items-start justify-start'
          style={bookingIconStyle}
        >
          <img className='w-full h-auto' loading='lazy' alt='' src={group} />
        </div>

        {/* Text Content */}
        <div className='flex flex-col items-start justify-start gap-2'>
          <b className='text-lg'>{instantBookNowTechnology}</b>
          <div className='text-base leading-[24px]'>
            <p className='m-0'>{enabledInstantDrayagePricing}</p>
            <p className='m-0'>{immediateRateQuotes}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

InstantBooking.propTypes = {
  className: PropTypes.string,
  group: PropTypes.string,
  instantBookNowTechnology: PropTypes.string,
  enabledInstantDrayagePricing: PropTypes.string,
  immediateRateQuotes: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propGap: PropTypes.any,
  propWidth1: PropTypes.any,
};

export default InstantBooking;
