import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const PaymentBenefitDetails = ({
  className = '',
  propWidth,
  propGap,
  propWidth1,
  propPadding,
  group1000001339,
  seamlessPaymentIntegration,
  propDisplay,
  integrationOfTheEWayPayment,
  transactionsDirectlyThrough,
}) => {
  const paymentBenefitDetailsStyle = useMemo(() => {
    return {
      width: propWidth,
      gap: propGap,
    };
  }, [propWidth, propGap]);

  const paymentBenefitIconStyle = useMemo(() => {
    return {
      width: propWidth1,
      padding: propPadding,
    };
  }, [propWidth1, propPadding]);

  const seamlessPaymentIntegrationStyle = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  return (
    <div
      className={`w-[808px] flex flex-row flex-wrap items-end justify-start gap-[41px] max-w-full text-left text-3xl text-gray-200 font-inter mq450:gap-5 ${className}`}
      style={paymentBenefitDetailsStyle}
    >
      <div
        className='h-[79px] w-20 flex flex-col items-start justify-end pt-0 px-0 pb-[18.8px] box-border'
        style={paymentBenefitIconStyle}
      >
        <img
          className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full'
          loading='lazy'
          alt=''
          src={group1000001339}
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start gap-[11px] min-w-[447px] max-w-full mq750:min-w-full'>
        <div
          className='relative font-semibold inline-block max-w-full z-[2] mq450:text-lg'
          style={seamlessPaymentIntegrationStyle}
        >
          {seamlessPaymentIntegration}
        </div>
        <div className='self-stretch relative text-lg leading-[30px] z-[2]'>
          <p className='m-0'>{integrationOfTheEWayPayment}</p>
          <p className='m-0'>{transactionsDirectlyThrough}</p>
        </div>
      </div>
    </div>
  );
};

PaymentBenefitDetails.propTypes = {
  className: PropTypes.string,
  group1000001339: PropTypes.string,
  seamlessPaymentIntegration: PropTypes.string,
  integrationOfTheEWayPayment: PropTypes.string,
  transactionsDirectlyThrough: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propGap: PropTypes.any,
  propWidth1: PropTypes.any,
  propPadding: PropTypes.any,
  propDisplay: PropTypes.any,
};

export default PaymentBenefitDetails;
