import React from 'react';
import ChallengeItem from './ChallengeItem';
import PropTypes from 'prop-types';

const FrameComponent = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center py-0 pl-5 pr-[26px] box-border max-w-full text-left text-29xl text-white font-inter ${className}`}
    >
      <div className='w-[1134px] flex flex-col items-start justify-start gap-[90px] max-w-full lg:gap-[45px] mq750:gap-[22px]'>
        <div className='w-[712px] rounded-26xl [background:linear-gradient(135deg,_#fccf31,_#f55555)] flex flex-col items-end justify-start pt-[30px] px-[30px] pb-[82px] box-border relative gap-[41.2px] max-w-full z-[2] mq450:pt-5 mq450:pb-[53px] mq450:box-border mq750:gap-[21px]'>
          <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#fccf31,_#f55555)] hidden max-w-full z-[0]' />
          <img
            className='w-[100px] h-[18.8px] relative object-cover z-[1]'
            alt=''
            src='/caseStudies/OZ-Party/logoblack-1removebgpreview-1-1@2x.png'
          />
          <div className='self-stretch flex flex-row items-start justify-start py-0 px-12 box-border max-w-full mq750:pl-6 mq750:pr-6 mq750:box-border'>
            <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
              <h1 className='m-0 w-[249px] relative text-inherit inline-block z-[1] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
                <p className='m-0'>
                  <b>{`Problem `}</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='h-[70px] relative text-5xl leading-[35px] font-medium inline-block z-[1] mq450:text-lgi mq450:leading-[28px]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of develop the application</p>
              </div>
            </div>
          </div>
          <img
            className='w-[60px] h-[73px] absolute !m-[0] top-[-6px] right-[86px] z-[3]'
            alt=''
          />
        </div>
        <div className='self-stretch flex flex-col items-end justify-start gap-[23px] max-w-full text-center text-13xl'>
          <div className='self-stretch flex flex-row flex-wrap items-start justify-start gap-[26px] max-w-full'>
            <ChallengeItem
              bookingTitle='1'
              bookingAnd='Booking and '
              schedulingIssues='Scheduling Issues'
              usersEncounteredDifficulties='Users encountered difficulties '
              inBookingEventsForFuture='in booking events for future '
              datesAndExperiencedIssuesWith='dates and experiencedissues with the scheduling '
              aPI='API.'
            />
            <div className='flex-1 flex flex-col items-end justify-start py-0 pl-0 pr-[13px] box-border gap-[257px] min-w-[363px] max-w-full text-17xl text-gray-200 mq450:gap-16 mq450:min-w-full mq750:gap-32'>
              <div className='self-stretch flex flex-row items-start justify-start gap-[43px] mq750:flex-wrap mq750:gap-[21px]'>
                <ChallengeItem
                  propWidth='unset'
                  propMinWidth='244px'
                  propFlex='unset'
                  propBackgroundColor='#f6704d'
                  propBackgroundColor1='#f6704d'
                  bookingTitle='1'
                  propWidth1='16px'
                  propMinWidth1='16px'
                  propAlignSelf='unset'
                  bookingAnd='Lack of Filtering '
                  schedulingIssues='Options'
                  usersEncounteredDifficulties='Users were unable to filter '
                  inBookingEventsForFuture='event packages according '
                  datesAndExperiencedIssuesWith='to their specific preferences '
                  aPI='and needs.'
                />
                <ChallengeItem
                  propWidth='unset'
                  propMinWidth='168px'
                  propFlex='1'
                  propBackgroundColor='#f77b49'
                  propBackgroundColor1='#f77b49'
                  bookingTitle='2'
                  propWidth1='unset'
                  propMinWidth1='21px'
                  propAlignSelf='stretch'
                  bookingAnd='Limited Payment '
                  schedulingIssues='Options'
                  usersEncounteredDifficulties='The platform offered only a '
                  inBookingEventsForFuture='restricted number of payment '
                  datesAndExperiencedIssuesWith='methods, lacking online'
                  aPI='payment capabilities.'
                />
              </div>
              <div className='w-[526px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
                <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
                  Solutions
                </h1>
              </div>
            </div>
            <ChallengeItem
              propWidth='unset'
              propMinWidth='unset'
              propFlex='unset'
              propBackgroundColor='#f88e44'
              propBackgroundColor1='#f88e44'
              bookingTitle='3'
              propWidth1='22px'
              propMinWidth1='22px'
              propAlignSelf='unset'
              bookingAnd='Insufficient '
              schedulingIssues='User Support'
              usersEncounteredDifficulties='Users faced challenges in '
              inBookingEventsForFuture='obtaining real-time assistance '
              datesAndExperiencedIssuesWith='while navigating the platform '
              aPI='and addressing queries.'
            />
          </div>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[26px] pr-5 text-xl text-gray-200'>
            <div className='w-[728px] relative inline-block mq450:text-base'>
              Devtrust implemented a comprehensive solution to address these
              challenges
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
