import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const AutomatedContent = ({
  className = '',
  propPadding,
  group,
  automatedDocumentation,
  propDisplay,
  propMinWidth,
  aIAssistantCanCreateDocumentati,
  medicalDetailsAutomatically,
}) => {
  const automatedIconStyle = useMemo(() => {
    return {
      padding: propPadding,
    };
  }, [propPadding]);

  const automatedDocumentationStyle = useMemo(() => {
    return {
      display: propDisplay,
      minWidth: propMinWidth,
    };
  }, [propDisplay, propMinWidth]);

  return (
    <div
      className={` font-Inter flex flex-row items-start justify-end py-0 px-[45px] box-border max-w-full text-left  font-inter text-xl text-[#282828]${className}`}
    >
      <div className='flex flex-row items-start justify-start gap-[57px] max-w-full mq450:gap-7 mq1050:flex-wrap'>
        <div
          className='h-[84.9px] w-[60px] flex flex-col items-start justify-start pt-[13px] px-0 pb-0 box-border'
          style={automatedIconStyle}
        >
          <img
            className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full'
            loading='lazy'
            alt=''
            src={group}
          />
        </div>
        <div className='flex flex-col items-start justify-start gap-3.5 max-w-full'>
          <b
            className='relative mq450:text-base'
            style={automatedDocumentationStyle}
          >
            {automatedDocumentation}
          </b>
          <div className='relative text-lg leading-[30px]'>
            <p className='m-0'>{aIAssistantCanCreateDocumentati}</p>
            <p className='m-0'>{medicalDetailsAutomatically}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

AutomatedContent.propTypes = {
  className: PropTypes.string,
  group: PropTypes.string,
  automatedDocumentation: PropTypes.string,
  aIAssistantCanCreateDocumentati: PropTypes.string,
  medicalDetailsAutomatically: PropTypes.string,

  /** Style props */
  propPadding: PropTypes.any,
  propDisplay: PropTypes.any,
  propMinWidth: PropTypes.any,
};

export default AutomatedContent;
