import React from 'react';
import PropTypes from 'prop-types';

const GroupComponent2 = ({ className = '' }) => {
  return (
    <div
      className={`w-full !m-[0] absolute right-[0px] bottom-[0px] left-[0px] flex flex-row flex-wrap items-start justify-center opacity-[0.6] mix-blend-luminosity max-w-full z-[1] text-center text-xl text-gray-200 font-inter ${className}`}
    >
      <div className='flex-1 flex flex-row items-start justify-start min-w-[267px] max-w-full [row-gap:20px] mq750:flex-wrap'>
        <img
          className='h-[205px] w-[205.7px] relative object-cover min-h-[205px] shrink-0'
          loading='lazy'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar@2x.png'
        />
        <img
          className='h-[205px] w-[205.7px] relative object-cover min-h-[205px] shrink-0'
          loading='lazy'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar-1@2x.png'
        />
      </div>
      <img
        className='h-[205px] w-[205.7px] relative object-cover min-h-[205px]'
        loading='lazy'
        alt=''
        src='/caseStudies/Financial-Chatbot/bg-sidebar-3@2x.png'
      />
      <div className='flex flex-row items-start justify-start relative'>
        <img
          className='h-[205px] w-[205.7px] relative object-cover'
          loading='lazy'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar-2@2x.png'
        />
        <div className='w-[222px] absolute !m-[0] right-[-8.2px] bottom-[50px] leading-[30px] font-medium inline-block z-[2]'>
          <p className='m-0'>Tyler Gall</p>
          <p className='m-0 text-base text-dimgray-200'>
            Founder of Financial Chatbot
          </p>
        </div>
      </div>
      <img
        className='h-[205px] w-[205.7px] relative object-cover min-h-[205px]'
        alt=''
        src='/caseStudies/Financial-Chatbot/bg-sidebar-4@2x.png'
      />
      <div className='h-[205px] flex-1 relative min-w-[267px] max-w-full'>
        <img
          className='absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[205.7px] object-cover'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar-5@2x.png'
        />
        <img
          className='absolute h-full top-[0px] bottom-[0px] left-[205.7px] max-h-full w-[205.7px] object-cover z-[1]'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar-6@2x.png'
        />
      </div>
    </div>
  );
};

GroupComponent2.propTypes = {
  className: PropTypes.string,
};

export default GroupComponent2;
