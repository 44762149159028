import React from 'react';
import ContentStructure from './components/ContentStructure';
import MainContent from './components/MainContent';
import FrameComponent from './components/FrameComponent';
import FrameComponent1 from './components/FrameComponent1';
import TestimonialContent from './components/TestimonialContent';
import TestimonialContent1 from './components/TestimonialContent1';
import GroupComponent2 from './components/GroupComponent2';
import ChatbotBenefits from './components/ChatbotBenefits';

const CaseStudiesFinancialChatbo = () => {
  return (
    <div className='w-full relative bg-white overflow-hidden flex flex-col items-end justify-start pt-20 px-0 pb-[85px] box-border gap-[145px] leading-[normal] tracking-[normal] mq750:gap-[72px] mq450:gap-9'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Financial-Chatbot/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Financial-Chatbot/background.svg'
      />
      <ContentStructure />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 pb-[90px] pl-5 pr-[26px] box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[1134px] flex flex-col items-end justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[27px] pr-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative text-3xl leading-[40px] mq450:text-lg mq450:leading-[32px]'>
            Financial chatbot, a cutting-edge platform designed for investors,
            aimed to provide tools and insights to navigate fast-paced financial
            markets. Facing the need for a robust, data-driven solution,
            Financial chatbot partnered with Devtrust to develop a comprehensive
            platform that integrates financial data and empowers users to make
            informed investment decisions.
          </div>
        </div>
      </section>
      <MainContent />
      <FrameComponent />
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[2739px] right-[-187.4px] z-[1]'
        alt=''
        src='/caseStudies/Financial-Chatbot/group-3051956.svg'
      />
      <section className='w-full !m-[0] absolute top-[1417px] left-[0px] flex flex-row items-start justify-start max-w-full text-left text-lg text-dimgray-200 font-inter'>
        <div className='absolute !m-[0] right-[189px] bottom-[142px] leading-[30px]'>
          <p className='m-0'>{`Ensuring up-to-date `}</p>
          <p className='m-0'>{`cryptocurrency prices and `}</p>
          <p className='m-0'>{`relevant financial news `}</p>
          <p className='m-0'>{`were readily available to `}</p>
          <p className='m-0'>users.</p>
        </div>
        <img
          className='h-[859px] flex-1 relative max-w-full overflow-hidden object-cover z-[2]'
          alt=''
          src='/caseStudies/Financial-Chatbot/workstepbg@2x.png'
        />
        <div className='absolute !m-[0] right-[293px] bottom-[305px] text-3xl leading-[30px] font-semibold text-gray-200 z-[3] mq450:text-lg mq450:leading-[24px]'>
          <p className='m-0'>{`Investment `}</p>
          <p className='m-0'>Insights</p>
        </div>
      </section>
      <img
        className='w-[712px] h-[495px] absolute !m-[0] top-[1251px] right-[-48px] rounded-26xl object-cover z-[3]'
        alt=''
        src='/caseStudies/Financial-Chatbot/rectangle-3416@2x.png'
      />
      <FrameComponent1 />
      <section className='bg-aliceblue flex flex-col items-start justify-end py-[157px] px-[231px] box-border relative min-h-[615px] max-w-full text-center text-xl text-gray-200 font-inter mq750:pl-[115px] mq750:pr-[115px] mq750:box-border mq450:py-[102px] mq450:px-5 mq450:box-border'>
        <div className='w-[1440px] h-[615px] relative bg-aliceblue hidden max-w-full z-[0]' />
        <div className='w-full !m-[0] absolute top-[0px] right-[0px] left-[0px] flex flex-col items-start justify-start opacity-[0.6] mix-blend-luminosity max-w-full z-[1]'>
          <TestimonialContent />
          <TestimonialContent1 />
        </div>
        <GroupComponent2 />
        <i className='h-[90px] relative leading-[30px] inline-block font-medium z-[2] mq450:text-base mq450:leading-[24px]'>
          <p className='m-0'>{`Devtrust has significantly enhanced our platform's functionality and user experience. `}</p>
          <p className='m-0'>
            Their expertise in integrating financial data and developing
            intuitive tools has empowered our users to
          </p>
          <p className='m-0'>make informed investment decisions.</p>
        </i>
      </section>
      <ChatbotBenefits />
      <div className='w-5 h-3 relative hidden z-[16]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
      <div className='w-5 h-3 relative hidden z-[18]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesFinancialChatbo;
