// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OurProcess_headetitle__WSbc9 {\n    margin-top: auto;\n    padding-top: 1.25rem;\n    padding-bottom: 1.25rem;\n    text-align: center;\n    font-family: Gelasio, serif;\n    font-size: 2.25rem;\n    line-height: 2.5rem;\n    font-weight: 900;\n    letter-spacing: 1px;\n    --tw-text-opacity: 1;\n    color: rgb(40 40 40 / var(--tw-text-opacity))\n}\n@media (min-width: 320px) {\n    .OurProcess_headetitle__WSbc9 {\n        line-height: 40px\n    }\n}\n@media (min-width: 1280px) {\n    .OurProcess_headetitle__WSbc9 {\n        margin-top: 2rem;\n        font-size: 44px;\n        line-height: 30px\n    }\n}\n.OurProcess_midimage__XUlfC img {\n    margin-top: 4rem;\n    margin-bottom: 4rem;\n    width: 100%;\n    object-fit: contain\n}\n", "",{"version":3,"sources":["webpack://./src/container/ourprocess/OurProcess.module.css"],"names":[],"mappings":"AACE;IAAA,gBAA0J;IAA1J,oBAA0J;IAA1J,uBAA0J;IAA1J,kBAA0J;IAA1J,2BAA0J;IAA1J,kBAA0J;IAA1J,mBAA0J;IAA1J,gBAA0J;IAA1J,mBAA0J;IAA1J,oBAA0J;IAA1J;AAA0J;AAA1J;IAAA;QAAA;IAA0J;AAAA;AAA1J;IAAA;QAAA,gBAA0J;QAA1J,eAA0J;QAA1J;IAA0J;AAAA;AAG1J;IAAA,gBAAkC;IAAlC,mBAAkC;IAAlC,WAAkC;IAAlC;AAAkC","sourcesContent":[".headetitle {\n  @apply text-center py-5 xl:mt-8 mt-auto font-black font-Gelasio text-[#282828] xl:leading-[30px] xsm:leading-[40px] text-4xl xl:text-[44px] tracking-[1px];\n}\n.midimage img {\n  @apply w-full object-contain my-16;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headetitle": "OurProcess_headetitle__WSbc9",
	"midimage": "OurProcess_midimage__XUlfC"
};
export default ___CSS_LOADER_EXPORT___;
