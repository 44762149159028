import React, { useMemo } from 'react';

const SolutionList = ({
  className = '',
  propFlex,
  propMinWidth,
  propBackgroundColor,
  propBackgroundColor1,
  solutionOneTitle,
  propWidth,
  propMinWidth1,
  propAlignSelf,
  seamlessIntegrationAnd,
  userFriendlyDesign,
  developingAPlatformWithDynamic,
  featuresLikeAudioEnhancedVocabu,
  animatedStorybooksAndInteractiv,
  globeAnimationsRequiredSeamless,
  integrationAndAUserFriendly,
}) => {
  const solutionListStyle = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
    };
  }, [propFlex, propMinWidth]);

  const groupDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const rectangleDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor1,
    };
  }, [propBackgroundColor1]);

  const solutionOneTitleStyle = useMemo(() => {
    return {
      width: propWidth,
      minWidth: propMinWidth1,
    };
  }, [propWidth, propMinWidth1]);

  const solutionOneDescriptionStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start gap-10 min-w-[231px] max-w-full text-center text-[32px] text-white font-inter mq450:gap-5 ${className}`}
      style={solutionListStyle}
    >
      <div
        className='rounded-xl bg-deepskyblue-200 flex flex-row items-center justify-center w-[69px] h-[69px] z-[1]'
        style={groupDivStyle}
      >
        <div
          className='relative rounded-xl bg-deepskyblue-200 hidden'
          style={rectangleDivStyle}
        />
        <b
          className='relative inline-block min-w-[16px] z-[1]'
          style={solutionOneTitleStyle}
        >
          {solutionOneTitle}
        </b>
      </div>
      <div
        className='self-stretch flex flex-col items-start justify-start gap-3 text-left leanding-[43.57px] text-[#282828]'
        style={solutionOneDescriptionStyle}
      >
        <div className='relative leading-[30px] font-semibold z-[1] text-[22px]'>
          <p className='m-0'>{seamlessIntegrationAnd}</p>
          <p className='m-0'>{userFriendlyDesign}</p>
        </div>
        <div className='relative text-lg text-[#5A5A5A] font-normal leading-[30px] text-[18px]'>
          <p className='m-0'>{developingAPlatformWithDynamic}</p>
          <p className='m-0'>{featuresLikeAudioEnhancedVocabu}</p>
          <p className='m-0'>{animatedStorybooksAndInteractiv}</p>
          <p className='m-0'>{globeAnimationsRequiredSeamless}</p>
          <p className='m-0'>{integrationAndAUserFriendly}</p>
        </div>
      </div>
    </div>
  );
};

export default SolutionList;
