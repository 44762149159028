import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const ChallengeHeaders = ({
  className = '',
  propFlex,
  propMinWidth,
  propBackgroundColor,
  propBackgroundColor1,
  emptyChallengeHeader,
  propWidth,
  propMinWidth1,
  propAlignSelf,
  propHeight,
  propDisplay,
  complexityOf,
  financialPlanning,
  manyIndividualsFind,
  traditionalRetirementPlanning,
  toolsComplexAndDifficultTo,
  navigateOftenRequiring,
  extensiveFinancialKnowledge,
  andTimeConsuming,
  dataEntry,
}) => {
  const challengeHeadersStyle = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
    };
  }, [propFlex, propMinWidth]);

  const groupDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const rectangleDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor1,
    };
  }, [propBackgroundColor1]);

  const emptyChallengeHeaderStyle = useMemo(() => {
    return {
      width: propWidth,
      minWidth: propMinWidth1,
    };
  }, [propWidth, propMinWidth1]);

  const challengeTitlesContainerStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const complexityOfFinancialContainerStyle = useMemo(() => {
    return {
      height: propHeight,
      display: propDisplay,
    };
  }, [propHeight, propDisplay]);

  return (
    <div
      className={`flex flex-col items-start justify-start gap-10 text-center text-13xl text-white font-inter mq450:gap-5 ${className}`}
      style={challengeHeadersStyle}
    >
      <div
        className='rounded-3xs bg-navy flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'
        style={groupDivStyle}
      >
        <div
          className='h-[69px] w-[69px] relative rounded-3xs bg-navy hidden'
          style={rectangleDivStyle}
        />
        <b
          className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'
          style={emptyChallengeHeaderStyle}
        >
          {emptyChallengeHeader}
        </b>
      </div>
      <div
        className='flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'
        style={challengeTitlesContainerStyle}
      >
        <div
          className='h-[60px] relative leading-[30px] font-semibold inline-block z-[1] mq450:text-lg mq450:leading-[24px]'
          style={complexityOfFinancialContainerStyle}
        >
          <p className='m-0'>{complexityOf}</p>
          <p className='m-0'>{financialPlanning}</p>
        </div>
        <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
          <p className='m-0'>{manyIndividualsFind}</p>
          <p className='m-0'>{traditionalRetirementPlanning}</p>
          <p className='m-0'>{toolsComplexAndDifficultTo}</p>
          <p className='m-0'>{navigateOftenRequiring}</p>
          <p className='m-0'>{extensiveFinancialKnowledge}</p>
          <p className='m-0'>{andTimeConsuming}</p>
          <p className='m-0'>{dataEntry}</p>
        </div>
      </div>
    </div>
  );
};

ChallengeHeaders.propTypes = {
  className: PropTypes.string,
  emptyChallengeHeader: PropTypes.string,
  complexityOf: PropTypes.string,
  financialPlanning: PropTypes.string,
  manyIndividualsFind: PropTypes.string,
  traditionalRetirementPlanning: PropTypes.string,
  toolsComplexAndDifficultTo: PropTypes.string,
  navigateOftenRequiring: PropTypes.string,
  extensiveFinancialKnowledge: PropTypes.string,
  andTimeConsuming: PropTypes.string,
  dataEntry: PropTypes.string,

  /** Style props */
  propFlex: PropTypes.any,
  propMinWidth: PropTypes.any,
  propBackgroundColor: PropTypes.any,
  propBackgroundColor1: PropTypes.any,
  propWidth: PropTypes.any,
  propMinWidth1: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propHeight: PropTypes.any,
  propDisplay: PropTypes.any,
};

export default ChallengeHeaders;
