import React from 'react';
import PropTypes from 'prop-types';

const Content = ({ className = '' }) => {
  return (
    <div
      className={`w-full flex flex-row items-start justify-between py-0 box-border gap-[0] max-w-full text-center font-inter ${className}`}
    >
      <div className='w-1/2 flex flex-col items-start justify-start pt-[194px] px-0 pb-0 box-border min-w-[493px] max-w-full lg:flex-1 mq750:min-w-full mq450:pt-[126px] mq450:box-border'>
        <div className='self-stretch flex flex-col items-end justify-start gap-[17px]'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[22px] pr-5'>
            <h1 className='m-0 w-[235px] text-black text-[64px] font-bold inline-block mq450:text-4xl mq1050:text-5xl'>
              Fintech
            </h1>
          </div>
          <div className='self-stretch flex flex-col items-end justify-start gap-[45px] text-5xl text-gray-200 mq750:gap-[22px]'>
            <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[22px] pr-5'>
              <div className='text-gray-600 text-[24px]'>
                Algorithmic Hedge Fund
              </div>
            </div>
            <div className='self-stretch flex flex-col items-end justify-start gap-[38px] text-xl text-dimgray-200 mq750:gap-[19px]'>
              <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5'>
                <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
              </div>
              <div className='self-stretch relative leading-[30px] text-black text-[20px] mq450:text-base mq450:leading-[24px]'>
                <p className='m-0'>
                  A fintech platform that simplifies trading and access
                </p>
                <p className='m-0'>
                  to the financial market for the end users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-1/2 flex flex-row items-start justify-center pt-[276px] pb-[277px] pl-[21px] pr-5 box-border relative min-w-[493px] max-w-full mq750:pt-[179px] mq750:pb-[180px] mq750:box-border mq750:min-w-full '>
        <div className='h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px] rounded-bl-[40px]'>
          <img
            className='absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover rounded-bl-[40px]'
            alt=''
            src='/caseStudies/Quant-Capital/rectangle-3410@2x.png'
          />
          <img
            className='absolute h-full w-full top-[0px] right-[0px] bottom-[0px] left-[0px] max-w-full overflow-hidden max-h-full object-cover z-[1] rounded-bl-[40px]'
            alt=''
            src='/caseStudies/Quant-Capital/group-48095470@2x.png'
          />
        </div>
        <img
          className='w-[400px] h-[102px] object-cover z-[2] rounded-bl-[40px]'
          loading='lazy'
          alt=''
          src='/caseStudies/Quant-Capital/quant-capital-inverted-color-transparent-bg-1@2x.png'
        />
      </div>
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
};

export default Content;
