import React from 'react';
import PropTypes from 'prop-types';

const MainContent = ({ className = '' }) => {
  return (
    <div
      className={`w-[1351px] flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-[85px] max-w-full text-center text-45xl text-red font-inter lg:flex-wrap mq750:gap-[42px] mq450:gap-[21px] ${className}`}
    >
      <div className='w-[487px] flex flex-col items-start justify-start pt-[247px] px-0 pb-0 box-border min-w-[487px] max-w-full lg:flex-1 mq750:min-w-full mq450:pt-[161px] mq450:box-border'>
        <div className='self-stretch flex flex-col items-start justify-start gap-[26.5px] max-w-full'>
          <h1 className='m-0 self-stretch relative text-inherit font-bold font-[inherit] mq450:text-19xl mq1050:text-32xl'>
            <span>{`Portfolio `}</span>
            <span className='text-gray-200'>Watch</span>
          </h1>
          <div className='w-[446px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
            <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
          </div>
          <div className='w-[446px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-xl text-dimgray-200'>
            <div className='relative leading-[30px] mq450:text-base mq450:leading-[24px]'>
              Securing investments
            </div>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Portfolio-Watc/mask-group@2x.png'
      />
    </div>
  );
};

MainContent.propTypes = {
  className: PropTypes.string,
};

export default MainContent;
