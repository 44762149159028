import React from 'react';
import '../manualTesting/menualTesting.css';
import jira from '../../../assets/socialsvg/jira.svg';
import aws from '../../../assets/socialsvg/aws.svg';
import browserstack from '../../../assets/socialsvg/browserstack.svg';
import Google_Sheets from '../../../assets/socialsvg/Google_Sheets.svg';
import TestRail from '../../../assets/socialsvg/TestRail.svg';
import google from '../../../assets/socialsvg/google.svg';

const ToolCard = ({ img, title, description }) => (
  <div className='product_box mb-3'>
    <span>
      <img className='m-auto' src={img} alt='img' />
    </span>
    <h5 className='text-xl font-bold mt-2 font-Gelasio'>{title}</h5>
    <p className='text-lg'>{description}</p>
  </div>
);

const MenualTesting = () => {
  return (
    <div>
      <section className='qaqc_sec my-5'>
        <div className='container mx-auto relative'>
          <div className='tools_sec'>
            <h2 className=''>
              Tools
              <br />
              <span>for manual testing</span>
            </h2>
            <p className='font-Inter font-normal'>
              Optimize your quality assurance with powerful manual <br />
              testing tools that provide in-depth, hands-on evaluation
              <br />
              of your software. These tools ensure thorough, detailed <br />
              testing to identify and address potential issues, <br />
              enhancing overall reliability and user experience.
            </p>
          </div>

          <div className='qaqc_body'>
            <div className='grid grid-cols-1 md:grid-cols-3 max-w-[1000px] m-auto'>
              <div className='col-span-1 md:col-span-1 flex justify-end'>
                <div className='product_parrent first_col'>
                  <ToolCard
                    img={google}
                    title='Google Document'
                    description='We utilize it for on-demand documentation in projects, capturing application flow for QA project analysis'
                  />
                </div>
              </div>
              <div className='col-span-1 md:col-span-1 flex justify-center'>
                <div className='product_parrent second_col'>
                  {cardData.middleData.map((item, index) => (
                    <ToolCard key={index} {...item} />
                  ))}
                </div>
              </div>

              <div className='col-span-1 md:col-span-1 flex justify-start'>
                <div className='product_parrent first_col'>
                  {cardData.lastdata.map((item, index) => (
                    <ToolCard key={index} {...item} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MenualTesting;

const cardData = {
  middleData: [
    {
      img: jira,
      title: 'Jira',
      description: `Software for issue tracking, project
        management, and bug tracking. An
        essential tool for efficient project
        and task management`,
    },
    {
      img: TestRail,
      title: 'Testrail',
      description: `Web-based tool for test case
        management. QA engineers and
        team leads use it for organized
        testing.`,
    },
    {
      img: Google_Sheets,
      title: 'Google Spreadsheet',
      description: `We employ it as a bug tracking
        tool, utilizing Google Sheets for
        open issue tracking, ensuring
        efficient management.`,
    },
  ],
  lastdata: [
    {
      img: browserstack,
      title: 'Browserstack',
      description: `UI Testing validates software's
        visual elements, ensuring they
        function and perform as per
        requirements`,
    },
    {
      img: aws,
      title: 'AWS Device Farm',
      description: `AWS Device Farm enhances app
        quality by testing across desktop
        browsers and real devices, eliminating
         testing infrastructure management.`,
    },
  ],
};
