import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const DocumentationProcess = ({ className = '' }) => {
  const navigate = useNavigate();
  const handleContactClick = () => {
    navigate('/contactus');
  };
  return (
    <section
      className={`font-inter  font-Inter self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[141px] box-border max-w-full text-center text-17xl text-[#282828] lg:pb-[92px] lg:box-border mq450:pb-[39px] mq450:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
    >
      <div className='w-[1140px] flex flex-col items-end justify-start gap-[95px] max-w-full lg:gap-[47px] mq750:gap-6'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 box-border max-w-full'>
          <div className='w-[645px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
            <h1 className='text-4xl m-0 relative text-inherit font-semibold font-[inherit] mq450:text-3xl mq1050:text-10xl self-stretch flex flex-row items-start justify-center'>
              AI-Assistant’s user-friendly interface
            </h1>
            <div className='self-stretch flex flex-row items-start justify-center py-0 px-5 text-xl'>
              <div className='relative mq450:text-base font-normal'>
                Documentation Process and Results
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-4 box-border max-w-full'>
          <div className='h-[942px] flex-1 relative max-w-full'>
            <img
              className='absolute top-[0px] left-[0px] rounded-3xs w-[670px] h-[441px] object-cover'
              alt=''
              src='/caseStudies/AI-Assistant/image-5@2x.png'
            />
            <img
              className='absolute top-[140px] left-[470px] rounded-3xs w-[670px] h-[441px] object-cover z-[1]'
              loading='lazy'
              alt=''
              src='/caseStudies/AI-Assistant/image-4@2x.png'
            />
            <img
              className='absolute top-[501px] left-[235px] rounded-3xs w-[670px] h-[441px] object-cover z-[2]'
              alt=''
              src='/caseStudies/AI-Assistant/image-3@2x.png'
            />
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-13xl'>
          <div className='w-[822px] flex flex-col items-end justify-start gap-[37px] max-w-full mq450:gap-[18px]'>
            <div className='flex flex-col items-end justify-start gap-3.5'>
              <h2 className='m-0 relative text-inherit font-semibold font-[inherit] mq450:text-lgi mq1050:text-7xl self-stretch flex flex-row items-start justify-center text-3xl '>
                Discover how DEVtrust can assist you build your idea
              </h2>
              <div className='text-[#5A5A5A] flex flex-row items-start justify-end py-0 pl-[61px] pr-[60px] text-xl text-dimgray-200 mq1050:pl-[30px] mq1050:pr-[30px] mq1050:box-border'>
                <div className='w-[701px] relative inline-block mq450:text-base'>
                  {`Contact us for more information or to `}
                  <span className='[text-decoration:underline]'>
                    schedule a meeting
                  </span>{' '}
                  with our experts
                </div>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5'>
              <Button
                className='h-[42px] w-[143px]'
                disableElevation
                onClick={handleContactClick}
                variant='contained'
                sx={{
                  textTransform: 'none',
                  color: '#fff',
                  fontSize: '18',
                  background: '#474747',
                  borderRadius: '0px 0px 0px 0px',
                  '&:hover': { background: '#474747' },
                  width: 143,
                  height: 42,
                }}
              >
                Contact us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

DocumentationProcess.propTypes = {
  className: PropTypes.string,
};

export default DocumentationProcess;
