import React from 'react';
import style from './ServicesCard.module.css';
import { useNavigate } from 'react-router-dom';
import { setScreen, setProcess } from '../../redux/Action/actions';
import { useDispatch } from 'react-redux';
import * as DOMPurify from 'dompurify';
function ServicesCard(props) {
  const { carddata } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNavigate = () => {
    dispatch(setScreen(window.scrollY));
    dispatch(setProcess(carddata));
    if (carddata?.acf?.image === 9278) {
      navigate('/uiux');
    }
    if (carddata?.id === 9295) {
      navigate('/MobileApp', {
        state: {
          carddata: carddata,
          replace: false,
        },
      });
    }
    if (carddata?.id === 8389) {
      navigate('/AIchatboat', {
        state: {
          carddata: carddata,
          replace: false,
        },
      });
    }
    if (carddata?.id === 8386) {
      navigate('/backend', {
        state: {
          carddata: carddata,
          replace: false,
        },
      });
    }
    if (carddata?.id === 8328) {
      navigate('/qaprocess', {
        state: {
          carddata: carddata,
          replace: false,
        },
      });
    }
    if (carddata?.id === 10278) {
      navigate('/cms', {
        state: {
          carddata: carddata,
          replace: false,
        },
      });
    }
    if (carddata?.id === 10276) {
      navigate('/database', {
        state: {
          carddata: carddata,
          replace: false,
        },
      });
    }
    if (carddata?.id === 8358) {
      navigate('/frontend', {
        state: {
          carddata: carddata,
          replace: false,
        },
      });
    }
  };
  return (
    <div
      className={`${style.servicesCard}`}
      style={{ height: '360px', cursor: 'pointer' }}
      onClick={() => handleNavigate()}
    >
      <div className='flex flex-col gap-y-10 justify-center items-center'>
        <h3 className='text-[#282828] text-[24px] font-bold leading-[30.47px] truncatesone	font-Gelasio'>
          {carddata.title.rendered}
        </h3>

        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(carddata.content.rendered),
          }}
          className={`${style.imagediv}`}
        ></div>
      </div>
    </div>
  );
}

export default ServicesCard;
