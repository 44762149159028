import React from 'react';
import groupImg from '../../../assets/socialsvg/group.svg';

const OurCulture = () => {
  return (
    <div>
      <div className=' py-20 flex  flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
        <div className='' data-aos='fade-right'>
          <h1 className='text-[40px]  text-[#282828] font-bold leading-[50.78px] font-Gelasio'>
            Our&nbsp;
            <span className='text-[#43BDC1]'>Culture</span>
          </h1>
          <p className='text-lg xl:w-[400px] w-auto  font-normal leading-[30px] py-2 font-[Inter]'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore Ut enim ad minim veniam, quis
            nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo.
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore fugiat nulla pariatu.
          </p>
          <div className='flex py-4 flex-col xl:items-start xsm:items-center justify-center'>
            <a className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'>
              <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
              <span className='relative group-hover:text-white text-lg font-medium leading-[21px]'>
                Know More
              </span>
            </a>
          </div>
        </div>
        <div className='p-0'>
          <img src={groupImg}></img>
        </div>
      </div>
    </div>
  );
};

export default OurCulture;
