import React from 'react';
import PropTypes from 'prop-types';

const ResultContent = ({
  className = '',
  increasedBookingSuccessRate,
  usersExperiencedAHigherSucc,
}) => {
  return (
    <div
      className={`w-[738px] flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter mq750:flex-wrap ${className}`}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
          loading='lazy'
          alt=''
          src='/caseStudies/Top-Spin/bxuparrow@2x.png'
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
        <b className='relative z-[3] mq450:text-base'>
          {increasedBookingSuccessRate}
        </b>
        <div className='self-stretch relative text-lg leading-[30px] z-[3]'>
          {usersExperiencedAHigherSucc}
        </div>
      </div>
    </div>
  );
};

ResultContent.propTypes = {
  className: PropTypes.string,
  increasedBookingSuccessRate: PropTypes.string,
  usersExperiencedAHigherSucc: PropTypes.string,
};

export default ResultContent;
