/* eslint-disable react/jsx-key */
import React from 'react';
import { Helmet } from 'react-helmet';
import style from './cms.module.css';
import icon from '../../assets/images/cmsImg.png';
import cmsImage from '../../assets/socialpng/cmsImage.png';
import OIP from '../../assets/socialpng/OIP.png';
import wordpress from '../../assets/socialpng/wordpress.png';
import shopify from '../../assets/socialpng/shopify.png';
import dots from '../../assets/socialpng/dots.png';

const CmsComponent = () => {
  return (
    <div className="className=' bg-white xl:py-28 py-24'" data-testid='Uiux'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='cms'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='container mx-auto mb-20'>
        <div className=' py-20 flex  flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
          <div className='' data-aos='fade-right'>
            <h1 className='text-[40px]  text-[#282828] font-bold leading-[50.78px] font-Gelasio'>
              The ultimate &nbsp;
              <br />
              <span className='text-[#43BDC1]'>headless CMS</span>
            </h1>
            <p className='text-lg xl:w-[400px] w-auto  font-normal leading-[30px] py-2 font-[Inter]'>
              {`A Headless CMS (Content Management System) is a content management
              system that separates the content creation and management from the
              presentation layer. Unlike traditional CMS, a headless CMS doesn't
              dictate how content is presented on the front end, providing more
              flexibility and scalability.`}
            </p>
          </div>
          <div className='p-0'>
            <img src={icon}></img>
          </div>
        </div>
      </div>

      <div className={style.backend}>
        <h1 className='text-[40px] text-center text-[#282828] font-bold leading-[50.78px] font-Gelasio'>
          <span className='text-[#43BDC1]'>CMS </span>Tools
        </h1>
        <p className='text-lg m-auto text-center font-normal leading-[30px] py-2 font-[Inter] mb-8'>
          Streamline and enhance your digital content management with our
          cutting-edge CMS Tools.
        </p>
        <div className='container mx-auto'>
          <div className='m-auto flex flex-col gap-7 md:flex-row'>
            {CmsData.map((item, index) => (
              <div
                key={index}
                className='w-full md:min-w-[360px] lg:min-w-[360px] h-[340px] bg-white border border-gray-200 rounded-lg shadow'
              >
                <div className='flex flex-col items-center p-[30px]'>
                  <img
                    className='w-24 h-24 mb-3 rounded-full shadow-lg'
                    src={item.img}
                    alt={`cms ${index + 1}`}
                  />
                  <h5 className='mb-1 text-[24px] leading-[30.47px] font-medium text-[#282828] font-Gelasio'>
                    {item.title}
                  </h5>
                  <span className='text-[14px] leading-[22px] text-center text-[#5A5A5A] font-[Inter] md:text-base'>
                    {item.description}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div>
        <div className='container mx-auto'>
          <h1 className='text-[40px] text-center text-[#282828] font-bold leading-[50.78px] font-Gelasio'>
            What <span className='text-[#43BDC1]'>We Do</span>
          </h1>
          <p className='text-lg m-auto text-center xl:w-[784px] font-normal leading-[30px] py-2 font-[Inter]'>
            A Content Management System (CMS) is a software application or a set
            of related programs that enable the creation, management, and
            modification of digital content.
          </p>
          <div className={style.child}>
            <div className='p-4 m-auto'>
              <img src={cmsImage} alt='img' />
            </div>

            <div className='p-4'>
              {backendData.map((item, index) => (
                <div
                  key={index}
                  className='w-full mb-8'
                  style={{ display: 'flex', alignItems: 'baseline' }}
                >
                  <img src={item.img} alt={`Service ${index + 1}`} />
                  <div className='px-5'>
                    <h5 className='text-[#282828] text-[16px] font-semibold font-Inter leading-[19.36px]'>
                      {item.title}
                    </h5>
                    <p className='text-[#5A5A5A] font-[Inter] text-[16px] leading-[24px] font-normal'>
                      {item.discription}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CmsComponent;

const backendData = [
  {
    img: dots,
    title: 'Content Creation and Management',
    discription: `Enables content creators to author and manage digital content in a user-friendly interface.`,
  },
  {
    img: dots,
    title: 'Decoupled Architecture',
    discription: `Separates the back end (content storage and management) from the front end (presentation layer), allowing for independent development and updates.`,
  },
  {
    img: dots,
    title: 'API-Driven Approach',
    discription: `Content is delivered through APIs (Application Programming Interfaces), enabling developers to fetch and display content on various platforms and devices.`,
  },
  {
    img: dots,
    title: 'Multi-Channel Content Delivery',
    discription: `Content can be easily distributed to different channels and platforms, supporting omnichannel strategies.`,
  },
  {
    img: dots,
    title: 'Development Stack Agnosticism',
    discription: `Developers can use their preferred tech stack for frontend development, allowing for a more diverse and specialized toolset.`,
  },
  {
    img: dots,
    title: 'Integration with Third-Party Services',
    discription: `Facilitates integration with various third-party services and tools for analytics, marketing, e-commerce, etc.`,
  },
  {
    img: dots,
    title: 'Headless E-Commerce',
    discription: `Ideal for headless e-commerce solutions where the shopping experience is separated from the content management.`,
  },
];

const CmsData = [
  {
    id: 1,
    img: OIP,
    title: 'Webflow',
    description:
      'Webflow is a web design and development platform that allows users to create responsive websites with a visual interface, without the need for coding.',
  },
  {
    id: 2,
    img: wordpress,
    title: 'Wordpress',
    description:
      'WordPress is a popular open-source content management system (CMS) that allows users to create and manage websites and blogs.',
  },
  {
    id: 3,
    img: shopify,
    title: 'Shopify',
    description:
      'Shopify is an e-commerce platform that allows businesses to set up and manage online stores. It provides a range of tools and features to streamline the process of selling products or services online.',
  },
];
