import React from 'react';
import BenefitList from './BenefitList';

const FrameComponent3 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-end justify-center pt-0 px-0 pb-[5px] box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <div className='h-[931px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-[490px] lg:flex-1 mq750:min-w-full'>
        <img
          className='self-stretch h-[53.375rem] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-w-full overflow-hidden shrink-0 object-cover lg:self-stretch lg:w-auto rounded-t-[45px]'
          loading='lazy'
          alt=''
          src='/caseStudies/Skyward/rectangle-3422@2x.png'
        />
      </div>

      <div className=' font-Inter flex-1 flex flex-col items-start justify-start min-w-[38.563rem] max-w-full mq750:min-w-full'>
        <div className='self-stretch [background:linear-gradient(135deg,_#90e2ac,_#465efb)] flex flex-row items-start justify-between pt-[2.625rem] pb-[2.687rem] pr-[2.5rem] pl-[3.875rem] box-border max-w-full gap-[1.25rem] z-[1] mq1050:flex-wrap mq1050:pl-[1.938rem] mq1050:box-border'>
          <div className='h-[15.75rem] w-[59.375rem] relative [background:linear-gradient(135deg,_#90e2ac,_#465efb)] hidden max-w-full' />
          <div className='flex flex-col items-start justify-start gap-[1rem] max-w-full'>
            <h1 className='m-0 w-[28.25rem] text-[48px] relative text-inherit inline-block max-w-full z-[2] font-inherit mq450:text-[1.813rem] mq1050:text-[2.375rem]'>
              <p className='m-0'>
                <b>{`Results `}</b>
              </p>
              <p className='m-0'>Which we achieved</p>
            </h1>
            <div className='relative text-[1.5rem] leading-[2.188rem] font-medium z-[2] mq450:text-[1.188rem] mq450:leading-[1.75rem]'>
              The implementation of solutions yielded impressive results
            </div>
          </div>
          <div className='h-[7.5rem] w-[3.938rem] flex flex-col items-start justify-start pt-[2.937rem] px-[0rem] pb-[0rem] box-border'>
            <img
              className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover z-[2]'
              alt=''
              src='/caseStudies/Skyward/logo-new@2x.png'
            />
          </div>
        </div>
        <div className='self-stretch rounded-t-none rounded-br-none rounded-bl-26xl bg-darkslategray-200 flex flex-col items-start justify-start pt-[4.687rem] px-[3.875rem] pb-[5rem] box-border gap-[3.393rem] max-w-full z-[2] text-[1.25rem] mq450:gap-[1.688rem] mq750:pt-[3.063rem] mq750:pb-[3.25rem] mq750:box-border mq1050:pl-[1.938rem] mq1050:pr-[1.938rem] mq1050:box-border rounded-b-[45px]'>
          <div className='w-[59.375rem] h-[36.875rem] relative rounded-t-none rounded-br-none rounded-bl-26xl bg-darkslategray-200 hidden max-w-full' />
          <BenefitList
            streamlinedClientOnboardi='Streamlined Client Onboarding'
            clientsCouldEasilySignUpA='Clients could easily sign up and apply for properties, reducing manual efforts by '
            prop='45%'
          />
          <BenefitList
            streamlinedClientOnboardi='Secure Transactions'
            clientsCouldEasilySignUpA='Stripe integration ensured secure payment processing, increasing user trust by '
            prop='40%'
          />
          <BenefitList
            streamlinedClientOnboardi='Efficient Document Management'
            clientsCouldEasilySignUpA='The PDF editor improved document handling efficiency by '
            prop='60%'
          />
          <BenefitList
            streamlinedClientOnboardi='Enhanced Appointment Scheduling'
            clientsCouldEasilySignUpA='Notification system improved scheduling efficiency, enhancing agent productivity by '
            prop='25%'
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent3;
