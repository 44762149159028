import React from 'react';
import InstantBooking from './InstantBooking';
import PropTypes from 'prop-types';

const SolutionsList = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[161px] box-border max-w-full text-left text-xl text-[#282828]  mq750:pb-[68px] mq750:box-border mq1050:pb-[105px] mq1050:box-border ${className}`}
    >
      <div className='w-[914px] flex flex-col items-start justify-start gap-[69px] max-w-full mq450:gap-[17px] mq1050:gap-[34px]'>
        <div className='self-stretch flex flex-col items-center justify-center gap-16 max-w-full mq450:gap-4'>
          <InstantBooking
            group='/caseStudies/Draydex/group.svg'
            instantBookNowTechnology="Instant 'Book Now' Technology"
            enabledInstantDrayagePricing='Enabled instant drayage pricing, allowing users to get '
            immediateRateQuotes='immediate rate quotes.'
          />
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
          <InstantBooking
            group='/caseStudies/Draydex/group-1.svg'
            instantBookNowTechnology='Rate Management System'
            enabledInstantDrayagePricing='Developed a paid and free rate management system for '
            immediateRateQuotes='easy rate comparison.'
          />
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
          <InstantBooking
            group='/caseStudies/Draydex/group-2.svg'
            instantBookNowTechnology='Real-Time and Historical Data'
            enabledInstantDrayagePricing='Provided real-time data for rate transactions and integrated historical tracking for better decision-making.'
            immediateRateQuotes=''
          />
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
          <InstantBooking
            group='/caseStudies/Draydex/group-1000001266.svg'
            instantBookNowTechnology='Google Maps Integration'
            enabledInstantDrayagePricing='Utilized integrated Google Maps for '
            immediateRateQuotes='effective routing.'
          />
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
          <InstantBooking
            group='/caseStudies/Draydex/group-1000001279.svg'
            instantBookNowTechnology='Third Party Integration'
            enabledInstantDrayagePricing='Integrated Mapbox for accurate location information and a payment gateway '
            immediateRateQuotes='for secure transactions.'
          />
        </div>

        <div className='self-stretch flex flex-col items-end justify-start gap-[61px] max-w-full mq450:gap-[15px] mq1050:gap-[30px]'>
          <div className='w-[845px] flex flex-row items-start justify-end py-0 px-7 box-border max-w-full'>
            <div className='flex-1 flex flex-row flex-wrap items-start justify-start gap-[60px] max-w-full mq1050:gap-[30px]'>
              <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[428px] max-w-full mq750:min-w-full'>
                <div className='self-stretch flex flex-row items-start justify-start relative text-lg'>
                  <div className='h-[1348px] w-[685px] absolute !m-[0] right-[-291px] bottom-[-876px]'>
                    <img
                      className='absolute top-[0px] left-[0px] w-[872.4px] h-[789.2px] z-[1]'
                      alt=''
                      src='/caseStudies/Draydex/group-3051956.svg'
                    />
                    <img
                      className='absolute top-[781px] left-[87px] rounded-xl w-[448px] h-[567px] object-cover z-[2]'
                      loading='lazy'
                      alt=''
                      src='/caseStudies/Draydex/rectangle-3430@2x.png'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SolutionsList.propTypes = {
  className: PropTypes.string,
};

export default SolutionsList;

// import React from 'react';
// import PropTypes from 'prop-types';
// import icon1 from '../../../../assets/draydex/icon1.svg';
// import icon2 from '../../../../assets/draydex/icon2.svg';
// import icon3 from '../../../../assets/draydex/icon3.svg';
// import icon4 from '../../../../assets/draydex/icon4.svg';
// import icon5 from '../../../../assets/draydex/icon5.svg';

// const FrameComponent2 = ({ className = '' }) => {
//   return (
//     <section
//       className={`self-stretch flex flex-row items-center justify-center pt-0 px-5 pb-[92px] box-border max-w-full text-left text-xl text-gray-300 font-inter mq750:pb-[39px] mq750:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
//     >
//       <div className='w-[914px] flex flex-row justify-center items-center gap-[39px] max-w-full'>
//         <div className='self-stretch w-[80%] flex flex-col items-start justify-start gap-[60px] mq450:gap-[21px]'>
//           {solutionData?.map((item, index) => (
//             <div
//               key={index}
//               className='flex flex-col items-start justify-start py-0 box-border max-w-full'
//             >
//               <div className='flex flex-row items-start justify-between w-full gap-[52px] max-w-full mq450:gap-[26px] mq1050:flex-wrap'>
//                 <div className='w-[70px] flex-shrink-0 flex flex-col items-start justify-start pt-[21px] box-border'>
//                   <img
//                     className='w-full h-auto max-w-full overflow-hidden object-cover'
//                     loading='lazy'
//                     alt=''
//                     src={item?.image}
//                   />
//                 </div>
//                 <div className='flex-1 flex flex-col items-start justify-start gap-3.5'>
//                   <b className='font-semibold text-[20px] leading-[43.57px] text-[#282828]'>
//                     {item?.title}
//                   </b>
//                   <div className='text-[18px] leading-[30px] text-[#282828]'>
//                     <p className='m-0'>{item?.discription}</p>
//                   </div>
//                 </div>
//               </div>
//               <div className='self-stretch mt-4'>
//                 <hr className='border-t-[1px] border-solid border-lightgray' />
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// FrameComponent2.propTypes = {
//   className: PropTypes.string,
// };

// const solutionData = [
//   {
//     id: 1,
//     image: icon1,
//     title: 'Instant `Book Now` Technology',
//     discription: `Enabled instant drayage pricing, allowing users to get
//     immediate rate quotes.`,
//   },
//   {
//     id: 2,
//     image: icon2,
//     title: 'Rate Management System',
//     discription: `Developed a paid and free rate management system for
//     easy rate comparison.`,
//   },
//   {
//     id: 3,
//     image: icon3,
//     title: 'Real-Time and Historical Data',
//     discription: `Provided real-time data for rate transactions and integrated historical tracking
//     for better decision-making.`,
//   },
//   {
//     id: 4,
//     image: icon4,
//     title: 'Google Maps Integration',
//     discription: `Utilized integrated Google Maps for
//     effective routing.`,
//   },
//   {
//     id: 5,
//     image: icon5,
//     title: 'Third Party Integration',
//     discription: `Integrated Mapbox for accurate location information and a payment gateway
//     for secure transactions.`,
//   }
// ];

// export default FrameComponent2;
