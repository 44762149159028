// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Portfolio_customdesign__mGL9e {\n    border-bottom-width: 2px;\n    --tw-border-opacity: 1;\n    border-color: rgb(67 189 193 / var(--tw-border-opacity));\n    font-weight: 600;\n    --tw-text-opacity: 1;\n    color: rgb(67 189 193 / var(--tw-text-opacity))\n}\n", "",{"version":3,"sources":["webpack://./src/container/portfolio/Portfolio.module.css"],"names":[],"mappings":"AACE;IAAA,wBAAqE;IAArE,sBAAqE;IAArE,wDAAqE;IAArE,gBAAqE;IAArE,oBAAqE;IAArE;AAAqE","sourcesContent":[".customdesign {\n  @apply text-custom-green font-semibold border-custom-green border-b-2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customdesign": "Portfolio_customdesign__mGL9e"
};
export default ___CSS_LOADER_EXPORT___;
