import React from 'react';
import PropTypes from 'prop-types';

const GroupComponent1 = ({
  className = '',
  improvedReporting,
  enhancedTransactionTracking,
  prop,
  improvementInDataReliability,
}) => {
  return (
    <div
      className={`flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter ${className}`}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
          alt=''
          src='/caseStudies/Draydex/bxuparrow@2x.png'
        />
      </div>
      <div className='flex flex-col items-start justify-start gap-3.5 shrink-0 max-w-[calc(100%_-_30px)]'>
        <b className='relative mq450:text-base'>{improvedReporting}</b>
        <div className='relative text-lg leading-[30px]'>
          <span>{enhancedTransactionTracking}</span>
          <span className='font-semibold text-[#FF9802]'>{prop}</span>
          <span>{improvementInDataReliability}</span>
        </div>
      </div>
    </div>
  );
};

GroupComponent1.propTypes = {
  className: PropTypes.string,
  improvedReporting: PropTypes.string,
  enhancedTransactionTracking: PropTypes.string,
  prop: PropTypes.string,
  improvementInDataReliability: PropTypes.string,
};

export default GroupComponent1;
