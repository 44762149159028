import React from 'react';
import PropTypes from 'prop-types';

const SideContent = ({ className = '' }) => {
  return (
    <div
      className={`w-[1310px] flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-[149px] max-w-full text-center text-45xl text-deepskyblue-100 font-inter lg:gap-[74px] mq450:gap-[19px] mq750:gap-[37px] mq1050:flex-wrap ${className}`}
    >
      <div className='w-[382px] flex flex-col items-start justify-start pt-[230px] px-0 pb-0 box-border min-w-[382px] max-w-full mq450:pt-[149px] mq450:box-border mq450:min-w-full mq1050:flex-1'>
        <div className='self-stretch flex flex-col items-end justify-start gap-7'>
          <div className='flex flex-row items-start justify-end py-0 pl-[79px] pr-[78px] mq450:pl-5 mq450:pr-5 mq450:box-border'>
            <h1 className='m-0 relative text-inherit font-bold font-[inherit] mq450:text-19xl mq1050:text-32xl'>
              Bounty
            </h1>
          </div>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
          </div>
          <div className='self-stretch relative text-xl leading-[30px] text-dimgray-200 mq450:text-base mq450:leading-[24px]'>
            <p className='m-0'>Lorem ipsum dolor sit amet, consectetur</p>
            <p className='m-0'>adipiscing elit sed</p>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Bounty/mask-group.svg'
      />
    </div>
  );
};

SideContent.propTypes = {
  className: PropTypes.string,
};

export default SideContent;
