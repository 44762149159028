import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[92px] pl-5 pr-[26px] box-border max-w-full text-left text-29xl text-white font-inter mq450:pb-[39px] mq450:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
    >
      <div className='w-[1134px] flex flex-row flex-wrap items-end justify-center gap-[59px] max-w-full mq750:gap-[29px]'>
        <div className='flex-1 flex flex-col items-start justify-start gap-[90px] min-w-[528px] max-w-full mq450:gap-[22px] mq750:min-w-full mq1050:gap-[45px]'>
          <div className='w-[712px] rounded-26xl [background:linear-gradient(135deg,_#22a7f0,_#130cb7)] flex flex-col items-start justify-start pt-[30px] pb-[82px] pl-[78px] pr-[30px] box-border relative gap-2.5 max-w-full z-[2] mq450:pt-5 mq450:pb-[53px] mq450:box-border mq750:pl-[39px] mq750:box-border'>
            <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#22a7f0,_#130cb7)] hidden max-w-full z-[0]' />
            <div className='self-stretch flex flex-row items-start justify-end'>
              <img
                className='h-[50px] w-[50px] relative object-cover z-[1]'
                loading='lazy'
                alt=''
                src='/caseStudies/Bounty/logoiconlight-1@2x.png'
              />
            </div>
            <div className='w-[249px] flex flex-row items-start justify-start pt-0 px-0 pb-1.5 box-border'>
              <h1 className='m-0 flex-1 relative text-inherit z-[1] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
                <p className='m-0'>
                  <b>{`Problem `}</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
            </div>
            <div className='h-[70px] relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[1] mq450:text-lgi mq450:leading-[28px]'>
              <p className='m-0'>Here are the challenges which we faced</p>
              <p className='m-0'>at the time of develop the application</p>
            </div>
            <img
              className='w-[60px] h-[73px] absolute !m-[0] top-[-6px] right-[86px] z-[3]'
              loading='lazy'
              alt=''
            />
          </div>
          <div className='self-stretch flex flex-col items-start justify-start gap-10 max-w-full text-center text-13xl mq450:gap-5'>
            <div className='w-[643px] flex flex-row items-start justify-between gap-5 max-w-full mq450:flex-wrap'>
              <div className='rounded-3xs bg-mediumblue flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'>
                <div className='h-[69px] w-[69px] relative rounded-3xs bg-mediumblue hidden' />
                <b className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                  1
                </b>
              </div>
              <div className='rounded-3xs bg-darkblue flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'>
                <div className='h-[69px] w-[69px] relative rounded-3xs bg-darkblue hidden' />
                <b className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                  1
                </b>
              </div>
              <div className='rounded-3xs bg-royalblue-200 flex flex-row items-start justify-start py-[15px] pl-[25px] pr-[23px] z-[1]'>
                <div className='h-[69px] w-[69px] relative rounded-3xs bg-royalblue-200 hidden' />
                <b className='relative inline-block min-w-[21px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                  2
                </b>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-start gap-[73px] max-w-full text-left text-3xl text-gray-200 mq450:gap-[18px] mq750:flex-wrap mq1050:gap-9'>
              <div className='flex flex-col items-start justify-start gap-[13px] min-w-[214px] mq750:flex-1'>
                <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                  Task Allotment
                </div>
                <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                  <p className='m-0'>{`Efficiently allocating and `}</p>
                  <p className='m-0'>{`tracking tasks within a `}</p>
                  <p className='m-0'>project was challenging.</p>
                </div>
              </div>
              <div className='flex-1 flex flex-row items-start justify-start gap-[42px] min-w-[342px] max-w-full mq450:min-w-full mq750:flex-wrap mq750:gap-[21px]'>
                <div className='flex-1 flex flex-col items-start justify-start gap-[13px] min-w-[159px]'>
                  <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                    Role Management
                  </div>
                  <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                    <p className='m-0'>{`Managing distinct user roles `}</p>
                    <p className='m-0'>and permissions effectively.</p>
                  </div>
                </div>
                <div className='flex-1 flex flex-col items-start justify-start gap-[13px] min-w-[155px]'>
                  <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                    Quality Assurance
                  </div>
                  <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                    <p className='m-0'>{`Ensuring tasks meet quality `}</p>
                    <p className='m-0'>standards consistently.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[262px] flex flex-col items-start justify-end pt-0 px-0 pb-[30px] box-border text-center text-13xl'>
          <div className='self-stretch flex flex-col items-start justify-start gap-10 mq450:gap-5'>
            <div className='rounded-3xs bg-royalblue-100 flex flex-row items-start justify-start py-[15px] px-[23px] z-[1]'>
              <div className='h-[69px] w-[69px] relative rounded-3xs bg-royalblue-100 hidden' />
              <b className='w-[22px] relative inline-block min-w-[22px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                3
              </b>
            </div>
            <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
              <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                Task Tracking
              </div>
              <div className='self-stretch relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                Monitoring task progress and completion in real-time.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
