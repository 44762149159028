import { SET_SCREEN } from '../Action/ActionType';
const screenReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_SCREEN:
      return {
        screen: action.payload,
      };
    case 'kdkk':
      return action.payload;
    case 'yasddf':
      return action.payload;
    default:
      return state;
  }
};
export default screenReducer;
