import React from 'react';
import AutomatedContent from './AutomatedContent';
import PropTypes from 'prop-types';

const SolutionList = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-12 box-border max-w-full text-left text-xl text-[#282828] mq450:pb-5 mq450:box-border mq1050:pb-[31px] mq1050:box-border ${className}`}
    >
      <div className='w-[914px] flex flex-col items-start justify-start gap-[51px] max-w-full mq450:gap-[25px]'>
        <div className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-[9px] box-border gap-[30px] max-w-full'>
          <AutomatedContent
            group='caseStudies/AI-Assistant/group.svg'
            automatedDocumentation='Automated Documentation'
            aIAssistantCanCreateDocumentati='AI Assistant can create documentation including treatment plans with all the '
            medicalDetailsAutomatically='medical details automatically which can save physician’s significant time.'
          />
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
        </div>
        <div className='w-[850px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
          <div className='flex flex-row items-end justify-start gap-[34.6px] max-w-full mq750:flex-wrap mq750:gap-[17px]'>
            <div className='w-[105.4px] flex flex-col items-start justify-end pt-0 px-0 pb-[18.8px] box-border'>
              <img
                className='self-stretch h-[30.2px] relative max-w-full overflow-hidden shrink-0'
                loading='lazy'
                alt=''
                src='/caseStudies/AI-Assistant/group-1000001296.svg'
              />
            </div>
            <div className='flex flex-col items-start justify-start gap-3.5 shrink-0 max-w-full'>
              <b className='relative mq450:text-base'>Transcription</b>
              <div className='relative text-lg leading-[30px]'>
                Azure speech-to-text convertor to transcribe the conversation.
              </div>
            </div>
          </div>
        </div>
        <div className=' font-Inter self-stretch flex flex-col items-start justify-start gap-[36.1px] max-w-full mq450:gap-[18px]'>
          <div className='self-stretch flex flex-col items-end justify-start gap-[43px] max-w-full mq450:gap-[21px]'>
            <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
            <AutomatedContent
              propPadding='16px 0px 0px'
              group='caseStudies/AI-Assistant/vector-1.svg'
              automatedDocumentation='Accuracy'
              propDisplay='inline-block'
              propMinWidth='94px'
              aIAssistantCanCreateDocumentati='AI-generated documents ensure more accuracy and completeness based on '
              medicalDetailsAutomatically='Standardized templates that adhere to the legal and regulatory standards.'
            />
          </div>
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[1]' />
        </div>
        <div className='font-Inter self-stretch flex flex-col items-end justify-start gap-[38px] max-w-full mq450:gap-[19px]'>
          <div className='self-stretch flex flex-col items-start justify-start gap-[59px] max-w-full mq450:gap-[29px]'>
            <div className='w-[654px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
              <div className='flex flex-row items-start justify-start gap-[57px] max-w-full mq450:gap-7 mq750:flex-wrap'>
                <div className='flex flex-col items-start justify-start pt-1.5 px-0 pb-0'>
                  <img
                    className='w-[60px] h-[55.2px] relative'
                    alt=''
                    src='/caseStudies/AI-Assistant/group-1.svg'
                  />
                </div>
                <div className='flex flex-col items-start justify-start gap-3.5'>
                  <b className='relative mq450:text-base'>HIPAA Compliance</b>
                  <div className='relative text-lg leading-[30px] z-[1]'>
                    The documents are HIPAA compliant.
                  </div>
                </div>
              </div>
            </div>
            <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[1]' />
          </div>
          <AutomatedContent
            propPadding='0px 0px'
            group='caseStudies/AI-Assistant/group-1000001297.svg'
            automatedDocumentation='Focus on Core Responsibilities'
            propDisplay='unset'
            propMinWidth='unset'
            aIAssistantCanCreateDocumentati='As the administration work and documentation are done by the AI Assistant, '
            medicalDetailsAutomatically='physicians can focus more on their primary clinical responsibilities.'
          />
        </div>
      </div>
    </section>
  );
};

SolutionList.propTypes = {
  className: PropTypes.string,
};

export default SolutionList;
