import React from 'react';
import style from './ModalCard.module.css';
import scrollIcon from '../../assets/images/scroll.webp';
const ModalCard = (props) => {
  const { carddata } = props;
  return (
    <div className={style.ModalCard}>
      <h1 className={style.ModalHeading}>{carddata.heading}</h1>
      <div className={style.CardDiv}>
        <ul className='p-7 '>
          {carddata?.list.map((item, index) => (
            <li className={`${style.list}`} key={index}>
              {item}
            </li>
          ))}
        </ul>

        <div
          className={`absolute bottom-0 z-1 group xl:h-[60px] h-[20px] mt-10 text-center w-full py-5 bg-white shadow-2xl drop-shadow-xl
text-custom-green ${style.sec}`}
        >
          <div className={style.scrolldiv}>
            <img className='' src={scrollIcon}></img>
          </div>

          <button className={style.WhenToUse}>When to Use?</button>
          <p className='hidden group-hover:block group-hover font-normal text-lg leading-[30px] font-[Inter] p-5 text-[#282828]'>
            {carddata.summary}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModalCard;
