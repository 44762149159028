/* eslint-disable react/jsx-key */
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import style from './chatboat.module.css';
import icon from '../../assets/socialpng/chatboat.png';
import Uihovercard from '../../atoms/card/Uihovercard';
import uilogo from '../../assets/socialpng/chatboatbanner.png';
import chatboatone from '../../assets/socialpng/chatboat-one.png';
import chatboattwo from '../../assets/socialpng/chatboat-two.png';
import chatboatthree from '../../assets/socialpng/chatboat-three.png';
import chatboatfour from '../../assets/socialpng/chatboat-four.png';
import chatboatfive from '../../assets/socialpng/chatboat-five.png';
import chatboatsix from '../../assets/socialpng/chatboat-six.png';
import dialogflow from '../../assets/socialpng/dialogflow.png';
import watson from '../../assets/socialpng/watson.png';
import bot from '../../assets/socialpng/bot.png';
import chat from '../../assets/socialpng/chat.png';
import botpress from '../../assets/socialpng/botpress.png';
import { Link } from 'react-router-dom';

const Chatboat = () => {
  const cardItems1 = useMemo(() => {
    return imga.map((item) => (
      <Uihovercard
        title={item.title}
        img={item.img}
        description={item.description}
        key={item.id}
      />
    ));
  }, []);

  return (
    <div className="className=' bg-white xl:py-28 py-24'" data-testid='Uiux'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='AIchatboat'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='container mx-auto'>
        <div className=' py-20 flex  flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
          <div className='' data-aos='fade-right'>
            <h1 className='text-[40px]  text-[#282828] font-bold leading-[50.78px] font-Gelasio'>
              DEVtrust &nbsp;
              <span className='text-[#43BDC1]'>AI Chatboat</span>
            </h1>
            <p className='text-lg xl:w-[400px] w-auto  font-normal leading-[30px] py-2 font-[Inter]'>
              Leverage our expertise in crafting avant garde UI & UX solutions.
              We bring to the table an efficient design process resulting in
              superior results in a short turn around time.
            </p>
            <div className='flex py-4 flex-col xl:items-start xsm:items-center justify-center'>
              <Link
                to={'/get-in-touch'}
                className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
              >
                <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                <span className='relative group-hover:text-white text-lg font-medium leading-[21px]'>
                  Get Quote
                </span>
              </Link>
            </div>
          </div>
          <div className='p-0'>
            <img src={icon}></img>
          </div>
        </div>
        <div className={style.list}>
          <h1 className='text-[32px] text-center leading-[41px] font-normal font-Gelasio'>
            <strong>
              Types of <span className='text-[#43BDC1]'>Services</span>{' '}
            </strong>
          </h1>
        </div>
      </div>

      <div className={style.Container}>
        <div className='flex flex-col sm:flex-row justify-between mt-24 mb-8'>
          {services.firstService.map((item, index) => (
            <div
              key={index}
              className='w-full sm:w-[337px]'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img
                className='w-full sm:w-auto'
                style={{ padding: '15px' }}
                src={item.img}
                alt={`Service ${index + 1}`}
              />
              <div>
                <p className='text-sm sm:text-base'>{item.description}</p>
              </div>
            </div>
          ))}
        </div>

        <div className='flex flex-col sm:flex-row justify-between'>
          {services.secoundService.map((item, index) => (
            <div
              className='w-[337px]'
              key={index}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img style={{ padding: '15px' }} src={item.img}></img>
              <div>
                <p className='text-sm sm:text-base'>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={style.Container}>
        <div>
          <h1 className={style.Title}>
            AI Chatboat <span className='text-custom-green '>Flow</span>
          </h1>
          <img src={uilogo} style={{ margin: 'auto' }}></img>
        </div>
      </div>

      <div className={style.Container}>
        <h1 className={style.Title}>
          Tools <span className='text-custom-green ml-2 '>AI Chatbot</span>
        </h1>
        <div className='font-[Inter] grid xl:grid-cols-3 xsm:grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-0 my-3 items-center justify-center '>
          {cardItems1}
        </div>
      </div>
    </div>
  );
};
export default Chatboat;

const services = {
  firstService: [
    {
      img: chatboatone,
      description: 'Building & deploying custom AI chatbots',
    },
    {
      img: chatboattwo,
      description: 'Training & maintenance of chatbots',
    },
    {
      img: chatboatthree,
      description: 'Integration with other platforms & tools',
    },
  ],

  secoundService: [
    {
      id: 4,
      img: chatboatfour,
      description: 'Data analysis and reporting',
    },
    {
      id: 5,
      img: chatboatfive,
      description: 'Consulting and support',
    },
    {
      id: 6,
      img: chatboatsix,
      description: 'Analytics and reporting',
    },
  ],
};

const imga = [
  {
    id: 1,
    img: dialogflow,
    description:
      'Dialogflow is a natural language processing (NLP) platform developed by Google for building conversational interfaces, such as chatbots and virtual agents, that can understand and respond to user input in a natural and meaningful way. It allows to create intelligent and interactive conversational experiences across various platforms and devices.',
  },
  {
    id: 2,
    img: watson,
    description:
      'IBM Watson Assistant is a conversational AI platform developed by IBM that enables businesses to build and deploy chatbots and virtual agents. It leverages natural language processing (NLP) and machine learning capabilities to understand and respond to user input conversationally.',
  },
  {
    id: 3,
    img: bot,
    description:
      'Microsoft offers a comprehensive set of tools and services for building conversational agents, chatbots, and virtual assistants. The primary platform for creating these conversational experiences is the Microsoft Bot Framework.',
  },
  {
    id: 4,
    img: chat,
    description:
      'It is a popular platform for creating chatbots and automating conversations on various messaging platforms, primarily on Facebook Messenger. It provides tools and features that make it easy for businesses, marketers, and individuals to build interactive chatbot experiences without extensive coding knowledge.',
  },
  {
    id: 5,
    img: botpress,
    description:
      'Botpress can integrate with various natural language understanding (NLU) engines, such as Rasa or Dialogflow, to enhance language understanding capabilities. This enables the chatbot to comprehend user intents and entities.',
  },
];
