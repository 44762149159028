import React from 'react';
import PropTypes from 'prop-types';

const TopContent = ({ className = '' }) => {
  return (
    <div
      className={`self-stretch flex flex-row items-end justify-start max-w-full text-left text-sm text-gray-200 font-inter ${className}`}
    >
      <div className='w-[1440px] flex flex-col items-start justify-start pt-0 px-0 pb-[55px] box-border min-h-[150px] max-w-full'></div>
    </div>
  );
};

TopContent.propTypes = {
  className: PropTypes.string,
};

export default TopContent;
