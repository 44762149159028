import React, { useEffect } from 'react';
import HeaderModal from '../../atoms/modal/HeaderModal';
import { getHeader } from '../../utils';
import style from './Header.module.css';
import HeaderImage from '../../atoms/headerimage/HeaderImage';
function Header() {
  const [showModal, setShowModal] = React.useState(false);
  const [Headerdata, setHeaderdata] = React.useState([]);
  const hide = () => {
    document.body.style.overflow = 'unset';
    setShowModal(false);
  };
  const getdata = async () => {
    let data = await getHeader();
    setHeaderdata(data);
  };
  const openModal = () => {
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
    setTimeout(() => {
      setShowModal(true);
    }, 400);
  };
  useEffect(() => {
    getdata();
  }, []);
  return (
    <div>
      <div
        className='test bg-white pt-20 xl:pb-10 md:pb-10 pb-0'
        data-testid='bttn'
      >
        {Headerdata?.map((item) => (
          <div className={`${style.header}`} key={item?.id}>
            <div className={`${style.mainHeader}`} data-aos='fade-right'>
              <h1 className={`${style.headerHeading}`}>
                {item?.title?.rendered}{' '}
                <span className={`${style.headerSubHeading}`}>
                  {item?.acf?.home1}
                </span>
              </h1>
              <p className={`${style.headerPara}`}>{item?.acf?.home2}</p>
              <div className='flex py-4 flex-col xl:items-start md:items-start xsm:items-center justify-center'>
                <button
                  onClick={() => openModal()}
                  className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
                >
                  <span className='absolute bottom-0 left-0 flex w-0 h-full mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                  <span className='relative group-hover:text-white text-lg font-semibold font-[Inter] leading-[21px]'>
                    {item?.acf?.home3}
                  </span>
                </button>
                <br />
              </div>
            </div>
            <HeaderImage data={item?.acf?.imgurl} />
          </div>
        ))}

        <HeaderModal showModal={showModal} hideModal={hide} />
      </div>
    </div>
  );
}

export default Header;
