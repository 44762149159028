import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={`font-Inter self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[105px] box-border max-w-full text-left text-xl font-inter  text-[#282828] mq750:pb-[68px] mq750:box-border ${className}`}
    >
      <div className='w-[914px] flex flex-col items-center justify-center gap-[55px] max-w-full mq450:gap-[27px]'>
        <div className='w-[824px] flex flex-row items-start justify-end py-0 px-[17px] box-border max-w-full'>
          <div className='flex-1 flex flex-col items-start justify-start max-w-full'>
            <div className='w-[458px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
              <b className='relative mq450:text-base'>Streamlined Workflow</b>
            </div>
            <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-[60px] max-w-full mt-[-4px] text-lg mq1050:gap-[30px]'>
              <img
                className='h-[58.8px] w-[60px] relative'
                loading='lazy'
                alt=''
                src='/caseStudies/Billing-Zero/group-1000001286.svg'
              />
              <div className='flex-1 flex flex-col items-start justify-start pt-[18px] px-0 pb-0 box-border min-w-[435px] max-w-full mq750:min-w-full'>
                <div className='relative leading-[30px]'>
                  <p className='m-0'>{`The paid subscribers can now create, send, and manage proposals, contracts, `}</p>
                  <p className='m-0'>
                    and invoices to their clients — all in one place
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-col items-center justify-center gap-[43px] max-w-full mq450:gap-[21px]'>
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[2]' />
          <div className='self-stretch flex flex-col items-center justify-center gap-[38px] max-w-full mq450:gap-[19px]'>
            <div className='flex flex-row items-start justify-end py-0 px-7 box-border max-w-full'>
              <div className='flex flex-row items-start justify-start gap-[55px] max-w-full mq450:gap-[27px] mq1050:flex-wrap'>
                <div className='flex flex-col items-start justify-start pt-3.5 px-0 pb-0'>
                  <img
                    className='w-[70px] h-[69.7px] relative'
                    loading='lazy'
                    alt=''
                    src='/caseStudies/Billing-Zero/group.svg'
                  />
                </div>
                <div className='flex flex-col items-start justify-start gap-3.5 max-w-full'>
                  <b className='relative mq450:text-base'>Integrated System</b>
                  <div className='relative text-lg leading-[30px] z-[2]'>
                    <p className='m-0'>{`Eliminates the need for multiple tools and manual processes, saving time and `}</p>
                    <p className='m-0'>reducing the risk of errors</p>
                  </div>
                </div>
              </div>
            </div>
            <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[2]' />
          </div>
          <div className='flex flex-row items-start justify-end py-0 px-3.5 box-border max-w-full'>
            <div className='flex flex-row items-start justify-start gap-[30px] max-w-full mq1050:flex-wrap'>
              <div className='h-[76.3px] w-[120px] flex flex-col items-start justify-start pt-[21px] px-0 pb-0 box-border'>
                <img
                  className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Billing-Zero/1280pxstripe-logo-revised-2014@2x.png'
                />
              </div>
              <div className='flex flex-col items-start justify-start gap-3.5 max-w-full'>
                <b className='relative mq450:text-base'>Online Payments</b>
                <div className='relative text-lg leading-[30px] z-[2]'>
                  <p className='m-0'>{`Stripe API integration to enable seamless and secure bank account verification `}</p>
                  <p className='m-0'>{`and payment processing using ACH, credit & debit cards`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
