import React from 'react';

const AcademyInfo = ({ className = '' }) => {
  return (
    <div
      className={`w-[1313px] flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-[50px] max-w-full text-center text-45xl text-peru font-inter lg:flex-wrap mq750:gap-[25px] ${className}`}
    >
      <div className='w-[484px] flex flex-col items-start justify-start pt-[193px] px-0 pb-0 box-border min-w-[484px] max-w-full lg:flex-1 mq750:min-w-full mq450:pt-[125px] mq450:box-border'>
        <div className='self-stretch flex flex-col items-end justify-start gap-[26.5px]'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[23px] pr-5'>
            <h1 className='m-0 relative text-inherit font-bold font-inherit mq450:text-19xl mq1050:text-32xl'>
              <p className='m-0'>Rank Up</p>
              <p className='m-0'>Academy</p>
            </h1>
          </div>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <div className='h-px w-[261px] relative border-lightgray border-t-[1px] border-solid box-border' />
          </div>
          <div className='h-[60px] relative text-xl leading-[30px] text-dimgray-200 inline-block mq450:text-base mq450:leading-[24px]'>
            <p className='m-0'>
              Making confident financial decisions for retirement,
            </p>
            <p className='m-0'>home buying, and college savings endeavours.</p>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/RankUpAcadmey/mask-group@2x.png'
      />
    </div>
  );
};

export default AcademyInfo;
