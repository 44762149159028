import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const FrameComponent1 = ({ className = '' }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/get-in-touch'); // This will navigate to the /get-in-touch route
  };
  return (
    <section
      className={`font-Inter self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[9.25rem] box-border max-w-full text-center text-[2rem] text-[#282828]  ${className}`}
    >
      <div className='w-[51.375rem] flex flex-col items-end justify-start gap-[2.312rem] max-w-full mq450:gap-[1.125rem]'>
        <div className='flex flex-col items-end justify-start gap-[0.875rem]'>
          <h2 className='m-0 relative text-inherit font-semibold font-inherit mq450:text-[1.188rem] mq1050:text-[1.625rem]'>
            Discover how DEVtrust can assist you build your idea
          </h2>
          <div className=' font-normal flex flex-row items-start justify-end py-[0rem] pr-[3.75rem] pl-[3.812rem] text-[1.25rem] text-dimgray-200 mq1050:pl-[1.875rem] mq1050:pr-[1.875rem] mq1050:box-border'>
            <div className='relative mq450:text-[1rem]'>
              {`Contact us for more information or to `}
              <span className='[text-decoration:underline]'>
                schedule a meeting
              </span>{' '}
              with our experts
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-center py-[0rem] pr-[1.25rem] pl-[1.312rem]'>
          <Button
            className='h-[2.625rem] w-[8.938rem] font-Inter'
            disableElevation
            variant='contained'
            sx={{
              textTransform: 'none',
              color: '#fff',
              fontSize: '18',
              background: '#474747',
              borderRadius: '0px 0px 0px 0px',
              '&:hover': { background: '#474747' },
              width: 143,
              height: 42,
            }}
            onClick={handleClick} // Call the function when button is clicked
          >
            Contact us
          </Button>
        </div>
      </div>
    </section>
  );
};

export default FrameComponent1;
