import React from 'react';

const BenefitList = ({
  className = '',
  streamlinedClientOnboardi,
  clientsCouldEasilySignUpA,
  prop,
}) => {
  return (
    <div
      className={`flex flex-row items-start justify-start gap-[0.75rem] max-w-full text-left text-[1.25rem] text-white font-inter ${className}`}
    >
      <div className='flex flex-col items-start justify-start pt-[0.187rem] px-[0rem] pb-[0rem]'>
        <img
          className='w-[1.125rem] h-[1.125rem] relative overflow-hidden shrink-0 object-contain z-[3]'
          loading='lazy'
          alt=''
          src='/caseStudies/Skyward/bxuparrow@2x.png'
        />
      </div>
      <div className='flex flex-col items-start justify-start gap-[0.875rem] max-w-[calc(100%_-_30px)]'>
        <b className='relative z-[3] mq450:text-[1rem]'>
          {streamlinedClientOnboardi}
        </b>
        <div className='relative text-[1.125rem] leading-[1.875rem] z-[3]'>
          <span>{clientsCouldEasilySignUpA}</span>
          <b className='text-mediumaquamarine'>
            <span className='text-[#8EDFAE]'>{prop}</span>
            <span className='text-white'>.</span>
          </b>
        </div>
      </div>
    </div>
  );
};

export default BenefitList;
