import React, { useEffect, useState } from 'react';
import style from './Products.module.css';
import facebook from '../../assets/images/facebookcolor.webp';
import linkedin from '../../assets/images/linkedincolor.webp';
import insta from '../../assets/images/instagramcolor.webp';
import service1 from '../../assets/images/service1.webp';
import service2 from '../../assets/images/service2.webp';
import service3 from '../../assets/images/service3.webp';
import service4 from '../../assets/images/service4.webp';
import ServiceProvider from '../../atoms/serviceprovider/ServiceProvider';
import challangeIcons from '../../assets/images/challangeIcons.webp';
import sideIcon from '../../assets/images/left.webp';
import solution from '../../assets/images/solution.webp';
import twitter from '../../assets/images/twittercolor.webp';
import { useSelector } from 'react-redux';
import Subtract from '../../assets/images/subtract.webp';
import { getOurProjects } from '../../utils';
import letftarrow from '../../assets/images/leftarrow.webp';
import righttarrow from '../../assets/images/rightarrow.webp';
import { Helmet } from 'react-helmet';
import * as DOMPurify from 'dompurify';
const Products = () => {
  const [projectdetails, setProjectDetails] = useState({});
  const [projectindex, setProjectIndex] = useState(0);
  const { projects, ind } = useSelector((reducers) => reducers.buisnessreducer);
  const getProjects = async () => {
    let data = await getOurProjects();
    setProjectDetails(data);
  };
  useEffect(() => {
    if (projects === undefined) {
      getProjects();
      return;
    }
    setProjectDetails(projects);
    setProjectIndex(ind);
  }, []);
  const handleProjectIndex = (india) => {
    let project = projectdetails[india];
    if (project != undefined) {
      window.scrollTo(0, 0);
      setProjectIndex(india);
    }
  };
  return (
    <div className={`${style.maindiv}`} data-testid='text'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='Products'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div
        className={`${style.image}`}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            projectdetails[projectindex]?.acf?.project_image
          ),
        }}
      ></div>
      <div className={`${style.secdiv} mb-10`}>
        <div className=''>
          <h3 className=' font-Gelasio font-bold text-[40px] py-2 leading-[50.78px]'>
            Overview
          </h3>
          <p
            className='font-Inter xl:w-[545px] xsm:w-full py-2 font-normal text-lg leading-[30px] text-[#282828]'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                projectdetails[projectindex]?.content?.rendered
              ),
            }}
          ></p>
          <div
            className='grid xl:grid-cols-4 xsm:grid-cols-3 gap-6 xl:w-[500px] xsm:w-auto py-4'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                projectdetails[projectindex]?.acf?.technology
              ),
            }}
          ></div>
        </div>
        <div className='h-[730px] xl:w-[457px] xsm:w-full xl:-mt-[110px] xsm:mt-0  bg-[#F8F8F8]'>
          <h1 className=' font-Inter text-center text-xl leading-5 font-semibold py-6 text-white bg-[#43BDC1]'>
            Project Details
          </h1>
          <div className='font-Inter flex flex-col p-5 divide-y '>
            <div className='py-5'>
              <h3 className='text-xl leading-5 font-medium text-[#828282] py-2'>
                Category::
              </h3>
              <h4 className='text-xl leading-5 font-bold py-2'>
                20 January 2021
              </h4>
            </div>
            <div className='py-5'>
              <h3 className='text-xl leading-5 font-medium text-[#828282] py-2'>
                Start Date:
              </h3>
              <h4 className='text-xl leading-5 font-bold py-2'>
                {projectdetails[projectindex]?.acf?.start_date}
              </h4>
            </div>
            <div className='py-5'>
              <h3 className='text-xl leading-5 font-medium text-[#828282] py-2'>
                End Date:
              </h3>
              <h4 className='text-xl leading-5 font-bold py-2'>
                {projectdetails[projectindex]?.acf?.end_date}
              </h4>
            </div>
            <div className='py-5'>
              <h3 className='text-xl leading-5 font-medium text-[#828282] py-2'>
                Website:
              </h3>
              <h4 className='text-xl leading-5 font-bold py-2'>
                www.moveskyward.com
              </h4>
            </div>
            <div className='py-5'>
              <h3 className='text-xl leading-5 font-medium text-[#828282] py-2'>
                Share
              </h3>
              <div className={`${style.icondiv} py-0`}>
                <div className={`${style.iconcontainer}`}>
                  <img
                    className={`${style.icon}`}
                    src={facebook}
                    alt='Workflow'
                  />
                </div>
                <div className={`${style.iconcontainer}`}>
                  <img
                    className={`${style.icon}`}
                    src={linkedin}
                    alt='Workflow'
                  />
                </div>
                <div className={`${style.iconcontainer}`}>
                  <img className={`${style.icon}`} src={insta} alt='Workflow' />
                </div>
                <div className={`${style.iconcontainer}`}>
                  <img
                    className={`${style.icon}`}
                    src={twitter}
                    alt='Workflow'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className='relative w-[231.18px] h-[231.18px] -mb-[140px] ml-[40px] z-0'
        src={Subtract}
      ></img>
      <div className='xl:h-[367px] xsm:h-full bg-[#F8F8F8] xl:mb-[100px] xl;mb-5 relative z-10'>
        <div className='container mx-auto flex py-10 xl:justify-between xl:flex-nowrap justify-center flex-wrap'>
          <div>
            <h3 className=' font-Gelasio  font-bold text-start text-[40px] xl:py-3 xsm:py-5 leading-[50.78px]'>
              Challenges
            </h3>
            <div
              className={`${style.list}`}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  projectdetails[projectindex]?.acf?.challenges
                ),
              }}
            ></div>
          </div>
          <div className='xl:-mt-[117px] xsm:mt-10'>
            <div className='xl:h-[514px] xsm:h-[300px] xl:w-[457px] xsm:w-[200px]  border-[16px] shadow-lg border-white flex justify-center items-center bg-[#E1F4F4]'>
              <img src={challangeIcons}></img>
            </div>
            <img
              className='xl:-ml-[55px] xsm:-ml-[52px]  xsm:-mt-[45px] xl:-mt-[40px]'
              src={sideIcon}
            ></img>
          </div>
        </div>
      </div>
      <div className='container mx-auto flex items-center xl:flex-nowrap flex-wrap gap-x-16 xl:py-20 py-5'>
        <div className='bg-[#E1F4F4] border-[16px] flex justify-center items-center shadow-lg h-[493px] w-[555px] border-white'>
          <img src={solution} alt='solution' />
        </div>

        <div>
          <h3 className=' font-Gelasio  font-bold text-[40px] py-3 leading-[50.78px]'>
            Solution
          </h3>
          <div
            className={`${style.list}`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                projectdetails[projectindex]?.acf?.solutions
              ),
            }}
          ></div>
        </div>
      </div>
      <ServiceProvider services={Services} />
      <div className='container mx-auto '>
        <div className=' flex xl:justify-between flex-wrap justify-center  items-center xl:py-16 py-6'>
          <h1 className='text-[40px] py-2 font-bold leading-[50.78px] font-Gelasio '>
            Result
          </h1>
          <div className=' w-[557px] '>
            <p className='font-Inter text-[#828282] px-7 text-lg font-normal leading-[30px]'>
              {projectdetails[projectindex]?.acf?.result}
            </p>
          </div>
        </div>
        <hr></hr>
        <div className='flex justify-between py-3'>
          <div className='flex items-center gap-x-3 cursor-pointer'>
            <img
              src={righttarrow}
              onClick={() => handleProjectIndex(projectindex - 1)}
            ></img>
            <p className='text-[#828282] text-lg leading-[50px] font-normal font-Inter'>
              Previous
            </p>
          </div>
          <div className='flex items-center gap-x-3 cursor-pointer'>
            <p className='text-[#828282] text-lg leading-[50px] font-normal font-Inter'>
              Next
            </p>
            <img
              src={letftarrow}
              onClick={() => handleProjectIndex(projectindex + 1)}
            />
          </div>
        </div>
        <hr></hr>
      </div>
    </div>
  );
};
export default Products;
const Services = [
  {
    title: 'Design Prototype',
    img: service1,
    dec: 'We developed a detailed design prototype to ensure a seamless user experience, focusing on clarity and usability.',
  },
  {
    title: 'Back-end Development',
    img: service2,
    dec: 'We built a secure and scalable back-end, integrating essential features and ensuring smooth data management.',
  },
  {
    title: 'Front-end Development',
    img: service3,
    dec: 'We delivered a responsive and visually appealing front-end, optimized for performance across all devices.',
  },
  {
    title: 'QA & Testing',
    img: service4,
    dec: 'We performed comprehensive QA and testing, ensuring the final product meets high-quality standards and functions flawlessly.',
  },
];
