import React from 'react';
import GroupComponent from './GroupComponent';
import PropTypes from 'prop-types';

const FrameComponent3 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex h-[1100px] flex-row items-start justify-center pt-0 px-0 pb-[5px] box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      {/* Image Container */}
      <div className='h-[931px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-[490px] lg:flex-1 mq750:min-w-full'>
        <img
          className='self-stretch h-[854px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-w-full overflow-hidden shrink-0 lg:self-stretch lg:w-auto'
          loading='lazy'
          alt=''
          src='/caseStudies/Precina-Health/rectangle-3422@2x.png'
        />
      </div>

      {/* Text Container */}
      <div className='flex-1 flex flex-col items-end justify-start min-w-[617px] max-w-full mq750:min-w-full mt-auto'>
        <div className='self-stretch [background:linear-gradient(135deg,_#0084D5,_#004680_60.5%)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 z-[1] mq1050:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
          <div className='h-[252px] w-[950px] relative hidden max-w-full' />
          <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
            <h1 className='text-[48px] leading-[58.09px]'>
              <p className='m-0 font-semibold '>
                <b>{`Results `}</b>
              </p>
              <p className='m-0 font-normal'>Which we achieved</p>
            </h1>
            <div className='text-[24px] leading-[35px]'>
              The solutions delivered impactful outcomes
            </div>
          </div>
          <div className='h-[108px] flex flex-col items-start justify-start'>
            <img
              className='w-[50px] h-[50px] relative z-[2]'
              alt=''
              src='/caseStudies/Precina-Health/precina3x-1@2x.png'
            />
          </div>
        </div>

        {/* Results List */}
        <div className='self-stretch rounded-t-none rounded-br-none rounded-bl-[45px] bg-[#1C4668] flex flex-row items-start justify-start py-[75px] px-[62px] box-border min-h-[590px] max-w-full z-[2] text-xl mq750:pt-[49px] mq750:pb-[49px] mq750:box-border mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
          <div className='flex flex-col items-start w-[80%] justify-start gap-[55px] max-w-full z-[3] mq750:gap-[27px]'>
            {/* Item 1 */}
            {/* <div className='flex flex-row items-start justify-start gap-3 max-w-full'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
                  alt=''
                  src='/caseStudies/Walead/bxuparrow@2x.png'
                />
              </div>
              <div className='flex flex-col items-start justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
                <b className='relative text-[20px] mq450:text-base'>
                  Advanced Patient Monitoring
                </b>
                <div className='h-[60px] w-[60%] relative text-[18px] leading-[30px] inline-block'>
                  <p className='m-0'> Doctors now have the capability to monitor patients daily medication routines. If a
                    patient misses any dosage, an automated reminder is sent via their mobile</p>
                  <p className='m-0'> 
                    application, advancing modern healthcare practices.</p>
                </div>
              </div>
            </div> */}
            <GroupComponent
              improvedBookingProcess='Advanced Patient Monitoring'
              usersBenefitedFromAStreamlined='Doctors now have the capability to monitor patients daily medication routines. If a patient misses any dosage, an automated reminder is sent via their mobile 
              application, advancing modern healthcare practices.'
            />

            {/* Item 4 */}
            <GroupComponent
              improvedBookingProcess='Smoother Coordination'
              usersBenefitedFromAStreamlined='Precina has achieved seamless coordination between doctors and patients through 
              real-time data synchronization between the web application and mobile app. All 
              patient-entered information on the mobile app instantly updates on the doctors 
              web application.'
            />
            <GroupComponent
              improvedBookingProcess='Self Awareness'
              usersBenefitedFromAStreamlined='Patients are now well-informed about their current health status. In cases of 
              declining health, they receive guidance on necessary actions.'
            />
            <GroupComponent
              improvedBookingProcess='Data Security'
              usersBenefitedFromAStreamlined='The implementation of OTP-based user authentication has bolstered security 
              measures, ensuring that users health data and statistics remain secure on both 
              the server and mobile application.'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent3;
