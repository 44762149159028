import React from 'react';

const InterfaceParent = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 box-border max-w-full text-center text-17xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[1139px] flex flex-col items-start justify-start gap-[95px] max-w-full lg:gap-[47px] mq750:gap-6'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
          <div className='text-[36px] text-[#282828] flex flex-col items-end justify-start gap-[23px] max-w-full'>
            <div className='flex flex-row items-start justify-end py-0 pl-[30px] pr-[27px]'>
              <h1 className='m-0 relative text-inherit font-semibold font-[inherit] mq450:text-3xl mq1050:text-10xl'>
                N Foundation’s user-friendly interface
              </h1>
            </div>
            <div className='self-stretch relative text-[20px] mq450:text-base'>
              <p className='m-0'>{`A non-profit organization, provides online tools to develop vocabulary, `}</p>
              <p className='m-0'>grammar, and geographic knowledge</p>
            </div>
          </div>
        </div>
        <div className='self-stretch h-[942px] relative'>
          <img
            className='absolute top-[0px] left-[0px] rounded-3xs w-[670px] h-[441px] object-cover'
            alt=''
            src='caseStudies/N-Foundation/home@2x.png'
          />
          <img
            className='absolute top-[140px] left-[469px] rounded-3xs w-[670px] h-[441px] object-cover z-[1]'
            loading='lazy'
            alt=''
            src='caseStudies/N-Foundation/globe@2x.png'
          />
          <img
            className='absolute top-[501px] left-[calc(50%_-_335.5px)] rounded-3xs w-[670px] h-[441px] object-cover z-[2]'
            alt=''
            src='caseStudies/N-Foundation/grammar@2x.png'
          />
        </div>
      </div>
    </section>
  );
};

export default InterfaceParent;
