import React from 'react';
import PropTypes from 'prop-types';
import stripe from '../../../../assets/images/stripe.svg';
import depiction from '../../../../assets/images/depiction.svg';
import refferals from '../../../../assets/images/refferals.svg';
import analytics from '../../../../assets/images/analytics.svg';
import calculation from '../../../../assets/images/Group.svg';

const SolutionItems = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[92px] box-border max-w-full text-left text-xl text-gray-300 font-inter mq750:pb-[39px] mq750:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
    >
      <div className='w-[914px] flex flex-row items-center justify-center gap-[39px] max-w-full mq450:gap-[19px]'>
        <div className='self-stretch w-[90%] flex flex-col items-start justify-start gap-[60px] max-w-full mq450:gap-[21px]'>
          {SolutionData?.map((item, index) => (
            <div
              key={index}
              className='flex flex-col items-start justify-start py-0 box-border max-w-full'
            >
              <div className='flex flex-row items-start justify-between w-full gap-[52px] max-w-full mq450:gap-[26px] mq1050:flex-wrap'>
                <div className='w-[70px] flex-shrink-0 flex flex-col items-start justify-start pt-[21px] box-border'>
                  <img
                    className='w-full h-auto max-w-full overflow-hidden object-cover'
                    loading='lazy'
                    alt=''
                    src={item?.image}
                  />
                </div>
                <div className='flex-1 flex flex-col items-start justify-start gap-3.5'>
                  <b className='font-semibold text-[20px] leading-[43.57px] text-[#282828]'>
                    {item?.title}
                  </b>
                  <div
                    className='text-[18px] leading-[30px] text-[#282828]'
                    dangerouslySetInnerHTML={{ __html: item?.discription }}
                  />
                </div>
              </div>
              <div className='self-stretch mt-4'>
                <hr className='border-t-[1px] border-solid border-lightgray' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

SolutionItems.propTypes = {
  className: PropTypes.string,
};

export default SolutionItems;

const SolutionData = [
  {
    id: 1,
    image: stripe,
    title: 'Payment Processing',
    discription: `<strong>Stripe integration</strong> to enable the users to deposit and withdraw funds using 
    the Web and Mobile App.`,
  },
  {
    id: 2,
    image: analytics,
    title: 'Analytics',
    discription: `Online <strong>web-based dashboard</strong> to track all the transactions and other 
    key metrics.`,
  },
  {
    id: 3,
    image: depiction,
    title: 'Personalized Depiction',
    discription: `The data stats can be viewed on a cumulative and per-user basis using 
    <strong>Apexchart</strong>.`,
  },
  {
    id: 4,
    image: calculation,
    title: 'Streamlining Fund Calculation',
    discription: `Stripe integration to enable the users to deposit and withdraw funds using 
    the Web and Mobile App.`,
  },
  {
    id: 5,
    image: refferals,
    title: 'Referrals',
    discription: `Web-based referral tracking.`,
  },
];
