import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import Loader from '../../shared/loader/loader';

import Header from '../../shared/Header/Header';
import CardHeader from '../../shared/cardHeader/CardHeader';
import Summary from '../../shared/summry/Summary';
import ServicesPortfolio from '../../shared/servicesPortfolio/ServicesPortfolio';
import Clients from '../../shared/clients/Clients';
import LatestBlog from '../../shared/latestBlog/LatestBlog';

import EngagementModal from '../../shared/engagementModal/EngagementModal';
import Technologies from '../../shared/technology/Technologies';
import GetInTouch from '../../shared/contact/GetInTouch';
import Customer from '../../shared/customer/Customer';
import BusinessCase from '../../shared/businesscase/BusinessCase';

function Home() {
  return (
    <Suspense fallback={<Loader status={true} />}>
      <div className='overflow-hidden select-none '>
        <Helmet
          data-testid='homes'
          meta={[
            { content: '', name: 'description' },
            { content: '', name: 'description' },
          ]}
          title='Devtrust'
          link={[{ rel: 'preconnect', href: '#' }]}
        ></Helmet>
        <Header />
        <CardHeader />
        <ServicesPortfolio />
        <Technologies />
        <Summary />
        <EngagementModal />
        <BusinessCase />
        <Clients />
        <Customer />
        <LatestBlog />
        <GetInTouch />
      </div>
    </Suspense>
  );
}

export default Home;
