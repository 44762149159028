import React from 'react';
import style from './TechTab.module.css';
const TechTab = (props) => {
  const { data, myfun, active } = props;

  return (
    <div>
      {data == active}
      <h1
        className={`${
          data == active ? style.custom : 'text-green'
        } text-lg cursor-pointer leading-[30px] font-[Inter] font-semibold hover:text-custom-green`}
        onClick={() => myfun(data)}
      >
        {data}
      </h1>
    </div>
  );
};

export default TechTab;
