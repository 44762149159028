import React from 'react';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[1.562rem] pr-[1.312rem] pl-[1.25rem] box-border max-w-full text-center text-[2.25rem] text-[#282828] ${className}`}
    >
      <div className='w-[71.188rem] flex flex-col items-end justify-start gap-[5.937rem] max-w-full lg:gap-[2.938rem] mq750:gap-[1.5rem]'>
        <div className='self-stretch flex flex-row items-start justify-center py-[0rem] pr-[1.25rem] pl-[1.375rem] box-border max-w-full'>
          <div className='flex flex-col items-start justify-start gap-[1.437rem] max-w-full'>
            <h2 className='m-0 relative text-inherit font-semibold font-inherit mq450:text-[1.375rem] mq1050:text-[1.813rem]'>
              Skyward&apos;s user-friendly interface
            </h2>
            <div className='flex flex-row items-start justify-start py-[0rem] px-[0.312rem] text-[1.25rem] font-normal'>
              <div className='relative mq450:text-[1rem] font-normal'>
                Simplifies property exploration and transaction management
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-[56.813rem] relative max-w-full shrink-0 flex items-center justify-center'>
          <img
            className='self-stretch h-full overflow-hidden shrink-0 object-contain absolute left-[0rem] top-[0.25rem] w-full [transform:scale(1.099)]'
            loading='lazy'
            alt=''
            src='/caseStudies/Skyward/group-1000001283@2x.png'
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent2;
