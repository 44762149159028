import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent = ({ className = '' }) => {
  return (
    <section
      className={`w-[1423px] flex flex-row items-start justify-center pt-0 px-5 pb-[7px] box-border max-w-full text-center text-17xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[931px] flex flex-col items-start justify-start gap-[135px] max-w-full mq750:gap-[34px] mq1050:gap-[67px]'>
        <div className='w-[914px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
          <div className='w-[728px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
            <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
              <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-inherit inline-block mq450:text-3xl mq1050:text-10xl'>
                Solutions
              </h1>
            </div>
            <div className='self-stretch relative text-xl mq450:text-base'>
              Devtrust implemented a comprehensive solution to address these
              challenges
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-col items-start justify-start gap-[31px] max-w-full text-left text-3xl mq750:gap-[15px]'>
          <div className='self-stretch flex flex-row items-start justify-end py-0 px-[31px] box-border max-w-full'>
            <div className='w-[786px] flex flex-col items-start justify-start gap-[11px] max-w-full'>
              <div className='w-[397px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
                <div className='relative font-semibold mq450:text-lg'>
                  API Integrations
                </div>
              </div>
              <div className='self-stretch flex flex-row items-start justify-start gap-[9px] text-lg'>
                <div className='flex flex-col items-start justify-start pt-[21px] pb-0 pl-0 pr-[34px]'>
                  <img
                    className='w-[70px] h-[69.9px] relative'
                    loading='lazy'
                    alt=''
                    src='/caseStudies/Air-Concierge/group-1000001348.svg'
                  />
                </div>
                <div className='flex flex-col items-start justify-start pt-[11px] px-0 pb-0'>
                  <div className='flex flex-col items-start justify-start gap-[84px]'>
                    <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                    <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                  </div>
                </div>
                <div className='relative leading-[30px] z-[2]'>
                  <p className='m-0'>
                    <span className='font-semibold font-inter'>
                      Property Listings:
                    </span>
                    <span>
                      {' '}
                      Integrated Airbnb SuperHost and VRBO PremierHostprofiles
                      to enhance property visibility and trust.
                    </span>
                  </p>
                  <p className='m-0'>
                    <span className='font-semibold font-inter'>
                      Mapping Services
                    </span>
                    <span>
                      : Used Leaflet API to provide detailed and interactive
                      maps
                    </span>
                  </p>
                  <p className='m-0'>for property locations.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-[915px] h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full' />
          <div className='self-stretch flex flex-row items-start justify-end max-w-full'>
            <div className='w-[842px] flex flex-col items-start justify-start gap-[11px] max-w-full'>
              <div className='w-[785px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
                <div className='relative font-semibold z-[2] mq450:text-lg'>
                  Secure Transactions and Contract Management
                </div>
              </div>
              <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-[9px] max-w-full text-lg'>
                <div className='h-[113.3px] w-[129px] flex flex-col items-start justify-start pt-[58px] pb-0 pl-0 pr-[9px] box-border'>
                  <img
                    className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover'
                    loading='lazy'
                    alt=''
                    src='/caseStudies/Air-Concierge//1280pxstripe-logo-revised-2014@2x.png'
                  />
                </div>
                <div className='flex flex-col items-start justify-start pt-3 px-0 pb-0'>
                  <div className='flex flex-col items-start justify-start gap-[82px]'>
                    <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666] z-[1]' />
                    <div className='w-2 h-2 relative rounded-[50%] [background:linear-gradient(#666,_#666),_#666]' />
                  </div>
                </div>
                <div className='flex-1 relative leading-[30px] inline-block min-w-[447px] max-w-full z-[2] mq750:min-w-full'>
                  <p className='m-0'>
                    <span className='font-semibold font-inter'>
                      Stripe Integration:
                    </span>
                    <span>
                      {' '}
                      Ensured secure handling of all transaction information
                      through Stripe API.
                    </span>
                  </p>
                  <p className='m-0'>&nbsp;</p>
                  <p className='m-0'>
                    <span className='font-semibold font-inter'>
                      E-Signature Implementation:
                    </span>
                    <span>
                      {' '}
                      Integrated Dropbox Sign (HelloSign) and Zoho Sign for
                      seamless contract execution. Documents with Dropbox Sign
                      IDs were sent to onboarded users based on a set
                      chronology, allowing them to fill in fields and sign forms
                      which were then delivered to both the Admin and the User.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-[915px] h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border max-w-full' />
          <div className='self-stretch flex flex-row items-start justify-end py-0 px-[17px] box-border max-w-full'>
            <div className='w-[795px] flex flex-row flex-wrap items-end justify-start gap-12 max-w-full mq450:gap-6'>
              <div className='flex flex-col items-start justify-end pt-0 px-0 pb-[18.4px]'>
                <img
                  className='w-[60px] h-[60.6px] relative'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Air-Concierge/group-1000001349.svg'
                />
              </div>
              <div className='flex-1 flex flex-col items-start justify-start gap-3 min-w-[447px] max-w-full mq750:min-w-full'>
                <div className='relative font-semibold inline-block max-w-full z-[2] mq450:text-lg'>
                  Enhanced Exposure and Booking System
                </div>
                <div className='self-stretch relative text-lg leading-[30px] z-[2]'>
                  <p className='m-0'>
                    Created an intuitive platform for users to input details,
                    browse listings, and
                  </p>
                  <p className='m-0'>complete bookings smoothly.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
