import React from 'react';
// import ResultHeaders from './ResultHeaders';
import icon1 from '../../../../assets/N-foundation/Group 1000001264.svg';
import icon2 from '../../../../assets/N-foundation/clarity_animation-solid.svg';
import icon3 from '../../../../assets/N-foundation/fa6-solid_file-audio.svg';
import icon4 from '../../../../assets/N-foundation/Group.svg';
import icon5 from '../../../../assets/N-foundation/fa_globe.svg';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <>
      <div className='w-full flex flex-col items-center justify-start gap-[23px] max-w-full text-[#282828] z-30'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 pl-5 pr-[21px]'>
          <h1 className='font-semibold text-[36px] leanding-[43.57px] text-[#282828]'>
            Solutions
          </h1>
        </div>
        <div className='text-[20px] font-normal leading-6 text-[#282828]'>
          Devtrust implemented several innovative solutions to address these
          challenges
        </div>
      </div>

      <section
        className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[92px] box-border max-w-full text-left text-xl text-gray-300 font-inter mq750:pb-[39px] mq750:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
      >
        <div className='w-[914px] flex flex-row items-center justify-center gap-[39px] max-w-full mq450:gap-[19px]'>
          <div className='self-stretch w-[90%] flex flex-col items-start justify-start gap-[60px] max-w-full mq450:gap-[21px]'>
            {solutionData?.map((item, index) => (
              <div
                key={index}
                className='flex flex-col items-start justify-start py-0 box-border max-w-full'
              >
                <div className='flex flex-row items-start justify-between w-full gap-[52px] max-w-full mq450:gap-[26px] mq1050:flex-wrap'>
                  <div className='w-[70px] flex-shrink-0 flex flex-col items-start justify-start pt-[21px] box-border'>
                    <img
                      className='w-full h-auto max-w-full overflow-hidden object-cover'
                      loading='lazy'
                      alt=''
                      src={item?.image}
                    />
                  </div>
                  <div className='flex-1 flex flex-col items-start justify-start gap-3.5'>
                    <b className='font-semibold text-[20px] leading-[43.57px] text-[#282828]'>
                      {item?.title}
                    </b>
                    <div className='text-[18px] leading-[30px] text-[#282828]'>
                      <p className='m-0'>{item?.discription}</p>
                    </div>
                  </div>
                </div>
                <div className='self-stretch mt-4'>
                  <hr className='border-t-[1px] border-solid border-lightgray' />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

const solutionData = [
  {
    id: 1,
    image: icon1,
    title: 'Immersive Language Learning Experience',
    discription: `By leveraging text-to-speech (TTS) APIs and pre-recorded audio files, 
    the platform offers an immersive language learning experience.`,
  },
  {
    id: 2,
    image: icon2,
    title: 'Animated and Interactive Storybooks',
    discription: `The platform features animated stories with visually stimulating illustrations and 
    interactive elements.`,
  },
  {
    id: 3,
    image: icon3,
    title: 'Diverse Audio Options',
    discription: `Integrated multiple audio options within the platform. Featuring various 
    narrators and languages, the solution utilizes the Web Audio API for superior 
    audio playback quality and enhanced control.`,
  },
  {
    id: 4,
    image: icon4,
    title: 'Global Accessibility',
    discription: `To enhance global accessibility, the platform integrates Google translator,
    supporting multiple.`,
  },
  {
    id: 5,
    image: icon5,
    title: 'Interactive 3D Globe',
    discription: `Implemented an innovative 3D globe with interactive hotspots.`,
  },
];

export default FrameComponent1;
