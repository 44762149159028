import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const ChallengeItem = ({
  className = '',
  propWidth,
  propMinWidth,
  propFlex,
  propBackgroundColor,
  propBackgroundColor1,
  bookingTitle,
  propWidth1,
  propMinWidth1,
  propAlignSelf,
  bookingAnd,
  schedulingIssues,
  usersEncounteredDifficulties,
  inBookingEventsForFuture,
  datesAndExperiencedIssuesWith,
  aPI,
}) => {
  const challengeItemStyle = useMemo(() => {
    return {
      width: propWidth,
      minWidth: propMinWidth,
      flex: propFlex,
    };
  }, [propWidth, propMinWidth, propFlex]);

  const groupDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const rectangleDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor1,
    };
  }, [propBackgroundColor1]);

  const bookingTitleStyle = useMemo(() => {
    return {
      width: propWidth1,
      minWidth: propMinWidth1,
    };
  }, [propWidth1, propMinWidth1]);

  const bookingDescriptionStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  return (
    <div
      className={`w-[261px] flex flex-col items-start justify-start gap-10 text-center text-13xl text-white font-inter mq450:gap-5 ${className}`}
      style={challengeItemStyle}
    >
      <div
        className='rounded-3xs bg-tomato flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'
        style={groupDivStyle}
      >
        <div
          className='h-[69px] w-[69px] relative rounded-3xs bg-tomato hidden'
          style={rectangleDivStyle}
        />
        <b
          className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'
          style={bookingTitleStyle}
        >
          {bookingTitle}
        </b>
      </div>
      <div
        className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'
        style={bookingDescriptionStyle}
      >
        <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
          <p className='m-0'>{bookingAnd}</p>
          <p className='m-0'>{schedulingIssues}</p>
        </div>
        <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
          <p className='m-0'>{usersEncounteredDifficulties}</p>
          <p className='m-0'>{inBookingEventsForFuture}</p>
          <p className='m-0'>{datesAndExperiencedIssuesWith}</p>
          <p className='m-0'>{aPI}</p>
        </div>
      </div>
    </div>
  );
};

ChallengeItem.propTypes = {
  className: PropTypes.string,
  bookingTitle: PropTypes.string,
  bookingAnd: PropTypes.string,
  schedulingIssues: PropTypes.string,
  usersEncounteredDifficulties: PropTypes.string,
  inBookingEventsForFuture: PropTypes.string,
  datesAndExperiencedIssuesWith: PropTypes.string,
  aPI: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propMinWidth: PropTypes.any,
  propFlex: PropTypes.any,
  propBackgroundColor: PropTypes.any,
  propBackgroundColor1: PropTypes.any,
  propWidth1: PropTypes.any,
  propMinWidth1: PropTypes.any,
  propAlignSelf: PropTypes.any,
};

export default ChallengeItem;
