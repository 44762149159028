import React from 'react';
import BenefitList from './BenefitList';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-2.5 box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <img
        className='w-[490px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1'
        loading='lazy'
        alt=''
        src='/caseStudies/Koach/rectangle-3422@2x.png'
      />
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full lg:pt-[58px] lg:box-border mq750:pt-[38px] mq750:box-border mq750:min-w-full'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#c9da2a,_#008541)] flex flex-col items-start justify-start pt-[42px] pb-[43px] pl-[62px] pr-5 box-border relative gap-4 max-w-full z-[1] mq750:pl-[31px] mq750:box-border'>
            <div className='w-[950px] h-[252px] relative [background:linear-gradient(135deg,_#c9da2a,_#008541)] hidden max-w-full z-[0]' />
            <img
              className='w-[50px] h-[48.5px] absolute !m-[0] right-[40px] bottom-[101.5px] object-cover z-[2]'
              alt=''
              src='/caseStudies/Koach/logoimage12x-1-1-1@2x.png'
            />
            <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[2] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
              <p className='m-0'>
                <b>{`Results `}</b>
              </p>
              <p className='m-0'>Which we achieved</p>
            </h1>
            <div className='relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[2] mq450:text-lgi mq450:leading-[28px]'>
              The solutions delivered impactful outcomes
            </div>
          </div>
          <div className='self-stretch bg-darkslategray-200 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[83px] box-border gap-[55px] max-w-full z-[2] text-xl mq450:gap-[27px] mq450:pt-8 mq450:pb-[35px] mq450:box-border mq1050:pt-[49px] mq1050:px-[31px] mq1050:pb-[54px] mq1050:box-border'>
            <img
              className='w-[950px] h-[805px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/Koach/rectangle-3424.svg'
            />
            <BenefitList
              increasedUserEngagement='Increased User Engagement'
              theIntuitiveReactjsInterface='The intuitive React.js interface boosted user interaction, making it easier for users to find and book coaching sessions, increasing session bookings by 50%.'
            />
            <BenefitList
              increasedUserEngagement='Streamlined Payments'
              theIntuitiveReactjsInterface='Stripe integration simplified the payment process, enhancing user experience and reducing transaction-related friction, resulting in a 30% increase in completed bookings.'
            />
            <BenefitList
              increasedUserEngagement='Enhanced Content Delivery'
              theIntuitiveReactjsInterface='Mailchimp integration enabled efficient management of podcasts and newsletters, increasing subscriber engagement by 40% and providing continuous value to the community.'
            />
            <BenefitList
              increasedUserEngagement='Scalable Solution'
              theIntuitiveReactjsInterface='The platform’s architecture ensured it could handle a growing user base and increasing traffic without performance issues, supporting Koach’s mission to expand globally. '
            />
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
