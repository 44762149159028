import React from 'react';
import PropTypes from 'prop-types';

const MainContent = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[7px] pl-5 pr-[41px] box-border max-w-full text-left text-29xl text-white font-inter ${className}`}
    >
      <div className='w-[1119px] flex flex-col items-start justify-start gap-[90px] max-w-full mq750:gap-[22px] mq1125:gap-[45px]'>
        <div className='w-[712px] rounded-26xl [background:linear-gradient(135deg,_#1876d1,_#18233a)] flex flex-col items-start justify-start pt-[27px] pb-[82px] pl-[78px] pr-7 box-border gap-[15.5px] max-w-full z-[4] mq750:pl-[39px] mq750:box-border mq450:pt-5 mq450:pb-[53px] mq450:box-border'>
          <img className='w-[60px] h-[73px] relative hidden' alt='' />
          <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#1876d1,_#18233a)] hidden max-w-full' />
          <div className='self-stretch flex flex-row items-start justify-end'>
            <img
              className='h-12 w-12 relative overflow-hidden shrink-0 z-[1]'
              loading='lazy'
              alt=''
              src='/caseStudies/Financial-Chatbot/hugeiconsbot.svg'
            />
          </div>
          <h1 className='m-0 w-[249px] relative text-inherit inline-block z-[1] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
            <p className='m-0'>
              <b>{`Problem `}</b>
            </p>
            <p className='m-0'>Statement</p>
          </h1>
          <div className='relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[1] mq450:text-lgi mq450:leading-[28px]'>
            <p className='m-0'>{`Financial chatbot encountered several challenges `}</p>
            <p className='m-0'>in their mission to support investors</p>
          </div>
        </div>
        <div className='flex flex-col items-end justify-start pt-0 px-0 pb-6 box-border gap-[23px] max-w-full text-center text-13xl'>
          <div className='flex flex-row items-start justify-start gap-[60.5px] max-w-full mq750:gap-[15px] mq1125:flex-wrap mq1125:gap-[30px]'>
            <div className='flex flex-col items-start justify-start gap-10'>
              <div className='rounded-3xs bg-darkslategray-400 flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[3]'>
                <div className='h-[69px] w-[69px] relative rounded-3xs bg-darkslategray-400 hidden' />
                <b className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                  1
                </b>
              </div>
              <div className='flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                <div className='relative leading-[30px] font-semibold z-[3] mq450:text-lg mq450:leading-[24px]'>
                  <p className='m-0'>{`Financial Data `}</p>
                  <p className='m-0'>Integration</p>
                </div>
                <div className='relative text-lg leading-[30px] text-dimgray-200 z-[3]'>
                  <p className='m-0'>{`Aggregating accurate and `}</p>
                  <p className='m-0'>{`real-time financial data `}</p>
                  <p className='m-0'>{`from multiple sources was `}</p>
                  <p className='m-0'>complex and resource-</p>
                  <p className='m-0'>intensive.</p>
                </div>
              </div>
            </div>
            <div className='w-[523px] flex flex-col items-end justify-start gap-[227px] max-w-full mq750:gap-[113px] mq450:gap-[57px]'>
              <div className='self-stretch flex flex-row items-start justify-start gap-[11px] mq750:flex-wrap'>
                <div className='flex-1 flex flex-col items-start justify-start gap-10 min-w-[179px] mq450:gap-5'>
                  <div className='rounded-3xs bg-darkslategray-200 flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[3]'>
                    <div className='h-[69px] w-[69px] relative rounded-3xs bg-darkslategray-200 hidden' />
                    <b className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                      1
                    </b>
                  </div>
                  <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                    <div className='relative leading-[30px] font-semibold z-[3] mq450:text-lg mq450:leading-[24px]'>
                      <p className='m-0'>{`Financial `}</p>
                      <p className='m-0'>Chatbot</p>
                    </div>
                    <div className='relative text-lg leading-[30px] text-dimgray-200 z-[3]'>
                      <p className='m-0'>{`Developed a Financial Chatbot `}</p>
                      <p className='m-0'>{`using prompt engineering and `}</p>
                      <p className='m-0'>{`OpenAI integration to cater to `}</p>
                      <p className='m-0'>{`various financial needs, such `}</p>
                      <p className='m-0'>{`as checking stock prices, `}</p>
                      <p className='m-0'>{`comparing stocks, and handling `}</p>
                      <p className='m-0'>stock predictions.</p>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col items-start justify-start gap-10 min-w-[236px] mq750:flex-1'>
                  <div className='rounded-3xs bg-steelblue-300 flex flex-row items-start justify-start py-[15px] pl-[25px] pr-[23px] z-[3]'>
                    <div className='h-[69px] w-[69px] relative rounded-3xs bg-steelblue-300 hidden' />
                    <b className='relative inline-block min-w-[21px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                      2
                    </b>
                  </div>
                  <div className='flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                    <div className='relative leading-[30px] font-semibold z-[3] mq450:text-lg mq450:leading-[24px]'>
                      <p className='m-0'>{`User `}</p>
                      <p className='m-0'>Engagement</p>
                    </div>
                    <div className='relative text-lg leading-[30px] text-dimgray-200 z-[3]'>
                      <p className='m-0'>{`Providing a seamless and `}</p>
                      <p className='m-0'>{`interactive user experience `}</p>
                      <p className='m-0'>{`to engage investors and `}</p>
                      <p className='m-0'>meet their financial needs.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-[480px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-17xl text-gray-200'>
                <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
                  Solutions
                </h1>
              </div>
            </div>
            <div className='flex flex-col items-start justify-start gap-10'>
              <div className='rounded-3xs bg-steelblue-100 flex flex-row items-start justify-start'>
                <div className='self-stretch w-[69px] relative rounded-3xs bg-steelblue-100 hidden' />
                <div className='rounded-3xs bg-steelblue-200 flex flex-row items-start justify-start py-[15px] px-[23px] z-[3]'>
                  <b className='relative hidden min-w-[22px] mq450:text-lgi mq1050:text-7xl'>
                    4
                  </b>
                  <div className='h-[69px] w-[69px] relative rounded-3xs bg-steelblue-200 hidden' />
                  <b className='w-[22px] relative inline-block min-w-[22px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                    3
                  </b>
                </div>
              </div>
              <div className='flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                <div className='relative leading-[30px] font-semibold mq450:text-lg mq450:leading-[24px]'>
                  <p className='m-0'>{`Crypto Data `}</p>
                  <p className='m-0'>Accessibility</p>
                </div>
                <div className='relative text-lg leading-[30px] text-dimgray-200 z-[3]'>
                  <p className='m-0'>{`Offering advanced tools to `}</p>
                  <p className='m-0'>{`analyze stock performance, `}</p>
                  <p className='m-0'>{`compare assets, and predict `}</p>
                  <p className='m-0'>financial trends effectively.</p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-[1098px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-xl text-gray-200'>
            <div className='w-[728px] relative inline-block mq450:text-base'>
              Devtrust implemented a comprehensive solution to address these
              challenges
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[41px] pr-5 box-border max-w-full text-3xl text-gray-200'>
          <div className='w-[914px] flex flex-col items-end justify-start gap-[41.5px] max-w-full mq450:gap-[21px]'>
            <div className='flex flex-row items-start justify-end py-0 px-[9px] box-border max-w-full'>
              <div className='flex flex-row items-start justify-start gap-[53px] max-w-full mq450:gap-[26px] mq1050:flex-wrap'>
                <div className='flex flex-col items-start justify-start pt-3.5 px-0 pb-0'>
                  <img
                    className='w-[70px] h-[70px] relative'
                    loading='lazy'
                    alt=''
                    src='/caseStudies/Financial-Chatbot/group.svg'
                  />
                </div>
                <div className='flex flex-col items-start justify-start gap-[11px] max-w-full'>
                  <div className='relative font-semibold mq450:text-lg'>
                    Financial Data Integration
                  </div>
                  <div className='relative text-lg leading-[30px]'>
                    <p className='m-0'>{`Integrated APIs from TradingView, Yahoo Finance, FinnHub, TradingEconomics, `}</p>
                    <p className='m-0'>
                      and CryptoCompare to provide comprehensive and accurate
                      financial data.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='self-stretch flex flex-col items-end justify-start gap-[25.5px] max-w-full'>
              <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
              <div className='w-[805px] flex flex-col items-start justify-start gap-px max-w-full'>
                <div className='w-[424px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
                  <div className='relative font-semibold mq450:text-lg'>
                    Financial Chatbot
                  </div>
                </div>
                <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-[58px] max-w-full text-lg mq450:gap-[29px]'>
                  <img
                    className='h-[72.5px] w-[60px] relative'
                    loading='lazy'
                    alt=''
                    src='/caseStudies/Financial-Chatbot/group-1.svg'
                  />
                  <div className='flex-1 flex flex-col items-start justify-start pt-2.5 px-0 pb-0 box-border min-w-[447px] max-w-full mq750:min-w-full'>
                    <div className='self-stretch relative leading-[30px]'>
                      Developed a Financial Chatbot using prompt engineering and
                      OpenAI integration to cater to various financial needs,
                      such as checking stock prices, comparing stocks, and
                      handling stock predictions.
                    </div>
                  </div>
                </div>
              </div>
              <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[2]' />
            </div>
            <div className='self-stretch flex flex-col items-end justify-start gap-[35px] max-w-full mq450:gap-[17px]'>
              <div className='self-stretch flex flex-col items-end justify-start gap-[38px] max-w-full mq450:gap-[19px]'>
                <div className='flex flex-row items-start justify-end py-0 px-[68px] box-border max-w-full mq1050:pl-[34px] mq1050:pr-[34px] mq1050:box-border'>
                  <div className='flex flex-row items-start justify-start gap-[58px] max-w-full mq750:gap-[29px] mq1050:flex-wrap'>
                    <div className='h-[85.9px] w-[60px] flex flex-col items-start justify-start pt-3 px-0 pb-0 box-border'>
                      <img
                        className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full'
                        loading='lazy'
                        alt=''
                        src='/caseStudies/Financial-Chatbot/layer-15.svg'
                      />
                    </div>
                    <div className='flex flex-col items-start justify-start gap-[11px] max-w-full'>
                      <div className='relative font-semibold mq450:text-lg'>
                        User Engagement
                      </div>
                      <div className='relative text-lg leading-[30px] z-[2]'>
                        <p className='m-0'>{`Designed an intuitive user interface to enhance user experience, making `}</p>
                        <p className='m-0'>
                          financial information easily accessible and
                          actionable.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
              </div>
              <div className='flex flex-row items-start justify-end py-0 px-3 box-border max-w-full'>
                <div className='flex flex-row items-start justify-start gap-[53px] max-w-full mq450:gap-[26px] mq1050:flex-wrap'>
                  <div className='h-[80.1px] w-[70px] flex flex-col items-start justify-start pt-[17px] px-0 pb-0 box-border'>
                    <img
                      className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full'
                      loading='lazy'
                      alt=''
                      src='/caseStudies/Financial-Chatbot/group-1000001328.svg'
                    />
                  </div>
                  <div className='flex flex-col items-start justify-start gap-[11px] max-w-full'>
                    <div className='relative font-semibold mq450:text-lg'>
                      Investment Insights
                    </div>
                    <div className='relative text-lg leading-[30px]'>
                      <p className='m-0'>{`Emphasized understanding the beta of assets and portfolios, enabling users to `}</p>
                      <p className='m-0'>
                        optimize their investments based on desired risk levels.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

MainContent.propTypes = {
  className: PropTypes.string,
};

export default MainContent;
