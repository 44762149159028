import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Home from './container/home/Home';
import NavBar from './shared/navbar/Navbar';
import Footer from './shared/footer/Footer';
import Products from './container/products/Products';
import NotFound from './container/notFound/NotFound';
import AboutUs from './container/aboutus/AboutUs';
import Qaprocess from './container/qaprocess/Qaprocess';
import ContactUs from './container/contactus/ContactUs';
import Portfolio from './container/portfolio/Portfolio';
import ScrollToTop from './ScrollToTop';
import OurProcess from './container/ourprocess/OurProcess';
import Uiux from './container/UI/UX/Uiux';
import MobileApp from './container/mobileapp/MobileApp';
import Blog from './container/blog/Blog';
import BlogDetails from './shared/blogdetails/BlogDetails';
import Alert from './shared/alert/alert';
import Loader from './shared/loader/loader';
import GetStarted from './container/getstarted/GetStarted';
import PortfolioDetail from './shared/portfoliodetail/PortfolioDetail';
import Chatboat from './container/chatboat/Chatboat';
import Backend from './container/backend/Backend';
import CmsComponent from './container/cms/CmsComponent';
import Database from './container/database/Database';
import Frontend from './container/frontend/Frontend';
import './App.css';
import Carrier from './container/carrier/Carrier';
import CaseStudiesLandingPage from './shared/caseStudies/landingPage/CaseStudiesLandingPage';
import CaseStudiesSkyward from './shared/caseStudies/Skyward/CaseStudies';
import CaseStudiesRankUpAcademy from './shared/caseStudies/RankUpAcadmey/CaseStudiesRankUpAcademy';
import CaseStudiesAIAssistant from './shared/caseStudies/AI-Assistant/CaseStudiesAIAssistant';
import CaseStudiesAimly from './shared/caseStudies/Aimly/CaseStudiesAimly';
import CaseStudiesAirConcierge from './shared/caseStudies/Air-Concierge/CaseStudiesAirConcierge';
import CaseStudiesAlimac from './shared/caseStudies/Alimac/CaseStudiesAlimac';
import CaseStudiesATR from './shared/caseStudies/ATR/CaseStudiesATR';
import CaseStudiesBillingZero from './shared/caseStudies/Billing-Zero/CaseStudiesBillingZero';
import CaseStudiesBounty from './shared/caseStudies/Bounty/CaseStudiesBounty';
import CaseStudiesCoachability from './shared/caseStudies/Coachability/CaseStudiesCoachability';
import CaseStudiesContractorETA from './shared/caseStudies/Contractor-ETA/CaseStudiesContractorETA';
import CaseStudiesDoctornalHealth from './shared/caseStudies/Doctornal-Health/CaseStudiesDoctornalHealth';
import CaseStudiesDraydex from './shared/caseStudies/Draydex/CaseStudiesDraydex';
import CaseStudiesFinancialChatbo from './shared/caseStudies/Financial-Chatbot/CaseStudiesFinancialChatbo';
import CaseStudiesGasMoney from './shared/caseStudies/Gas-Money/CaseStudiesGasMoney';
import CaseStudiesImagineScholars from './shared/caseStudies/Imagine-Scholarship/CaseStudiesImagineScholars';
import CaseStudiesIntrax from './shared/caseStudies/Intrax/CaseStudiesIntrax';
import CaseStudiesKoach from './shared/caseStudies/Koach/CaseStudiesKoach';
import CaseStudiesKrugg from './shared/caseStudies/Krugg/CaseStudiesKrugg';
import CaseStudiesMustMotivate from './shared/caseStudies/Must-Motivate/CaseStudiesMustMotivate';
import CaseStudiesNFoundation from './shared/caseStudies/N-Foundation/CaseStudiesNFoundation';
import CaseStudiesOZParty from './shared/caseStudies/OZ-Party/CaseStudiesOZParty';
import CaseStudiesPlanwell from './shared/caseStudies/Planwell/CaseStudiesPlanwell';
import CaseStudiesPortfolioWatch from './shared/caseStudies/Portfolio-Watc/CaseStudiesPortfolioWatch';
import CaseStudiesPrecinaHealth from './shared/caseStudies/Precina-Health/CaseStudiesPrecinaHealth';
import CaseStudiesQuantCapital from './shared/caseStudies/Quant-Capital/CaseStudiesQuantCapital';
import CaseStudiesScheduling from './shared/caseStudies/Scheduling/CaseStudiesScheduling';
import CaseStudiesTopSpin from './shared/caseStudies/Top-Spin/CaseStudiesTopSpin';
import CaseStudiesWalead from './shared/caseStudies/Walead/CaseStudiesWalead';
import GetInTouch from './shared/contact/GetInTouch';

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000, delay: 200 });
  }, []);

  return (
    <>
      <NavBar />
      <Loader />
      <ScrollToTop />
      <Alert />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/buisnesscasestudy' element={<Products />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/qaprocess' element={<Qaprocess />} />
        <Route path='/Uiux' element={<Uiux />} />
        <Route path='/MobileApp' element={<MobileApp />} />
        <Route path='/AIchatboat' element={<Chatboat />} />
        <Route path='/backend' element={<Backend />} />
        <Route path='/cms' element={<CmsComponent />} />
        <Route path='/database' element={<Database />} />
        <Route path='/frontend' element={<Frontend />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/portfolio' element={<Portfolio />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/ourprocess' element={<OurProcess />} />
        <Route path='/blogdetails' element={<BlogDetails />} />
        <Route path='/getstarted' element={<GetStarted />} />
        <Route path='/portfoliodetail' element={<PortfolioDetail />} />
        <Route path='/career' element={<Carrier />} />
        <Route path='/case-studies' element={<CaseStudiesLandingPage />} />
        <Route path='/skyward' element={<CaseStudiesSkyward />} />
        <Route path='/rankupacadmey' element={<CaseStudiesRankUpAcademy />} />
        <Route path='/ai-assistant' element={<CaseStudiesAIAssistant />} />
        <Route path='/aimly' element={<CaseStudiesAimly />} />
        <Route path='/airconcierge' element={<CaseStudiesAirConcierge />} />
        <Route path='/alimac' element={<CaseStudiesAlimac />} />
        <Route path='/atr' element={<CaseStudiesATR />} />
        <Route path='/billing-zero' element={<CaseStudiesBillingZero />} />
        <Route path='/bounty' element={<CaseStudiesBounty />} />
        <Route path='/coachability' element={<CaseStudiesCoachability />} />
        <Route path='/contractorETA' element={<CaseStudiesContractorETA />} />
        <Route
          path='/doctornal-health'
          element={<CaseStudiesDoctornalHealth />}
        />
        <Route path='/draydex' element={<CaseStudiesDraydex />} />
        <Route
          path='/financial-chatbot'
          element={<CaseStudiesFinancialChatbo />}
        />
        <Route path='/gas-money' element={<CaseStudiesGasMoney />} />
        <Route
          path='/imagine-scholarship'
          element={<CaseStudiesImagineScholars />}
        />
        <Route path='/intrax' element={<CaseStudiesIntrax />} />
        <Route path='/koach' element={<CaseStudiesKoach />} />
        <Route path='/krugg' element={<CaseStudiesKrugg />} />
        <Route path='/must-motivate' element={<CaseStudiesMustMotivate />} />
        <Route path='/nfoundation' element={<CaseStudiesNFoundation />} />
        <Route path='/ozparty' element={<CaseStudiesOZParty />} />
        <Route path='/planwell' element={<CaseStudiesPlanwell />} />
        <Route
          path='/portfolio-watch'
          element={<CaseStudiesPortfolioWatch />}
        />
        <Route path='/precina-health' element={<CaseStudiesPrecinaHealth />} />
        <Route path='/quant-capital' element={<CaseStudiesQuantCapital />} />
        <Route path='/scheduling' element={<CaseStudiesScheduling />} />
        <Route path='/top-spin' element={<CaseStudiesTopSpin />} />
        <Route path='/walead' element={<CaseStudiesWalead />} />
        <Route path='/get-in-touch' element={<GetInTouch />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
      <Footer />
    </>
  );
}
export default App;
