import React from 'react';
import PropTypes from 'prop-types';
import styles from './FrameComponent1.module.css';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <div className={[styles.heroContentParent, className].join(' ')}>
      <div className={styles.heroContent}>
        <div className={styles.heroTitle}>
          <h1 className={styles.imagineScholarship}>
            <p className={styles.imagine}>
              <span>Imagine</span>
              <span className={styles.span}>{` `}</span>
            </p>
            <p className={styles.scholarship}>Scholarship</p>
          </h1>
          <div className={styles.heroDivider}>
            <div className={styles.titleDivider} />
          </div>
          <div className={styles.heroSubtitle}>
            <div className={styles.empoweringEducationalDreamsContainer}>
              <p className={styles.imagine}>Empowering Educational Dreams</p>
              <p className={styles.imagine}>by Devtrust</p>
            </div>
          </div>
        </div>
      </div>
      <img
        className={styles.maskGroupIcon}
        loading='lazy'
        alt=''
        src='/caseStudies/Imagine-Scholarship/mask-group@2x.png'
      />
    </div>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
