import React, { useMemo, useEffect, useRef } from 'react';
import Cards from '../../atoms/card/Cards';
import team from '../../assets/images/dadicatedteam.webp';
import project from '../../assets/images/projectmanagement.webp';
import account from '../../assets/images/accountmanagement.webp';
import contracts from '../../assets/images/flexiblecontract.webp';
import style from './CardHeader.module.css';
import Slider from 'react-slick';
function CardHeader() {
  const sliderref = useRef();
  const cardItems = useMemo(() => {
    return data.map((item) => (
      <Cards
        title={item.title}
        img={item.img}
        description={item.description}
        key={item.id}
      />
    ));
  }, []);
  useEffect(() => {
    if (sliderref !== undefined) {
      sliderref.current.slickGoTo(4);
    }
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 2000,
    arrows: false,
    cssEase: 'linear',
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className='xl:py-6 xsm:py-0 ' style={{ overflow: 'hidden' }}>
      <h1
        className=' text-center font-Gelasio xl:text-[50px] xsm:text-[32px] xl:p-2 xsm:px-0 xsm:pb-16 font-bold xl:leading-[63.3px] xsm:leading-[40.63px]
       xsm:bg-white xl:bg-transparent'
      >
        Why Hire <span className='text-custom-green'>DEVtrust?</span>
      </h1>
      <div className={`${style['main-cardheader']}`}>
        <Slider {...settings} ref={sliderref}>
          {cardItems}
        </Slider>
      </div>
    </div>
  );
}
export default CardHeader;
const data = [
  {
    id: 1,
    img: team,
    title: 'Dedicated Team',
    description:
      'Scalable team of skillful,dedicated & experienced software professionals.',
  },
  {
    id: 2,
    img: project,
    title: 'Project Management',
    description:
      'As you scale your development team,Devtrust engages Project Manager to make task assignment & communication seamless',
  },
  {
    id: 3,
    img: account,
    title: 'Account Management',
    description:
      'Dedicated Account Managers to take you through your Devtrust journey.',
  },
  {
    id: 4,
    img: contracts,
    title: 'Flexible Contracts',
    description: 'Simple flexible monthly billing contracts.',
  },
];
