import React, { useEffect, useMemo, useState } from 'react';
import { getblog } from '../../redux/Action/actions';
import { useDispatch, useSelector } from 'react-redux';
import BlogCard from '../../atoms/blogcard/BlogCard';
import BlogAction from '../../atoms/blogfilter/BlogAction';
import { Helmet } from 'react-helmet';
const Blog = () => {
  const [blog, setBlog] = useState([]);
  const dispatch = useDispatch();
  const select = useSelector((state) => state.blogreducer?.blogdata) ?? [];
  const blogtypes = useSelector((state) => state?.blogfilterreducer);
  useEffect(() => {
    if (select.length <= 0) {
      dispatch(getblog());
      return;
    }
    if (blogtypes.category) {
      filterBlog();
      return;
    }
    setBlog(select);
  }, [select, blogtypes]);

  const blogCard = useMemo(() => {
    return blog.map((item) => <BlogCard blogdata={item} key={item.id} />);
  }, [blog]);

  const filterBlog = () => {
    if (blogtypes.category === 'All') {
      setBlog(select);
      return;
    }
    let filter = select.filter(
      (item) => item._embedded?.['wp:term'][0][0].name == blogtypes.category
    );
    setBlog(filter);
  };

  return (
    <div className='container mx-auto xl:py-28 py-24'>
      <Helmet
        data-testid='blgid'
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='Blog'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <h1 className='text-center font-bold text-[50px] py-3 leading-[63.48px] font-Gelasio'>
        Our <span className='text-[#25C7C7]'>Blogs </span>
      </h1>
      <BlogAction />
      <div className='grid grid-cols-2 lg:grid-cols-2 xsm:grid-cols-1 gap-10'>
        {blogCard}
      </div>
    </div>
  );
};

export default Blog;
