import React from 'react';
import './backend.css';
import database from '../../../assets/socialsvg/database-design.png';
import server from '../../../assets/socialsvg/server-side-logic.png';
import api from '../../../assets/socialsvg/API-development.png';
import fronted from '../../../assets/socialsvg/front-end-integration.png';
import unit from '../../../assets/socialsvg/integration.png';
import deployment from '../../../assets/socialsvg/deployment.png';

const BackendCard = () => {
  return (
    <section className='backend_sec'>
      <div className='container'>
        <div className='backend_development_sec mb-5'>
          {renderDevelopmentBox(
            'Database Design',
            'Clarify requirements, back-end developer designs database schema, selects database management system, and creates tables, indexes, and relationships for storage and retrieval',
            database,
            'db-1'
          )}
          {renderDevelopmentBox(
            'Server-Side Logic',
            'Developer codes server-side logic, implementing business logic, data processing, and authentication using languages like Java, Python, Ruby, or Node.js',
            server,
            'db-2'
          )}
          {renderDevelopmentBox(
            'API Development',
            'Back-end developers craft APIs facilitating front-end and back-end communication. Define endpoints, request-response formats, and authentication for seamless API interaction',
            api,
            'db-3'
          )}
        </div>

        <div className='seprator_sec '>
          {[...Array(3)].map((_, index) => (
            <div className='bullet_point' key={index}>
              <div className='bullet_point_2'></div>
            </div>
          ))}
        </div>

        <div className='backend_development_sec mb-5'>
          {renderDevelopmentBox(
            'Front-end Integration',
            'Collaborating with front-end developers, back-end developer integrates server-side logic with the UI. Tests APIs, ensuring accurate data transmission and display',
            fronted,
            'db-4'
          )}
          {renderDevelopmentBox(
            'Integration Unit Test Cases',
            'Post-testing, back-end code deploys to server/cloud. Establish infrastructure, configure environment, and deploy code for user accessibility',
            unit,
            'db-5'
          )}
          {renderDevelopmentBox(
            'Deployment - CI/CD',
            'Together, CI/CD pipelines streamline the development process, reduce errors, and accelerate the delivery of high-quality software while ensuring that new features, bug fixes, and updates are delivered to users quickly and reliably, without manual intervention.',
            deployment,
            'db-6'
          )}
        </div>
      </div>
    </section>
  );
};

const renderDevelopmentBox = (title, description, imageSrc, className) => (
  <div className={`develop_box ${className} relative`}>
    <h5 className='mb-2 font-Gelasio'>{title}</h5>
    <p className='font-Inter'>{description}</p>
    <span className='develop_icon'>
      <img src={imageSrc} alt='img' />
    </span>
  </div>
);

export default BackendCard;
