import React from 'react';
import FundManagement from './FundManagement';
import PropTypes from 'prop-types';

const Results = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-end justify-center pt-0 px-0 pb-[5px] box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <div className='h-[931px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-[490px] lg:flex-1 mq750:min-w-full'>
        <img
          className='self-stretch h-[854px] relative rounded-tl-none rounded-tr-[45px] rounded-b-none max-w-full overflow-hidden shrink-0 object-cover lg:self-stretch lg:w-auto'
          loading='lazy'
          alt=''
          src='/caseStudies/Quant-Capital/rectangle-3422@2x.png'
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start min-w-[617px] max-w-full mq750:min-w-full'>
        <div className='self-stretch [background:linear-gradient(135deg,_#2de6ff,_#255ffe_49%,_#e106ff)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 z-[1] mq1050:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
          <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#2de6ff,_#255ffe_49%,_#e106ff)] hidden max-w-full' />
          <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
            <h1 className='text-[48px] leading-[58.09px]'>
              <p className='m-0 font-semibold '>
                <b>{`Results `}</b>
              </p>
              <p className='m-0 font-normal'>Which we achieved</p>
            </h1>
            <div className='text-[24px] leading-[35px]'>
              The implementation of solutions yielded impressive results
            </div>
          </div>
          <div className='h-[115px] w-[53px] flex flex-col items-start justify-start'>
            <img
              className='self-stretch h-[65px] relative max-w-full overflow-hidden shrink-0 z-[2]'
              alt=''
              src='/caseStudies/Quant-Capital/frame.svg'
            />
          </div>
        </div>
        <div className='self-stretch rounded-t-none rounded-br-none rounded-bl-[45px] bg-darkslategray-200 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[171px] box-border gap-[55px] max-w-full z-[2] text-xl mq750:pt-[49px] mq750:pb-[111px] mq750:box-border mq450:gap-[27px] mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
          <div className='w-[950px] h-[590px] relative rounded-t-none rounded-br-none rounded-bl-26xl bg-darkslategray-200 hidden max-w-full' />
          <FundManagement
            fundManagement='Fund Management'
            endUsersCanEasilyDepositAnd='End users can easily deposit and withdraw the funds through the Quant Capital tool.'
          />
          <div className='flex flex-row items-start justify-start gap-3 max-w-full'>
            <div className='flex flex-col items-start justify-start pt-0.5 px-0 pb-0'>
              <img
                className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
                alt=''
                src='/caseStudies/Quant-Capital/bxuparrow@2x.png'
              />
            </div>
            <div className='flex flex-col items-start justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
              <b className='relative text-[20px] z-[3] mq450:text-base'>
                Impressive Portfolio
              </b>
              <div className='relative text-[18px] leading-[30px] z-[3]'>
                <p className='m-0'>{`Better visibility of their investment portfolio, including profits and withdrawals, `}</p>
                <p className='m-0'>through web and mobile dashboards.</p>
              </div>
            </div>
          </div>
          <FundManagement
            fundManagement='Time Management by Automation'
            propDisplay='inline-block'
            endUsersCanEasilyDepositAnd='Automation leads to reduced overheads, thus saving time.'
          />
        </div>
      </div>
    </section>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
