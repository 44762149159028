import React from 'react';
import PropTypes from 'prop-types';

const TestimonialContent1 = ({ className = '' }) => {
  return (
    <div
      className={`self-stretch flex flex-row flex-wrap items-start justify-start max-w-full ${className}`}
    >
      <div className='w-[205.7px] flex flex-row items-start justify-start pt-[92px] px-[60px] pb-[93px] box-border bg-cover bg-no-repeat bg-[top]'>
        <img
          className='h-[205px] w-[205.7px] relative object-cover hidden'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar@2x.png'
        />
        <img
          className='h-5 w-[10.5px] relative object-contain z-[2]'
          alt=''
          src='/caseStudies/Financial-Chatbot/group-3051953.svg'
        />
      </div>
      <img
        className='h-[205px] w-[205.7px] relative object-cover min-h-[205px]'
        loading='lazy'
        alt=''
        src='/caseStudies/Financial-Chatbot/bg-sidebar-1@2x.png'
      />
      <img
        className='h-[205px] w-[205.7px] relative object-cover min-h-[205px]'
        loading='lazy'
        alt=''
        src='/caseStudies/Financial-Chatbot/bg-sidebar-3@2x.png'
      />
      <div className='flex flex-row items-start justify-start relative'>
        <img
          className='h-[205px] w-[205.7px] relative object-cover'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar-2@2x.png'
        />
        <img
          className='h-[150px] w-[150px] absolute !m-[0] top-[-13px] right-[27.8px] object-cover z-[2]'
          alt=''
          src='/caseStudies/Financial-Chatbot/group-3051952@2x.png'
        />
      </div>
      <img
        className='h-[205px] w-[205.7px] relative object-cover min-h-[205px] z-[1]'
        alt=''
        src='/caseStudies/Financial-Chatbot/bg-sidebar-4@2x.png'
      />
      <div className='h-[205px] flex-1 relative min-w-[267px] max-w-full'>
        <img
          className='absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[205.7px] object-cover z-[2]'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar-5@2x.png'
        />
        <div className='absolute top-[0px] left-[205.7px] w-[205.7px] flex flex-row items-start justify-end pt-[92px] px-[59px] pb-[93px] box-border bg-cover bg-no-repeat bg-[top] z-[3]'>
          <img
            className='h-[205px] w-[205.7px] relative object-cover hidden'
            alt=''
            src='/caseStudies/Financial-Chatbot/bg-sidebar-6@2x.png'
          />
          <img
            className='h-5 w-[10.5px] relative z-[2]'
            alt=''
            src='/caseStudies/Financial-Chatbot/group-3051951.svg'
          />
        </div>
      </div>
    </div>
  );
};

TestimonialContent1.propTypes = {
  className: PropTypes.string,
};

export default TestimonialContent1;
