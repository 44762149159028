import React from 'react';
import FrameComponent from './components/FrameComponent';
import ContentDelivery from './components/ContentDelivery';
import ImpactItems from './components/ImpactItems';
import FrameComponent1 from './components/FrameComponent1';
import FrameComponent2 from './components/FrameComponent2';
import FrameComponent3 from './components/FrameComponent3';

const CaseStudiesKoach = () => {
  return (
    <div className='w-full relative bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[86px] box-border gap-[135px] leading-[normal] tracking-[normal] mq450:gap-[34px] mq750:gap-[67px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Koach/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Koach/background.svg'
      />
      <img
        className='w-full h-[739px] absolute !m-[0] top-[1457px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Koach/workstepbg@2x.png'
      />
      <img
        className='w-[712px] h-[495px] absolute !m-[0] top-[1291px] right-[-48px] rounded-26xl object-cover z-[1]'
        alt=''
        src='/caseStudies/Koach/rectangle-3416@2x.png'
      />
      <section className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-2.5 box-border max-w-full'>
        <FrameComponent />
      </section>
      <section className='self-stretch flex flex-row items-start justify-center pt-0 pb-3.5 pl-5 pr-[26px] box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[1134px] flex flex-col items-end justify-start gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[27px] pr-5'>
            <h1 className='m-0 w-[223px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative text-3xl leading-[40px] mq450:text-lg mq450:leading-[32px]'>
            <p className='m-0'>
              Koach is on a mission to improve lives and well-being by making
              high-quality coaching accessible to everyone. To achieve this
              vision, Koach partnered with Devtrust to develop a dynamic
              platform that
            </p>
            <p className='m-0'>
              connects users with approved coaches worldwide. This case study
              details how Devtrust
            </p>
            <p className='m-0'>
              leveraged modern technologies to enhance Koach’s website,
              integrating essential tools and features
            </p>
            <p className='m-0'>to foster positive change globally.</p>
          </div>
        </div>
      </section>
      <ContentDelivery />
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[2694px] right-[-187.4px] z-[2]'
        alt=''
        src='/caseStudies/Koach/group-3051956.svg'
      />
      <section className='self-stretch flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[728px] flex flex-col items-start justify-start gap-[23px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Solutions
            </h1>
          </div>
          <div className='self-stretch relative text-xl mq450:text-base'>
            Devtrust implemented a comprehensive solution to address these
            challenges
          </div>
        </div>
      </section>
      <ImpactItems />
      <FrameComponent1 />
      <FrameComponent2 />
      <FrameComponent3 />
      <div className='w-5 h-3 relative hidden z-[17]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesKoach;
