import React from 'react';
import PropTypes from 'prop-types';

const ImpactItems = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[17px] box-border max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[914px] flex flex-col items-end justify-start gap-[38px] max-w-full mq450:gap-[19px]'>
        <div className='flex flex-row items-start justify-end py-0 px-2 box-border max-w-full'>
          <div className='flex flex-row items-end justify-start gap-12 max-w-full mq450:gap-6 mq1050:flex-wrap'>
            <div className='h-[76px] w-[60px] flex flex-col items-start justify-end pt-0 px-0 pb-[21.2px] box-border'>
              <img
                className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full'
                loading='lazy'
                alt=''
                src='/caseStudies/Koach/group-1000001341.svg'
              />
            </div>
            <div className='flex flex-col items-start justify-start gap-[11px] max-w-full'>
              <div className='relative font-semibold mq450:text-lg'>
                User-Friendly Interface
              </div>
              <div className='relative text-lg leading-[30px]'>
                <p className='m-0'>{`Developed the front-end using React.js, creating an intuitive and engaging user `}</p>
                <p className='m-0'>
                  experience that allows users to discover and book coaching
                  sessions easily.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
        <div className='w-[825px] flex flex-row flex-wrap items-start justify-start pt-0 px-0 pb-1 box-border gap-[18px] max-w-full'>
          <div className='h-[76.3px] w-[120px] flex flex-col items-start justify-start pt-[21px] px-0 pb-0 box-border'>
            <img
              className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover'
              loading='lazy'
              alt=''
              src='/caseStudies/Koach/1280pxstripe-logo-revised-2014@2x.png'
            />
          </div>
          <div className='flex-1 flex flex-col items-start justify-start gap-3 min-w-[447px] max-w-full mq750:min-w-full'>
            <div className='relative font-semibold z-[3] mq450:text-lg'>
              Secure Payment Processing
            </div>
            <div className='self-stretch relative text-lg leading-[30px] z-[3]'>
              Integrated Stripe for seamless and secure payment processing,
              enabling users to book free and paid sessions with ease.
            </div>
          </div>
        </div>
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
        <div className='w-[806px] flex flex-row flex-wrap items-end justify-start pt-0 px-0 pb-[5px] box-border gap-[59px] max-w-full mq450:gap-[29px]'>
          <div className='flex flex-col items-start justify-end pt-0 px-0 pb-[18.3px]'>
            <img
              className='w-[60px] h-[60.7px] relative'
              loading='lazy'
              alt=''
              src='/caseStudies/Koach/group-1000001347.svg'
            />
          </div>
          <div className='flex-1 flex flex-col items-start justify-start gap-[11px] min-w-[447px] max-w-full mq750:min-w-full'>
            <div className='relative font-semibold z-[3] mq450:text-lg'>
              Appointment Management
            </div>
            <div className='self-stretch relative text-lg leading-[30px] z-[3]'>
              Incorporated a calendar system for users to schedule in-person or
              online sessions, ensuring seamless booking and management of
              appointments.
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-col items-end justify-start gap-6 max-w-full'>
          <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
          <div className='w-[811px] flex flex-col items-start justify-start gap-0.5 max-w-full'>
            <div className='w-[480px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
              <div className='relative font-semibold mq450:text-lg'>
                Content Management
              </div>
            </div>
            <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-[54px] max-w-full text-lg mq450:gap-[27px]'>
              <img
                className='h-[70px] w-[70px] relative overflow-hidden shrink-0'
                loading='lazy'
                alt=''
                src='/caseStudies/Koach/simpleiconsmailchimp.svg'
              />
              <div className='flex-1 flex flex-col items-start justify-start pt-[9px] px-0 pb-0 box-border min-w-[447px] max-w-full mq750:min-w-full'>
                <div className='self-stretch relative leading-[30px] z-[3]'>
                  Integrated Mailchimp for managing email campaigns and
                  delivering valuable insights and podcast episodes to
                  subscribers, enhancing user engagement and providing
                  continuous inspiration.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ImpactItems.propTypes = {
  className: PropTypes.string,
};

export default ImpactItems;
