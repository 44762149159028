// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ServicesCard_servicesCard__b7BDR {\n  word-break: break-all;\n  border-width: 1px;\n  --tw-border-opacity: 1;\n  border-color: rgb(209 213 219 / var(--tw-border-opacity));\n  --tw-bg-opacity: 1;\n  background-color: rgb(255 255 255 / var(--tw-bg-opacity));\n  padding: 2rem;\n  padding-top: 5rem;\n  padding-bottom: 5rem;\n}\n.ServicesCard_servicesCard__b7BDR:hover {\n  background-color: #f0fafa;\n}\n\n.ServicesCard_imagediv__2wPFK {\n  cursor: pointer;\n  font-family: Inter;\n  font-size: 1.25rem;\n  line-height: 1.75rem;\n  font-weight: 600;\n  line-height: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/atoms/servicesCard/ServicesCard.module.css"],"names":[],"mappings":"AACE;EAAA,qBAA0D;EAA1D,iBAA0D;EAA1D,sBAA0D;EAA1D,yDAA0D;EAA1D,kBAA0D;EAA1D,yDAA0D;EAA1D,aAA0D;EAA1D,iBAA0D;EAA1D;AAA0D;AAE5D;EACE,yBAAyB;AAC3B;;AAGE;EAAA,eAAuE;EAAvE,kBAAuE;EAAvE,kBAAuE;EAAvE,oBAAuE;EAAvE,gBAAuE;EAAvE;AAAuE","sourcesContent":[".servicesCard {\n  @apply bg-white p-8 py-20 break-all border border-gray-300;\n}\n.servicesCard:hover {\n  background-color: #f0fafa;\n}\n\n.imagediv {\n  @apply font-semibold text-xl leading-[20px] font-[Inter] cursor-pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"servicesCard": "ServicesCard_servicesCard__b7BDR",
	"imagediv": "ServicesCard_imagediv__2wPFK"
};
export default ___CSS_LOADER_EXPORT___;
