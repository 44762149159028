import React from 'react';
import PropTypes from 'prop-types';

const TestimonialContent = ({ className = '' }) => {
  return (
    <div
      className={`self-stretch flex flex-row flex-wrap items-end justify-between max-w-full gap-5 text-center text-17xl ${className}`}
    >
      <div className='flex flex-row items-start justify-start [row-gap:20px] max-w-full mq750:flex-wrap'>
        <img
          className='h-[205px] w-[205.7px] relative object-cover min-h-[205px] shrink-0'
          loading='lazy'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar@2x.png'
        />
        <img
          className='h-[205px] w-[205.7px] relative object-cover min-h-[205px] shrink-0'
          loading='lazy'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar-1@2x.png'
        />
      </div>
      <div className='h-36 w-[277px] flex flex-col items-start justify-start'>
        <div className='self-stretch flex flex-col items-start justify-start gap-[23px]'>
          <div className='self-stretch flex flex-row items-start justify-start py-0 px-7'>
            <div className='flex-1 flex flex-row items-start justify-start relative'>
              <img
                className='h-[205px] w-[calc(100%_-_15.3px)] absolute !m-[0] right-[8.2px] bottom-[-100px] left-[7.1px] max-w-full overflow-hidden object-cover'
                alt=''
                src='/caseStudies/Financial-Chatbot/bg-sidebar-2@2x.png'
              />
              <h1 className='m-0 w-[221px] relative text-inherit font-semibold font-[inherit] inline-block z-[2] mq450:text-3xl mq1050:text-10xl'>
                Testimonials
              </h1>
            </div>
          </div>
          <div className='flex flex-row items-start justify-start relative text-xl'>
            <img
              className='h-[205px] w-[205.7px] absolute !m-[0] top-[-128px] left-[-170.6px] object-cover'
              loading='lazy'
              alt=''
              src='/caseStudies/Financial-Chatbot/bg-sidebar-3@2x.png'
            />
            <img
              className='h-[205px] w-[205.7px] absolute !m-[0] top-[-128px] right-[-169.6px] object-cover'
              alt=''
              src='/caseStudies/Financial-Chatbot/bg-sidebar-4@2x.png'
            />
            <div className='relative z-[2] mq450:text-base'>
              Let’s see what are client says
            </div>
          </div>
        </div>
      </div>
      <div className='h-[205px] w-[411.4px] relative max-w-full'>
        <img
          className='absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[205.7px] object-cover'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar-5@2x.png'
        />
        <img
          className='absolute h-full top-[0px] bottom-[0px] left-[205.7px] max-h-full w-[205.7px] object-cover z-[1]'
          alt=''
          src='/caseStudies/Financial-Chatbot/bg-sidebar-6@2x.png'
        />
      </div>
    </div>
  );
};

TestimonialContent.propTypes = {
  className: PropTypes.string,
};

export default TestimonialContent;
