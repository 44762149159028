import React from 'react';
import './frontend.css';
import one from '../../../assets/socialsvg/1.svg';
import two from '../../../assets/socialsvg/2.svg';
import three from '../../../assets/socialsvg/3.svg';
import four from '../../../assets/socialsvg/4.svg';
import five from '../../../assets/socialsvg/5.svg';

const FrontentStep = () => {
  return (
    <div>
      <section className='frontend_steps_sec my-5'>
        <div className='container'>
          <div className='steps_body'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-3'>
              <div className='mb-8'>
                <div className='left_steps'></div>
              </div>
              <div className='mb-8'>
                <div className='right_steps'>
                  <div className='step_no'>
                    <span>
                      <img className='w-[100px]' src={one} alt='img' />
                    </span>
                  </div>
                  <div className='step_content'>
                    <h5>Requirement Analysis</h5>
                    <p>
                      {`Identify and understand user needs and project specifications to guide the development process.`}
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-8'>
                <div className='left_steps'>
                  <div className='step_content'>
                    <h5>Writing Code</h5>
                    <p>
                      {`Develop the application's user interface and functionality using HTML, CSS, and JavaScript.`}
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-8'>
                <div className='right_steps flex'>
                  <div className='step_no'>
                    <span>
                      <img className='w-auto' src={two} alt='img' />
                    </span>
                  </div>
                </div>
              </div>
              <div className='mb-8'>
                <div className='left_steps'></div>
              </div>
              <div className='mb-8'>
                <div className='right_steps flex'>
                  <div className='step_no'>
                    <span>
                      <img className='w-[100px]' src={three} alt='img' />
                    </span>
                  </div>
                  <div className='step_content'>
                    <h5>Optimization</h5>
                    <p>
                      {`Enhance performance by refining code, reducing load times, and improving responsiveness.`}
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-8'>
                <div className='left_steps'>
                  <div className='step_content'>
                    <h5>Testing & Refinement</h5>
                    <p>
                      {`Verify functionality and user experience through rigorous testing and iterative improvements.`}
                    </p>
                  </div>
                </div>
              </div>
              <div className='mb-8'>
                <div className='right_steps flex'>
                  <div className='step_no'>
                    <span>
                      <img className='w-auto' src={four} alt='img' />
                    </span>
                  </div>
                </div>
              </div>
              <div className='mb-8'>
                <div className='left_steps'></div>
              </div>
              <div className='mb-8'>
                <div className='right_steps flex'>
                  <div className='step_no line_hide'>
                    <span>
                      <img className='w-[100px]' src={five} alt='img' />
                    </span>
                  </div>
                  <div className='step_content'>
                    <h5>Bug Fixing & Debugging</h5>
                    <p>
                      {`Address and resolve issues to ensure a smooth and error-free user experience.`}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FrontentStep;
