import React, { useEffect, useState } from 'react';
import { aboutUsData } from '../../utils/index';
import { setLoader } from '../../redux/Action/actions';
import style from './About.module.css';
import { useDispatch } from 'react-redux';
import * as DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
const AboutUs = () => {
  const [data, setData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    getAboutData();
  }, []);

  const getAboutData = async () => {
    dispatch(setLoader(true));
    let aboutData = await aboutUsData();
    dispatch(setLoader(false));
    setData(aboutData);
  };
  return (
    <div className='bg-grey xl:py-32 py-24 overflow-hidden' data-testid='text'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='AboutUs'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='container mx-auto'>
        <div className={`${style.main}`}>
          <div
            className={`${style.head}`}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data[2]?.content?.rendered),
            }}
          ></div>
          <div
            className={style.aboutimg}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data[2]?.acf?.content),
            }}
          ></div>
        </div>
        <div
          className={`${style.secondiv}`}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data[1]?.content?.rendered),
          }}
        ></div>
      </div>
      <div className={`${style.thirddiv}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data[0]?.content?.rendered),
          }}
        ></div>
        <div
          className={style.cardheader}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data[0]?.acf?.content),
          }}
        ></div>
      </div>
    </div>
  );
};

export default AboutUs;
