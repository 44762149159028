import React, { useEffect, useMemo, useState } from 'react';
import ReviewCard from '../../atoms/reviewCard/ReviewCard';
import ReactSlicky from '../reactSlicky/ReactSlicky';
import { getTestimonial } from '../../utils';
const Customer = () => {
  const [testimonial, setTestimonial] = useState([]);
  const getTestimonialData = async () => {
    let data = await getTestimonial();
    setTestimonial(data);
  };
  useEffect(() => {
    getTestimonialData();
  }, []);
  const testimonialCard = useMemo(() => {
    return testimonial.map((item) => <ReviewCard data={item} key={item.id} />);
  }, [testimonial]);
  return (
    <div className='bg-white p-3 '>
      <div className='container mx-auto  ' data-aos='zoom-in'>
        {/* <img className='absolute right-0 bottom-0' alt = "circle" width = "200" height = "200" src = {subTract}/> */}
        <h1 className='font-sans xl:text-5xl xsm:text-[40px] font-bold xl:w-[785px] block mx-auto text-center pt-5 xl:leading-[66.67px] xsm:leading-[50px]'>
          What Clients <span className='text-custom-green'>Say</span>
        </h1>
        <br />
        <br />
        <div className='relative xl:h-[700px] xsm:h-[550px]'>
          {/* <img
            className='absolute -left-24 -top-10 xl:block xsm:hidden'
            alt='circle'
            width='200'
            height='200'
            src={subTract}
          />
          <img
            className='absolute -right-24 bottom-20 xl:block xsm:hidden '
            alt='circle'
            width='200'
            height='200'
            src={subTract}
          />
          <br />
          <br /> */}

          <div className='gap-y-2 shadow-lg bg-white border-none'>
            <ReactSlicky length={testimonial.length} max={3}>
              {testimonialCard}
            </ReactSlicky>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Customer;
