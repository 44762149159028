import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[15px] pl-[21px] pr-5 box-border max-w-full text-center text-17xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[921px] flex flex-col items-start justify-start gap-[88px] max-w-full mq450:gap-[22px] mq1050:gap-11'>
        <div className='self-stretch flex flex-row items-start justify-start py-0 px-[55px] box-border max-w-full mq1050:pl-[27px] mq1050:pr-[27px] mq1050:box-border'>
          <div className='flex-1 flex flex-col items-end justify-start gap-[23px] max-w-full'>
            <h1 className='m-0 relative text-inherit font-semibold font-[inherit] mq450:text-3xl mq1050:text-10xl'>
              The Financial Chatbot’s user-friendly interface
            </h1>
            <div className='self-stretch flex flex-row items-start justify-end py-0 pl-[71px] pr-[70px] text-3xl mq1050:pl-[35px] mq1050:pr-[35px] mq1050:box-border'>
              <div className='relative leading-[40px] mq450:text-lg mq450:leading-[32px]'>
                Simplifies financial data access and investment decision-making
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-[441px] relative rounded-3xs  bg-cover bg-no-repeat bg-[top]'>
          <img
            className='absolute top-[0px] left-[0px] rounded-3xs w-full h-full object-cover hidden'
            alt=''
            src='/caseStudies/Financial-Chatbot/image-5@2x.png'
          />
          <div className='absolute top-[0px] left-[62px] bg-gray-300 w-[127px] h-7 z-[1]' />
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
