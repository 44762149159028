import React from 'react';
import PropTypes from 'prop-types';

const GroupComponent1 = ({
  className = '',
  enhancedDataAccess,
  integratedAPIsProvidedUsers,
}) => {
  return (
    <div
      className={`self-stretch flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter mq750:flex-wrap ${className}`}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
          loading='lazy'
          alt=''
          src='/caseStudies/Financial-Chatbot/bxuparrow@2x.png'
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start gap-3.5 min-w-[460px] max-w-full mq750:min-w-full'>
        <b className='relative mq450:text-base'>{enhancedDataAccess}</b>
        <div className='self-stretch relative text-lg leading-[30px]'>
          {integratedAPIsProvidedUsers}
        </div>
      </div>
    </div>
  );
};

GroupComponent1.propTypes = {
  className: PropTypes.string,
  enhancedDataAccess: PropTypes.string,
  integratedAPIsProvidedUsers: PropTypes.string,
};

export default GroupComponent1;
