export const navbaritems = [
  {
    title: 'HOME',
    link: '/',
  },
  {
    title: 'ABOUT US',
    link: '/aboutus',
  },
  {
    title: 'PORTFOLIO',
    link: 'portfolio',
  },
  {
    title: 'OUR PROCESS',
    link: 'ourprocess',
  },
  {
    title: 'RESOURCES',
    link: 'dropdown',
    innerDropdown: [
      {
        title: 'BLOG',
        link: 'blog',
      },
      {
        title: 'CASE STUDIES',
        link: 'case-studies',
      },
    ],
  },
];
