import React from 'react';
import MarketplaceItems from './MarketplaceItems';

const FrameComponent4 = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[5.375rem] pr-[1.25rem] pl-[1.562rem] box-border max-w-full text-left text-[3rem] text-white font-inter mq450:pb-[2.25rem] mq450:box-border mq1050:pb-[3.5rem] mq1050:box-border ${className}`}
    >
      <div className='w-[71.563rem] flex flex-col items-start justify-start gap-[5.625rem] max-w-full lg:gap-[2.813rem] mq750:gap-[1.375rem]'>
        <div
          style={{ borderRadius: '45px' }}
          className='w-[720px] h-[374px] rounded-26xl [background:linear-gradient(135deg,_#90e2ac,_#465efb)] flex flex-col items-start justify-start pt-[1.875rem] pb-[5.125rem] pr-[1.875rem] pl-[4.875rem] box-border gap-[1rem] max-w-full z-[2] ml-4 mt-20 mq450:pt-[1.25rem] mq450:pb-[3.313rem] mq450:box-border mq750:pl-[2.438rem] mq750:box-border'
        >
          <div className='w-[720px] h-[23.375rem] relative rounded-26xl [background:linear-gradient(135deg,_#90e2ac,_#465efb)] hidden max-w-full' />
          <div className='self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq750:flex-wrap'>
            <div className='flex flex-col items-start justify-start pt-[3.75rem] px-[0rem] pb-[0rem] box-border'>
              <h1 className='m-0 text-[48px] self-stretch relative text-inherit z-[3] font-inherit mq450:text-[1.813rem] mq1050:text-[2.375rem]'>
                <p className='m-0 font-bold'>
                  <b>Problem</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='text-[24px] leading-[35px] font-normal z-[3]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of developing the application</p>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-end'>
              <img
                className='h-[48.5px] w-[50px] relative object-cover z-[3]'
                loading='lazy'
                alt=''
                src='/caseStudies/Skyward/logo-new@2x.png'
              />
            </div>
          </div>
        </div>

        <div className='self-stretch flex flex-row items-start justify-start gap-[60px] max-w-full text-center mq450:gap-[18px] mq750:flex-wrap mt-20'>
          <MarketplaceItems
            placeholder='1'
            marketplace='Client Verification'
            propBackgroundColor='#004F6B'
            propBackgroundColor1='#004F6B'
            definingAndImplementingFeatures='Verifying clients manually was 
            time-consuming and allowed 
            fake users to exploit the 
            system.'
            // setTheCoachingMarketplaceApart='The follow-up procedure for the leads
            // was manual because of which there '
            // competitors='were high chances of missing a lead.'
          />
          <MarketplaceItems
            // propMinWidth='253px'
            propFlex='1'
            propBackgroundColor='#00749D'
            propBackgroundColor1='#00749D'
            placeholder='2'
            marketplace='Payment Processing'
            definingAndImplementingFeatures='Collecting booking payments 
            was cumbersome, affecting
            transaction efficiency. '
            // setTheCoachingMarketplaceApart='time-consuming process into different onboarding and
            // payroll systems.'
            // competitors='processing, and user analytics.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#0099CF'
            propBackgroundColor1='#0099CF'
            placeholder='3'
            marketplace='Document Management'
            definingAndImplementingFeatures='Managing and organizing client 
            documents required significant
            manual effort.'
            // setTheCoachingMarketplaceApart='sheet causing multiple issues including duplicate entries and
            // data mismanagement.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#00B9FB'
            propBackgroundColor1='#00B9FB'
            placeholder='4'
            marketplace='Appointment Scheduling'
            definingAndImplementingFeatures='Coordinating property viewings 
            with clients was inefficient, 
            impacting agent productivity.'
            // setTheCoachingMarketplaceApart='sheet causing multiple issues including duplicate entries and
            // data mismanagement.'
          />
        </div>
      </div>
    </section>
  );
};

export default FrameComponent4;
