import React from 'react';
import BenefitItems from './BenefitItems';
import PropTypes from 'prop-types';

const FrameComponent3 = ({ className = '' }) => {
  return (
    <section
      className={`font-Inter   font-inter self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-[21px] box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <div className='h-[931px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-[490px] lg:flex-1 mq750:min-w-full'>
        <img
          className='w-[490px] h-[854px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1'
          loading='lazy'
          alt=''
          src='/caseStudies/Billing-Zero/rectangle-3422@2x.png'
          style={{ borderTopRightRadius: '45px' }}
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full mq750:pt-[38px] mq750:box-border mq750:min-w-full mq1050:pt-[58px] mq1050:box-border'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#49c9ff,_#0093d1)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 z-[2] mq750:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
            <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#49c9ff,_#0093d1)] hidden max-w-full' />
            <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
              <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[3] font-[inherit] mq450:text-10xl mq1050:text-19xl text-5xl'>
                <p className='m-0'>
                  <b>{`Results `}</b>
                </p>
                <p className='m-0'>Which we achieved</p>
              </h1>
              <div className='relative text-2xl leading-[35px] font-medium z-[3] mq450:text-lgi mq450:leading-[28px]'>
                The solutions delivered impactful outcomes
              </div>
            </div>
            <div className='h-[94px] w-[100px] flex flex-col items-start justify-start'>
              <img
                className='self-stretch h-[22.7px] relative max-w-full overflow-hidden shrink-0 object-cover z-[3]'
                loading='lazy'
                alt=''
                src='/caseStudies/Billing-Zero/billingzerologo-1@2x.png'
              />
            </div>
          </div>
          <div
            className='font-Inter self-stretch bg-steelblue-300 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[103px] box-border gap-[55.3px] min-h-[616px] max-w-full z-[3] text-xl mq750:pt-[49px] mq750:pb-[67px] mq750:box-border mq450:gap-7 mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border bg-[#005F88]'
            style={{ borderBottomLeftRadius: '45px' }}
          >
            <img
              className='w-[950px] h-[616px] relative hidden max-w-full'
              alt=''
              src='caseStudies/Billing-Zero/billingzerologo-1@2x.png'
            />
            <BenefitItems
              efficientManagement='Efficient Management'
              streamlinedProposalContract='Streamlined proposal, contract, and invoice management'
            />
            <BenefitItems
              efficientManagement='Versatile Contracts'
              streamlinedProposalContract='Recurring (hourly/weekly/monthly) and one-time contracts'
            />
            <BenefitItems
              efficientManagement='Payment Automation'
              streamlinedProposalContract='Automated payment reminders with secure online payments'
            />
            <BenefitItems
              efficientManagement='Seamless Transfers'
              streamlinedProposalContract={`Automated funds transfer to the paid subscribers using "Stripe Connect"`}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent3;
