import React from 'react';
import Slider from 'react-slick';

const ReactSlicky = ({ children, length, max }) => {
  const setSlides = (slide) => {
    if (max - slide <= 0) {
      return 1;
    }
    if (length <= 0) return max - slide;
    return length >= max - slide ? max - slide : length;
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: setSlides(0),
    slidesToScroll: 1,
    arrows: false,
    className: 'center',
    autoplay: true,
    speed: 2500,
    cssEase: 'linear',
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: setSlides(1),
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: setSlides(2),
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: setSlides(3),
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
};
export default ReactSlicky;
