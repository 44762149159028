import React from 'react';
import PropTypes from 'prop-types';
import icon1 from '../../../../assets/coach/1.svg';
import icon2 from '../../../../assets/coach/2.svg';
import icon3 from '../../../../assets/coach/5.svg';
import icon4 from '../../../../assets/coach/3.svg';
import icon5 from '../../../../assets/coach/4.svg';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[92px] box-border max-w-full text-left text-xl text-gray-300 font-inter mq750:pb-[39px] mq750:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
    >
      <div className='w-[914px] flex flex-row items-center justify-center gap-[39px] max-w-full mq450:gap-[19px]'>
        <div className='self-stretch w-[90%] flex flex-col items-start justify-start gap-[60px] max-w-full mq450:gap-[21px]'>
          {solutionData?.map((item, index) => (
            <div
              key={index}
              className='flex flex-col items-start justify-start py-0 box-border max-w-full'
            >
              <div className='flex flex-row items-start justify-between w-full gap-[52px] max-w-full mq450:gap-[26px] mq1050:flex-wrap'>
                <div className='w-[70px] flex-shrink-0 flex flex-col items-start justify-start pt-[21px] box-border'>
                  <img
                    className='w-full h-auto max-w-full overflow-hidden object-cover'
                    loading='lazy'
                    alt=''
                    src={item?.image}
                  />
                </div>
                <div className='flex-1 flex flex-col items-start justify-start gap-3.5'>
                  <b className='font-semibold text-[20px] leading-[43.57px] text-[#282828]'>
                    {item?.title}
                  </b>
                  <div
                    className='text-[18px] leading-[30px] text-[#282828]'
                    dangerouslySetInnerHTML={{ __html: item?.discription }}
                  />
                </div>
              </div>
              <div className='self-stretch mt-4'>
                <hr className='border-t-[1px] border-solid border-lightgray' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

const solutionData = [
  {
    id: 1,
    image: icon1,
    title: 'Efficient Integration with Map box',
    discription: `Successfully implemented <strong>Mapbox</strong> for precise location-based search.`,
  },
  {
    id: 2,
    image: icon2,
    title: 'Efficient Integration with Stripe',
    discription: `Integrated <strong>Stripe</strong> for secure and fast online payments.`,
  },
  {
    id: 3,
    image: icon3,
    title: 'Tailored Marketplace Functionality with Share tribe',
    discription: `Customized the Sharetribe platform to meet marketplace needs for coaching. services, ensuring flexibility and ease of use for coaches and clients.`,
  },
  {
    id: 4,
    image: icon4,
    title: 'Streamlined Workflow Automation via Zapier',
    discription: `Integrated Zapier to automate administrative tasks such as scheduling and notifications, optimizing operational efficiency and user experience.`,
  },
  {
    id: 5,
    image: icon5,
    title: 'Robust Backend Architecture and Security Measures',
    discription: `Designed and implemented a scalable backend infrastructure to support growth and maintain high performance under varying user loads.`,
  },
];

export default FrameComponent2;
