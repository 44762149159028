import React from 'react';
import PropTypes from 'prop-types';

const GroupComponent = ({
  className = '',
  improvedInvestmentInsights,
  advancedToolsAndAnalyticsEmpowe,
  optimizingTheirPortfoliosAnd,
}) => {
  return (
    <div
      className={`flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter ${className}`}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
          loading='lazy'
          alt=''
          src='/caseStudies/Financial-Chatbot/bxuparrow@2x.png'
        />
      </div>
      <div className='flex flex-col items-start justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
        <b className='relative mq450:text-base'>{improvedInvestmentInsights}</b>
        <div className='relative text-lg leading-[30px]'>
          <p className='m-0'>{advancedToolsAndAnalyticsEmpowe}</p>
          <p className='m-0'>{optimizingTheirPortfoliosAnd}</p>
        </div>
      </div>
    </div>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
  improvedInvestmentInsights: PropTypes.string,
  advancedToolsAndAnalyticsEmpowe: PropTypes.string,
  optimizingTheirPortfoliosAnd: PropTypes.string,
};

export default GroupComponent;
