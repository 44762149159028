import React from 'react';

const VerificationSolution = ({ className = '' }) => {
  return (
    <section
      className={`font-inter font-Inter self-stretch flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[5.5rem] box-border max-w-full text-left text-[1.25rem]  mq450:pb-[2.313rem] mq450:box-border mq1050:pb-[3.563rem] mq1050:box-border ${className}`}
    >
      <div className='w-[57.125rem] flex flex-col items-start justify-start gap-[3.437rem] max-w-full mq450:gap-[1.688rem]'>
        <div className='self-stretch flex flex-col items-end justify-start gap-[2.625rem] max-w-full mq450:gap-[1.313rem]'>
          <div className='w-[53.938rem] flex flex-row items-start justify-end py-[0rem] px-[1.5rem] box-border max-w-full'>
            <div className='flex-1 flex flex-col items-start justify-start gap-[0.875rem] max-w-full'>
              <div className='w-[30.063rem] flex flex-row items-start justify-center py-[0rem] px-[1.25rem] box-border max-w-full'>
                <b className='relative mq450:text-[1rem] text-[#282828]'>
                  Client Verification
                </b>
              </div>
              <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-[4.5rem] max-w-full text-[1.125rem] mq450:gap-[1.125rem] mq1050:gap-[2.25rem]'>
                <div className='flex flex-col items-start justify-start pt-[0.375rem] px-[0rem] pb-[0rem]'>
                  <img
                    className='w-[5rem] h-[4.975rem] relative object-cover'
                    loading='lazy'
                    alt=''
                    src='/caseStudies/Skyward/223f2f003b7511ea95c1817edb7663ff@2x.png'
                  />
                </div>
                <div className='flex-1 flex flex-row items-start justify-start gap-[0.875rem] min-w-[26.938rem] max-w-full mq750:flex-wrap mq750:min-w-full'>
                  <div className='flex flex-col items-start justify-start pt-[0.875rem] px-[0rem] pb-[0rem]'>
                    <div className='flex flex-col items-start justify-start gap-[4rem]'>
                      <div className='w-[0.375rem] h-[0.375rem] relative rounded-[50%] bg-gray-900' />
                      <div className='w-[0.375rem] h-[0.375rem] relative rounded-[50%] bg-gray-900' />
                    </div>
                  </div>
                  <div className='flex-1 flex flex-col items-start justify-start gap-[0.625rem] min-w-[26.125rem] max-w-full mq750:min-w-full font-normal text-[#282828]'>
                    <div className='relative leading-[1.875rem] inline-block max-w-full'>
                      <p className='m-0'>
                        <span className='font-inter'>{`Integrated `}</span>
                        <i className='font-semibold font-inter'>Plaid API</i>
                        <span>{` to ensure authentic client verification, significantly `}</span>
                      </p>
                      <p className='m-0'>reducing fraudulent activities.</p>
                    </div>
                    <div className='relative leading-[1.875rem]'>
                      <p className='m-0'>{`Transunion: To gather information on credit history and activity to generate `}</p>
                      <p className='m-0'>credit reports.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=' font-Inter self-stretch h-[0.063rem] relative box-border border-t-[1px] border-solid border-whitesmoke-200' />
        </div>
        <div className=' font-Inter self-stretch flex flex-col items-start justify-start gap-[3.5rem] max-w-full mq450:gap-[1.75rem]'>
          <div className=' flex flex-row items-start justify-start py-[0rem] px-[3.437rem] box-border max-w-full mq750:pl-[1.688rem] mq750:pr-[1.688rem] mq750:box-border'>
            <div className='flex flex-row items-start justify-start gap-[3.25rem] max-w-full mq750:flex-wrap mq750:gap-[1.625rem]'>
              <div className='h-[3.831rem] w-[7.5rem] flex flex-col items-start justify-start pt-[0.375rem] px-[0rem] pb-[0rem] box-border'>
                <img
                  className='self-stretch flex-1 relative max-w-full overflow-hidden max-h-full object-cover'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Skyward/1280pxstripe-logo-revised-2014@2x.png'
                />
              </div>
              <div className='flex flex-col items-start justify-start gap-[0.875rem] max-w-full'>
                <b className='relative mq450:text-[1rem] text-[#282828]'>
                  Payment Processing
                </b>
                <div className='relative text-[1.125rem] leading-[1.875rem] z-[1] font-normal text-[#282828]'>
                  <span>{`Utilized `}</span>
                  <i className='font-semibold'>Stripe</i>
                  <span> for quick and secure online payment.</span>
                </div>
              </div>
            </div>
          </div>
          <div className='self-stretch h-[0.438rem] flex flex-row items-start justify-start pt-[0rem] px-[0rem] pb-[0.375rem] box-border max-w-full'>
            <div className='self-stretch flex-1 relative box-border max-w-full z-[1] border-t-[1px] border-solid border-whitesmoke-200' />
          </div>
          <div className='w-[55.25rem] flex flex-row items-start justify-center pt-[0rem] px-[1.25rem] pb-[0.812rem] box-border max-w-full'>
            <div className='w-[44rem] flex flex-row items-end justify-between max-w-full gap-[1.25rem] mq750:flex-wrap'>
              <img
                className='h-[4.169rem] w-[3.125rem] relative min-h-[4.188rem]'
                loading='lazy'
                alt=''
                src='/caseStudies/Skyward/group-3051926.svg'
              />
              <div className='flex flex-col items-start justify-start gap-[0.875rem] max-w-full'>
                <b className='relative mq450:text-[1rem] text-[#282828]'>
                  Document Management
                </b>
                <div className='relative text-[1.125rem] leading-[1.875rem] z-[1] font-normal text-[#282828]'>
                  <span>{`Developed a custom `}</span>
                  <i className='font-semibold'>PDF annotator</i>
                  <span> for efficient document editing.</span>
                </div>
              </div>
            </div>
          </div>
          <div className='self-stretch flex flex-col items-end justify-start gap-[3.062rem] max-w-full mq450:gap-[1.5rem]'>
            <div className='self-stretch h-[0.063rem] relative box-border z-[1] border-t-[1px] border-solid border-whitesmoke-200' />
            <div className='w-[55.688rem] flex flex-row items-start justify-end py-[0rem] px-[4.187rem] box-border max-w-full mq1050:pl-[2.063rem] mq1050:pr-[2.063rem] mq1050:box-border'>
              <div className='flex-1 flex flex-row items-start justify-between max-w-full gap-[1.25rem] mq1050:flex-wrap'>
                <div className='flex flex-col items-start justify-start pt-[0.25rem] px-[0rem] pb-[0rem]'>
                  <img
                    className='w-[3.438rem] h-[3.438rem] relative overflow-hidden shrink-0'
                    loading='lazy'
                    alt=''
                    src='/caseStudies/Skyward/simpleiconsgooglecalendar.svg'
                  />
                </div>
                <div className='flex flex-col items-start justify-start gap-[0.875rem] max-w-full'>
                  <b className='relative mq450:text-[1rem] text-[#282828]'>
                    Appointment Scheduling
                  </b>
                  <div className='relative text-[1.125rem] leading-[1.875rem] z-[1] font-normal text-[#282828]'>
                    <span>{`Quick appointment booking with alerts, integrated with `}</span>
                    <i className='font-semibold'>Google calendar.</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerificationSolution;
