import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const ChallengeColumns = ({
  className = '',
  propBackgroundColor,
  propBackgroundColor1,
  emptyChallenge,
  propWidth,
  propMinWidth,
  needForAn,
  interactivePlatform,
  usersRequiredAnEngaging,
  andInteractivePlatformThat,
  enhancesTheirInvestment,
  monitoringExperience,
}) => {
  const groupDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const rectangleDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor1,
    };
  }, [propBackgroundColor1]);

  const emptyChallengeStyle = useMemo(() => {
    return {
      width: propWidth,
      minWidth: propMinWidth,
    };
  }, [propWidth, propMinWidth]);

  return (
    <div
      className={`flex flex-col items-start justify-start gap-10 text-center text-13xl text-white font-inter ${className}`}
    >
      <div
        className='rounded-3xs bg-darkred flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'
        style={groupDivStyle}
      >
        <div
          className='h-[69px] w-[69px] relative rounded-3xs bg-darkred hidden'
          style={rectangleDivStyle}
        />
        <b
          className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'
          style={emptyChallengeStyle}
        >
          {emptyChallenge}
        </b>
      </div>
      <div className='flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
        <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
          <p className='m-0'>{needForAn}</p>
          <p className='m-0'>{interactivePlatform}</p>
        </div>
        <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
          <p className='m-0'>{usersRequiredAnEngaging}</p>
          <p className='m-0'>{andInteractivePlatformThat}</p>
          <p className='m-0'>{enhancesTheirInvestment}</p>
          <p className='m-0'>{monitoringExperience}</p>
        </div>
      </div>
    </div>
  );
};

ChallengeColumns.propTypes = {
  className: PropTypes.string,
  emptyChallenge: PropTypes.string,
  needForAn: PropTypes.string,
  interactivePlatform: PropTypes.string,
  usersRequiredAnEngaging: PropTypes.string,
  andInteractivePlatformThat: PropTypes.string,
  enhancesTheirInvestment: PropTypes.string,
  monitoringExperience: PropTypes.string,

  /** Style props */
  propBackgroundColor: PropTypes.any,
  propBackgroundColor1: PropTypes.any,
  propWidth: PropTypes.any,
  propMinWidth: PropTypes.any,
};

export default ChallengeColumns;
