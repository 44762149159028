import React from 'react';
import PropTypes from 'prop-types';

const Problem = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[5.375rem] pr-[1.25rem] pl-[1.562rem] box-border max-w-full text-left text-[3rem] text-white font-inter mq450:pb-[2.25rem] mq450:box-border mq1050:pb-[3.5rem] mq1050:box-border ${className}`}
    >
      <div className='w-[71.563rem] flex flex-col items-start justify-start gap-[5.625rem] max-w-full lg:gap-[2.813rem] mq750:gap-[1.375rem]'>
        <div
          style={{ borderRadius: '50px' }}
          className='w-[720px] h-[374px] rounded-26xl [background:linear-gradient(135deg,_#90E2AC,_#465EFB)] flex flex-col items-start justify-start pt-[1.875rem] pb-[5.125rem] pr-[1.875rem] pl-[4.875rem] box-border gap-[1rem] max-w-full z-[2] ml-4 mt-14 mq450:pt-[1.25rem] mq450:pb-[3.313rem] mq450:box-border mq750:pl-[2.438rem] mq750:box-border'
        >
          <div className='w-[720px] h-[23.375rem] relative rounded-26xl [background:linear-gradient(135deg,_#90E2AC,_#465EFB)] hidden max-w-full' />
          <div className='self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq750:flex-wrap'>
            <div className='flex flex-col items-start justify-start pt-[3.75rem] px-[0rem] pb-[0rem] box-border'>
              <h1 className='m-0 self-stretch relative text-inherit z-[3] font-inherit mq450:text-[1.813rem] mq1050:text-[2.375rem]'>
                <p className='m-0 font-bold'>
                  <b>Problem</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='text-[24px] leading-[35px] font-normal z-[3]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of developing the application</p>
              </div>
            </div>
            <div className='h-[73px] w-28 relative'>
              {/* <img
              className='absolute h-full top-[0px] bottom-[0px] left-[0px] max-h-full w-[60px] z-[3]'
              loading='lazy'
              alt=''
            /> */}
              <img
                className='absolute top-[0px] left-[59px] w-[53px] h-[65px] overflow-hidden z-[4]'
                alt=''
                src='/caseStudies/Quant-Capital/frame.svg'
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col items-start justify-start gap-10 max-w-full  mt-20 text-center text-13xl mq750:gap-5'>
          <div className='w-[941px] flex flex-row items-start justify-between gap-5 max-w-full mq450:flex-wrap mq450:justify-center'>
            <div className='bg-blue-600 flex flex-row h-[69px] w-[69px] rounded-xl justify-center items-center z-[1]'>
              {/* <div className='h-[69px] w-[69px] relative  bg-royalblue hidden' /> */}
              <b className='relative inline-block text-3xl z-[1] mq450:text-lgi mq1050:text-7xl'>
                1
              </b>
            </div>
            <div className='bg-blue-500 flex flex-row  h-[69px] w-[69px] rounded-xl justify-center items-center z-[1]'>
              <div className='h-[69px] w-[69px] relative rounded-3xs bg-dodgerblue hidden' />
              <b className='relative inline-block text-3xl z-[1] mq450:text-lgi mq1050:text-7xl'>
                2
              </b>
            </div>
            <div className='w-20 flex flex-col items-start justify-start py-0 pl-0 pr-[11px] box-border'>
              <div className='bg-blue-400 flex flex-row h-[69px] w-[69px] rounded-xl justify-center items-center z-[1]'>
                <div className='h-[69px] w-[69px] relative rounded-3xs bg-cornflowerblue-200 hidden' />
                <b className='relative inline-block text-3xl z-[1] mq450:text-lgi mq1050:text-7xl'>
                  3
                </b>
              </div>
            </div>
            <div className='bg-blue-300 flex flex-row h-[69px] w-[69px] rounded-xl justify-center items-center z-[1]'>
              <div className='h-[69px] w-[69px] relative rounded-3xs bg-cornflowerblue-100 hidden' />
              <b className='relative inline-block text-3xl z-[1] mq450:text-lgi mq1050:text-7xl'>
                4
              </b>
            </div>
          </div>
          <div className='flex flex-row items-start justify-start gap-14 max-w-full text-left text-[22px] font-semibold leanding-[43.57px] text-[#282828] lg:flex-wrap mq750:gap-7'>
            <div className='flex flex-col items-start justify-start gap-[13px]'>
              <div className='relative text-[22px] font-semibold z-[1] mq450:text-lg'>
                Manual Deposits
              </div>
              <div className='h-[90px] relative leading-[30px] font-normal text-[18px] leanding-[43.57px] text-[#5A5A5A] inline-block z-[1]'>
                <p className='m-0'>{`The funds were deposited `}</p>
                <p className='m-0'>and all the details were</p>
                <p className='m-0'>tracked using Excel sheets.</p>
              </div>
            </div>
            <div className='flex flex-row items-start justify-start gap-10 max-w-full mq750:flex-wrap mq750:gap-5'>
              <div className='flex flex-col items-start justify-start gap-3 min-w-[247px] mq750:flex-1'>
                <div className='relative text-[22px] font-semibold inline-block min-w-[106px] z-[1] mq450:text-lg'>
                  Efficiency
                </div>
                <div className='relative leading-[30px] font-normal text-[18px] leanding-[43.57px] text-[#5A5A5A] text-dimgray-200 z-[1]'>
                  <p className='m-0'>{`Tremendous overhead was `}</p>
                  <p className='m-0'>{`generated due to a manual `}</p>
                  <p className='m-0'>{`process that was hampering `}</p>
                  <p className='m-0'>the efficiency.</p>
                </div>
              </div>
              <div className='flex flex-col items-start justify-start gap-3  min-w-[241px] mq750:flex-1'>
                <div className='relative text-[22px] font-semibold z-[1] mq450:text-lg'>
                  Massive Calculation
                </div>
                <div className='relative leading-[30px] font-normal text-[18px] leanding-[43.57px] text-[#5A5A5A] text-dimgray-200 z-[1]'>
                  <p className='m-0'>{`Excel sheets were used in a `}</p>
                  <p className='m-0'>{`cumbersome manner to `}</p>
                  <p className='m-0'>{`calculate profit and `}</p>
                  <p className='m-0'>commission.</p>
                </div>
              </div>
            </div>
            <div className='flex flex-col items-start justify-start gap-3'>
              <div className='relative text-[22px] font-semibold z-[1] mq450:text-lg'>
                Sloppy Tracking
              </div>
              <div className='relative leading-[30px] font-normal text-[18px] leanding-[43.57px] text-[#5A5A5A] text-dimgray-200 z-[1]'>
                <p className='m-0'>{`Inefficient tracking of deposits, `}</p>
                <p className='m-0'>{`depositors, commissions and `}</p>
                <p className='m-0'>profits.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Problem.propTypes = {
  className: PropTypes.string,
};

export default Problem;
