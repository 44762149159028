import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent = ({ className = '' }) => {
  return (
    // <div
    //   className={`font-Inter flex flex-row items-start justify-between py-0 pl-5 pr-0 box-border gap-[71px] w-full text-center text-65xl text-black lg:flex-wrap mq750:gap-[35px] mq450:gap-[18px] ${className}`}
    // >
    //   <div className='ml-20 w-1/2 flex flex-col items-start justify-start pt-[205px] px-0 pb-0 box-border min-w-[538px] max-w-full lg:flex-1 mq750:min-w-full mq450:pt-[133px] mq450:box-border'>
    //     <div className='self-stretch flex flex-col items-start justify-start gap-[29px] max-w-full'>
    //       <div className='self-stretch flex flex-row items-start justify-start py-0 px-[38px] box-border max-w-full'>
    //         <div className='flex-1 flex flex-col items-start justify-start gap-[22px] max-w-full'>
    //           <h1 className='m-0 relative text-inherit font-bold font-[inherit] mq450:text-6xl mq1050:text-23xl text-[84px]'>
    // <span>Billing</span>
    // <span className='text-[#0070B9]'> Zero</span>
    //           </h1>
    //           <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
    //             <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
    //           </div>
    //         </div>
    //       </div>
    //       <div className='h-[90px] relative text-xl leading-[30px] text-dimgray-200 inline-block mq450:text-base mq450:leading-[24px] text-[#5A5A5A] font-normal'>
    // <p className='m-0'>
    //   Billing Zero is an all-in-one SAAS solution for
    // </p>
    // <p className='m-0 whitespace-pre-wrap'>
    //   automating business workflows and managing contracts
    // </p>
    // <p className='m-0'>with advanced invoicing and billing features.</p>
    //       </div>
    //     </div>
    //   </div>
    //   <img
    //     className='h-[655px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
    //     alt=''
    //     src='/caseStudies/Billing-Zero/mask-group@2x.png'
    //     style={{ borderBottomLeftRadius: '45px' }}
    //   />
    // </div>
    <div
      className={`w-full flex flex-row items-start justify-between py-0 box-border gap-[0] max-w-full text-center font-inter ${className}`}
    >
      <div className='w-1/2 flex flex-col items-start justify-start pt-[194px] px-0 pb-0 box-border min-w-[493px] max-w-full lg:flex-1 mq750:min-w-full mq450:pt-[126px] mq450:box-border'>
        <div className='self-stretch flex flex-col items-end justify-start gap-[17px]'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[22px] pr-5'>
            <h1 className='m-0 w-[538px] text-[84px] font-bold inline-block mq450:text-4xl mq1050:text-5xl'>
              <span>Billing</span>
              <span className='text-[#0070B9]'> Zero</span>
            </h1>
          </div>
          <div className='self-stretch flex flex-col items-end justify-start gap-[45px] text-5xl text-gray-200 mq750:gap-[22px]'>
            <div className='self-stretch flex flex-col items-end justify-start gap-[38px] text-xl text-dimgray-200 mq750:gap-[19px]'>
              <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5'>
                <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
              </div>
              <div className='self-stretch relative leading-[30px] text-[#5A5A5A] text-[20px] mq450:text-base mq450:leading-[24px]'>
                <p className='m-0'>
                  Billing Zero is an all-in-one SAAS solution for
                </p>
                <p className='m-0 whitespace-pre-wrap'>
                  automating business workflows and managing contracts
                </p>
                <p className='m-0'>
                  with advanced invoicing and billing features.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full rounded-bl-[40px] overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Billing-Zero/mask-group@2x.png'
      />
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
