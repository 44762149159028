import React from 'react';
import '../manualTesting/menualTesting.css';
import aws from '../../../assets/socialsvg/aws_farm.svg';
import jmetar from '../../../assets/socialsvg/jmetar.svg';
import frame from '../../../assets/socialsvg/Frame.svg';
import selenium from '../../../assets/socialsvg/se.svg';
import appium from '../../../assets/socialsvg/appium.svg';

const ToolCard = ({ img, title, description }) => (
  <div className='product_box mb-3'>
    <span>
      <img className='m-auto' src={img} alt='img' />
    </span>
    <h5 className='text-xl font-bold mt-2 font-Gelasio'>{title}</h5>
    <p className='text-lg'>{description}</p>
  </div>
);

const ToolsAutomation = () => {
  return (
    <div>
      <section className='qaqc_tool my-5'>
        <div className='container mx-auto relative tools_right'>
          <div className='tools_sec'>
            <h2 className='sm:text-end text-center'>
              Tools
              <br />
              <span>for automation testing</span>
            </h2>
            <p className='font-Inter font-normal sm:text-end text-center'>
              Utilize advanced automation testing tools to streamline <br />
              and accelerate your testing processes. These tools help <br />
              ensure thorough coverage, enhance software reliability, <br />
              and deliver faster, more accurate results, allowing <br />
              you to maintain high quality and performance <br />
              in your applications.
            </p>
          </div>

          <div className='qaqc_body_tool'>
            <div className='grid grid-cols-1 md:grid-cols-3 max-w-[1000px] m-auto'>
              <div className='col-span-1 md:col-span-1 flex justify-end'>
                <div className='product_parrent first_col'>
                  {cardData.lastdata.map((item, index) => (
                    <ToolCard key={index} {...item} />
                  ))}
                </div>
              </div>
              <div className='col-span-1 md:col-span-1 flex justify-center'>
                <div className='product_parrent second_col'>
                  {cardData.middleData.map((item, index) => (
                    <ToolCard key={index} {...item} />
                  ))}
                </div>
              </div>

              <div className='col-span-1 md:col-span-1 flex justify-start'>
                <div className='product_parrent firsts_col'>
                  <ToolCard
                    img={aws}
                    title='AWS Device Farm'
                    description='Streamline web and mobile app testing across diverse environments without provisioning or managing testing infrastructure'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ToolsAutomation;

const cardData = {
  middleData: [
    {
      img: jmetar,
      title: 'JMeter',
      description: `Apache project, a load-testing 
      tool analyzing and measuring 
      performance across services, 
      emphasizing web applications`,
    },
    {
      img: appium,
      title: 'Appium',
      description: `Appium is an open-source
      automation mobile testing tool
       used to test mobile applications. 
      It is used to automate native 
      and hybrid mobile apps`,
    },
  ],
  lastdata: [
    {
      img: frame,
      title: 'End Test',
      description: `Low-code automation platform 
      for web and mobile apps, enabling
      efficient creation of automated
      end-to-end tests`,
    },
    {
      img: selenium,
      title: 'Selenium',
      description: `Selenium is an open-source 
      framework that enables automated
      testing of web application across
      multiple browsers and platforms`,
    },
  ],
};
