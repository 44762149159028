import React from 'react';
import MarketplaceItems from './MarketplaceItems';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[87px] pl-5 pr-6 box-border max-w-full text-left text-29xl text-white font-inter mq450:pb-[37px] mq450:box-border mq1050:pb-[57px] mq1050:box-border ${className}`}
    >
      <div className='w-[1136px] flex flex-row items-end justify-center gap-[38px] max-w-full mq750:gap-[19px] mq1050:flex-wrap'>
        <div className='flex-1 flex flex-col items-start justify-start gap-[90px] min-w-[524px] max-w-full mq450:gap-[22px] mq750:min-w-full mq1050:gap-[45px]'>
          <div className='w-[712px] flex flex-row items-start justify-start relative max-w-full ml-4 mt-20'>
            <img
              className='h-[73px] w-[60px] absolute !m-[0] top-[34px] right-[86px] z-[1]'
              alt=''
            />
            <div
              className='flex-1 rounded-26xl [background:linear-gradient(135deg,_#144d83,_#51428c)] flex flex-col items-start justify-start pt-[30px] pb-[82px] pl-[78px] pr-[30px] box-border gap-4 max-w-full z-[4] mq450:pt-5 mq450:pb-[53px] mq450:box-border mq750:pl-[39px] mq750:box-border'
              style={{ borderRadius: '45px' }}
            >
              <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#144d83,_#51428c)] hidden max-w-full' />
              <div className='self-stretch h-44 relative'>
                <img
                  className='absolute top-[0px] left-[534px] w-[70px] h-[62.1px] z-[5]'
                  alt=''
                  src='/caseStudies/AI-Assistant/group-1000001294.svg'
                />
                <h1 className='m-0 absolute top-[60px] left-[0px] text-inherit z-[5] font-[inherit] mq450:text-10xl mq1050:text-19xl text-5xl'>
                  <p className='m-0 font-bold'>
                    <b>{`Problem `}</b>
                  </p>
                  <p className='m-0 font-normal'>Statement</p>
                </h1>
              </div>
              <div className='h-[70px] relative  leading-[35px] font-medium inline-block max-w-full z-[5] mq450:text-lgi mq450:leading-[28px] text-2xl'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of develop the application</p>
              </div>
            </div>
          </div>
          <div className='self-stretch flex flex-row items-start justify-start gap-[30px] max-w-full text-center text-13xl mq450:gap-[18px] mq750:flex-wrap mt-6'>
            <MarketplaceItems
              placeholder='1'
              marketplace='Documentation Time'
              propBackgroundColor='#51428C'
              propBackgroundColor1='#51428C'
              definingAndImplementingFeatures='Physicians often spend plenty of time in 
            documenting patient information, treatment 
            plans, and other medical details resulting in 
            significant overheads.'
              // setTheCoachingMarketplaceApart='set the coaching marketplace apart from'
              // competitors='competitors.'
            />
            <MarketplaceItems
              // propMinWidth='253px'
              propFlex='1'
              propBackgroundColor='#6858A9'
              propBackgroundColor1='#6858A9'
              placeholder='2'
              marketplace='Compliance'
              definingAndImplementingFeatures='Medical documentation must adhere to strict 
            legal and regulatory standards.'
              // setTheCoachingMarketplaceApart='functionality, including scheduling, payment '
              // competitors='processing, and user analytics.'
            />
            <MarketplaceItems
              propMinWidth='308px'
              propBackgroundColor='#8372C5'
              propBackgroundColor1='#8372C5'
              placeholder='3'
              marketplace='Distraction'
              definingAndImplementingFeatures='Significant distraction from 
            addressing the core area 
            (consultation) due to manual note 
            taking process.'
              // setTheCoachingMarketplaceApart='increased traffic and support future growth effectively.'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
