// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TechTab_custom__zl1hg {\n    --tw-border-opacity: 1;\n    border-color: rgb(67 189 193 / var(--tw-border-opacity));\n    --tw-text-opacity: 1;\n    color: rgb(67 189 193 / var(--tw-text-opacity))\n}\n@media (min-width: 320px) {\n    .TechTab_custom__zl1hg {\n        border-bottom-width: 2px;\n        padding-bottom: 0.75rem\n    }\n}\n@media (min-width: 1280px) {\n    .TechTab_custom__zl1hg {\n        border-bottom-width: 4px;\n        padding-bottom: 1.75rem\n    }\n}\n", "",{"version":3,"sources":["webpack://./src/atoms/tab/TechTab.module.css"],"names":[],"mappings":"AACE;IAAA,sBAA2F;IAA3F,wDAA2F;IAA3F,oBAA2F;IAA3F;AAA2F;AAA3F;IAAA;QAAA,wBAA2F;QAA3F;IAA2F;AAAA;AAA3F;IAAA;QAAA,wBAA2F;QAA3F;IAA2F;AAAA","sourcesContent":[".custom {\n  @apply text-custom-green  border-custom-green xl:border-b-4 xsm:border-b-2\txl:pb-7 xsm:pb-3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom": "TechTab_custom__zl1hg"
};
export default ___CSS_LOADER_EXPORT___;
