import React from 'react';

const JobOption = ({ options, defaultValue, ...rest }) => {
  return (
    <select {...rest}>
      <option selected disabled>
        {defaultValue}
      </option>
      {options.map((option, index) => (
        <option key={index}>{option}</option>
      ))}
    </select>
  );
};

export default JobOption;
