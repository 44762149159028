import React from 'react';
import Content from './components/Content';
import Problem from './components/Problem';
import SolutionItems from './components/SolutionItems';
import Results from './components/Results';
import Mobile from './components/Mobile';
import Admin from './components/Admin';
import Build from './components/Build';

const CaseStudiesQuantCapital = () => {
  return (
    <div className='w-full relative font-Inter bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[97px] box-border gap-[138px] leading-[normal] tracking-[normal] mq750:gap-[69px] mq450:gap-[34px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Quant-Capital/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Quant-Capital/background.svg'
      />
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[2853px] right-[-187.4px]'
        alt=''
        src='/caseStudies/Quant-Capital/group-3051956.svg'
      />
      <section className='self-stretch flex flex-col items-end justify-start pt-0 px-0 pb-[7px] box-border max-w-full'>
        <Content />
      </section>
      <img
        className='w-full h-[734px] absolute !m-[0] top-[1400px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Quant-Capital/workstepbg@2x.png'
      />
      <img
        className='w-1/2 h-[495px] absolute !m-[0] top-[1171px] right-[-48px] rounded-[45px] object-cover z-[1]'
        loading='lazy'
        alt=''
        src='/caseStudies/Quant-Capital/rectangle-3416@2x.png'
      />
      <section className='self-stretch flex flex-col items-center justify-center pt-0 pb-[97px] px-[21px] box-border max-w-full text-center'>
        <div className='w-[775px] flex flex-col items-center justify-center gap-[19px] max-w-full'>
          <div className='self-stretch flex flex-row items-center justify-center py-0 px-5'>
            <h1 className='m-0 text-black text-[36px] font-semibold inline-block px-36'>
              Introduction
            </h1>
          </div>
          <div className='self-stretch relative text-center text-black text-[22px] leading-[40px] mq450:text-base'>
            <p className='m-0 '>
              Quant Capital is a fintech platform that enables cumulative funds
              collected
            </p>
            <p className='m-0'>
              <span>{`from multiple users to be invested using `}</span>
              <b className='font-inter'>MetaTrader4</b>
              <span className='font-inter'> API.</span>
            </p>
          </div>
        </div>
      </section>
      <Problem />
      <section className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 box-border max-w-full text-center text-17xl text-gray-300 font-inter'>
        <div className='w-full flex flex-col items-center justify-center gap-[23px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-5 pr-[21px]'>
            <h1 className='font-semibold text-[36px] leanding-[43.57px] text-[#282828]'>
              Solutions
            </h1>
          </div>
          <div className='text-[20px] font-normal leading-6 text-[#282828]'>
            DEVtrust implemented several innovative solutions to address these
            challenges
          </div>
        </div>
      </section>
      <SolutionItems />
      <Results />
      <Mobile />
      <Admin />
      <Build />
      <div className='w-5 h-3 relative hidden z-[18]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-300 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-300 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-300 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesQuantCapital;
