import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../redux/Action/actions';
import { ourProcess } from '../../utils';
import style from './OurProcess.module.css';
import { Helmet } from 'react-helmet';
import * as DOMPurify from 'dompurify';
import blocklog from '../../assets/images/organizeblocklog.svg';
import features from '../../assets/images/featuresselection.svg';
import sprint from '../../assets/images/sprintinitiation.svg';
import standup from '../../assets/images/standups.svg';
import review from '../../assets/images/sprintreview.svg';
import retrospective from '../../assets/images/retrospective.svg';
import OurProcessCard from '../../atoms/card/OurProcessCard';

const OurProcess = () => {
  const [header, setHeader] = useState({});

  const dispatch = useDispatch();
  const processData = async () => {
    dispatch(setLoader(true));
    let data = await ourProcess();
    dispatch(setLoader(false));
    setHeader(data[6]);
  };
  useEffect(() => {
    processData();
  }, []);

  const cardItems = useMemo(() => {
    return Data.map((item) => (
      <OurProcessCard
        title={item.title}
        img={item.img}
        description={item.description}
        key={item.id}
      />
    ));
  }, []);

  return (
    <div className='bg-white xl:py-28 py-24 overflow-hidden' data-testid='text'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'description' },
        ]}
        title='ourprocess'
        link={[{ rel: 'preconnent', href: '#' }]}
      ></Helmet>
      <div className=' container mx-auto '>
        <h1 className={style.headetitle}>
          <span
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(header?.title?.rendered.slice(0, 5)),
            }}
          ></span>
          <span
            className='text-[#25C7C7]'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(header?.title?.rendered.slice(5)),
            }}
          ></span>
        </h1>
        <p
          className='text-center xl:text-[30px] xsm:text-[20px] xsm:font-normal xl:font-medium my-15px font-[Inter]'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(header?.content?.rendered),
          }}
        ></p>
        <div>
          <div
            className={style.midimage}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(header?.acf?.image),
            }}
          ></div>
          <h1 className='text-[40px] font-medium font-Gelasio my-[15px] text-center '>
            How <span className='text-[#25C7C7]'>Scrum Works</span>
          </h1>
          <div className='grid grid-cols-3 lg:grid-cols-3 md:grid-cols-2 xsm:grid-cols-1 my-14'>
            {cardItems}
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurProcess;
const Data = [
  {
    id: 1,
    img: blocklog,
    title: 'Organize the Backlog',
    description:
      'Identification of a particular set of features by the product owner for each sprint which is defined in the product backlog.',
  },
  {
    id: 2,
    img: features,
    title: 'Features Selection',
    description:
      'Development team selects the top features from the backlog which they will deliver at the end of a sprint.',
  },
  {
    id: 3,
    img: sprint,
    title: 'Sprint Initiation',
    description:
      'After agreeing to do a particular feature, the feature is then broken into a smaller tasks which are developed by the product Owner found in the sprint backlog.',
  },
  {
    id: 4,
    img: standup,
    title: 'Standups',
    description:
      'The Sprint Starts & during this, there are daily meetings where the team members provide status of their tasks & discuss the problem related to a task. Scrum master is responsible for solving the problem during these daily meetings.',
  },
  {
    id: 5,
    img: review,
    title: 'Sprint Review',
    description:
      'After sprint completion, the features are presented to the product owner for approval & review is given to the team.',
  },
  {
    id: 6,
    img: retrospective,
    title: 'Retrospective',
    description:
      'Finally, in retrospective meeting the team, scrum master & product owner discuss improvements in the overall process.',
  },
];
