import React from 'react';
import PropTypes from 'prop-types';
import styles from './FrameComponent2.module.css';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section className={[styles.problemContentWrapper, className].join(' ')}>
      <div className={styles.problemContent}>
        <div className={styles.rectangleParent}>
          <div className={styles.frameChild} />
          <div className={styles.imagine}>IMAGINE</div>
          <div className={styles.problemStatement}>
            <div className={styles.problemDescription}>
              <h1 className={styles.problemStatement1}>
                <p className={styles.problem}>
                  <b>{`Problem `}</b>
                </p>
                <p className={styles.problem}>Statement</p>
              </h1>
              <div className={styles.hereAreTheContainer}>
                <p className={styles.problem}>
                  Here are the challenges which we faced
                </p>
                <p className={styles.problem}>
                  at the time of develop the application
                </p>
              </div>
            </div>
          </div>
          <img
            className={styles.problemIllustrationIcon}
            loading='lazy'
            alt=''
          />
        </div>
        <div className={styles.challengesContentParent}>
          <div className={styles.challengesContent}>
            <div className={styles.rectangleGroup}>
              <div className={styles.frameItem} />
              <b className={styles.challengeDivider}>1</b>
            </div>
            <div className={styles.challengeItem}>
              <div className={styles.rectangleContainer}>
                <div className={styles.frameInner} />
                <b className={styles.b}>2</b>
              </div>
            </div>
            <div className={styles.groupDiv}>
              <div className={styles.rectangleDiv} />
              <b className={styles.b1}>3</b>
            </div>
          </div>
          <div className={styles.solutionsContentParent}>
            <div className={styles.solutionsContent}>
              <div className={styles.solutionsHeader}>
                <div className={styles.scholarshipDiscovery}>
                  Scholarship Discovery
                </div>
                <div className={styles.aggregatingAccurateUpToDaContainer}>
                  <p
                    className={styles.problem}
                  >{`Aggregating accurate, up-to-date `}</p>
                  <p
                    className={styles.problem}
                  >{`scholarship opportunities was `}</p>
                  <p className={styles.problem}>labor-intensive.</p>
                </div>
              </div>
              <div className={styles.userEngagement}>
                <div className={styles.engagementDescription}>
                  <div className={styles.scholarshipDiscovery}>
                    User Engagement
                  </div>
                  <div className={styles.aggregatingAccurateUpToDaContainer}>
                    <p
                      className={styles.problem}
                    >{`Providing a seamless, interactive `}</p>
                    <p className={styles.problem}>user experience.</p>
                  </div>
                </div>
                <div className={styles.solutionTitle}>
                  <h1 className={styles.solutions}>Solutions</h1>
                </div>
              </div>
              <div className={styles.solutionsHeader1}>
                <div className={styles.guidance}>Guidance</div>
                <div className={styles.aggregatingAccurateUpToDaContainer}>
                  <p
                    className={styles.problem}
                  >{`Offering consistent guidance and `}</p>
                  <p
                    className={styles.problem}
                  >{`motivation through the college `}</p>
                  <p className={styles.problem}>admissions process.</p>
                </div>
              </div>
            </div>
            <div className={styles.solutionImplementation}>
              <div className={styles.devtrustImplementedA}>
                Devtrust implemented a comprehensive solution to address these
                challenges
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
