import React from 'react';
import FrameComponent from './components/FrameComponent';
import Content from './components/Content';
import FrameComponent1 from './components/FrameComponent1';
import ResultsParent from './components/ResultsParent';
import InterfaceParent from './components/InterfaceParent';
import ContactParent from './components/ContactParent';

const CaseStudiesNFoundation = () => {
  return (
    <div className='w-full relative font-Inter bg-white overflow-hidden flex flex-col items-start justify-start pt-0 px-0 pb-[85px] box-border gap-[113px] leading-[normal] tracking-[normal] mq450:gap-7 mq750:gap-14'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/N-Foundation/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/N-Foundation/background.svg'
      />
      <img
        className='w-full h-[800px] absolute !m-[0] top-[1400px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/N-Foundation/workstepbg@2x.png'
      />
      <section className='self-stretch flex flex-col pt-20 items-end justify-start px-0 pb-[7px] box-border max-w-full'>
        <Content />
      </section>
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[2959px] right-[-187.4px]'
        alt=''
        src='/caseStudies/N-Foundation/group-3051956.svg'
      />
      <img
        className='w-1/2 h-[495px] absolute !m-[0] top-[1211px] right-[-48px] rounded-[45px] object-cover z-[1]'
        loading='lazy'
        alt=''
        src='/caseStudies/N-Foundation/rectangle-3416@2x.png'
      />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 pb-[122px] pl-[21px] pr-5 box-border max-w-full text-center text-17xl text-gray-200 font-inter'>
        <div className='w-[969px] flex flex-col items-start justify-start gap-[19px] max-w-full font-semibold text-4xl leanding-[43.57px] text-[#282828]'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5 '>
            <h1 className='m-0 w-[223px] relative text-[36px] text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
              Introduction
            </h1>
          </div>
          <div className='relative font-normal text-[22px] leading-[40px] mq450:text-lg mq450:leading-[32px]'>
            <p className='m-0'>
              Sandeep and Nirmal Foundation is a non-profit organization
              dedicated to providing education
            </p>
            <p className='m-0'>
              to different age group. When they sought a web app development
              company to build
            </p>
            <p className='m-0'>
              an effective e-learning platform for children, DEVtrust&apos;s
              expertise stood out.
            </p>
          </div>
        </div>
      </section>
      <FrameComponent />

      <FrameComponent1 />
      <ResultsParent />
      <InterfaceParent />
      <ContactParent />
      <div className='w-5 h-3 relative hidden z-[16]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesNFoundation;
