import React, { useEffect, useMemo, useState } from 'react';
import style from './Portfolio.module.css';
import Modal from '../../atoms/modal/Modal';
import icon from '../../assets/images/portfoliobanner.svg';
import { setPortfolio } from '../../redux/Action/actions';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import PortfolioCard from '../../atoms/portfoliocard/portfolioCard/PortfolioCard';
const Portfolio = () => {
  const dataTabs = [
    'ALL',
    'EDUCATION',
    'FINANCE',
    'MARKETING',
    'CRM',
    'HEALTH',
    'MARKETPLACE',
    'SAAS',
    'REALSTATE',
  ];
  const [category, setCategory] = useState('ALL');
  const [showModal, setShowModal] = useState(false);
  const [portfoliodata, setPortfolioData] = useState([]);
  const [visibleItems, setVisibleItems] = useState(5);
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const { portfolio } = useSelector((reducers) => reducers.portfolioreducer);
  useEffect(() => {
    if (portfolio === undefined) {
      dispatch(setPortfolio());
      return;
    }
    setPortfolioData(portfolio);
  }, [portfolio]);
  const closeModal = () => {
    document.body.style.overflow = 'unset';
    setShowModal(false);
  };
  const openModal = () => {
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
    setData();
    setShowModal(true);
  };
  const reversedportfoliodata = [...portfoliodata].reverse();
  const portfolioData = useMemo(() => {
    return reversedportfoliodata?.slice(0, visibleItems).map((item) => (
      // <PortfolioData data={item} key={item.id} open={openModal} />
      <PortfolioCard
        data={item}
        key={item.id}
        open={openModal}
        imageUrl={item?.portfolio_bg_url}
        textContentColor={item?.acf?.text_color}
      />
    ));
  }, [reversedportfoliodata, visibleItems, openModal]);

  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 1);
  };

  const handleCategory = (selectedcategory) => {
    setCategory(selectedcategory);
    if (selectedcategory !== 'ALL') {
      let filterportfolio = portfolio.filter(
        (item) => item.acf.portfolio_type === selectedcategory
      );
      setPortfolioData(filterportfolio);
      return;
    }
    setPortfolioData(portfolio);
  };
  return (
    <div className='bg-white xl:py-28 py-24 overflow-hidden' data-testid='text'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='Portfolio'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>

      <div className='container mx-auto'>
        <div className=' py-20 flex  flex-col-reverse xl:flex-row gap-x-10 justify-between xl:flex-nowrap flex-wrap'>
          <div className='' data-aos='fade-right'>
            <h1 className='text-[40px]  text-[#282828] font-bold leading-[50.78px] font-Gelasio'>
              Our Innovation &nbsp;
              <br />
              <span className='text-[#43BDC1]'>& Experties</span>
            </h1>
            <p className='text-lg xl:w-[400px] w-auto  font-normal leading-[30px] py-2 font-[Inter]'>
              We go beyond being a typical product development firm that simply
              handles client needs. Instead, we serve as visionary architects of
              digital transformation and progress, driven by unwavering passion
              and a strong commitment to achieving excellence.
            </p>
            <div className='flex py-4 flex-col xl:items-start xsm:items-center justify-center'>
              <Link
                to={'/get-in-touch'}
                className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
              >
                <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                <span className='relative group-hover:text-white text-lg font-medium leading-[21px]'>
                  Get Quote
                </span>
              </Link>
            </div>
          </div>
          <div className='p-0'>
            <img src={icon}></img>
          </div>
        </div>
      </div>

      <div className='container mx-auto'>
        <h1 className='text-center text-[50px] font-bold font-Gelasio py-3 leading-[63px]'>
          Projects <span className='text-[#25C7C7]'>We’ve done</span>
        </h1>
        <div className='flex justify-center gap-x-10 gap-y-5 py-5 flex-wrap'>
          {dataTabs.map((item, index) => (
            <button
              className={`${
                item == category ? style.customdesign : 'text-green'
              } font-[Inter] text-[14px] font-medium leading-[16.94px] hover:text-custom-green`}
              key={index}
              onClick={() => handleCategory(item)}
            >
              {item}
            </button>
          ))}
        </div>
        <div className='w-full h-full'>
          {portfolioData}

          {/* Load More button */}
          {reversedportfoliodata.length > visibleItems && (
            <button
              onClick={handleLoadMore}
              className='load-more-button shadow-loadShadow w-full text-center mt-8 p-4 text-sm text-[#474747] font-medium'
            >
              Load More
            </button>
          )}
        </div>
      </div>
      <Modal showModal={showModal} data={data} hideModal={closeModal} />
    </div>
  );
};
export default Portfolio;
