import React from 'react';
import PropTypes from 'prop-types';

const SolutionList = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[13px] box-border max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div className='w-[914px] flex flex-col items-end justify-start gap-[54.5px] max-w-full mq450:gap-[27px]'>
        <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[26px] pr-5 box-border max-w-full'>
          <div className='flex flex-row items-start justify-start gap-12 max-w-full mq750:flex-wrap mq750:gap-6'>
            <div className='flex flex-col items-start justify-start pt-[19px] px-0 pb-0'>
              <img
                className='w-[60px] h-[60px] relative overflow-hidden shrink-0'
                loading='lazy'
                alt=''
                src='/caseStudies/Portfolio-Watc/website.svg'
              />
            </div>
            <div className='flex flex-col items-start justify-start gap-[11px] max-w-full'>
              <div className='relative font-semibold inline-block max-w-full mq450:text-lg'>
                Enhanced Interactive Web Application
              </div>
              <div className='relative text-lg leading-[30px]'>
                <p className='m-0'>
                  Developed a feature-rich web application that offers a dynamic
                  and
                </p>
                <p className='m-0'>engaging user experience.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border' />
        <div className='w-[795px] flex flex-col items-start justify-start gap-3 max-w-full'>
          <div className='w-[602px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
            <div className='relative font-semibold mq450:text-lg'>
              Integration of Plaid&apos;s Investment API
            </div>
          </div>
          <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-12 max-w-full text-lg mq450:gap-6'>
            <div className='flex flex-col items-start justify-start pt-[86px] px-0 pb-0'>
              <img
                className='w-[60px] h-[60px] relative overflow-hidden shrink-0'
                loading='lazy'
                alt=''
                src='/caseStudies/Portfolio-Watc/api.svg'
              />
            </div>
            <div className='flex-1 relative leading-[30px] inline-block min-w-[447px] max-w-full z-[1] mq750:min-w-full'>
              <p className='m-0'>
                Portfolio Watch uses Plaid&apos;s Investment API to integrate
                with online stock brokerage accounts. Users can connect their
                brokerage accounts to the program to monitor potential
                securities fraud, lawsuits, and settlements.
              </p>
              <p className='m-0'>&nbsp;</p>
              <p className='m-0'>
                By using Plaid&apos;s Investment API, Portfolio Watch creates a
                secure connection between the program and users&apos; online
                stock brokerage accounts.
              </p>
              <p className='m-0'>&nbsp;</p>
              <p className='m-0'>
                This connection allows for real-time monitoring of the linked
                accounts to detect any suspicious activities related to
                securities fraud or ongoing legal actions
              </p>
            </div>
          </div>
        </div>
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[1]' />
        <div className='w-[795px] flex flex-col items-start justify-start gap-2 max-w-full'>
          <div className='w-[471px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'>
            <div className='relative font-semibold mq450:text-lg'>
              Data Security Measures
            </div>
          </div>
          <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-12 max-w-full text-lg mq450:gap-6'>
            <img
              className='h-[60px] w-[60px] relative overflow-hidden shrink-0'
              loading='lazy'
              alt=''
              src='/caseStudies/Portfolio-Watc/data-security-measures.svg'
            />
            <div className='flex-1 flex flex-col items-start justify-start pt-1 px-0 pb-0 box-border min-w-[447px] max-w-full mq750:min-w-full'>
              <div className='self-stretch relative leading-[30px] z-[1]'>
                Implemented stringent security protocols to safeguard user data
                and detect potential securities fraud or legal actions. An Admin
                Panel was also introduced for monitoring user activities and
                analysing risks.
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch h-px relative border-whitesmoke-200 border-t-[1px] border-solid box-border z-[1]' />
        <div className='w-[795px] flex flex-row flex-wrap items-start justify-start gap-12 max-w-full mq450:gap-6'>
          <div className='flex flex-col items-start justify-start pt-[19px] px-0 pb-0'>
            <img
              className='w-[60px] h-[60px] relative overflow-hidden shrink-0'
              loading='lazy'
              alt=''
              src='/caseStudies/Portfolio-Watc/uiux.svg'
            />
          </div>
          <div className='flex-1 flex flex-col items-start justify-start gap-3 min-w-[447px] max-w-full mq750:min-w-full'>
            <div className='relative font-semibold mq450:text-lg'>
              UI/UX Improvements
            </div>
            <div className='self-stretch relative text-lg leading-[30px] z-[1]'>
              Enhanced navigation and user customization through the addition of
              intuitive search filters.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SolutionList.propTypes = {
  className: PropTypes.string,
};

export default SolutionList;
