import React from 'react';

const ResultsParent = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-end justify-center pt-0 px-0 pb-[5px] box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <div className='h-[931px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-[490px] lg:flex-1 mq750:min-w-full'>
        <img
          className='self-stretch h-[854px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-w-full overflow-hidden shrink-0  lg:self-stretch lg:w-auto'
          loading='lazy'
          alt=''
          src='/caseStudies/N-Foundation/rectangle-3422@2x.png'
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start min-w-[617px] max-w-full mq750:min-w-full'>
        <div className='self-stretch [background:linear-gradient(135deg,_#abdcff,_#0396ff)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 z-[1] mq1050:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
          <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#abdcff,_#0396ff)] hidden max-w-full' />
          <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
            <h1 className='text-[48px] leading-[58.09px]'>
              <p className='m-0 font-semibold '>
                <b>{`Results `}</b>
              </p>
              <p className='m-0 font-normal'>Which we achieved</p>
            </h1>
            <div className='text-[24px] leading-[35px]'>
              The implementation of solutions yielded impressive results
            </div>
          </div>
          <div className='h-[107px] w-[70px] flex flex-col items-start justify-start'>
            <img
              className='self-stretch h-[48.1px] relative max-w-full overflow-hidden shrink-0 z-[2]'
              alt=''
              src='/caseStudies/N-Foundation/group-1000001263-1.svg'
            />
          </div>
        </div>
        <div className='self-stretch rounded-t-none rounded-br-none rounded-bl-[45px] bg-darkslategray-200 flex flex-row items-start justify-start py-[75px] px-[62px] box-border min-h-[590px] max-w-full z-[2] text-xl mq750:pt-[49px] mq750:pb-[49px] mq750:box-border mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border'>
          <div className='h-[590px] w-[950px] relative rounded-t-none rounded-br-none rounded-bl-26xl bg-darkslategray-200 hidden max-w-full' />
          <div className='flex flex-col items-start justify-start gap-[55px] max-w-full z-[3] mq750:gap-[27px]'>
            <div className='flex flex-row items-start justify-start gap-3 max-w-full'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/N-Foundation/bxuparrow@2x.png'
                />
              </div>
              <div className='flex flex-col items-start text-[20px] justify-start gap-3.5 shrink-0 max-w-[calc(100%_-_30px)]'>
                <b className='relative mq450:text-base'>
                  Increased User Engagement
                </b>
                <div className='relative text-[18px] leading-[30px]'>
                  <span>{`The interactive and captivating content led to a `}</span>
                  <span className='font-semibold text-[#70C3FF]'>40%</span>
                  <span> increase in user engagement.</span>
                </div>
              </div>
            </div>
            <div className='flex flex-row items-start justify-start gap-3 max-w-full'>
              <div className='flex flex-col items-start justify-start pt-0.5 px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
                  alt=''
                  src='/caseStudies/N-Foundation/bxuparrow@2x.png'
                />
              </div>
              <div className='flex flex-col items-start text-[20px] justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
                <b className='relative inline-block max-w-full mq450:text-base'>
                  Improved Vocabulary Acquisition
                </b>
                <div className='relative text-[18px] leading-[30px]'>
                  <p className='m-0'>
                    <span className='font-inter text-white'>{`The multilingual features contributed to a `}</span>
                    <span className='font-semibold font-inter text-[#70C3FF]'>
                      35%
                    </span>
                    <span>{` improvement in vocabulary `}</span>
                  </p>
                  <p className='m-0'>
                    acquisition across different linguistic backgrounds.
                  </p>
                </div>
              </div>
            </div>
            <div className='flex flex-row items-start justify-start gap-3 max-w-full'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
                  alt=''
                  src='/caseStudies/N-Foundation/bxuparrow@2x.png'
                />
              </div>
              <div className='flex flex-col items-start text-[20px] justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
                <b className='relative mq450:text-base'>
                  Enhanced User Satisfaction
                </b>
                <div className='relative text-[18px] leading-[30px]'>
                  <p className='m-0'>{`Customizable audio options and interactive features significantly enhanced user `}</p>
                  <p className='m-0'>
                    <span>{`satisfaction, reflected in a `}</span>
                    <span className='font-semibold font-inter text-[#70C3FF]'>
                      30%
                    </span>
                    <span className='font-inter text-white'>
                      {' '}
                      increase in positive feedback.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResultsParent;
