import React from 'react';
import ResultContent from './ResultContent';
import PropTypes from 'prop-types';

const SolutionIllustration = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-[13px] box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <img
        className='w-[490px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1'
        loading='lazy'
        alt=''
        src='/caseStudies/Top-Spin/rectangle-3422@2x.png'
      />
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full lg:pt-[58px] lg:box-border mq750:pt-[38px] mq750:box-border mq750:min-w-full'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#d27200,_#502b00)] flex flex-col items-start justify-start pt-[42px] pb-[43px] pl-[62px] pr-5 box-border gap-4 max-w-full z-[1] mq750:pl-[31px] mq750:box-border'>
            <div className='w-[950px] h-[252px] relative [background:linear-gradient(135deg,_#d27200,_#502b00)] hidden max-w-full' />
            <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[2] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
              <p className='m-0'>
                <b>{`Results `}</b>
              </p>
              <p className='m-0'>Which we achieved</p>
            </h1>
            <div className='relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[2] mq450:text-lgi mq450:leading-[28px]'>
              The solutions delivered impactful outcomes
            </div>
          </div>
          <div className='self-stretch bg-gray-300 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[83px] box-border gap-[55px] max-w-full z-[2] text-xl mq450:gap-[27px] mq450:pt-8 mq450:pb-[35px] mq450:box-border mq1050:pt-[49px] mq1050:px-[31px] mq1050:pb-[54px] mq1050:box-border'>
            <img
              className='w-[950px] h-[775px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/Top-Spin/rectangle-3424.svg'
            />
            <ResultContent
              increasedBookingSuccessRate='Increased Booking Success Rate'
              usersExperiencedAHigherSucc='Users experienced a 50% higher success rate in securing their desired Tennis and Golf Court slots, with bookings completed promptly as soon as slots became available.'
            />
            <ResultContent
              increasedBookingSuccessRate='Eliminated Manual Effort'
              usersExperiencedAHigherSucc='The automation solution eliminated the need for users to stay awake or monitor booking availability manually, saving time and effort.'
            />
            <ResultContent
              increasedBookingSuccessRate='Improved User Satisfaction'
              usersExperiencedAHigherSucc='User feedback indicated a notable improvement in satisfaction levels, as they no longer faced disappointment from missing out on preferred slots. This also resulted in a 35% rise in user base.'
            />
            <ResultContent
              increasedBookingSuccessRate='Scalable and Reliable System'
              usersExperiencedAHigherSucc='The implemented system proved to be scalable and reliable, handling a large volume of booking requests with 0% downtime or performance issues.'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

SolutionIllustration.propTypes = {
  className: PropTypes.string,
};

export default SolutionIllustration;
