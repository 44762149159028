import React from 'react';
import GroupComponent from './GroupComponent';
import GroupComponent1 from './GroupComponent1';
import PropTypes from 'prop-types';

const Results = ({ className = '' }) => {
  return (
    <section
      className={`font-Inter self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-2 box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <div className='h-[931px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-[490px] lg:flex-1 mq750:min-w-full'>
        <img
          className='w-[490px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1 h-[854px]'
          loading='lazy'
          alt=''
          src='/caseStudies/Draydex/rectangle-3422@2x.png'
          style={{ borderTopRightRadius: '45px' }}
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full lg:pt-[58px] lg:box-border mq750:pt-[38px] mq750:box-border mq750:min-w-full'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#01409c,_#002760)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 mq750:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
            <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#01409c,_#002760)] hidden max-w-full' />
            <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
              <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[1] font-[inherit] mq450:text-10xl mq1050:text-19xl text-5xl'>
                <p className='m-0'>
                  <b>{`Results `}</b>
                </p>
                <p className='m-0'>Which we achieved</p>
              </h1>
              <div className='relative text-2xl leading-[35px] font-medium z-[1] mq450:text-lgi mq450:leading-[28px]'>
                The solutions delivered impactful outcomes
              </div>
            </div>
            <div className='h-[103px] w-[91.6px] flex flex-col items-start justify-start'>
              <img
                className='self-stretch h-10 relative max-w-full overflow-hidden shrink-0 object-cover z-[1]'
                alt=''
                src='/caseStudies/Draydex/download-2-1@2x.png'
              />
            </div>
          </div>
          <div
            className='self-stretch bg-steelblue flex flex-row items-start justify-start py-[75px] px-[62px] box-border max-w-full z-[1] text-xl mq750:pt-[49px] mq750:pb-[49px] mq750:box-border mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border bg-[#3D577D]'
            style={{ borderBottomLeftRadius: '45px' }}
          >
            <img
              className='h-[730px] w-[950px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/Draydex/rectangle-3424.svg'
            />
            <div className='flex flex-col items-start justify-start gap-[55px] max-w-full z-[2] mq450:gap-[27px]'>
              <GroupComponent
                instantPricing='Instant Pricing'
                reducedWaitTimesBoostingTransa='Reduced wait times, boosting transaction efficiency by approximately '
                prop='35%'
              />
              <div className='flex flex-row items-start justify-start gap-3 max-w-full'>
                <div className='flex flex-col items-start justify-start pt-0.5 px-0 pb-0'>
                  <img
                    className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
                    alt=''
                    src='/caseStudies/Draydex/bxuparrow@2x.png'
                  />
                </div>
                <div className='flex flex-col items-start justify-start gap-3.5 shrink-0 max-w-[calc(100%_-_30px)]'>
                  <b className='relative mq450:text-base'>
                    Efficient Rate Management
                  </b>
                  <div className='relative text-lg leading-[30px]'>
                    <span>Simplified rate comparison, resulting in a</span>
                    <span className='text-darkorange'>
                      <span className='font-medium font-inter'>{` `}</span>
                      <span className='font-semibold text-[#FF9802]'>25%</span>
                    </span>
                    <span> increase in user satisfaction.</span>
                  </div>
                </div>
              </div>
              <GroupComponent
                propWidth='724px'
                propFlex='1'
                propMinWidth='457px'
                instantPricing='Accurate Location and Secure Payments'
                propDisplay='inline-block'
                propAlignSelf='stretch'
                reducedWaitTimesBoostingTransa='Integrated tools ensured precision and security, reducing payment errors by '
                prop='20%'
              />
              <GroupComponent1
                improvedReporting='Improved Reporting'
                enhancedTransactionTracking='Enhanced transaction tracking accuracy, leading to a '
                prop='30% '
                improvementInDataReliability=' improvement in data reliability.'
              />
              <GroupComponent1
                improvedReporting='User Experience Enhancement'
                enhancedTransactionTracking='Optimized interface and performance, resulting in a '
                prop='40%'
                improvementInDataReliability=' decrease in user complaints.'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Results.propTypes = {
  className: PropTypes.string,
};

export default Results;
