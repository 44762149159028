import React, { useState, useEffect, useTransition, useMemo } from 'react';
import style from './BusinessCase.module.css';
import share from '../../assets/images/share.webp';
import colorlink from '../../assets/images/colorlink.png';
import { useNavigate } from 'react-router-dom';
import link from '../../assets/images/link.webp';
import colorShare from '../../assets/images/colorshare.png';
import { getOurProjects, getImage } from '../../utils/index';
import { setProjectsDetails, setScreen } from '../../redux/Action/actions';
import { useDispatch, useSelector } from 'react-redux';
const BusinessCase = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const projectdata = useSelector((reducers) => reducers.buisnessreducer);
  const [count, setCount] = useState();
  const [progress, setProgress] = useState();
  const [projects, setProjects] = useState([]);
  const [, startTransition] = useTransition();
  const [imageloadcomplete, setImageLoadComplete] = useState(false);
  const getImageUrl = async (id) => {
    let imgurl = await getImage(id);
    return imgurl.guid.rendered;
  };
  const getProjects = async () => {
    let data = [];
    let imgurls = [];
    let counted = 0;
    if (projectdata.projects === undefined) {
      data = await getOurProjects();
      counted = 1;
      startTransition(async () => {
        if (projectdata.projectImages === undefined) {
          let newimgurls = [];
          for (let value of data) {
            let urls = await getImageUrl(value.featured_media);
            newimgurls.push(urls);
          }
          setImage(newimgurls);
        }
      });
    } else {
      data = projectdata.projects;
      imgurls = projectdata.projectImages;
      counted = projectdata.ind + 1;
    }
    setProgress(100 / data?.length);
    setProjects(data);
    setCount(counted);
    setImage(imgurls);
  };
  const openTab = (url) => {
    window.open(url, '_blank');
  };
  useEffect(() => {
    getProjects();
  }, [projectdata]);

  const singleProject = (index) => {
    let int = index + 1;
    if (count !== int) {
      let number = 100 / projects?.length;
      setCount(int);
      setProgress(number * int);
      setImageLoadComplete(false);
    }
  };

  const allProjects = useMemo(() => {
    return projects?.map((item, index) => (
      <div key={item?.id}>
        <button
          onClick={() => {
            singleProject(index);
          }}
          style={{
            boxShadow:
              index + 1 === count ? ' 0px 0px 10px 2px #0000000D' : 'none',
          }}
          className={`bg-transparent  xl:w-[293px] xsm:w-[250px] h-[72px] text-[#282828] xl:font-700 xsm:font-600 text-lg font-semibold xl:text-center md:text-center text-start font-[Inter] leading-[30px]	 py-2 px-4 ${
            index + 1 === count &&
            'bg-[#2b2a2a] my-2 text-[#FFFF] transition duration-200 ease-out  hover:text-slate-200  hover:ease-in border-transparent'
          }`}
        >
          {item?.title.rendered}
        </button>
      </div>
    ));
  }, [count, image]);

  const handleNavigate = () => {
    dispatch(
      setProjectsDetails({
        projects: projects,
        projectImages: image,
        ind: count - 1,
      })
    );
    dispatch(setScreen(window.scrollY));
    navigate('/buisnesscasestudy', { state: { image: image } });
  };

  const projectImages = useMemo(() => {
    return (
      <img
        className={`${style.image} group-hover:brightness-50 border-[1px] ${
          imageloadcomplete ? 'visible' : 'hidden'
        }`}
        src={image[count - 1]}
        onLoad={() => {
          setImageLoadComplete(true);
        }}
      />
    );
  }, [image, count, imageloadcomplete]);
  const [state, setState] = useState({ link: false, share: false });
  return (
    <>
      <div className={`${style.maindiv}`}>
        <h1 className={style.Heading}>
          <span className='text-custom-green font-Gelasio'>Business Case </span>
          <span className='font-Inter'>Studies</span>
        </h1>
        <div className={`${style.secdiv}`}>
          <div className='flex items-start  xl:gap-x-8 gap-x-4'>
            <div className='flex items-center flex-col  gap-y-2'>
              <div className='flex flex-col flex-nowrap justify-start w-1 h-44 bg-gray-200  overflow-hidden dark:bg-gray-700'>
                <div
                  className='bg-[#474747] overflow-hidden'
                  role='progressbar'
                  style={{ height: progress + '%' }}
                  aria-valuenow='25'
                  aria-valuemin='0'
                  aria-valuemax='100'
                ></div>
              </div>
              <p className='-rotate-[88deg] mt-5'>
                0{count}&nbsp;/&nbsp;
                <span className='text-[#9A9A9A]'>0{projects?.length}</span>
              </p>
            </div>
            <div>{allProjects}</div>
          </div>

          <div className='relative group'>
            <div className='group-hover:brightness-50 h-auto group-hover:bg-[rgba(0,0,0,0.4)]'>
              {projectImages}
            </div>
            <div
              className={`${
                style.image
              } overflow-hidden bg-gray-300 rounded  dark:bg-gray-700 ' +
                ${imageloadcomplete ? 'hidden' : 'visible'}
              `}
            >
              <svg
                className=' text-gray-200'
                xmlns='http://www.w3.org/2000/svg'
                aria-hidden='true'
                fill='currentColor'
                viewBox='0 0 640 512'
              >
                <path d='M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z' />
              </svg>
            </div>
            <div className='absolute top-0 left-0 w-full h-0 flex  justify-center    opacity-0 group-hover:h-full group-hover:opacity-100 gap-x-10 items-center text-6xl text-black font-semibold'>
              <div
                className={`${style.hoverIcon}`}
                onMouseEnter={(prev) => {
                  setState({ ...prev, link: true });
                }}
                onMouseLeave={(prev) => {
                  setState({ ...prev, link: false });
                }}
                onClick={handleNavigate}
              >
                <img src={!state.link ? link : colorlink}></img>
              </div>
              <div
                data-testid='bussinesscs'
                className={`${style.hoverIcon}`}
                onMouseEnter={(prev) => {
                  setState({ ...prev, share: true });
                }}
                onMouseLeave={(prev) => {
                  setState({ ...prev, share: false });
                }}
                onClick={() => {
                  openTab(projects[count - 1]?.acf?.website);
                }}
              >
                <img src={!state.share ? share : colorShare}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BusinessCase;
