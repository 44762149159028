import React from 'react';
import style from './TechnologyTools.module.css';
import DOMPurify from 'dompurify';

const TechnologyTools = (props) => {
  const { title, images } = props;

  let containerClassName = style.Container;
  let imageDivClassName = style.ImageDiv1;
  let additionalClass = '';

  switch (title) {
    case 'Automated':
    case 'Programming Language':
      additionalClass = style.DisplayFlex;
      break;
    case 'API Testing Technology':
      containerClassName = `${style.Container} ${style.FlexContainer}`;
      imageDivClassName = `${style.ImageDiv1} ${style.FlexImageDiv1}`;
      break;
    default:
      break;
  }

  return (
    <div className={`${containerClassName} ${additionalClass}`}>
      <div>
        <h1 className={style.Title}>
          {title} <span className='text-custom-green'>Tools</span>
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(images) }}
          className={imageDivClassName}
        />
        <div>
          <p className='para' />
        </div>
      </div>
    </div>
  );
};

export default TechnologyTools;
