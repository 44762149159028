import React from 'react';
import MarketplaceItems from './MarketplaceItems';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[5.375rem] pr-[1.25rem] pl-[1.562rem] box-border max-w-full text-left text-[3rem] text-white font-inter mq450:pb-[2.25rem] mq450:box-border mq1050:pb-[3.5rem] mq1050:box-border ${className}`}
    >
      <div className='w-[67%] flex flex-col items-start justify-start gap-[5.625rem] max-w-full lg:gap-[2.813rem] mq750:gap-[1.375rem]'>
        <div
          style={{ borderRadius: '45px' }}
          className='w-[720px] h-[374px] rounded-26xl [background:linear-gradient(135deg,_#00AEEE,_#0277B6)] flex flex-col items-start justify-start pt-[1.875rem] pb-[5.125rem] pr-[1.875rem] pl-[4.875rem] box-border gap-[1rem] max-w-full z-[2] ml-4 mt-16 mq450:pt-[1.25rem] mq450:pb-[3.313rem] mq450:box-border mq750:pl-[2.438rem] mq750:box-border'
        >
          <div className='w-[720px] h-[23.375rem] relative rounded-26xl [background:linear-gradient(135deg,_#90E2AC,_#465EFB)] hidden max-w-full' />
          <div className='self-stretch flex flex-row items-start justify-between gap-[1.25rem] mq750:flex-wrap'>
            <div className='flex flex-col items-start justify-start pt-[3.75rem] px-[0rem] pb-[0rem] box-border'>
              <h1 className='m-0 text-[48px] self-stretch relative text-inherit z-[3] font-inherit mq450:text-[1.813rem] mq1050:text-[2.375rem]'>
                <p className='m-0 font-bold'>
                  <b>Problem</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='text-[24px] leading-[35px] font-normal z-[3]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of developing the application</p>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-end'>
              <img
                className='h-[48.5px] w-[50px] relative object-cover z-[3]'
                loading='lazy'
                alt=''
                src='/caseStudies/Gas-Money/new-gas-money-logo3-69e2b69dc1b94526b5b18ff4e1b8c0bc-1-1@2x.png'
              />
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-start gap-[50px] max-w-full text-center mq450:gap-[18px] mq750:flex-wrap mt-20'>
          <MarketplaceItems
            placeholder='1'
            marketplace='Business Expansion'
            propBackgroundColor='#0277B6'
            propBackgroundColor1='#0277B6'
            definingAndImplementingFeatures='Needed to expand its online 
            operations beyond its current 
            scope as a lawn care service and become a comprehensive 
            platform for various home and 
            yard services.'
            // setTheCoachingMarketplaceApart='The follow-up procedure for the leads
            // was manual because of which there '
            // competitors='were high chances of missing a lead.'
          />
          <MarketplaceItems
            // propMinWidth='253px'
            propFlex='1'
            propBackgroundColor='#0277B6'
            propBackgroundColor1='#0277B6'
            placeholder='2'
            marketplace='Need for an Interactive 
            Platform'
            definingAndImplementingFeatures='Needed to create a unified 
            platform that connects 
            homeowners seeking services
            with local student helpers 
            offering a wide range of home 
            and yard services.'
            // setTheCoachingMarketplaceApart='time-consuming process into different onboarding and
            // payroll systems.'
            // competitors='processing, and user analytics.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#0277B6'
            propBackgroundColor1='#0277B6'
            placeholder='3'
            marketplace='Delayed Appointment Scheduling'
            definingAndImplementingFeatures='Issues with appointment 
            scheduling, resulting in delays 
            and potentially affecting customer satisfaction and 
            service delivery efficiency.'
            // setTheCoachingMarketplaceApart='sheet causing multiple issues including duplicate entries and
            // data mismanagement.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#0277B6'
            propBackgroundColor1='#0277B6'
            placeholder='4'
            marketplace='Payment Delays'
            definingAndImplementingFeatures='Delays in processing payments 
            for services rendered, which 
            impacted on the financial operations and satisfaction of 
            both homeowners and 
            student helpers.'
            // setTheCoachingMarketplaceApart='sheet causing multiple issues including duplicate entries and
            // data mismanagement.'
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
