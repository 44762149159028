import React from 'react';
import PropTypes from 'prop-types';
import styles from './SolutionDetails.module.css';

const SolutionDetails = ({ className = '' }) => {
  return (
    <section className={[styles.solutionDetails, className].join(' ')}>
      <div className={styles.solutionDetail}>
        <div className={styles.aggregationContentParent}>
          <div className={styles.aggregationContent}>
            <div className={styles.aggregationIllustration}>
              <div className={styles.aggregationImage}>
                <img
                  className={styles.groupIcon}
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Imagine-Scholarship/group.svg'
                />
              </div>
              <div className={styles.aggregationDescription}>
                <div className={styles.comprehensiveDataAggregation}>
                  Comprehensive Data Aggregation
                </div>
                <div className={styles.utilizedWebScrapingContainer}>
                  <p
                    className={styles.utilizedWebScraping}
                  >{`Utilized Web Scraping and APIs for daily updates of `}</p>
                  <p className={styles.utilizedWebScraping}>
                    scholarship opportunities.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.detailDivider} />
        </div>
        <div className={styles.interfaceContentParent}>
          <div className={styles.interfaceContent}>
            <div className={styles.interfaceIllustration}>
              <img
                className={styles.interfaceIllustrationChild}
                loading='lazy'
                alt=''
                src='/caseStudies/Imagine-Scholarship/group-1000001341.svg'
              />
            </div>
            <div className={styles.interfaceDescription}>
              <div className={styles.userFriendlyInterface}>
                User-Friendly Interface
              </div>
              <div className={styles.builtUsingReactContainer}>
                <p
                  className={styles.utilizedWebScraping}
                >{`Built using React, Laravel, Python (Django), `}</p>
                <p className={styles.utilizedWebScraping}>and PostgreSQL.</p>
              </div>
            </div>
          </div>
          <div className={styles.detailDivider} />
        </div>
        <div className={styles.resourcesContentParent}>
          <div className={styles.resourcesContent}>
            <img
              className={styles.resourcesContentChild}
              loading='lazy'
              alt=''
              src='/caseStudies/Imagine-Scholarship/group-1000001343.svg'
            />
          </div>
          <div className={styles.interfaceDescription}>
            <div className={styles.guidanceAndResources}>
              Guidance and Resources
            </div>
            <div className={styles.builtUsingReactContainer}>
              Offered educational and motivational resources.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SolutionDetails.propTypes = {
  className: PropTypes.string,
};

export default SolutionDetails;
