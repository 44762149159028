import React from 'react';
import './uxComponent.css';
import ui01 from '../../../assets/socialpng/ui-1.png';
import ui02 from '../../../assets/socialpng/ui-2.png';
import ui03 from '../../../assets/socialpng/ui-3.png';
import ui04 from '../../../assets/socialpng/ui-4.png';

const UxComponent = () => {
  return (
    <div>
      <section className='uiux_sec my-5'>
        <h2>UX & UI</h2>
        <p>Design & Prototyping</p>
        <div className='container mx-auto'>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 row_parent'>
            <div className=' ui_box box_1'>
              <span>
                <img src={ui01} alt='img' />
              </span>

              <div className='ui_content'>
                <h2 className='mb-2 font-Gelasio'>01</h2>
                <h3 className='mb-3 font-Gelasio'>Analysis</h3>
                <p>
                  {` Analysis lays the groundwork for a user-centered and effective
                  design process by delving into user needs, behaviors, and the
                  product's context.`}
                </p>
              </div>
            </div>

            <div className=' ui_box box_2'>
              <span>
                <img className='max-w-none' src={ui02} alt='img' />
              </span>
              <div className='ui_content'>
                <h2 className='mb-2 font-Gelasio'>02</h2>
                <h3 className='mb-3 font-Gelasio'>Wireframes</h3>
                <p>
                  {`Wireframes provide a simplified visual representation of the
                  product's layout, facilitating communication and feedback
                  during the early design stages.`}
                </p>
              </div>
            </div>

            <div className='ui_box box_3'>
              <span>
                <img src={ui03} alt='img' />
              </span>
              <div className='ui_content'>
                <h2 className='mb-2 h2_class font-Gelasio'>03</h2>
                <h3 className='mb-3 font-Gelasio'>UI concept</h3>
                <p>
                  {` UI concepts showcase the product's visual elements, including
                  colors, fonts, and imagery, guiding the UI's development and
                  overall look and feel.`}
                </p>
              </div>
            </div>

            <div className='ui_box box_4'>
              <span>
                <img src={ui04} alt='img' />
              </span>
              <div className='ui_content'>
                <h2 className='mb-2 h2_class font-Gelasio'>04</h2>
                <h3 className='mb-3 font-Gelasio'>Prototype</h3>
                <p>
                  {`Prototypes bring wireframes to life, 
                    allowing users to simulate interacting 
                    with the product and providing valuable
                    usability feedback.`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UxComponent;
