import React from 'react';
import SolutionList from './SolutionList';

const FrameComponent = ({ className = '' }) => {
  return (
    <>
      <section
        className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[88px] pl-[22px] pr-5 box-border max-w-full text-left text-29xl text-white font-inter mq450:pb-[37px] mq450:box-border mq1050:pb-[57px] mq1050:box-border ${className}`}
      >
        <div className='w-[71.563rem] flex flex-col items-start justify-start gap-[5.625rem] max-w-full lg:gap-[2.813rem] mq750:gap-[1.375rem]'>
          <div
            style={{ borderRadius: '45px' }}
            className='w-[720px] h-[374px] rounded-26xl [background:linear-gradient(135deg,_#ABDCFF,_#0396FF)] flex flex-row items-start justify-between pt-[1.875rem] pb-[5.125rem] pr-[1.875rem] pl-[4.875rem] box-border gap-[1rem] max-w-full z-[2] ml-4 mt-28 mq450:pt-[1.25rem] mq450:pb-[3.313rem] mq450:box-border mq750:pl-[2.438rem] mq750:box-border'
          >
            <div className='h-[374px] w-full relative rounded-26xl [background:linear-gradient(135deg,_#43cbff,_#9708cc)] hidden ' />
            <div className='flex flex-col items-start justify-start pt-14 px-0 pb-0 box-border max-w-full'>
              <div className='flex flex-col items-start justify-start gap-4'>
                <h1 className='m-0 text-[48px] relative text-inherit inline-block z-[3] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
                  <p className='m-0 w-[249px]'>
                    <b>{`Problem `}</b>
                  </p>
                  <p className='m-0'>Statement</p>
                </h1>
                <div className='h-[70px] relative text-[24px] leading-[35px] font-normal inline-block z-[3] mq450:text-lgi mq450:leading-[28px]'>
                  <p className='m-0'>Here are the challenges which we faced</p>
                  <p className='m-0'>at the time of develop the application</p>
                </div>
              </div>
            </div>
            <div className='h-[73px] w-28 relative'>
              {/* <img
                className='absolute h-[calc(100%_-_4.6px)] top-[4.6px] bottom-[0px] left-[0px] max-h-full w-[60px] z-[3]'
                alt=''
              /> */}
              <img
                className='absolute top-[0px] left-[45.4px] w-[70px] h-[48.1px] z-[4]'
                alt=''
                src='/caseStudies/N-Foundation/group-1000001263.svg'
              />
            </div>
          </div>
          <div className='self-stretch flex flex-col font-semibold text-4xl leanding-[43.57px] text-[#282828]justify-start gap-[23px] max-w-full text-center text-13xl mt-16'>
            <div className='self-stretch flex flex-row items-start justify-start gap-[57px] max-w-full mq750:gap-7 mq1050:flex-wrap'>
              <div className='flex-1 flex flex-row items-start justify-start gap-[41px] min-w-[479px] max-w-full mq750:flex-wrap mq750:gap-5 mq750:min-w-full'>
                <SolutionList
                  solutionOneTitle='1'
                  propBackgroundColor='#00B8F2'
                  propBackgroundColor1='#00B8F2'
                  seamlessIntegrationAnd='Seamless Integration and '
                  userFriendlyDesign='User-Friendly Design'
                  developingAPlatformWithDynamic='Developing a platform with dynamic '
                  featuresLikeAudioEnhancedVocabu='features like audio-enhanced vocabulary, '
                  animatedStorybooksAndInteractiv='animated storybooks, and interactive '
                  globeAnimationsRequiredSeamless='globe animations required seamless '
                  integrationAndAUserFriendly='integration and a user-friendly design.'
                />
                <div className='w-[340px] flex flex-col items-end justify-start gap-[229px] min-w-[340px] max-w-full text-17xl text-gray-200 mq450:gap-[114px] mq450:min-w-full mq750:flex-1'>
                  <SolutionList
                    propFlex='unset'
                    propMinWidth='unset'
                    propBackgroundColor='#33CEFF'
                    propBackgroundColor1='#33CEFF'
                    solutionOneTitle='2'
                    propWidth='unset'
                    propMinWidth1='21px'
                    propAlignSelf='unset'
                    seamlessIntegrationAnd='Engaging and Interactive '
                    userFriendlyDesign='Content'
                    developingAPlatformWithDynamic='Keeping children engaged necessitates '
                    featuresLikeAudioEnhancedVocabu='captivating and interactive content '
                    animatedStorybooksAndInteractiv='tailored to maintain their attention '
                    globeAnimationsRequiredSeamless='spans and promote continuous '
                    integrationAndAUserFriendly='learning.'
                  />
                </div>
              </div>
              <SolutionList
                propFlex='unset'
                propMinWidth='342px'
                propBackgroundColor='#5AD6FD'
                propBackgroundColor1='#5AD6FD'
                solutionOneTitle='3'
                propWidth='22px'
                propMinWidth1='22px'
                propAlignSelf='unset'
                seamlessIntegrationAnd='Precise Translation and '
                userFriendlyDesign='Localization'
                developingAPlatformWithDynamic='Providing vocabulary and book content '
                featuresLikeAudioEnhancedVocabu='in various languages demanded precise '
                animatedStorybooksAndInteractiv='translation and localization to ensure '
                globeAnimationsRequiredSeamless='cultural relevance and linguistic '
                integrationAndAUserFriendly='accuracy.'
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FrameComponent;
