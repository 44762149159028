import React from 'react';
import FrameComponent from './components/FrameComponent';
import FrameComponent1 from './components/FrameComponent1';
import FrameComponent2 from './components/FrameComponent2';
import FrameComponent3 from './components/FrameComponent3';
import FrameComponent4 from './components/FrameComponent4';
import FrameComponent5 from './components/FrameComponent5';

const CaseStudiesCoachability = () => {
  return (
    <div className='w-full font-Inter relative bg-white overflow-hidden flex flex-col items-end justify-start pt-0 px-0 pb-[85px] box-border gap-[145px] leading-[normal] tracking-[normal] mq450:gap-9 mq750:gap-[72px]'>
      <div className='self-stretch h-[404px] relative bg-whitesmoke-100 hidden z-[0]' />
      <img
        className='w-[364px] relative max-h-full hidden max-w-full z-[1]'
        alt=''
        src='/caseStudies/Coachabilty/background-simple.svg'
      />
      <img
        className='w-[872px] relative max-h-full hidden max-w-full z-[2]'
        alt=''
        src='/caseStudies/Coachabilty/background.svg'
      />
      <img
        className='w-full h-[734px] absolute !m-[0] top-[1500px] right-[0px] left-[0px] max-w-full overflow-hidden shrink-0 object-cover'
        alt=''
        src='/caseStudies/Coachabilty/workstepbg@2x.png'
      />
      <img
        className='w-[872.4px] h-[789.2px] absolute !m-[0] top-[3023px] right-[-187.4px]'
        alt=''
        src='/caseStudies/Coachabilty/group-3051956.svg'
      />
      <section className='self-stretch flex flex-col items-end justify-start max-w-full'>
        <FrameComponent />
      </section>
      <img
        className='w-1/2 h-[495px] absolute !m-[0] rounded-[45px] top-[1291px] right-[-48px] rounded-26xl object-cover z-[2]'
        alt=''
        src='/caseStudies/Coachabilty/rectangle-3416@2x.png'
      />
      <section className='self-stretch flex flex-col items-center justify-start pt-0 px-4 pb-16 box-border max-w-full text-center font-inter sm:pb-12'>
        <div className='w-full max-w-[1012px] flex flex-col items-center justify-start gap-4 px-4'>
          <div className='self-stretch text-[#282828] flex flex-col items-center justify-center py-0 px-4'>
            <h1 className='m-0 text-inherit font-semibold text-[36px] sm:text-4xl md:text-5xl lg:text-4xl'>
              Introduction
            </h1>
          </div>
          <div className='font-normal items-center text-[#282828] leading-10 text-[20px]'>
            <p className='m-0'>
              Coachability Group sought to create a scalable web marketplace
              connecting individuals in
            </p>
            <p className='m-0'>
              London and the UK with qualified football coaches. The platform
              required seamless integration
            </p>
            <p className='m-0'>
              with various applications, automated workflow tasks, and advanced
              features for coaching events.
            </p>
            <p className='m-0'>
              Coachability Group chose to partner with DEVtrust, recognized for
              its expertise in developing
            </p>
            <p className='m-0'>scalable and secure web marketplaces.</p>
          </div>
        </div>
      </section>

      {/* <img
        className='w-[100px] h-[22.7px] relative object-cover hidden z-[8]'
        alt=''
        src='/caseStudies/Coachabilty/billingzerologo-1@2x.png'
      /> */}
      <FrameComponent1 />
      <section className='self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[5px] box-border max-w-full text-center text-[36px] text-gray-200 font-inter'>
        <div className='w-full flex flex-col items-center justify-center gap-[23px] max-w-full'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='font-semibold text-4xl leanding-[43.57px] text-[#282828]'>
              Solutions
            </h1>
          </div>
          <div className='text-[22px] font-normal leading-6 text-[#282828]'>
            DEVtrust implemented several innovative solutions to address these
            challenges
          </div>
        </div>
      </section>
      <FrameComponent2 />
      <FrameComponent3 />
      <FrameComponent4 />
      <FrameComponent5 />
      <div className='w-5 h-3 relative hidden z-[18]'>
        <div className='absolute top-[-1px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[11px] left-[2px] border-gray-200 border-t-[2px] border-solid box-border w-4 h-0.5' />
        <div className='absolute top-[5px] left-[-1px] border-gray-200 border-t-[2px] border-solid box-border w-[22px] h-0.5' />
      </div>
    </div>
  );
};

export default CaseStudiesCoachability;
