import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const SolutionHeaders = ({
  className = '',
  propWidth,
  automationOfOrderAndInvoice,
  automationOfOrderAndInvoice1,
  automatedTheProcessOfManaging,
  implementedASystemToPullInvoic,
}) => {
  const solutionHeaderLabelsStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  return (
    <div
      className={`w-[795px] flex flex-col items-start justify-start gap-[11px] max-w-full text-left text-3xl text-gray-200 font-inter ${className}`}
    >
      <div
        className='w-[707px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full'
        style={solutionHeaderLabelsStyle}
      >
        <div className='relative font-semibold z-[1] mq450:text-lg'>
          {automationOfOrderAndInvoice}
        </div>
      </div>
      <div className='self-stretch flex flex-row flex-wrap items-start justify-center gap-12 max-w-full text-lg mq450:gap-6'>
        <div className='flex flex-col items-start justify-start pt-[11px] px-0 pb-0'>
          <img
            className='w-[60px] h-[60px] relative overflow-hidden shrink-0'
            loading='lazy'
            alt=''
            src={automationOfOrderAndInvoice1}
          />
        </div>
        <div className='flex-1 relative leading-[30px] inline-block min-w-[447px] max-w-full z-[1] mq750:min-w-full'>
          <ul className='m-0 font-inherit text-inherit pl-6'>
            <li className='mb-0'>{automatedTheProcessOfManaging}</li>
            <li>{implementedASystemToPullInvoic}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

SolutionHeaders.propTypes = {
  className: PropTypes.string,
  automationOfOrderAndInvoice: PropTypes.string,
  automationOfOrderAndInvoice1: PropTypes.string,
  automatedTheProcessOfManaging: PropTypes.string,
  implementedASystemToPullInvoic: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
};

export default SolutionHeaders;
