import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import SideImage from '../../assets/images/rectangleleft.webp';
import ServicesCard from '../../atoms/servicesCard/ServicesCard';
import style from './ServicesPortfolio.module.css';
import Cards from '../../atoms/card/Cards';
import { getProcess } from '../../utils';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
function ServicesPortfolio() {
  const [services, setServices] = useState([]);
  const { screen } = useSelector((reducers) => reducers.screenreducer);
  const getProcessService = async () => {
    let data = await getProcess();
    setServices(data);
  };
  useEffect(() => {
    getProcessService();
  }, []);
  useLayoutEffect(() => {
    window.scroll({
      top: screen || 0,
      behavior: 'smooth',
    });
  });
  const sliderref = useRef();
  const cardItems1 = useMemo(() => {
    return services?.map((item) => <Cards carddata={item} key={item.id} />);
  }, []);
  useEffect(() => {
    if (sliderref !== undefined) {
      sliderref.current.slickGoTo(4);
    }
  }, []);
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 2000,
    arrows: false,
    cssEase: 'linear',
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  return (
    <div className='bg-white xl:block '>
      <div className={`${style.servicePortal}`}>
        <div className={`${style.servicePortalleft}`}>
          <img
            className={`${style.servicePortalImg}`}
            width='1600'
            height='900'
            src={SideImage}
          />
          <div className={`${style.servicePortalTextMain}`}>
            <h3 className={`${style.servicePortalTextHeading}`}>
              What We are Offering.
            </h3>
            <p className={`${style.servicePortalTextPara}`}>
              We deliver comprehensive IT solutions tailored to meet your
              industry needs and drive success across all digital platforms.
            </p>
          </div>
          <div className={`${style.servicePortalButtonMain}`}></div>
        </div>
        <div className={`${style.servicePortalRight}`}>
          <div className={`${style.servicePortalGrid}`}>
            {services?.map((item) => (
              <ServicesCard carddata={item} key={item.id} />
            ))}
            <Slider {...settings} ref={sliderref}>
              {cardItems1}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ServicesPortfolio;
