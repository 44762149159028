import React from 'react';
import PropTypes from 'prop-types';
import MarketplaceItems from './MarketplaceItems';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 pb-[88px] pl-5 pr-7 box-border max-w-full text-left text-29xl text-white font-inter mq450:pb-[37px] mq450:box-border mq1050:pb-[57px] mq1050:box-border ${className}`}
    >
      <div className='w-[1132px] flex flex-col items-start justify-start gap-[90px] max-w-full lg:gap-[45px] mq750:gap-[22px]'>
        <div
          className=' ml-4 mt-20 rounded-26xl [background:linear-gradient(135deg,_#01409c,_#002760)] flex flex-row items-start justify-start pt-[30px] pb-[82px] pl-[78px] pr-[29px] box-border gap-1.5 max-w-full z-[2] mq450:pt-5 mq450:pb-[53px] mq450:box-border mq750:flex-wrap mq750:pl-[39px] mq750:box-border'
          style={{ borderRadius: '45px' }}
        >
          <div className='h-[374px] w-[712px] relative rounded-26xl [background:linear-gradient(135deg,_#01409c,_#002760)] hidden max-w-full' />
          <div className='flex flex-col items-start justify-start pt-[60px] px-0 pb-0 box-border max-w-full'>
            <div className='flex flex-col items-start justify-start gap-4'>
              <h1 className='m-0 w-[249px] relative text-inherit inline-block z-[3] font-[inherit] mq450:text-10xl mq1050:text-19xl text-5xl'>
                <p className='m-0'>
                  <b>{`Problem `}</b>
                </p>
                <p className='m-0'>Statement</p>
              </h1>
              <div className='relative  leading-[35px] font-medium z-[3] mq450:text-lgi mq450:leading-[28px] text-2xl  '>
                <p className='m-0'>{`Draydex faced several challenges with its `}</p>
                <p className='m-0'>legacy system</p>
              </div>
            </div>
          </div>
          <div className='h-[77px] w-[116.6px] relative'>
            <img className='' alt='' />
            <img
              className='absolute top-[0px] left-[25px] w-[91.6px] h-10 object-cover z-[4]'
              alt=''
              src='/caseStudies/Draydex/download-2-1@2x.png'
            />
          </div>
        </div>

        <div className='self-stretch flex flex-row items-start justify-start gap-[60px] max-w-full text-center mq450:gap-[18px] mq750:flex-wrap mt-20'>
          <MarketplaceItems
            placeholder='1'
            marketplace='Outdated Software'
            propBackgroundColor='#002863'
            propBackgroundColor1='#002863'
            definingAndImplementingFeatures='The existing system was 
            almost 20 years old, leading 
            to inefficiencies and a 
            cumbersome user experience.'
            // setTheCoachingMarketplaceApart='The follow-up procedure for the leads
            // was manual because of which there '
            // competitors='were high chances of missing a lead.'
          />
          <MarketplaceItems
            // propMinWidth='253px'
            propFlex='1'
            propBackgroundColor='#003A8E'
            propBackgroundColor1='#003A8E'
            placeholder='2'
            marketplace='Data Migration'
            definingAndImplementingFeatures='A large dataset from the old 
            system needed to be 
            imported and understood.'
            // setTheCoachingMarketplaceApart='time-consuming process into different onboarding and
            // payroll systems.'
            // competitors='processing, and user analytics.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#0251C5'
            propBackgroundColor1='#0251C5'
            placeholder='3'
            marketplace='Rate Management'
            definingAndImplementingFeatures='Lack of real-time data and 
            historical tracking affected 
            the accuracy and efficiency 
            of rate transactions.'
            // setTheCoachingMarketplaceApart='sheet causing multiple issues including duplicate entries and
            // data mismanagement.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#0364F1'
            propBackgroundColor1='#0364F1'
            placeholder='4'
            marketplace='Third-Party Integrations'
            definingAndImplementingFeatures='Difficulty in integrating with 
            modern tools and services 
            such as Mapbox and payment 
            gateways.'
            // setTheCoachingMarketplaceApart='sheet causing multiple issues including duplicate entries and
            // data mismanagement.'
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
