import React from 'react';
import FrameComponent from './FrameComponent';
import PropTypes from 'prop-types';
import styles from './FrameComponent3.module.css';

const FrameComponent3 = ({ className = '' }) => {
  return (
    <section className={[styles.resultsContentParent, className].join(' ')}>
      <div className={styles.resultsContent}>
        <img
          className={styles.resultsShapeIcon}
          loading='lazy'
          alt=''
          src='/caseStudies/Imagine-Scholarship/rectangle-3422@2x.png'
        />
      </div>
      <div className={styles.resultsHeader}>
        <div className={styles.rectangleParent}>
          <div className={styles.frameChild} />
          <div className={styles.resultsHeader1}>
            <h1 className={styles.resultsWhichWeContainer}>
              <p className={styles.results}>
                <b>{`Results `}</b>
              </p>
              <p className={styles.results}>Which we achieved</p>
            </h1>
            <div className={styles.theSolutionsDelivered}>
              The solutions delivered impactful outcomes
            </div>
          </div>
          <div className={styles.imagineLogo}>
            <div className={styles.imagine}>IMAGINE</div>
          </div>
        </div>
        <div className={styles.resultsHighlights}>
          <img
            className={styles.resultsHighlightsChild}
            alt=''
            src='/caseStudies/Imagine-Scholarship/rectangle-3424.svg'
          />
          <FrameComponent
            enhancedDataAccess='Enhanced Data Access'
            increasedAccuracyAndRangeOf='Increased accuracy and range of scholarship opportunities, reducing search time by '
            prop='50%'
            prop1='.'
          />
          <FrameComponent
            enhancedDataAccess='Improved User Experience'
            increasedAccuracyAndRangeOf='Intuitive interface led to a '
            prop='40%'
            prop1=' reduction in search time. '
          />
          <FrameComponent
            enhancedDataAccess='Better Guidance'
            increasedAccuracyAndRangeOf=' Improved user satisfaction and success rate in college admissions by '
            prop='30%'
            prop1='. '
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent3;
