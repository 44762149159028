import React from 'react';
import PropTypes from 'prop-types';
import MarketplaceItems from './MarketplaceItems';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`font-Inter w-[1407px] flex flex-row items-start justify-center py-0 px-5 box-border max-w-full text-left text-29xl text-white font-inter ml-48 ${className}`}
    >
      <div className='w-[1107px] flex flex-col items-start justify-start gap-[90px] max-w-full lg:gap-[45px] mq750:gap-[22px] mt-10'>
        <div className='w-[712px] flex flex-row items-start justify-start relative max-w-full  mt-24'>
          <img
            className='self-stretch h-[22.7px]  max-w-full overflow-hidden shrink-0 object-cover z-[8] absolute !m-[0] top-[34px] right-[86px]'
            loading='lazy'
            alt=''
            src='/caseStudies/Billing-Zero/billingzerologo-1@2x.png'
          />
          <div
            className='flex-1 rounded-26xl [background:linear-gradient(135deg,_#49c9ff,_#0093d1)] flex flex-col items-start justify-start pt-[90px] pb-[82px] pl-[78px] pr-5 box-border gap-4 max-w-full z-[4] mq750:pl-[39px] mq750:box-border mq450:pt-[58px] mq450:pb-[53px] mq450:box-border'
            style={{ borderRadius: '45px' }}
          >
            <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#49c9ff,_#0093d1)] hidden max-w-full' />
            <h1 className='m-0 w-[249px] relative text-inherit inline-block z-[5] font-[inherit] mq450:text-10xl mq1050:text-19xl text-5xl'>
              <p className='m-0'>
                <b>{`Problem `}</b>
              </p>
              <p className='m-0'>Statement</p>
            </h1>
            <div className='h-[70px] relative  leading-[35px] font-medium inline-block max-w-full z-[5] mq450:text-lgi mq450:leading-[28px] text-2xl'>
              <p className='m-0'>Here are the challenges which we faced</p>
              <p className='m-0'>at the time of develop the application</p>
            </div>
          </div>
        </div>

        <div className='self-stretch flex flex-row items-start justify-start gap-[30px] max-w-full text-center text-13xl mq450:gap-[18px] mq750:flex-wrap mt-10'>
          <MarketplaceItems
            placeholder='1'
            marketplace='Process-related Overheads'
            propBackgroundColor='#0093D1'
            propBackgroundColor1='#0093D1'
            definingAndImplementingFeatures='Time-consuming proposal, contract, and 
            invoice management processes using 
            Excel sheets and other offline tools.'
            // setTheCoachingMarketplaceApart='set the coaching marketplace apart from'
            // competitors='competitors.'
          />
          <MarketplaceItems
            // propMinWidth='253px'
            propFlex='1'
            propBackgroundColor='#1CACE9'
            propBackgroundColor1='#1CACE9'
            placeholder='2'
            marketplace='Offline Payments'
            definingAndImplementingFeatures='Offline fund transfers with little to no 
            online tracking or reports.'
            // setTheCoachingMarketplaceApart='functionality, including scheduling, payment '
            // competitors='processing, and user analytics.'
          />
          <MarketplaceItems
            propMinWidth='308px'
            propBackgroundColor='#3FC6FF'
            propBackgroundColor1='#3FC6FF'
            placeholder='3'
            marketplace='Repeated Follow-ups'
            definingAndImplementingFeatures='Rigorous follow-ups to get the 
            invoices cleared.'
            // setTheCoachingMarketplaceApart='increased traffic and support future growth effectively.'
          />
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
