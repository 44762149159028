import React from 'react';
import * as DOMPurify from 'dompurify';

const PortfolioCard = (props) => {
  const divStyle = {
    backgroundImage: `url(${props?.imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '570px',
    marginBottom: '40px',
  };

  const { data } = props;
  const openTab = (url) => {
    window.open(url, '_blank');
  };

  const makeProtfolioContentStyle = {
    8128: {
      p_desc: { paddingRight: '90px' },
      div_postion: {},
    },
    8743: {
      p_desc: { paddingRight: '62px' },
      div_postion: { position: 'relative', bottom: '30px' },
    },
    9755: { p_desc: { paddingRight: '100px' }, div_postion: {} },
    9764: { p_desc: { paddingRight: '100px' }, div_postion: {} },
    9767: { p_desc: { paddingRight: '100px' }, div_postion: {} },
    9784: { p_desc: { paddingRight: '100px' }, div_postion: {} },
    10375: {
      p_desc: { paddingRight: '80px' },
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10380: { p_desc: { paddingRight: '85px' }, div_postion: {} },
    10385: { p_desc: { paddingRight: '100px' }, div_postion: {} },
    10387: { p_desc: { paddingRight: '100px' }, div_postion: {} },
    10389: {
      p_desc: { paddingRight: '80px' },
      div_postion: { position: 'relative', bottom: '45px' },
    },
    10391: { p_desc: { paddingRight: '90px' }, div_postion: {} },
    10393: { p_desc: { paddingRight: '80px' }, div_postion: {} },
    10395: {
      p_desc: { paddingRight: '80px' },
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10400: {
      p_desc: { paddingRight: '70px' },
      div_postion: { position: 'relative', bottom: '45px' },
    },
    10402: {
      p_desc: { paddingRight: '28px' },
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10408: {
      p_desc: {},
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10410: { p_desc: { paddingRight: '40px' }, div_postion: {} },
    10417: {
      p_desc: {},
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10419: {
      p_desc: {},
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10425: {
      p_desc: { paddingRight: '90px' },
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10427: {
      p_desc: {},
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10429: {
      p_desc: { paddingRight: '60px' },
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10431: {
      p_desc: { paddingRight: '60px' },
      div_postion: { position: 'relative', bottom: '58px' },
    },
    10433: {
      p_desc: { paddingRight: '60px' },
      div_postion: { position: 'relative', bottom: '40px' },
    },
    10435: {
      p_desc: {},
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10437: {
      p_desc: {},
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10445: {
      p_desc: {},
      div_postion: { position: 'relative', bottom: '30px' },
    },
    10446: {
      p_desc: {},
      div_postion: { position: 'relative', bottom: '60px' },
    },
    10450: {
      p_desc: { paddingRight: '40px' },
      div_postion: { position: 'relative', bottom: '60px' },
    },
  };

  const filteredStyle = makeProtfolioContentStyle[data.id]?.p_desc || null;
  const filteredDivPositionStyle =
    makeProtfolioContentStyle[data.id]?.div_postion || null;
  console.log('filteredStyle', filteredStyle, filteredDivPositionStyle);

  return (
    <div style={divStyle}>
      <div
        className='mx-auto max-w-7xl px-6 lg:px-8 shadow-md py-10 mb-9'
        style={{ paddingTop: '9.5rem' }}
      >
        <div
          className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2'
          style={{ paddingLeft: '16px', ...filteredDivPositionStyle }}
        >
          <div className='lg:pr-8 lg:pt-4' style={{ height: '376px' }}>
            <div className='lg:max-w-lg'>
              <h3
                className={`text-xl  font-semibold font-[Inter] leading-5 text-${props?.textContentColor}`}
              >
                {data?.acf?.title}
              </h3>
              <div
                className={`xl:text-base text-sm font-normal leading-[30px] text-${props?.textContentColor} xl:w-[483px] w-auto my-3 font-[Inter]`}
                style={filteredStyle}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data?.content?.rendered),
                }}
              ></div>
              <div className='mt-10'>
                <div className='mx-auto max-w-2xl lg:mx-0'>
                  <h2
                    className={`font-Inter font-semibold text-base  leading-[19.36px] text-${props?.textContentColor} mb-3`}
                  >
                    Technology Stack
                  </h2>
                  <div className='flex gap-x-3 gap-y-2.5 flex-wrap'>
                    {data?.acf?.technology?.split(',').map((item, index) => (
                      <p
                        className={`text-lg font-normal font-[Inter] text-${props?.textContentColor} border border-${props?.textContentColor} p-2`}
                        key={index}
                      >
                        {item}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              <div className='flex justify-start xl:mt-6 mt-2'>
                {data?.acf?.link ? (
                  <button
                    onClick={() => {
                      openTab(data?.acf?.link);
                    }}
                    className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#FFFFFF] text-[#474747] inline-block '
                  >
                    <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                    <span className='relative group-hover:text-white text-lg font-medium font-[Inter] leading-[21px]'>
                      View Live Site
                    </span>
                  </button>
                ) : (
                  <button className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#FFFFFF] text-[#474747] inline-block '>
                    <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                    <span className='relative group-hover:text-white text-lg font-medium font-[Inter] leading-[21px]'>
                      Request a Demo
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioCard;
