import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={` font-Inter self-stretch flex flex-row items-start justify-center [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <div className='h-[931px] w-[490px] flex flex-col items-start justify-start min-w-[490px] max-w-[490px] lg:flex-1 mq750:min-w-full'>
        <img
          className='w-[490px] h-[854px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1'
          loading='lazy'
          alt=''
          src='/caseStudies/AI-Assistant/rectangle-3422@2x.png'
          style={{ borderTopRightRadius: '45px' }}
        />
      </div>
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full mq750:pt-[38px] mq750:box-border mq750:min-w-full mq1050:pt-[58px] mq1050:box-border'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#144d83,_#51428c)] flex flex-row items-end justify-between pt-[42px] pb-[43px] pl-[62px] pr-10 box-border max-w-full gap-5 z-[1] mq750:flex-wrap mq1050:pl-[31px] mq1050:box-border'>
            <div className='h-[252px] w-[950px] relative [background:linear-gradient(135deg,_#144d83,_#51428c)] hidden max-w-full' />
            <div className='flex flex-col items-start justify-start gap-4 max-w-full'>
              <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[2] font-[inherit] mq450:text-10xl mq1050:text-19xl text-5xl	'>
                <p className='m-0'>
                  <b>{`Results `}</b>
                </p>
                <p className='m-0'>Which we achieved</p>
              </h1>
              <div className='relative  leading-[35px] font-medium z-[2] mq450:text-lgi mq450:leading-[28px] text-2xl	'>
                The solutions delivered impactful outcomes
              </div>
            </div>
            <div className='h-[114px] w-[70px] flex flex-col items-start justify-start'>
              <img
                className='self-stretch h-[62.1px] relative max-w-full overflow-hidden shrink-0 z-[2]'
                alt=''
                src='/caseStudies/AI-Assistant/group-1000001294.svg'
              />
            </div>
          </div>
          <div
            className='self-stretch bg-darkslateblue-300 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[164px] box-border gap-[55px] max-w-full z-[2] text-xl mq450:gap-[27px] mq750:pt-[49px] mq750:pb-[107px] mq750:box-border mq1050:pl-[31px] mq1050:pr-[31px] mq1050:box-border bg-[#2C3968]'
            style={{ borderBottomLeftRadius: '45px' }}
          >
            <img
              className='w-[950px] h-[613px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/AI-Assistant/rectangle-3424.svg'
            />
            <div className='flex flex-row items-start justify-start gap-3 max-w-full'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/AI-Assistant/bxuparrow@2x.png'
                />
              </div>
              <div className='flex flex-col items-start justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
                <b className='relative z-[3] mq450:text-base'>Time Savings</b>
                <div className='relative text-lg leading-[30px] z-[3]'>
                  <p className='m-0'>{`OpenAI (ChatGPT 4.0) completes the documentation and administration work in `}</p>
                  <p className='m-0'>
                    <span className='font-semibold font-inter text-mediumpurple-100 text-[#9C8CFF]'>
                      60%
                    </span>
                    <span> lesser time.</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='flex flex-row items-start justify-start gap-3 max-w-full'>
              <div className='flex flex-col items-start justify-start pt-0.5 px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
                  alt=''
                  src='/caseStudies/AI-Assistant/bxuparrow@2x.png'
                />
              </div>
              <div className='flex flex-col items-start justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
                <b className='relative z-[3] mq450:text-base'>
                  Reduced Overhead
                </b>
                <div className='relative text-lg leading-[30px] z-[3]'>
                  <p className='m-0'>{`Azure speech-to-text convertor driven transcription reduced the `}</p>
                  <p className='m-0'>
                    <span>{`overhead by `}</span>
                    <span className='font-semibold font-inter text-mediumpurple-100 text-[#9C8CFF]'>
                      80%
                    </span>
                    <span className='font-inter text-white'>.</span>
                  </p>
                </div>
              </div>
            </div>
            <div className='flex flex-row items-start justify-start gap-3 max-w-full'>
              <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
                <img
                  className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain z-[3]'
                  alt=''
                  src='/caseStudies/AI-Assistant/bxuparrow@2x.png'
                />
              </div>
              <div className='flex flex-col items-start justify-start gap-3.5 max-w-[calc(100%_-_30px)]'>
                <b className='relative z-[3] mq450:text-base'>
                  Data Confidentiality
                </b>
                <div className='relative text-lg leading-[30px] z-[3]'>
                  <span>{`HIPAA compliant generated SOAP note ensured `}</span>
                  <span className='font-semibold text-mediumpurple-100 text-[#9C8CFF]'>
                    100%
                  </span>
                  <span> data confidentiality.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent2;
