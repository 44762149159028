import React from 'react';
import PropTypes from 'prop-types';

const ProblemStatement = ({ className = '' }) => {
  return (
    <section
      className={`w-[1421px] flex flex-row items-start justify-center pt-0 px-5 pb-[82px] box-border max-w-full text-left text-29xl text-white font-inter mq450:pb-[34px] mq450:box-border mq1050:pb-[53px] mq1050:box-border ${className}`}
    >
      <div className='w-[1159px] flex flex-row items-end justify-center gap-[46px] max-w-full mq750:gap-[23px] mq1050:flex-wrap'>
        <div className='flex-1 flex flex-col items-start justify-start gap-[90px] min-w-[519px] max-w-full mq750:min-w-full mq450:gap-[22px] mq1050:gap-[45px]'>
          <div className='w-[712px] flex flex-row items-start justify-start relative max-w-full'>
            <img
              className='h-[73px] w-[60px] absolute !m-[0] top-[-46px] right-[86px] z-[2]'
              loading='lazy'
              alt=''
            />
            <div className='flex-1 rounded-26xl [background:linear-gradient(135deg,_#36b3a8,_#094e7e)] flex flex-col items-start justify-start pt-[30px] pb-[82px] pl-[78px] pr-7 box-border gap-4 max-w-full z-[3] mq750:pl-[39px] mq750:box-border mq450:pt-5 mq450:pb-[53px] mq450:box-border'>
              <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#36b3a8,_#094e7e)] hidden max-w-full' />
              <div className='self-stretch h-44 relative'>
                <h1 className='m-0 absolute top-[60px] left-[0px] text-inherit z-[1] font-inherit mq450:text-10xl mq1050:text-19xl'>
                  <p className='m-0'>
                    <b>{`Problem `}</b>
                  </p>
                  <p className='m-0'>Statement</p>
                </h1>
                <img
                  className='absolute top-[0px] left-[575px] w-[30.7px] h-[70px] object-cover z-[1]'
                  loading='lazy'
                  alt=''
                  src='/caseStudies/Air-Concierge/airconcierge-logo-black-1-1@2x.png'
                />
              </div>
              <div className='h-[70px] relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[1] mq450:text-lgi mq450:leading-[28px]'>
                <p className='m-0'>Here are the challenges which we faced</p>
                <p className='m-0'>at the time of develop the application</p>
              </div>
            </div>
          </div>
          <div className='self-stretch flex flex-row items-start justify-start gap-[34px] max-w-full text-13xl mq750:flex-wrap mq450:gap-[17px]'>
            <div className='flex-1 flex flex-col items-start justify-start gap-10 min-w-[250px] max-w-full mq450:gap-5'>
              <div className='rounded-3xs bg-steelblue-200 flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1] text-center'>
                <div className='h-[69px] w-[69px] relative rounded-3xs bg-steelblue-200 hidden' />
                <b className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                  1
                </b>
              </div>
              <div className='relative text-3xl font-semibold text-gray-200 z-[1] mq450:text-lg'>
                Platform Integration
              </div>
              <div className='self-stretch relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                <ol className='m-0 font-inherit text-inherit pl-6'>
                  <li className='mb-0'>{`Coordinating and integrating various `}</li>
                </ol>
                <p className='m-0'>{`     external services to enhance property     visibility and user trust. 2. Ensuring seamless interoperability among `}</p>
                <p className='m-0'> these services to provide a cohesive</p>
                <p className='m-0'> user experience.</p>
              </div>
            </div>
            <div className='flex-1 flex flex-col items-start justify-start gap-10 min-w-[246px] max-w-full text-center mq450:gap-5'>
              <div className='rounded-3xs bg-steelblue-100 flex flex-row items-start justify-start py-[15px] pl-[25px] pr-[23px] z-[1]'>
                <div className='h-[69px] w-[69px] relative rounded-3xs bg-steelblue-100 hidden' />
                <b className='relative inline-block min-w-[21px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                  2
                </b>
              </div>
              <div className='self-stretch flex flex-col items-start justify-start gap-[13px] max-w-full text-left text-3xl text-gray-200'>
                <div className='relative font-semibold inline-block max-w-full z-[1] mq450:text-lg'>
                  <p className='m-0'>{`User Engagement and Booking `}</p>
                  <p className='m-0'>Efficiency</p>
                </div>
                <div className='self-stretch relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                  <ol className='m-0 font-inherit text-inherit pl-6'>
                    <li className='mb-0'>{`Enhancing property visibility to attract `}</li>
                  </ol>
                  <p className='m-0'>{`      more travellers and boost booking rates. 2. Creating a user-friendly booking process `}</p>
                  <p className='m-0'>that is efficient and easy to navigate.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[315px] flex flex-col items-start justify-start gap-10 min-w-[315px] text-center text-13xl mq450:gap-5 mq1050:flex-1'>
          <div className='rounded-3xs bg-teal flex flex-row items-start justify-start py-[15px] px-[23px] z-[1]'>
            <div className='h-[69px] w-[69px] relative rounded-3xs bg-teal hidden' />
            <b className='w-[22px] relative inline-block min-w-[22px] z-[1] mq450:text-lgi mq1050:text-7xl'>
              3
            </b>
          </div>
          <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
            <div className='relative font-semibold z-[1] mq450:text-lg'>
              <p className='m-0'>{`Scalability and `}</p>
              <p className='m-0'>Performance</p>
            </div>
            <div className='relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
              <ol className='m-0 font-inherit text-inherit pl-6'>
                <li className='mb-0'>{`Ensuring the platform could `}</li>
              </ol>
              <p className='m-0'>{`      handle an increasing number `}</p>
              <p className='m-0'>{`      of users and properties without `}</p>
              <p className='m-0'>{`      performance degradation. 2. Optimizing the system for high `}</p>
              <p className='m-0'> availability and minimal downtime.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProblemStatement.propTypes = {
  className: PropTypes.string,
};

export default ProblemStatement;
