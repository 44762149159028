import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const FrameComponent = ({
  className = '',
  propFlex,
  propMinWidth,
  propBackgroundColor,
  propBackgroundColor1,
  prop,
  propWidth,
  propMinWidth1,
  propAlignSelf,
  manualEffortAnd,
  lateNightStruggles,
  propHeight,
  propDisplay,
  usersFacedChallenges,
  stayingUpLateOrWakingEarly,
  toManuallyBookDesired,
  tennisAndGolfCourtSlots,
  asSoonAsTheyBecame,
  availableAtMidnightPST,
}) => {
  const frameDivStyle = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
    };
  }, [propFlex, propMinWidth]);

  const groupDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor,
    };
  }, [propBackgroundColor]);

  const rectangleDivStyle = useMemo(() => {
    return {
      backgroundColor: propBackgroundColor1,
    };
  }, [propBackgroundColor1]);

  const bStyle = useMemo(() => {
    return {
      width: propWidth,
      minWidth: propMinWidth1,
    };
  }, [propWidth, propMinWidth1]);

  const frameDiv1Style = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  const usersFacedChallengesContainerStyle = useMemo(() => {
    return {
      height: propHeight,
      display: propDisplay,
    };
  }, [propHeight, propDisplay]);

  return (
    <div
      className={`flex flex-col items-start justify-start gap-10 text-center text-13xl text-white font-inter mq450:gap-5 ${className}`}
      style={frameDivStyle}
    >
      <div
        className='rounded-3xs bg-saddlebrown-200 flex flex-row items-start justify-start py-[15px] pl-[27px] pr-[26px] z-[1]'
        style={groupDivStyle}
      >
        <div
          className='h-[69px] w-[69px] relative rounded-3xs bg-saddlebrown-200 hidden'
          style={rectangleDivStyle}
        />
        <b
          className='w-4 relative inline-block min-w-[16px] z-[1] mq450:text-lgi mq1050:text-7xl'
          style={bStyle}
        >
          {prop}
        </b>
      </div>
      <div
        className='flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'
        style={frameDiv1Style}
      >
        <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
          <p className='m-0'>{manualEffortAnd}</p>
          <p className='m-0'>{lateNightStruggles}</p>
        </div>
        <div
          className='h-[180px] relative text-lg leading-[30px] text-dimgray-200 inline-block z-[1]'
          style={usersFacedChallengesContainerStyle}
        >
          <p className='m-0'>{usersFacedChallenges}</p>
          <p className='m-0'>{stayingUpLateOrWakingEarly}</p>
          <p className='m-0'>{toManuallyBookDesired}</p>
          <p className='m-0'>{tennisAndGolfCourtSlots}</p>
          <p className='m-0'>{asSoonAsTheyBecame}</p>
          <p className='m-0'>{availableAtMidnightPST}</p>
        </div>
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
  prop: PropTypes.string,
  manualEffortAnd: PropTypes.string,
  lateNightStruggles: PropTypes.string,
  usersFacedChallenges: PropTypes.string,
  stayingUpLateOrWakingEarly: PropTypes.string,
  toManuallyBookDesired: PropTypes.string,
  tennisAndGolfCourtSlots: PropTypes.string,
  asSoonAsTheyBecame: PropTypes.string,
  availableAtMidnightPST: PropTypes.string,

  /** Style props */
  propFlex: PropTypes.any,
  propMinWidth: PropTypes.any,
  propBackgroundColor: PropTypes.any,
  propBackgroundColor1: PropTypes.any,
  propWidth: PropTypes.any,
  propMinWidth1: PropTypes.any,
  propAlignSelf: PropTypes.any,
  propHeight: PropTypes.any,
  propDisplay: PropTypes.any,
};

export default FrameComponent;
