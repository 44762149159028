import React from 'react';
import jobImg from '../../assets/socialpng/jobImg.png';
import onSide from '../../assets/socialpng/onSide.png';

const JobCards = () => {
  return (
    <div>
      <div className='py-16  sm:py-24'>
        <div className='grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 xl:gap-x-2'>
          {jobCards.map((item, index) => (
            <div
              key={index}
              className='w-full sm:min-w-[560px] min-h-[261px] p-6 bg-white border border-gray-200 rounded-lg shadow'
            >
              <>
                <h5 className='mb-2 text-2xl font-semibold tracking-tight text-[#282828] font-Gelasio'>
                  {item.jobTitle}
                </h5>
              </>
              <p className='mb-3 text-base font-Inter font-normal text-[#6A6968]'>
                {item.jobDiscription.length > 45 ? (
                  <>
                    {item.jobDiscription.substring(0, 45)}
                    <br />
                    {item.jobDiscription.substring(45)}
                  </>
                ) : (
                  item.jobDiscription
                )}
              </p>

              <div className='flex justify-between mb-3'>
                <h5 className='inline-flex items-center gap-3'>
                  <img src={item.img} alt={item.img} className='w-5 h-5' />
                  {item.exprince}
                </h5>

                <h5 className='inline-flex items-center gap-3'>
                  <img
                    src={item.imgOnSide}
                    alt={item.imgOnSide}
                    className='w-[18.32px] min-h-[18.32px]'
                  />
                  {item.location}
                  <span className='text-[#C1C1C1] font-[Inter] font-medium text-[16px] leading-[19.36px]'>
                    {item.side}
                  </span>
                </h5>
              </div>
              <div className='flex gap-3 flex-col sm:flex-row'>
                <button
                  type='button'
                  className='text-white bg-[#474747] font-medium font-Inter text-sm px-5 py-2.5 me-2 mb-2 sm:mb-0 w-full sm:w-auto'
                >
                  View Detail
                </button>
                <button
                  type='button'
                  className='text-white bg-[#23C7C8] font-medium font-Inter text-sm px-5 py-2.5 me-2 mb-2 sm:mb-0 w-full sm:w-auto '
                >
                  Apply Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobCards;

const jobCards = [
  {
    id: 1,
    jobTitle: 'Android Developer',
    jobDiscription:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut',
    img: jobImg,
    exprince: '5-8 Years',
    imgOnSide: onSide,
    location: 'Lucknow',
    side: '(Onsite)',
  },
  {
    id: 2,
    jobTitle: 'React Developer',
    jobDiscription:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut',
    img: jobImg,
    exprince: '5-8 Years',
    imgOnSide: onSide,
    location: 'Lucknow',
    side: '(Onsite)',
  },
  {
    id: 3,
    jobTitle: 'WordPress Developer ',
    jobDiscription:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut',
    img: jobImg,
    exprince: '5-8 Years',
    imgOnSide: onSide,
    location: 'Lucknow',
    side: '(Onsite)',
  },
  {
    id: 4,
    jobTitle: 'QA Engineer',
    jobDiscription:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut',
    img: jobImg,
    exprince: '5-8 Years',
    imgOnSide: onSide,
    location: 'Lucknow',
    side: '(Onsite)',
  },
  {
    id: 5,
    jobTitle: 'Project Manager',
    jobDiscription:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut',
    img: jobImg,
    exprince: '5-8 Years',
    imgOnSide: onSide,
    location: 'Lucknow',
    side: '(Onsite)',
  },
  {
    id: 5,
    jobTitle: 'Node JS Developer',
    jobDiscription:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut',
    img: jobImg,
    exprince: '5-8 Years',
    imgOnSide: onSide,
    location: 'Lucknow',
    side: '(Onsite)',
  },
];
