import React from 'react';
import PropTypes from 'prop-types';
import icon1 from '../../../../assets/walead/icon1.svg';
import icon2 from '../../../../assets/walead/icon2.svg';
import icon3 from '../../../../assets/walead/icon3.svg';
import icon4 from '../../../../assets/walead/icon4.svg';
import icon5 from '../../../../assets/walead/icon5.svg';
import icon6 from '../../../../assets/walead/icon6.svg';

const FrameComponent2 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[92px] box-border max-w-full text-left text-xl text-gray-300 font-inter mq750:pb-[39px] mq750:box-border mq1050:pb-[60px] mq1050:box-border ${className}`}
    >
      <div className='w-[914px] flex flex-col items-center justify-center gap-[39px] max-w-full mq450:gap-[19px]'>
        <div className='self-stretch flex flex-col items-start justify-start gap-[60px] max-w-full mq450:gap-[21px]'>
          {solutionData?.map((item, index) => (
            <div
              key={index}
              className='flex flex-col items-start justify-start py-0 box-border max-w-full'
            >
              <div className='flex flex-row items-start justify-between w-full gap-[52px] max-w-full mq450:gap-[26px] mq1050:flex-wrap'>
                <div className='w-[70px] flex-shrink-0 flex flex-col items-start justify-start pt-[21px] box-border'>
                  <img
                    className='w-full h-auto max-w-full overflow-hidden object-cover'
                    loading='lazy'
                    alt=''
                    src={item?.image}
                  />
                </div>
                <div className='flex-1 flex flex-col items-start justify-start gap-3.5'>
                  <b className='font-semibold text-[20px] leading-[43.57px] text-[#282828]'>
                    {item?.title}
                  </b>
                  <div className='text-[18px] leading-[30px] text-[#282828]'>
                    <p className='m-0'>{item?.discription}</p>
                  </div>
                </div>
              </div>
              <div className='self-stretch mt-4'>
                <hr className='border-t-[1px] border-solid border-lightgray' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

FrameComponent2.propTypes = {
  className: PropTypes.string,
};

const solutionData = [
  {
    id: 1,
    image: icon1,
    title: 'Chrome Extension',
    discription: `Developed a Google Chrome extension that activates an "Add to WaLead" button. 
    A click on this button pulls the LinkedIn Profile data into the WaLead CRM that 
    simplifies lead management on the LinkedIn platform.`,
  },
  {
    id: 2,
    image: icon2,
    title: 'Personalized Pipelines',
    discription: `A custom Kanban board to manage the "Lead Pipelines" which are customizable 
    as per the User's Choice.`,
  },
  {
    id: 3,
    image: icon5,
    title: 'Sales Made Easy',
    discription: `Drag and Drop feature to manage both Leads as well the Pipelines 
    independently.`,
  },
  {
    id: 4,
    image: icon6,
    title: '+1.2M B2B Verified Leads',
    discription: `Lead Finder to filter the Leads using various heads like Job Title, 
    Company Name, Company Size etc.`,
  },
  {
    id: 5,
    image: icon3,
    title: 'Smooth Payment Processing',
    discription: `Stripe ACH for payments based on the membership level which in turn 
    depends on the number of users.`,
  },
  {
    id: 5,
    image: icon4,
    title: 'Simplified Management',
    discription: `Super Admin to manage all the Users, their Subscriptions, the Workspaces 
    and Tutorial Videos.`,
  },
];

export default FrameComponent2;
