import React from 'react';
import style from './ServiceProvider.module.css';
const ServiceProvider = ({ services }) => {
  return (
    <div className={style.ServiceProvider} data-testid='service'>
      <div className={style.Container}>
        <h1 className={style.Heading}>
          Services We <span className='text-custom-green '>Provided</span>
        </h1>
        <div className={style.FlexDiv}>
          {services?.map((item, index) => (
            <div key={index} className={style.CardDiv}>
              <img className={style.CardIcon} src={item.img}></img>
              <div className=''>
                <h1 className={style.Title}>{item.title}</h1>
                <p className={style.Description}>{item.dec}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceProvider;
