import React from 'react';
import FrameComponent from './FrameComponent';
import PropTypes from 'prop-types';

const FrameComponent1 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center py-0 pl-5 pr-6 box-border max-w-full text-left text-29xl text-white font-inter ${className}`}
    >
      <div className='w-[1136px] flex flex-col items-start justify-start gap-[90px] max-w-full lg:gap-[45px] mq750:gap-[22px]'>
        <div className='w-[712px] rounded-26xl [background:linear-gradient(135deg,_#d27200,_#502b00)] flex flex-col items-start justify-start pt-[90px] pb-[82px] pl-[78px] pr-5 box-border relative gap-4 max-w-full z-[2] mq450:pt-[58px] mq450:pb-[53px] mq450:box-border mq750:pl-[39px] mq750:box-border'>
          <div className='w-[712px] h-[374px] relative rounded-26xl [background:linear-gradient(135deg,_#d27200,_#502b00)] hidden max-w-full z-[0]' />
          <h1 className='m-0 w-[249px] relative text-inherit inline-block z-[3] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
            <p className='m-0'>
              <b>{`Problem `}</b>
            </p>
            <p className='m-0'>Statement</p>
          </h1>
          <div className='h-[70px] relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[3] mq450:text-lgi mq450:leading-[28px]'>
            <p className='m-0'>Here are the challenges which we faced</p>
            <p className='m-0'>at the time of develop the application</p>
          </div>
          <img
            className='w-[60px] h-[73px] absolute !m-[0] top-[-46px] right-[86px] z-[3]'
            loading='lazy'
            alt=''
          />
        </div>
        <div className='self-stretch flex flex-row items-start justify-start py-0 pl-[3px] pr-0 box-border max-w-full text-center text-13xl'>
          <div className='flex-1 flex flex-col items-end justify-start gap-[23px] max-w-full'>
            <div className='self-stretch flex flex-row flex-wrap items-start justify-start gap-8 max-w-full mq750:gap-4'>
              <FrameComponent
                prop='1'
                manualEffortAnd='Manual Effort and'
                lateNightStruggles='Late-Night Struggles'
                usersFacedChallenges='Users faced challenges'
                stayingUpLateOrWakingEarly='staying up late or waking early'
                toManuallyBookDesired='to manually book desired'
                tennisAndGolfCourtSlots='Tennis and Golf Court slots'
                asSoonAsTheyBecame='as soon as they became'
                availableAtMidnightPST='available at midnight PST.'
              />
              <div className='flex-1 flex flex-col items-end justify-start gap-[227px] min-w-[356px] max-w-full text-17xl text-gray-200 mq450:gap-[57px] mq450:min-w-full mq750:gap-[113px]'>
                <div className='self-stretch flex flex-row items-start justify-start gap-[31px] mq750:flex-wrap mq750:gap-[15px]'>
                  <FrameComponent
                    propFlex='1'
                    propMinWidth='175px'
                    propBackgroundColor='#9f5701'
                    propBackgroundColor1='#9f5701'
                    prop='2'
                    propWidth='unset'
                    propMinWidth1='21px'
                    propAlignSelf='stretch'
                    manualEffortAnd='High Demand and'
                    lateNightStruggles='Limited Availability'
                    propHeight='unset'
                    propDisplay='unset'
                    usersFacedChallenges='Popular court slots were in high'
                    stayingUpLateOrWakingEarly='demand and often got fully'
                    toManuallyBookDesired='booked within minutes of'
                    tennisAndGolfCourtSlots='becoming available, leaving'
                    asSoonAsTheyBecame='users frustrated and unable to'
                    availableAtMidnightPST='secure their preferred times.'
                  />
                  <FrameComponent
                    propFlex='unset'
                    propMinWidth='248px'
                    propBackgroundColor='#bb6601'
                    propBackgroundColor1='#bb6601'
                    prop='3'
                    propWidth='22px'
                    propMinWidth1='22px'
                    propAlignSelf='unset'
                    manualEffortAnd='Inefficient Booking'
                    lateNightStruggles='Processes'
                    propHeight='unset'
                    propDisplay='unset'
                    usersFacedChallenges='Existing booking systems did'
                    stayingUpLateOrWakingEarly='not provide an efficient way'
                    toManuallyBookDesired='for users to automate the'
                    tennisAndGolfCourtSlots='booking process, requiring'
                    asSoonAsTheyBecame='constant manual monitoring'
                    availableAtMidnightPST='and quick response times.'
                  />
                </div>
                <div className='self-stretch flex flex-row items-start justify-center py-0 pl-7 pr-5'>
                  <h1 className='m-0 w-[164px] relative text-inherit font-semibold font-[inherit] inline-block mq450:text-3xl mq1050:text-10xl'>
                    Solutions
                  </h1>
                </div>
              </div>
              <div className='w-[262px] flex flex-col items-start justify-start gap-10 mq450:gap-5'>
                <div className='rounded-3xs bg-chocolate-200 flex flex-row items-start justify-start py-[15px] px-[23px] z-[1]'>
                  <div className='h-[69px] w-[69px] relative rounded-3xs bg-chocolate-200 hidden' />
                  <b className='relative inline-block min-w-[22px] z-[1] mq450:text-lgi mq1050:text-7xl'>
                    4
                  </b>
                </div>
                <div className='self-stretch flex flex-col items-start justify-start gap-[13px] text-left text-3xl text-gray-200'>
                  <div className='relative leading-[30px] font-semibold z-[1] mq450:text-lg mq450:leading-[24px]'>
                    <p className='m-0'>User Frustration and</p>
                    <p className='m-0'>Disappointment</p>
                  </div>
                  <div className='self-stretch relative text-lg leading-[30px] text-dimgray-200 z-[1]'>
                    Users often experienced disappointment and frustration due
                    to missing out on desired slots, leading to a negative
                    booking experience.
                  </div>
                </div>
              </div>
            </div>
            <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 text-xl text-gray-200'>
              <div className='w-[728px] relative inline-block mq450:text-base'>
                Devtrust implemented a comprehensive solution to address these
                challenges
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent1.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent1;
