import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const FrameComponent4 = ({ className = '' }) => {
  return (
    <section
      className={`font-Inter font-inter text-4xl self-stretch flex flex-row items-start justify-center pt-0 px-5 pb-[161px] box-border max-w-full text-center text-17xl text-[#282828] lg:pb-[105px] lg:box-border mq450:pb-11 mq450:box-border mq1050:pb-[68px] mq1050:box-border ${className}`}
    >
      <div className='w-[1140px] flex flex-col items-center justify-center gap-[95px] max-w-full lg:gap-[47px] mq750:gap-6'>
        <div className='self-stretch flex flex-row items-center justify-center py-0 pl-[23px] pr-5 box-border max-w-full'>
          <div className='w-full flex flex-col items-center justify-center gap-[23px] max-w-full'>
            <h1 className='m-0 relative text-inherit font-semibold font-[inherit] mq450:text-3xl mq1050:text-10xl text-nowrap'>
              Billing Zero&apos;s user-friendly interface
            </h1>
            <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5 text-xl'>
              <div className='relative mq450:text-base'>
                Visual Representation of Billing Zero&apos;s Features
              </div>
            </div>
          </div>
        </div>
        <div className='self-stretch flex flex-row items-start justify-start pt-0 px-0 pb-[17px] box-border max-w-full'>
          <div className='h-[1048px] flex-1 flex flex-row items-start justify-start relative max-w-full'>
            <div className='h-full w-full absolute !m-[0] top-[0px] right-[0px] bottom-[0px] left-[0px]'>
              <img
                className='absolute top-[0px] left-[0px] rounded-3xs w-[670px] h-[441px] object-cover'
                alt=''
                src='/caseStudies/Billing-Zero/image-5@2x.png'
              />
              <img
                className='absolute top-[140px] left-[470px] rounded-3xs w-[670px] h-[441px] object-cover z-[1]'
                loading='lazy'
                alt=''
                src='/caseStudies/Billing-Zero/image-4@2x.png'
              />
              <img
                className='absolute top-[501px] left-[0px] rounded-3xs w-[670px] h-[441px] object-cover z-[2]'
                alt=''
                src='/caseStudies/Billing-Zero/image-3@2x.png'
              />
              <img
                className='absolute top-[607px] left-[470px] rounded-3xs w-[670px] h-[441px] object-cover z-[3]'
                alt=''
                src='/caseStudies/Billing-Zero/image-4-1@2x.png'
              />
            </div>
          </div>
        </div>
        <div className='w-[822px] flex flex-col items-end justify-start gap-[37px] max-w-full mq450:gap-[18px]'>
          <div className='flex flex-col items-end justify-start gap-3.5 text-[32px] text-[#282828]'>
            <h2 className='m-0 relative text-inherit font-semibold font-[inherit] mq450:text-lgi mq1050:text-7xl'>
              Discover how DEVtrust can assist you build your idea
            </h2>
            <div className='flex flex-row items-start justify-end py-0 pl-[61px] pr-[60px] text-xl text-dimgray-200 mq1050:pl-[30px] mq1050:pr-[30px] mq1050:box-border'>
              <div className='w-[701px] relative inline-block mq450:text-base'>
                {`Contact us for more information or to `}
                <span className='[text-decoration:underline]'>
                  schedule a meeting
                </span>{' '}
                with our experts
              </div>
            </div>
          </div>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-[21px] pr-5'>
            <Button
              className='h-[42px] w-[143px]'
              disableElevation
              // onClick={handleContactLink}
              variant='contained'
              sx={{
                textTransform: 'none',
                color: '#fff',
                fontSize: '18',
                background: '#474747',
                borderRadius: '0px 0px 0px 0px',
                '&:hover': { background: '#474747' },
                width: 143,
                height: 42,
              }}
            >
              Contact us
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent4.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent4;
