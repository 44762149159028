import React from 'react';
import PropTypes from 'prop-types';

const HeaderContent = ({ className = '' }) => {
  return (
    <div
      className={`w-[1334px] flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-[124px] max-w-full text-center text-45xl text-lightseagreen font-inter lg:gap-[62px] mq750:gap-[31px] mq450:gap-[15px] mq1050:flex-wrap ${className}`}
    >
      <div className='w-[431px] flex flex-col items-start justify-start pt-[246px] px-0 pb-0 box-border min-w-[431px] max-w-full mq750:min-w-full mq450:pt-40 mq450:box-border mq1050:flex-1'>
        <div className='self-stretch flex flex-col items-start justify-start gap-[30px]'>
          <div className='self-stretch flex flex-col items-start justify-start gap-[25px]'>
            <h1 className='m-0 self-stretch relative text-inherit font-bold font-inherit mq450:text-19xl mq1050:text-32xl'>
              Air Concierge
            </h1>
            <div className='self-stretch flex flex-row items-start justify-center py-0 pl-5 pr-[21px]'>
              <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
            </div>
          </div>
          <div className='flex flex-row items-start justify-start py-0 pl-10 pr-[41px] text-xl text-dimgray-200'>
            <div className='w-[350px] relative leading-[30px] inline-block mq450:text-base mq450:leading-[24px]'>
              {' '}
              Building Air Concierge with DEVtrust
            </div>
          </div>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Air-Concierge/mask-group@2x.png'
      />
    </div>
  );
};

HeaderContent.propTypes = {
  className: PropTypes.string,
};

export default HeaderContent;
