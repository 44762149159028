import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent = ({ className = '' }) => {
  return (
    <div
      className={`font-Inter flex flex-row items-start justify-start py-0 pl-5 pr-0 box-border gap-[130px] w-full text-center text-45xl text-darkcyan font-inter lg:gap-[65px] mq450:gap-4 mq750:gap-8 mq1050:flex-wrap ${className}`}
    >
      <div className='w-1/2 flex flex-col items-start justify-start pt-[230px] px-0 pb-0 box-border min-w-[419px] max-w-full mq450:pt-[149px] mq450:box-border mq750:min-w-full mq1050:flex-1 '>
        <div className='self-stretch flex flex-col items-start justify-start gap-7 text-nowrap pr-30'>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5'>
            <h1 className='m-0 relative font-bold font-inherit mq450:text-19xl mq1050:text-32xl text-[#1D9B95] text-[65px] font-inter'>
              Krugg
            </h1>
          </div>
          <div className='self-stretch flex flex-row items-start justify-center py-0 pl-5 pr-[21px]'>
            <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
          </div>
          <h3 className='self-stretch relative leading-[30px] mq450:text-base mq450:leading-[24px] text-[#5A5A5A] font-normal text-xl font-Inter'>
            <p className='m-0'>Transforming Home Improvement and Decor</p>
            <p className='m-0'>with Advanced Technology</p>
          </h3>
        </div>
      </div>
      <img
        className='h-[655px] flex-1 relative max-w-full overflow-hidden object-cover min-w-[493px] mq750:min-w-full'
        loading='lazy'
        alt=''
        src='/caseStudies/Krugg/mask-group@2x.png'
        style={{ borderBottomLeftRadius: '45px' }}
      />
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
