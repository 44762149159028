import React from 'react';
import FrameComponent from './FrameComponent';
import PropTypes from 'prop-types';

const FrameComponent3 = ({ className = '' }) => {
  return (
    <section
      className={`self-stretch flex flex-row items-start justify-center pt-0 px-0 pb-[11px] box-border [row-gap:20px] max-w-full text-left text-29xl text-white font-inter lg:flex-wrap ${className}`}
    >
      <img
        className='w-[490px] relative rounded-tl-none rounded-tr-26xl rounded-b-none max-h-full object-cover max-w-full lg:flex-1'
        loading='lazy'
        alt=''
        src='/caseStudies/Planwell/rectangle-3422@2x.png'
      />
      <div className='flex-1 flex flex-col items-start justify-start pt-[89px] px-0 pb-0 box-border min-w-[617px] max-w-full lg:pt-[58px] lg:box-border mq750:pt-[38px] mq750:box-border mq750:min-w-full'>
        <div className='self-stretch flex flex-col items-start justify-start max-w-full'>
          <div className='self-stretch [background:linear-gradient(135deg,_#2c4bd7,_#09085e)] flex flex-col items-start justify-start pt-[42px] pb-[43px] pl-[62px] pr-5 box-border relative gap-4 max-w-full z-[1] mq750:pl-[31px] mq750:box-border'>
            <div className='w-[950px] h-[252px] relative [background:linear-gradient(135deg,_#2c4bd7,_#09085e)] hidden max-w-full z-[0]' />
            <img
              className='w-[43.5px] h-[50px] absolute !m-[0] top-[calc(50%_-_25px)] right-[35.5px] object-cover z-[2]'
              alt=''
              src='/caseStudies/Planwell/vector-2-1@2x.png'
            />
            <h1 className='m-0 w-[455px] h-[116px] relative text-inherit inline-block max-w-full z-[2] font-[inherit] mq450:text-10xl mq1050:text-19xl'>
              <p className='m-0'>
                <b>{`Results `}</b>
              </p>
              <p className='m-0'>Which we achieved</p>
            </h1>
            <div className='relative text-5xl leading-[35px] font-medium inline-block max-w-full z-[2] mq450:text-lgi mq450:leading-[28px]'>
              The solutions delivered impactful outcomes
            </div>
          </div>
          <div className='self-stretch bg-gray-300 flex flex-col items-start justify-start pt-[75px] px-[62px] pb-[83px] box-border gap-[55px] max-w-full z-[2] text-xl mq450:gap-[27px] mq450:pt-8 mq450:pb-[35px] mq450:box-border mq1050:pt-[49px] mq1050:px-[31px] mq1050:pb-[54px] mq1050:box-border'>
            <img
              className='w-[950px] h-[835px] relative hidden max-w-full'
              alt=''
              src='/caseStudies/Planwell/rectangle-3424.svg'
            />
            <FrameComponent
              swiftDecisionMaking='Swift Decision-Making'
              usersCanNowObtainPersonalized='Users can now obtain personalized retirement, home affordability, and college savings recommendations within minutes, significantly reducing the time spent on complex financial planning.'
            />
            <FrameComponent
              swiftDecisionMaking='Increased Accessibility and Usage'
              propDisplay='inline-block'
              usersCanNowObtainPersonalized='The transition from Excel spreadsheets to a web-based platform has improved accessibility, leading to 40% increase in user engagement and adoption across different demographics.'
            />
            <FrameComponent
              swiftDecisionMaking='Empowered Financial Planning'
              propDisplay='unset'
              usersCanNowObtainPersonalized="Planwell's 'what-if' analysis capabilities empower users to make informed financial decisions by exploring various scenarios and understanding the consequences of their choices."
            />
            <FrameComponent
              swiftDecisionMaking='Enhanced User Satisfaction'
              propDisplay='unset'
              usersCanNowObtainPersonalized="80% of the feedback indicates high user satisfaction due to the tool's ease of use, real-time updates, and comprehensive insights provided through interactive graphs and detailed PDF reports."
            />
          </div>
        </div>
      </div>
    </section>
  );
};

FrameComponent3.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent3;
