import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const GroupComponent = ({
  className = '',
  propWidth,
  propFlex,
  propMinWidth,
  instantPricing,
  propDisplay,
  propAlignSelf,
  reducedWaitTimesBoostingTransa,
  prop,
}) => {
  const groupDivStyle = useMemo(() => {
    return {
      width: propWidth,
    };
  }, [propWidth]);

  const descriptionsStyle = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
    };
  }, [propFlex, propMinWidth]);

  const instantPricingStyle = useMemo(() => {
    return {
      display: propDisplay,
    };
  }, [propDisplay]);

  const reducedWaitTimesContainerStyle = useMemo(() => {
    return {
      alignSelf: propAlignSelf,
    };
  }, [propAlignSelf]);

  return (
    <div
      className={`flex flex-row items-start justify-start gap-3 max-w-full text-left text-xl text-white font-inter ${className}`}
      style={groupDivStyle}
    >
      <div className='flex flex-col items-start justify-start pt-[3px] px-0 pb-0'>
        <img
          className='w-[18px] h-[18px] relative overflow-hidden shrink-0 object-contain'
          loading='lazy'
          alt=''
          src='/caseStudies/Draydex/bxuparrow@2x.png'
        />
      </div>
      <div
        className='flex flex-col items-start justify-start gap-3.5 shrink-0 max-w-[calc(100%_-_30px)]'
        style={descriptionsStyle}
      >
        <b className='relative mq450:text-base' style={instantPricingStyle}>
          {instantPricing}
        </b>
        <div
          className='relative text-lg leading-[30px]'
          style={reducedWaitTimesContainerStyle}
        >
          <span>{reducedWaitTimesBoostingTransa}</span>
          <span className='font-semibold text-darkorange'>
            <span className='text-[#FF9802]'>{prop}</span>
            <span className='text-white'>.</span>
          </span>
        </div>
      </div>
    </div>
  );
};

GroupComponent.propTypes = {
  className: PropTypes.string,
  instantPricing: PropTypes.string,
  reducedWaitTimesBoostingTransa: PropTypes.string,
  prop: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propFlex: PropTypes.any,
  propMinWidth: PropTypes.any,
  propDisplay: PropTypes.any,
  propAlignSelf: PropTypes.any,
};

export default GroupComponent;
