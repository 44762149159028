import React from 'react';
import ThankYou from '../../assets/images/ThankYou.svg';
import Home from '../../assets/images/Home.svg';
import { useNavigate } from 'react-router-dom';
const Success = () => {
  const navigate = useNavigate();
  const backTohome = () => {
    navigate('/');
  };
  return (
    <div className=' bg-white  py-24'>
      <h1 className='font-Gelasio xl:text-[50px] py-10 pb-2 font-bold text-[40px]  text-center  md:leading-[63.48px] leading-[51px]'>
        Thank{' '}
        <span className='text-custom-green leading-[63.48px] font-Gelasio'>
          You!
        </span>
      </h1>
      <div className='w-full flex justify-center'>
        <p className="font-Inter text-[18px] text-[#828282] font-normal md:w-[540px] w-auto leading-[30px] text-center '">
          Thanks for reaching out! Your message just showed up in our inbox .
          Our team will contact to you soon!
        </p>
      </div>
      <div className='flex  gap-x-8 justify-center p-7'>
        <img src={ThankYou} width='450px' height='190px'></img>
      </div>
      <div className='flex justify-center xl:mt-14 mt-7'>
        <button
          className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
          onClick={backTohome}
        >
          <span className='absolute bottom-0 left-0 flex w-0 h-full mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
          <span className='relative group-hover:text-white text-lg font-semibold font-[Inter] leading-[21px]'>
            <div className='flex justify-center items-center gap-2'>
              <img src={Home} alt='backtohome' />
              <span>Back to Home</span>
            </div>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Success;
