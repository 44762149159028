import React from 'react';
import PropTypes from 'prop-types';

const FrameComponent = ({ className = '' }) => {
  return (
    <div
      className={` flex flex-row items-start justify-between py-[0rem] pr-[0rem] pl-[1.25rem] box-border gap-[6.937rem] w-full text-center text-[5.25rem] text-darkslategray-300 font-inter lg:flex-wrap mq450:gap-[1.75rem] mq750:gap-[3.438rem] ${className}`}
    >
      <div className='w-1/2 flex flex-col items-start justify-start pt-[194px] px-0 pb-0 box-border min-w-[493px] max-w-full lg:flex-1 mq750:min-w-full mq450:pt-[126px] mq450:box-border'>
        <div className='self-stretch flex flex-col items-center justify-start  max-w-full mq450 gap-[1.188rem]'>
          <div className='self-stretch flex flex-row items-start justify-center pt-[0rem] pb-[0.437rem] pr-[0.437rem] pl-[0.5rem] box-border max-w-full'>
            <div className='flex flex-col items-center justify-start gap-[0.75rem] max-w-full'>
              <div className='self-stretch flex flex-row items-start justify-center py-[0rem] pr-[2.5rem] pl-[2.437rem]'>
                <h1 className='m-0 w-[375px] relative font-bold font-[inherit] inline-block mq450:text-6xl mq1050:text-23xl text-[#00337F] text-[84px] pl-12'>
                  Logistics
                </h1>
              </div>
              <div className='self-stretch relative  text-[#282828] font-normal text-2xl ml-20'>
                <p className='m-0 whitespace-nowrap'>{`Revolutionizing Rate Management `}</p>
                <p className='m-0'>in the Freight Industry</p>
              </div>
            </div>
          </div>
          <div className='self-stretch flex flex-row items-start justify-center py-0 px-5 ml-28'>
            <div className='h-px w-[181px] relative border-lightgray border-t-[1px] border-solid box-border' />
          </div>
          <div className='h-[60px] relative text-xl leading-[30px]  inline-block mq450:text-base mq450:leading-[24px] font-normal ml-28 text-[#5A5A5A]'>
            <p className='m-0'>
              <span className=''>{`How `}</span>
              <b className='font-bold text-[#5A5A5A] text-xl'>DEVtrust</b>
              <span> Enabled Draydex to Enhance</span>
            </p>
            <p className='m-0'>{`Rate Management and Improve User Experience `}</p>
          </div>
        </div>
      </div>
      <div className='h-[655px] flex-1 relative min-w-[493px] max-w-full mq750:min-w-full '>
        <img
          className='absolute top-[0px] left-[0px] w-full h-full object-cover rounded-b-26xl'
          alt=''
          src='/caseStudies/Draydex/mask-group@2x.png'
          style={{ borderBottomLeftRadius: '45PX' }}
        />
        <img
          className='absolute top-[277px] left-[76px] w-[508px] h-[100px] object-cover z-[1]'
          loading='lazy'
          alt=''
          src='/caseStudies/Draydex/download-2@2x.png'
        />
      </div>
    </div>
  );
};

FrameComponent.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent;
