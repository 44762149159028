import React, { useState } from 'react';
import { FIELD_REQUIRED } from '../../redux/constant/ErrorMessage';
import { submitForm } from '../../utils/index';
import address1 from '../../assets/images/address1.webp';
import address2 from '../../assets/images/address2.webp';
import downloadfile from '../../assets/images/downloadfile.webp';
import cut from '../../assets/images/cut.webp';
import { checkSize, checkEmail } from '../../utils/Validation';
import { useDispatch } from 'react-redux';
import { setAlert, setLoader } from '../../redux/Action/actions';
import { Helmet } from 'react-helmet';
const ContactUs = () => {
  const [data, setData] = useState();
  const [validateErr, setValidateErr] = useState({});
  const [image, setImage] = useState();
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setValidateErr({ ...validateErr, [name]: '' });
    setData({ ...data, [name]: value });
  };
  const isValid = () => {
    let valid = true;
    if (data?.name === undefined || data?.name.trim() === '') {
      validateErr.name = FIELD_REQUIRED;
      valid = false;
    }
    if (data?.email === undefined || data?.email.trim() === '') {
      validateErr.email = FIELD_REQUIRED;
      valid = false;
    } else {
      let { status, msg } = checkEmail(data?.email);
      if (status !== undefined) {
        valid = status;
        validateErr.email = msg;
      }
    }
    if (data?.mobile === undefined || data?.mobile.trim() === '') {
      validateErr.mobile = FIELD_REQUIRED;
      valid = false;
    }
    if (data?.subject === undefined || data?.subject.trim() === '') {
      validateErr.subject = FIELD_REQUIRED;
      valid = false;
    }
    if (data?.comment === undefined || data?.comment.trim() === '') {
      validateErr.comment = FIELD_REQUIRED;
      valid = false;
    }
    if (image === undefined) {
      validateErr.image = FIELD_REQUIRED;
      valid = false;
    }
    setValidateErr({ ...validateErr });
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validateData = isValid();
    if (validateData === true) {
      dispatch(setLoader(true));
      let formdata = new FormData();
      formdata.append('fullname', data?.name);
      formdata.append('email', data?.email);
      formdata.append('contact', data?.mobile);
      formdata.append('subject', data?.subject);
      formdata.append('description', data?.comment);
      formdata.append('type', 'contactus');
      for (let value of image) {
        formdata.append('file', value);
      }

      let res = await submitForm(formdata);
      dispatch(setLoader(false));
      if (res.status === 200) {
        dispatch(setAlert({ type: 'success', msg: 'File Sent Successfully.' }));
        setData({});
        setImage({});
        return;
      }
      dispatch(setAlert({ type: 'danger', msg: 'Somethings Went Wrong.' }));
    }
  };

  const checkImage = (files) => {
    let status = true;
    let size = checkSize(files);
    validateErr.image = '';
    if (files.length > 5) {
      validateErr.image = 'Only 5 Images Allowed';
      status = false;
    }
    if (size > 5242880) {
      validateErr.image = 'Image below than 5mb';
      status = false;
    }
    setValidateErr({ ...validateErr });
    return status;
  };

  const deleteImage = (file) => {
    let filterimages = {};
    let ind = 0;
    for (let value of image) {
      if (file.name === value?.name) continue;
      filterimages[ind] = value;
      ind++;
    }

    filterimages.length = ind;
    setImage(filterimages);
  };

  const handleFileUpload = (e) => {
    let files = e.target.files;
    let checkimage = checkImage(files);
    if (checkimage === true) {
      setImage(files);
    }
  };

  return (
    <div className='bg-white xl:py-28 overflow-hidden' data-testid='text'>
      <Helmet
        meta={[
          { content: '', name: 'description' },
          { content: '', name: 'deccription' },
        ]}
        title='ContactUs'
        link={[{ rel: 'preconnect', href: '#' }]}
      ></Helmet>
      <div className='container mx-auto'>
        <h1 className='text-center pt-14 pb-2 text-[50px] font-bold leading-[63.48px] font-Gelasio'>
          Have some <span className='text-[#25C7C7]'>questions?</span>
        </h1>
        <p className='text-lg text-center leading-[30px] font-normal font-[Inter]'>
          Feel free to use the contact form to reach out to us
        </p>
        <div className='flex flex-col  md:flex-row xl:justify-between justify-center items-center gap-5 py-12'>
          <div className='md:w-[50%] w-full '>
            <div className='flex  gap-x-10  '>
              <img
                className='xl:w-[50px] w-auto h-[50px]'
                src={address1}
                alt='address'
              ></img>
              <div>
                <h1 className='xl:text-[24px] text-lg font-semibold font-[Inter] leading-5 pb-5'>
                  Address: (India)
                </h1>
                <h1 className='text-lg pb-5 font-normal xl:w-[363px] w-auto  leading-[30px] font-[Inter] text-[#828282]'>
                  <span className='font-semibold text-[#282828]'>Lucknow</span>
                  <br />
                  CP-6, 3rd Floor, Vikrant Khand, Gomti Nagar Lucknow, Uttar
                  Pradesh, 226010.
                </h1>
                <h1 className='text-lg font-normal pb-2 xl:w-[363px] w-auto leading-[30px] font-[Inter] text-[#828282]'>
                  <span className='font-semibold text-[#282828]'>Noida</span>
                  <br />
                  Green Boulevard 5th Floor, Tower C, Block B, Sector 62,Noida,
                  Uttar Pradesh 201309.
                </h1>
                <h1 className='text-lg font-normal xl:w-[363px] w-auto leading-[30px] font-[Inter] text-[#828282]'>
                  <span className='font-semibold text-[#282828]'>
                    Phone # (India):
                  </span>
                  <br />
                  +91-8447154118
                </h1>
              </div>
            </div>
            <hr className='m-5 block mx-auto border-[1px] my-6 md:w-[369px] w-auto'></hr>
            <div className='flex  gap-x-10 py-2'>
              <img
                className='xl:w-[50px] w-auto h-[50px]'
                src={address2}
                alt='address'
              ></img>
              <div>
                <h1 className='text-[24px] font-semibold font-[Inter] leading-5 '>
                  Address: (US)
                </h1>
                <h1 className='text-lg font-normal xl:w-[379px] w-auto py-3 leading-[30px] font-[Inter] text-[#828282]'>
                  221 E Indianola Ave, Phoenix, Arizona 85012.
                </h1>

                <h1 className='text-lg font-normal xl:w-[363px] w-auto leading-[30px] font-[Inter] text-[#828282]'>
                  <span className='font-semibold text-[#282828]'>
                    Phone # (USA):
                  </span>
                  <br />
                  +1-347-719-4819
                </h1>
              </div>
            </div>
          </div>
          <div className='xl:basis-[42%] basis-none'>
            <form
              onSubmit={(e) => handleSubmit(e)}
              className='bg-white shadow-md px-6 py-9 mb-4'
            >
              <div className='mb-4'>
                <input
                  name='name'
                  value={data?.name || ''}
                  onChange={(e) => handleChange(e)}
                  className='shadow appearance-none border border-[#D5E3EE] rounded w-full py-3 px-3 placeholder:text-[#c4c4c4] text-[#282828] leading-tight focus:outline-none focus:shadow-outline'
                  type='text'
                  placeholder='Full Name'
                />
                {validateErr && (
                  <span
                    className='text-end	block	w-[100%] text-red-600	'
                    role='error'
                  >
                    {validateErr?.name}
                  </span>
                )}
              </div>
              <div className='mb-4'>
                <input
                  name='email'
                  value={data?.email || ''}
                  onChange={(e) => handleChange(e)}
                  className='shadow appearance-none border border-[#D5E3EE] rounded w-full py-3 px-3 placeholder:text-[#c4c4c4] text-[#282828]  leading-tight focus:outline-none focus:shadow-outline'
                  type='text'
                  placeholder='Email Address'
                />
                {validateErr && (
                  <span
                    className='text-end	block	w-[100%] text-red-600	'
                    role='error'
                  >
                    {validateErr?.email}
                  </span>
                )}
              </div>

              <div className='mb-4'>
                <input
                  name='mobile'
                  value={data?.mobile || ''}
                  onChange={(e) => handleChange(e)}
                  className='shadow appearance-none border border-[#D5E3EE] rounded w-full py-3 px-3 placeholder:text-[#c4c4c4] text-[#282828]  leading-tight focus:outline-none focus:shadow-outline'
                  type='number'
                  placeholder='Phone Number'
                />
                {validateErr && (
                  <span
                    className='text-end	block	w-[100%] text-red-600	'
                    role='error'
                  >
                    {validateErr?.mobile}
                  </span>
                )}
              </div>

              <div className='mb-4'>
                <input
                  name='subject'
                  value={data?.subject || ''}
                  onChange={(e) => handleChange(e)}
                  className='shadow appearance-none border border-[#D5E3EE] rounded w-full py-3 px-3 placeholder:text-[#c4c4c4] text-[#282828]  leading-tight focus:outline-none focus:shadow-outline'
                  type='text'
                  placeholder='Subject'
                />
                {validateErr && (
                  <span
                    className='text-end	block	w-[100%] text-red-600	'
                    role='error'
                  >
                    {validateErr?.subject}
                  </span>
                )}
              </div>

              <div className='mb-4'>
                <textarea
                  name='comment'
                  value={data?.comment || ''}
                  onChange={(e) => handleChange(e)}
                  className='shadow appearance-none border rounded w-full py-3 px-3 placeholder:text-[#c4c4c4] text-[#282828]  leading-tight focus:outline-none focus:shadow-outline'
                  type='text'
                  rows='5'
                  placeholder='Comment'
                />
                {validateErr && (
                  <span
                    className='text-end	block	w-[100%] text-red-600	'
                    role='error'
                  >
                    {validateErr?.comment}
                  </span>
                )}
              </div>
              <div className='mb-4'>
                <div className='shadow appearance-none border rounded w-full text-[#c4c4c4]   leading-tight focus:outline-none focus:shadow-outline flex  items-center justify-between  '>
                  <div className='flex gap-x-1 px-2'>
                    {image?.length >= 1
                      ? [...Array(image?.length)].map((item, index) => {
                          const file = image[index];
                          return (
                            <>
                              <img
                                className='rounded h-[35px] w-[35px] object-cover bg-[#828282]'
                                src={URL.createObjectURL(file)}
                                key={index}
                              />
                              <img
                                className='-mt-[8px] -ml-3 h-5 cursor-pointer text-red-600'
                                onClick={() => deleteImage(file)}
                                src={cut}
                              ></img>
                            </>
                          );
                        })
                      : 'Upload Your Files'}
                  </div>
                  <label>
                    <a className='text-white bg-[#25C7C7] cursor-pointer w-auto xl:w-[169px]  xl:text-lg text-sm  leading-[21.78px]  font-medium px-5 py-3 text-center flex justify-center items-center  font-[Inter]'>
                      <img
                        className='mr-2 -ml-1 w-[24.48px] h-[20.33px] '
                        src={downloadfile}
                      ></img>
                      Upload
                    </a>

                    <input
                      onChange={(e) => handleFileUpload(e)}
                      name='file'
                      type='file'
                      placeholder='Upload Your File'
                      className='hidden'
                      multiple
                    />
                  </label>
                </div>
              </div>
              {validateErr.image && (
                <span
                  className='text-end	block	w-[100%] text-red-600	'
                  role='error'
                >
                  {validateErr?.image}
                </span>
              )}
              <p className='font-normal leading-[24px] text-[16px] font-[Inter] p-3 pt-0 text-[#828282]'>
                Maximum file size: 20 MB
              </p>
              <div className='flex items-center justify-center py-2'>
                <button
                  className='px-[25px] py-2.5 relative w-full  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
                  type='submit'
                >
                  <span className='absolute bottom-0 left-0 flex w-0 h-full mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
                  <span className='relative group-hover:text-white text-lg font-medium font-[Inter] leading-[21.78px]'>
                    Start a conversation
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
