import React, { useState } from 'react';
import { FIELD_REQUIRED } from '../../redux/constant/ErrorMessage';
import { checkEmail } from '../../utils/Validation';
import Progressbar from '../../atoms/progress/Progressbar';
const FirstStep = ({ nextStep, formdata, fvalue, steps }) => {
  const [validateErr, setValidateErr] = useState({});
  const handleChange = (event) => {
    formdata(event);
    const { name } = event.target;
    setValidateErr({ ...validateErr, [name]: '' });
  };
  const isValid = () => {
    let valid = true;
    if (fvalue?.fullname === undefined || fvalue?.fullname.trim() === '') {
      validateErr.fullname = FIELD_REQUIRED;
      valid = false;
    }
    if (fvalue?.email === undefined || fvalue?.email.trim() === '') {
      validateErr.email = FIELD_REQUIRED;
      valid = false;
    } else {
      let { status, msg } = checkEmail(fvalue?.email);
      if (status !== undefined) {
        valid = status;
        validateErr.email = msg;
      }
    }
    if (fvalue?.company === undefined || fvalue?.company.trim() === '') {
      validateErr.company = FIELD_REQUIRED;
      valid = false;
    }
    if (fvalue?.message === undefined || fvalue?.message.trim() === '') {
      validateErr.message = FIELD_REQUIRED;
      valid = false;
    }

    setValidateErr({ ...validateErr });
    return valid;
  };
  const formsubmit = (e) => {
    e.preventDefault();
    if (isValid() !== false) {
      nextStep();
    }
  };

  return (
    <div className='container mx-auto xl:py-28 py-24 '>
      <h1 className='text-center capitalize font-Gelasio font-bold xl:text-[40px] lg:text-[30px] text-[30px] leading-[63.48px]'>
        Let’s get <span className='text-[#25C7C7]'>going!</span>
      </h1>
      <div className='flex justify-center py-5 pb-8 '>
        <Progressbar steps={steps} nextStep={nextStep} />
      </div>
      <div className='block mx-auto xl:p-10 md:p-8 p-5 rounded-lg  bg-[#FCFCFC] max-w-3xl xl:my-8 my-5'>
        <form
          className=''
          onSubmit={(e) => {
            formsubmit(e);
          }}
        >
          <div className='form-group xl:mb-4 mb-2'>
            <input
              type='text'
              className='form-control block
        w-full
        px-3
        py-2
        font-Inter
        text-base
        font-normal
        text-gray-700
        bg-white 
        border border-solid border-[#E3E3E3]
        rounded
        m-0
        focus:text-gray-700 focus:bg-white focus:outline-none'
              name='fullname'
              value={fvalue?.fullname}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder='Full Name'
            />
            {validateErr && (
              <span className='text-start	block	w-[100%] text-red-600	'>
                {validateErr?.fullname}
              </span>
            )}
          </div>
          <div className='form-group xl:mb-4 mb-3'>
            <input
              type='text'
              className='form-control block
       w-full
       px-3
       py-2
       font-Inter
       text-base
       font-normal
       text-gray-700
       bg-white 
       border border-solid border-[#E3E3E3]
       rounded
       m-0
       focus:text-gray-700 focus:bg-white focus:outline-none'
              name='email'
              value={fvalue?.email}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder='Email Address'
            />
            {validateErr && (
              <span className='text-start	block	w-[100%] text-red-600	'>
                {validateErr?.email}
              </span>
            )}
          </div>
          <div className='form-group xl:mb-4 mb-3'>
            <input
              type='text'
              className='form-control block
       w-full
       px-3
       py-2
       font-Inter
       text-base
       font-normal
       text-gray-700
       bg-white 
       border border-solid border-[#E3E3E3]
       rounded
       m-0
       focus:text-gray-700 focus:bg-white focus:outline-none'
              name='company'
              value={fvalue?.company}
              onChange={(e) => {
                handleChange(e);
              }}
              placeholder='Company Name'
            />
            {validateErr && (
              <span className='text-start	block	w-[100%] text-red-600	'>
                {validateErr?.company}
              </span>
            )}
          </div>
          <div className='form-group xl:mb-4 mb-3'>
            <textarea
              className='
        form-control
        resize-none
        block
        w-full
        px-3
        py-2
        font-Inter
        text-base
        font-normal
        text-gray-700
        bg-white 
        border border-solid border-[#E3E3E3]
        rounded
        m-0
        focus:text-gray-700 focus:bg-white focus:outline-none
      '
              id='exampleFormControlTextarea13'
              rows='3'
              placeholder='We are curious, how did you hear about us?'
              name='message'
              value={fvalue?.message}
              onChange={(e) => {
                handleChange(e);
              }}
            ></textarea>
            {validateErr && (
              <span className='text-start	block	w-[100%] text-red-600	'>
                {validateErr?.message}
              </span>
            )}
          </div>

          <div className='flex justify-center   mt-5'>
            <button className='px-[25px] py-2.5 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'>
              <span className='absolute bottom-0 left-0 flex w-0 h-full mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
              <span className='relative group-hover:text-white text-lg font-semibold font-[Inter] leading-[21px]'>
                Get Started
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FirstStep;
