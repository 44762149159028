import React from 'react';
import Aanalysis from '../../assets/images/servicesimg1.webp';
import sideIcon from '../../assets/images/leftTrangle.webp';
import style from './Summury.module.css';

function Summary() {
  const handleScroll = () => {
    window.scroll({
      top: 6800,
      behavior: 'smooth',
    });
  };
  return (
    <div className={`${style.Summary} hidden xl:block lg:block`}>
      <div className={`${style.SummaryMain}`}>
        <div className='' data-aos='fade-right'>
          <div className={`${style.SummaryRightContent}`}>
            <h3 className={`${style.SummaryHeadingOne}`}>DEVtrust is a</h3>
            <h3 className={`${style.SummarysecondHeadingOne}`}>
              <span className='text-custom-green text-[51px]'>
                {' '}
                Full-Service{' '}
              </span>
            </h3>

            <h3 className={`${style.SummaryHeadingTwo}`}>
              software&nbsp;development agency offering solutions businesses
              deserve.
            </h3>
            <p className={`${style.SummaryPara}`}>
              Be it a start-up or an established enterprise, we strive to exceed
              expectations by working with a razor-sharp focus on craftsmanship
              & communication.
            </p>
          </div>
        </div>
        <div className='' data-aos='fade-left'>
          <div className={`${style.RightCardOne}`}></div>
          <br />
          <div className={`${style.RightCardTwo}`}>
            <img className={`${style.CardTwoImage}`} src={Aanalysis} />
            <h5 className={`${style.CardTwoTextOne}`}>How Can We Help</h5>
            <h3 className={`${style.CardTwoTextTwo}`}>Your business to Grow</h3>
            <button
              className='py-2.5 xl:px-[25px] md:px-3	xl:mt-10 md:mt-4 relative  group overflow-hidden font-medium bg-[#474747] text-[#FFFFFF] inline-block'
              onClick={() => handleScroll()}
            >
              <span className='absolute bottom-0 left-0 flex h-full w-0 mb-0 transition-all duration-700 ease-out transform translate-x-0 bg-[#4A4F4F]  group-hover:w-full opacity-90'></span>
              <span className='relative group-hover:text-white xl:text-lg text-base block w-full font-normal font-[Inter] leading-[21px]'>
                Get In Touch
              </span>
            </button>
          </div>
          <img
            className={`${style.SideIcon}`}
            width='100'
            height='100'
            src={sideIcon}
          />
        </div>
      </div>
    </div>
  );
}

export default Summary;
